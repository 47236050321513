import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation, Route } from 'react-router-dom';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import RealTimeCloud from './realTimeCloud/realTimeCloud';
import Media from './media/media';
import styles from './index.less';

const Index: FC = (): ReactElement => {
    const history: any = useHistory();
    const location: any = useLocation();
    const options: any[] = [
        {
            key: 'problem',
            label: '实时音视频'
        },
        {
            key: 'cloud',
            label: '云端录制'
        },
        {
            key: 'media',
            label: '输入在线媒体流'
        },
    ];
    const [selectedKeys, setSelectedKeys]: [string, Function] = useState('problem');

    const handleClick: Function = (e: MenuInfo): void => {
        setSelectedKeys(e.key);
        if (e.key ==='problem') {
            history.push(`/content/problem`, { gType: 1 });
        } else {
            history.push(`/content/problem/${e.key}`, { gType: 2 });
        }
    };

    useEffect((): void => {
        const path: Array<string> = location.pathname.split('/');
        setSelectedKeys(path[path.length - 1]);
    }, [location.pathname]);
    
    return (
        <div>
            <Menu onClick={ (e) => handleClick(e) } selectedKeys={ [selectedKeys] } mode="horizontal">
                {options.map((item: any, index: number): ReactElement => {
                    return (<Fragment key={ index }>
                        <Menu.Item key={ item.key } className={ styles.menuItem }>{ item.label } { item.num }</Menu.Item>
                    </Fragment>)
                })}
            </Menu>
            <Route path='/content/problem/cloud' component={ RealTimeCloud } exact />
            <Route path='/content/problem/media' component={ Media } exact />
            <Route path='/content/problem' component={ RealTimeCloud } exact />
        </div>
    )
};

export default Index;