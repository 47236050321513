import { CSSProperties, FC, ReactElement, useEffect, useState } from 'react';
import { Descriptions, Input, Button, Modal, Table, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { moneyComma } from '../../../../../../api/index';
import { findPermissions } from '../../../../../../api/permiss';
import { SearchOutlined } from '@ant-design/icons';
import { Option } from '../../../../../../axios/axios-types';
import axios from '../../../../../../axios/axios';
import styles from './index.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

enum VerifyStatus {never, '待审核', '已通过', '未通过'};

const labelStyle: CSSProperties = { 
    width: '80px', 
    textAlign: 'right', 
    fontSize: '12px',
    display: 'inline-block'
};

const Index: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();

    const [searchParam, setSearchParam]: [string, Function] = useState('');
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [url, setUrl]: [string, Function] = useState('getMachList');
    const [current, setCurrent]: [number, Function] = useState(1);
    const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [visible, setVisible]: [boolean, Function] = useState(false);
	const [record, setRecord]: [any, Function] = useState({});
    const [confirmLoading, setConfirmLoading]: [boolean, Function] = useState(false);
    const [modalTitle, setModalTitle]: [string, Function] = useState('');
    const [modalType, setModalType]: [number, Function] = useState(1);
    const [isSearch, setIsSearch]: [boolean, Function] = useState(false);

    
    
    useEffect((): void => {
        getMachList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current, url]);

    const getMachList: Function = async (): Promise<void> => {
        const option: Option = {
            url: `/primgr/${url}`,
            data: {
                uid: location.state.uid,
                oem: location.state.oem,
                pageNum: current,
                pageSize: pageSize,
                searchParam,
            },
            method: 'POST'
        };
        setTableLoading(true);
        url === 'searchMachList' && setLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        url === 'searchMachList' && setLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.licType = item.licType === 2? '混合云' : item.licType === 3? '私有云' : item.licType;
                item.verifyStatus = VerifyStatus[item.verifyStatus];
                item.totalAmount = moneyComma(item.totalAmount);
            });
            if (result.data.data.isAddMach === 2 && result.data.data.list.length > 0) {
                result.data.data.list[0].isAddMach = true;
            }
            setTotal(result.data.data.totalNumber);
            setTableData(result.data.data.list);
        } else {
            message.error('数据获取失败');
        }
    };

    const handleSearch: Function = (): void => {
        if (searchParam) {
            setUrl('searchMachList');
            setIsSearch(true);
            setCurrent(1);
        } else {
            message.error('请输入内容');
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleOperation: Function = (record: any, index: number): void => {
        setRecord(record);
        setModalType(index);
        setVisible(true);
        if (index === 1) {
            setModalTitle('删除服务');
        } else if (index === 2) {
            setModalTitle('编辑服务');
        }
    };

    const handleOk: Function = (): void => {
        if (modalType === 1) {
            handleDeal({
                url: '/primgr/deleteMach',
                data: { machId: record.machId },
                success: '删除成功',
                error: '删除失败'
            });
        } else if (modalType === 2) {
            handleDeal({
                url: '/primgr/updateMach',
                data: record,
                success: '服务器信息修改成功',
                error: '服务器信息修改失败'
            });
        }
    };

    const handleDeal: Function = async (config: any): Promise<void> => {
        const option: Option = {
            url: config.url,
            data: config.data,
            method: 'POST'
        };
        setConfirmLoading(true);
        const result: Result = await axios(option);
        setConfirmLoading(false);
        if (result.data.code === 0) {
            getMachList();
            setVisible(false);
            message.success(config.success);
        } else {
            message.error(config.error);
        }
    };

    const handleInputChange: Function = (value: string, type: string): void => {
        const rec: any = JSON.parse(JSON.stringify(record));
        rec[type] = value;
        setRecord(rec);
    };

    const handleView: Function = (record: any): void => {
        history.push('/content/private/detail/server/detail', 
        Object.assign(location.state, record, { sc: location.state.perm.jqxq + 1  }));
    };

    const handleSearchBack: Function = (): void => {
        setSearchParam('');
        setUrl('getMachList');
        setIsSearch(false);
    };

    const handleAssociated: Function = async (record: any): Promise<void> => {
        const option: Option = {
            url: '/primgr/updateServiceByMId',
            data: {
                machId: record.machId,
                oem: record.oem
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            getMachList();
            message.success("关联成功");
        } else {
            message.error("关联失败");
        }
    };
    return (
        <div className={ styles.index }>
            <div className={ styles.search }>
                <Input className={ styles.input } value={ searchParam } onChange={ (e) => setSearchParam(e.target.value) } placeholder="请输入内容" />
                <Button className={ styles.searchBtn } loading={ loading } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
                { isSearch && <Button onClick={ () => handleSearchBack() } type="primary">Back</Button> }
            </div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="Mach-ID"  dataIndex="machId" />
                <Column align='center' title="MAC地址" dataIndex="macAddr" />
                <Column align='center' title="内网IP" dataIndex="lanIp" />
                <Column align='center' title="外网IP"  dataIndex="wanIp" />
                <Column align='center' title="域名" dataIndex="gwDomain" />
                <Column align='center' width={ 200 } title="厂商"  dataIndex="oem" />
                <Column align='center' width={ 300 } title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { record.isAddMach && <p onClick={ () => handleAssociated(record) } className={ styles.operation }>associated</p> }
                        { (!record.isAddMach && findPermissions(location.state.perm.jqxq)) && <p onClick={ () => handleView(record) } className={ styles.operation }>查看</p> }
                        { (!record.isAddMach && findPermissions(location.state.perm.bjxx)) && <p onClick={ () => handleOperation(record, 2) } className={ styles.operation }>编辑</p> }
                        { (!record.isAddMach && findPermissions(location.state.perm.sc)) && <p onClick={ () => handleOperation(record, 1) } className={ [styles.operation, styles.delete].join(' ') }>删除</p> }
                    </div>
                ) } />
            </Table>

            <Modal title={ modalTitle } width={ 560 } getContainer={ false } centered 
            confirmLoading={ confirmLoading }
            visible={ visible } onOk={ () => handleOk() } onCancel={ () => setVisible(false)}>
                { modalType === 1 && <p>您确定要删除此服务吗?</p> }
                { modalType === 2 && <Descriptions column={ 1 }>
                    <Descriptions.Item labelStyle={ labelStyle } label="Mach-ID">
                        <Input className={ styles.desInput } value={ record.machId } disabled={ true } />
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="MAC地址">
                        <Input className={ styles.desInput } onChange={ (e) => handleInputChange(e.target.value, 'macAddr') } value={ record.macAddr } placeholder="Please enter the MAC address" />
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="内网IP">
                        <Input className={ styles.desInput } onChange={ (e) => handleInputChange(e.target.value, 'lanIp') } value={ record.lanIp } placeholder="Enter the Intranet IP address" />
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="外网IP">
                        <Input className={ styles.desInput } onChange={ (e) => handleInputChange(e.target.value, 'wanIp') } value={ record.wanIp } placeholder="Please enter the external IP address" />
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="域名">
                        <Input className={ styles.desInput } onChange={ (e) => handleInputChange(e.target.value, 'gwDomain') } value={ record.gwDomain } placeholder="Please fill in the domain name" />
                    </Descriptions.Item>
                </Descriptions> }
            </Modal>
        </div>
    )
};

export default Index;