import { FC, ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './monitoringHorse.less';

interface IProps {
    show: boolean;
    change: Function;
};

const list: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const MonitoringHorse: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();
    const searchData: any = JSON.parse(decodeURI(location.search).split('?')[1]);
    const [swop, setSwop]: [boolean, Function] = useState(false);

    const swopFn: Function = (): void => {
        setSwop(!swop);
        props.change(!swop);
    };

    return (
        <div className={ styles.monitoringHorse }>
            <div className={ [styles.flex, styles.flexColumn].join(' ') }>
            <img src={ require('../../../../../assets/images/bianzu1.png').default } alt="" />
            <div className={ [styles.font, styles.green].join(' ') }>
                <img src={ require('../../../../../assets/images/fasong.svg').default } alt="" />
                <span className={ [styles.fontBlod, styles.feed].join(' ') }>{ swop? searchData.peerId : searchData.uId }</span>
            </div>
            </div>
            <div className={ styles.result }>
            <div className={ styles.lineRound }>
                {
                    list.map((i: number): ReactElement => {
                        return <span key={ i } style={{ left: (i - 1) * 1.3 + 'rem', animationDelay: (i - 1) * 0.06 + 's' }}></span>
                    })
                }
            </div>
            </div>
            <div className={ [styles.flex, styles.flexColumn].join(' ') }>
            <img src={ require('../../../../../assets/images/bianzu2.png').default } alt="" />
            { props.show && <div className={ [styles.qiehuan, styles.flex].join(' ') } onClick={ () => swopFn() }>Switch</div> }
            </div>
            <div className={ styles.result }>
            <div className={ styles.lineRound }>
                {
                    list.map((i: number): ReactElement => {
                        return <span key={ i } style={{ left: (i - 1) * 1.3 + 'rem', animationDelay: (i - 1) * 0.06 + 's' }}></span>
                    })
                }
            </div>
            </div>
            <div className={ [styles.flex, styles.flexColumn].join(' ') }>
            <img src={ require('../../../../../assets/images/bianzu1.png').default } alt="" />
            <div className={ [styles.font, styles.yellow].join(' ') }>
                <img src={ require('../../../../../assets/images/jieshou.svg').default } alt="" />
                <span className={ [styles.fontBlod, styles.feed].join(' ') }>{ swop? searchData.uId : searchData.peerId }</span>
            </div>
            </div>
        </div>
    )
};

export default MonitoringHorse;