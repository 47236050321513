export interface SearchType {
    url: string;
	icon: string;
	name: string;
    type: string;
	number: number;
    perm: number;
}

export interface ItemList {
	[type: string]: Array<SearchType>
}

const option: ItemList = {
    'User related': [
        {
            url: '/content/user/all',
            icon: 'icon-kehu',
            name: '总用户',
            type: 'allUserNum',
            number: 0,
            perm: 11001
        },
        {
            url: '/content/user/recent',
            icon: 'icon-tuichudenglu',
            name: '最近登录用户',
            type: 'recentLogNum',
            number: 0,
            perm: 12005
        },
        {
            url: '/content/user/usage',
            icon: 'icon-shujukanban',
            name: '近期用户用量',
            type: 'recentUserNum',
            number: 0,
            perm: 12020
        },
        {
            url: '/content/user/realname',
            icon: 'icon-shenfenzhengrenzheng',
            name: '实名认证用户',
            type: 'authedUserNum',
            number: 0,
            perm: 12006
        },
        {
            url: '/content/user/registration',
            icon: 'icon-xinyonghu1',
            name: '新注册用户',
            type: 'newUserNum',
            number: 0,
            perm: -1
        },
        // {
        //     url: '/content/user/registered',
        //     icon: 'icon-libao',
        //     name: '新手福利',
        //     type: 'newUserNum',
        //     number: 0,
        //     perm: 12004
        // },
        // {
        //     url: '/content/user/oldUser',
        //     icon: 'icon-guke_consumer',
        //     name: '老用户专属',
        //     type: 'oldUserNum',
        //     number: 0,
        //     perm: 12023
        // }
    ],
    'Application Review': [
        {
            url: '/content/user/invoice',
            icon: 'icon-wj-mxjl',
            name: '发票申请',
            type: 'invoiceNum',
            number: 0,
            perm: 12003
        },
        {
            url: '/content/user/crtification',
            icon: 'icon-ceshishenqing',
            name: '认证申请',
            type: 'authingUserNum',
            number: 0,
            perm: 12007
        }
    ],
    'Charges related to': [
        {
            url: '/content/user/pay',
            icon: 'icon-guanliff',
            name: '付费用户',
            type: 'payUserNum',
            number: 0,
            perm: 12001
        },
        {
            url: '/content/user/oweMoney',
            icon: 'icon-heimingdan',
            name: '欠费用户',
            type: 'arrearsUserNum',
            number: 0,
            perm: 12002
        },
        {
            url: '/content/user/balance',
            icon: 'icon-zhangdan',
            name: '抹平余额用户',
            type: 'zeroUserNum',
            number: 0,
            perm: 12018
        },
        {
            url: '/content/user/giving',
            icon: 'icon-svg-',
            name: '赠送套餐包用户',
            type: 'pkgUserNum',
            number: 0,
            perm: 12017
        },
        {
            url: '/content/user/monthly',
            icon: 'icon-yuejiejifei',
            name: '包月用户',
            type: 'monthlyUserNum',
            number: 0,
            perm: 12011
        },
        {
            url: '/content/user/minimum',
            icon: 'icon-minzhengtubiao1-34',
            name: '最低消费用户',
            type: 'minChargeUserNum',
            number: 0,
            perm: 12012
        },
        {
            url: '/content/user/ladderPricing',
            icon: 'icon-kehu',
            name: '阶梯定价用户',
            type: 'stepUserNum',
            number: 0,
            perm: 12010
        }
    ],
    'Application of the relevant': [
        {
            url: '/content/user/totalApp',
            icon: 'icon-app',
            name: '总应用数量',
            type: 'allAppNum',
            number: 0,
            perm: 12008
        },
        {
            url: '/content/user/added',
            icon: 'icon-jiaoyi',
            name: '开通增值用户',
            type: 'payServiceUserNum',
            number: 0,
            perm: 12009
        },
        {
            url: '/content/user/cloudtorecord',
            icon: 'icon-cloud-link-full',
            name: '开通云录制应用',
            type: 'vodNum',
            number: 0,
            perm: 12013
        },
        {
            url: '/content/user/smallProgram',
            icon: 'icon-xiaochengxu1',
            name: '开通小程序应用',
            type: 'weChatNum',
            number: 0,
            perm: 12014
        },
        {
            url: '/content/user/live',
            icon: 'icon-zhibo',
            name: '开通旁路直播应用',
            type: 'pushNum',
            number: 0,
            perm: 12015
        },
        {
            url: '/content/user/evenWheat',
            icon: 'icon-maikefeng1',
            name: '开通跨房间连麦应用',
            type: 'mutiChanNum',
            number: 0,
            perm: 12016
        },
        {
            url: '/content/user/plate',
            icon: 'icon-baiban',
            name: '开通互动白板应用数',
            type: 'wbAppTotalNum',
            number: 0,
            perm: -1
        },
        {
            url: '/content/user/tokenAuth',
            icon: 'icon-shoujiyanzheng',
            name: 'token验证项目',
            type: 'tokenAppTotalNum',
            number: 0,
            perm: 12019
        },
        {
            url: '/content/user/mediaStream',
            icon: 'icon-bofangwenjian',
            name: '开通在线媒体流应用',
            type: 'streamAppTotalNum',
            number: 0,
            perm: -1
        }
    ],
    'Activity zone': [
        {
            url: '/content/user/halfPrice',
            icon: 'icon-a-1_dierjianbanjia1',
            name: '半价购活动',
            type: 'halfPriceUserNum',
            number: 0,
            perm: -1
        },
        {
            url: '/content/user/receive',
            icon: 'icon-lingqu',
            name: '用户领取',
            type: 'couponUserNum',
            number: 0,
            perm: -1
        }
    ]
};

export default option;