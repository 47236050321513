import { FC, ReactElement, useEffect, useState } from 'react';
import { Input, Button, Tooltip, Select, message, Switch, Table } from 'antd';
import { IssuesCloseOutlined, SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { scriptUrl } from '../../../../../axios/baseUrl';
import { Option } from '../../../../../axios/axios-types';
import { dataReconstitutionFn, dataComparison } from '../../../../../api/monitor';
import { connect } from 'react-redux';
import { MONITORDATA } from '../../../../../store/reducer';
import axios from '../../../../../axios/axios';
import styles from './callAddus.less';
const { Column } = Table;
const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

namespace Antd {
    export const { Option } = Select;
}

interface IProps {
    calladduserdata: any;
    monitorData?: MONITORDATA;
    ADDMONITODATA?: Function;
    REMOVEMONITODATA?: Function;
};

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const tip: string = '当用户数量较大时，用户列表中只显示部分关键用户的数据。您需要手动添加其他用户，以显示其他用户的通话体验';

const CallAddus: FC<IProps> = (props): ReactElement => {
    const [unfold, setUnfold]: [boolean, Function] = useState(false);
    const [os, setOs]: [number, Function] = useState(99);
    const [role, setRole]: [number, Function] = useState(0);
    const [adduser, setAdduser]: [string, Function] = useState('');
    const [addUserDatas, setAddUserDatas]: [Array<any>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [loading, setLoading]: [boolean, Function] = useState(false);
    const [roleList]: [Array<any>, Function] = useState([
        { label: "全部", value: 0 },
        { label: "主播", value: 1 },
        { label: "观众", value: 2 }
    ]);
    const [osList, setOsList]: [Array<any>, Function] = useState([]);

    useEffect((): void => {
        const oOs: Array<string> = ["其他", "android", "ios", "Web", "小程序", "Windows", "linux", "macOS"];
        const oA: Array<any> = [{ label: "全部", value: 99 }];
        oOs.forEach((item: any, index: number): void => {
            oA.push({
                label: item,
                value: index
            });
        });
        setOsList(oA);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect((): void => {
        getChanUserByPlatformAndRoleFn(unfold);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.monitorData]);

    useEffect((): void => {
        getChanUserByPlatformAndRoleFn(unfold);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, pageSize]);

    const searchChanUserFn: Function = async (): Promise<void> => {
        const addus: string = adduser.replace(/\s*/g, "");
        setAdduser(addus);
        if (addus) {
            const oData: any = Object.assign(props.calladduserdata, { chanUId: addus });
            const option: Option = {
                url: '/v2/project/searchChanUser',
                data: oData,
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                const { reconstitutionData } = await dataReconstitutionFn(result.data.data, oData, false, true);
                const oa: MONITORDATA = props.monitorData;
                if (oa.length >= 20) {
                    message.warning('添加人数(最多20人)');
                } else {
                    props.ADDMONITODATA && props.ADDMONITODATA(reconstitutionData[0]);
                    message.success('添加成功');
                }
            } else if (result.data.code === 1042) {
                message.error('用户不存在');
            } else if (result.data.code === 1043) {
                message.error('用户不在该时间段内');
            }
        } else {
            message.warning('输入要添加的用户的ID');
        }
    };

    const litsFn: Function = (): void => {
        setUnfold(!unfold);
        !unfold && getChanUserByPlatformAndRoleFn(!unfold);
    };

    const getChanUserByPlatformAndRoleFn: Function = async (unfold: boolean): Promise<void> => {
        if (unfold) {
            setLoading(true);
            const oData: any = Object.assign(props.calladduserdata, {
                pageNum: current,
                pageSize: 10,
                platform: os,
                role: role
            });
            const option: Option = {
                url: '/v2/project/getChanUserByPlatformAndRole',
                data: oData,
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                setTotal(result.data.data.total);
                const { reconstitutionData } = await dataReconstitutionFn(result.data.data.list, oData, false, false);
                setAddUserDatas(await dataComparison(props.monitorData, reconstitutionData));
            }
            setLoading(false);
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleSelectChange: Function = (val: boolean, index: number): void => {
        const target = JSON.parse(JSON.stringify(addUserDatas));
        target[index].adduser = val;
        setAddUserDatas(target);
        const oa: MONITORDATA = props.monitorData;
        if (val) {
            if (oa.length >= 20) {
                val = false;
                message.warning('添加人数(最多20人)');
            } else {
                props.ADDMONITODATA && props.ADDMONITODATA(addUserDatas[index]);
            }
        } else {
            props.REMOVEMONITODATA && props.REMOVEMONITODATA(addUserDatas[index]);
        }
    };

    return (
        <div className={ styles.callAddus }>
            <div className={ styles.adduserSingle }>
                <div className={ styles.adduserSingleAdd }>
                    <Input placeholder="输入要添加的用户的ID" onChange={ (e) => setAdduser(e.target.value) } className={ styles.input } value={ adduser } />
                    <Button type="primary" className={ styles.adduserSingleAddM } onClick={ () => searchChanUserFn() }>添加用户</Button>
                    <Tooltip placement="top" title={ tip } color='green'>
                        <IssuesCloseOutlined />
                    </Tooltip>
                </div>
                <div className={ styles.adduserUnfold }>
                    <Button type="link" className={ styles.adduserSingleAddM } onClick={ () => litsFn() }>
                        <span className={ styles.pr3 }>{ unfold? "收起" : "展开" }</span>
                        { unfold || <IconFont type="icon-arrow" /> }
                        { unfold && <IconFont type="icon-up" /> }
                    </Button>
                </div>
            </div>
            { unfold && <div className={ [styles.flex, styles.itemsCenter, styles.multiUser].join(' ') }>
                <span>platform：</span>
                <Select
                    style={{ width: 200 }}
                    className={ styles.select }
                    value={ os }
                    placeholder="请选择平台"
                    onChange={ (val) => setOs(val) }
                >
                    {
                        osList.map((item: any): ReactElement => {
                            return <Antd.Option key={ item.label } value={ item.value }>{ item.label }</Antd.Option>
                        })
                    }
                </Select>
                <span>role：</span>
                <Select
                    style={{ width: 200 }}
                    className={ styles.select }
                    value={ role }
                    placeholder="请选择角色"
                    onChange={ (val) => setRole(val) }
                >
                    {
                        roleList.map((item: any): ReactElement => {
                            return <Antd.Option key={ item.label } value={ item.value }>{ item.label }</Antd.Option>
                        })
                    }
                </Select>
                <Button type="primary" icon={<SearchOutlined />} onClick={ () => getChanUserByPlatformAndRoleFn(unfold) }>search</Button>
            </div> }
        
            { unfold && <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ loading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowKey={(record) => `${record.uId}${new Date().getTime()}`}
                sticky={ true }
                dataSource={ addUserDatas }>
                <Column align='center' title="用户ID" dataIndex="uId" />
                <Column align='center' title="平台" dataIndex="os" />
                <Column align='center' title="网络" dataIndex="netType" />
                <Column align='center' title="设备" render={ (email: any, record: any): ReactElement => (
                    <Tooltip placement="top" color='green' title={ record.deviceModel }>{ record.deviceModel.substr(0, 20) }</Tooltip>
                ) } />
                <Column align='center' title="添加用户" render={ (email: any, record: any, index: number): ReactElement => (
                    <Switch checked={ record.adduser } onChange={ (val) => handleSelectChange(val, index) } />
                ) } />
            </Table> }
        </div>
    )
};

type MapStateToProps = (state: object) => object;
type MapDispatchToProps = (dispatch: Function) => object;

const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

const mapDispatchToProps: MapDispatchToProps = (dispatch: Function): object => {
    return {
        ADDMONITODATA: (value: MONITORDATA): object => dispatch({ type: 'ADDMONITODATA', value }),
        REMOVEMONITODATA: (value: MONITORDATA): object => dispatch({ type: 'REMOVEMONITODATA', value }),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CallAddus);