import { FC, ReactElement, useState, useEffect } from 'react';
import { Table, Modal, Button, Input, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { findPermissions } from '../../../../../../../api/permiss';
import { baseURL } from "../../../../../../../axios/baseUrl";
import { moneyComma, getTimestampToTime } from '../../../../../../../api/index';
import { Option } from '../../../../../../../axios/axios-types';
import axios from '../../../../../../../axios/axios';
import exportSCV from '../../../../../../../api/excal';
import styles from './pendingInvoicesDetails.less';
const { Column } = Table;

interface Data {
	code: number;
	data: any;
};

interface Result {
	data: Data;
};

const fileName: string = '账单.pdf';

const PendingInvoicesDetails: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [invVBRKItem, setInvVBRKItem]: [any, Function] = useState({});
    const [invoiceVisible, setInvoiceVisible]: [boolean, Function] = useState(false);
    const [invoiceTitle, setInvoiceTitle]: [string, Function] = useState('');
    const [record, setRecord]: [any, Function] = useState({});
    const [offon, setOffon]: [boolean, Function] = useState(false);
    const [reason, setReason]: [string, Function] = useState('');
    const [order, setOrder]: [string, Function] = useState('');
    const [note, setNote]: [string, Function] = useState('');

    
    useEffect((): void => {
        getUnHandledInvoiceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUnHandledInvoiceInfo: Function = async (): Promise<void> => {
        setTableLoading(true);
        const option: Option = {
            url: '/developers/getUnHandledInvoiceInfo',
            data: { 
                pageSize,
                pageNum: current,
                invoiceid: location.state.invoiceid,
                uid: location.state.uid
            },
            method: 'POST'
        };
        let result: Result = await axios(option);
        if (result.data.code === 0) {
            setInvVBRKItem(result.data.data.invVBRKItem);
            result.data.data.invAmount = moneyComma(result.data.data.invAmount);
            result.data.data.applyTs = getTimestampToTime(result.data.data.applyTs, 'yyyy-MM-DD');
            result.data.data.invoiceBilList.forEach((item: any): void => {
                item.billAmount = moneyComma(item.billAmount);
                item.billState = item.billState? '已扣费' : '未扣费';
            });
            setRecord(result.data.data);
            setTableData(result.data.data.invoiceBilList);
			setTotal(result.data.data.totalNumber);
        };
        setTableLoading(false);
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleView: Function = (record: any): void => {
        history.push('/content/user/detail/financial/invoice', location.state);
    };

    const handleClickDecision: Function = (offon: boolean): void => {
        if (offon) {
            setInvoiceTitle('同意开票');
        } else {
            setInvoiceTitle('拒绝开票');
        };
        setInvoiceVisible(true);
        setOrder('');
        setNote('');
        setReason('');
        setOffon(offon);
    };

    const handleInvoiceModalOk: Function = async (): Promise<void> => {
        offon? handleAagree() : handleRefused();
    };

    const handlePdfFn: Function = (row: any): void => {
        const { billId } = row;
        const option: Option = {
            url: `${baseURL.replace('/v1', '/v3')}/developers/exportBillDetailInfo/${billId}`,
            method: 'GET',
            responseType: 'arraybuffer'
        };
        axios(option).then(res => {
            const fileName: string = `账单.pdf`;
            const url: string = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf;charset=utf-8" }));
            const a = document.createElement("a");
            a.download = fileName;
            a.href = url;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    };

    const handleDownload: Function =  async (row: any): Promise<void> => {
        const { billId } = row;
        const option: Option = {
            url: '/developers/getBillDayDetailInfo',
            data: { billId, devpId: location.state.uid },
            method: 'POST',
        }; 
        const result = await axios(option);
        if (result.data.code !== 0) {
            message.error("Bill details download failed");
            return;
        }
        const tHeader: Array<string> = [
            "项目ID",
            "日期",
            "音频",
            "高清视频",
            "超高清视频",
            "实时消息",
            "2K消费分钟数",
            "2K+消费分钟数",
            "实时对讲"
        ];
        const filterVal: Array<string> = [
            "appId",
            "day",
            "audMin",
            "hdMin",
            "hhdMin",
            "rtmNum",
            "tkMin",
            "tkpMin",
            "talkAudMin"
        ];
        if (result.data.data.isMiniAppOn) {
            tHeader.push("小程序音频");
            tHeader.push("小程序视频");
            filterVal.push("miniAppAudMin");
            filterVal.push("miniAppVidMin");
        };
        if (result.data.data.isVodOn) {
            tHeader.push("云端录制音频");
            tHeader.push("云端录制高清视频");
            tHeader.push("云端录制超高清视频");
            tHeader.push("云端录制2K视频消费分钟数");
            tHeader.push("云端录制2k+视频消费分钟数");

            filterVal.push("audRecMin");
            filterVal.push("hdRecMin");
            filterVal.push("hhdRecMin");
            filterVal.push(" tkRecMin");
            filterVal.push("tkpRecMin");
        };
        if (result.data.data.isPushOn) {
            tHeader.push("音频旁路推流");
            tHeader.push("高清视频旁路推流");
            tHeader.push("超高清视频旁路推流");
            tHeader.push("2k旁路推流视频消费分钟数");
            tHeader.push("2k+旁路推流视频消费分钟数");

            filterVal.push("audPushMin");
            filterVal.push("hdPushMin");
            filterVal.push("hhdPushMin");
            filterVal.push("tkPushMin");
            filterVal.push("tkpPushMin");
        };
        exportSCV(tHeader, filterVal, result.data.data.data, fileName);
    };

    const handleRefused: Function = async (): Promise<void> => {
        if (reason === "") {
            message.error("请输入拒绝开票原因");
            return;
        }
        const option: Option = {
            url: '/developers/refuseUnHandledInvoice',
            data: {
                invoiceid: location.state.invoiceid,
                detail: reason,
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("拒绝开票申请成功");
            getUnHandledInvoiceInfo();
            setInvoiceVisible(false);
        } else {
            message.error("拒绝开票申请失败");
        }
    };

    const handleAagree: Function = async (): Promise<void> => {
        if (order === "" || note === "") {
            message.error("请输入快递单号或备注");
            return;
        };
        const option: Option = {
            url: '/developers/accessUnHandledInvoice',
            data: {
                invoiceid: location.state.invoiceid,
                remarks: note,
                waybillNo: order,
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("同意开票申请成功");
            getUnHandledInvoiceInfo();
            setInvoiceVisible(false);
        } else {
            message.error("同意开票申请失败");
        }
    };
    return (
        <div className={ styles.pendingInvoicesDetails }>
            <div className={ styles.titleBox }>
                <p className={ styles.title } onClick={ () => handleView() }>发票管理</p>
                <span className={ styles.slash }>/</span>
                <span>待处理发票详情</span>
            </div>
            <div className={ styles.information }>
                <p className={ styles.stTitle }>发票信息</p>
                <div className={ styles.lineBox }>
                    <p>发票抬头</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>企业名称：</span>
                        <p>{ invVBRKItem.comName }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>社会信用代码：</span>
                        <p>{ invVBRKItem.comSocialId }</p>
                    </div> 
                    <div className={ styles.line }>
                        <span className={ styles.span }>企业注册地址：</span>
                        <p>{ invVBRKItem.comAddr }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>企业固定电话：</span>
                        <p>{ invVBRKItem.comPhone }</p>
                    </div>
                    { invVBRKItem.invType === 1 && <div className={ styles.line }>
                        <span className={ styles.span }>个人抬头：</span>
                        <p>{ invVBRKItem.pIdName }</p>
                    </div> }

                    { invVBRKItem.invType !== 1 && <>
                        <p>开户银行信息</p>
                        <div className={ styles.line }>
                            <span className={ styles.span }>银行名称：</span>
                            <p>{ invVBRKItem.comBankName }</p>
                        </div>
                        <div className={ styles.line }>
                            <span className={ styles.span }>开户银行：</span>
                            <p>{ invVBRKItem.openingBank }</p>
                        </div>
                        <div className={ styles.line }>
                            <span className={ styles.span }>银行账户：</span>
                            <p>{ invVBRKItem.comBankNum }</p>
                        </div> 
                    </>}

                    <p>收件地址</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>收件人：</span>
                        <p>{ invVBRKItem.invType === 1? invVBRKItem.pIdCnee : invVBRKItem.comCnee }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>联系方式：</span>
                        <p>{ invVBRKItem.invType === 1? invVBRKItem.pIdCneePhone : invVBRKItem.comCneePhone }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>收件地址：</span>
                        <p>{ invVBRKItem.invType === 1? invVBRKItem.pIdCneeAddr : invVBRKItem.comCneeAddr }</p>
                    </div>
                </div>

                <div className={ styles.lineOne }>
                    <span className="key">申请日期：</span>
                    <span className="value">{ record.applyTs }</span>
                </div>
                <div className={ styles.lineOne }>
                    <span className="key">开票金额：</span>
                    <span className="value">{ record.invAmount }</span>
                </div>

                <p className={ styles.payment }>账期</p>
                <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
                    loading={ tableLoading } 
                    className={ styles.table }
                    pagination={{
                        defaultCurrent: 1,
                        current,
                        showSizeChanger: true,
                        pageSize,
                        total
                    }}
                    rowKey='billId'
                    sticky={ true }
                    dataSource={ tableData }>
                    <Column align='center' title="账期" render={ (operation: any, record: any): ReactElement => (
                        <span>{ record.billMonth } { record.extra }</span>
				    ) } />
                    <Column align='center' title="金额" dataIndex="billAmount" />
                    <Column align='center' title="状态" dataIndex="billState" />
                    <Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
                        <div className={ styles.operationBox }>
                            { (record.billType !== 5 && findPermissions(11455)) && <p onClick={ () => handlePdfFn(record) } className={ styles.operation }>下载</p> }
                            { (record.billType !== 5 && record.billType !== 4 && findPermissions(11456)) && <p onClick={ () => handleDownload(record) } className={ styles.operation }>下载详情</p> }
                        </div>
                    ) } />
                </Table>
                <div className={ styles.btnBox }>
                    <Button type="primary" onClick={ () => handleView() }>返回</Button>
                    { record.invState === 0 &&<div className={ styles.dealBtnBox }>
                        { findPermissions(11453) && <Button type="primary" onClick={ () => handleClickDecision(true) }>同意</Button> }
                        { findPermissions(11453) && <Button type="primary" onClick={ () => handleClickDecision(false) } danger>拒绝</Button> }
                    </div> }
                </div>
            </div>


            <Modal title={ invoiceTitle } width={ 600 } getContainer={ false } centered visible={ invoiceVisible } onOk={ () => handleInvoiceModalOk() } onCancel={ () => setInvoiceVisible(false) }>
                { offon && <>
                        <div className={ styles.modalLine }>
                            <span>快递单号：</span>
                            <Input placeholder="请输入快递单号" value={ order } onChange={ (e) => setOrder(e.target.value) } />
                        </div>
                        <div className={ styles.modalLine }>
                            <span>备注：</span>
                            <Input placeholder="请输入备注" value={ note } onChange={ (e) => setNote(e.target.value) } />
                        </div>
                </> }
                { offon || <div className={ styles.modalLine }>
                        <span>拒绝原因：</span>
                        <Input placeholder="请输入拒绝原因" value={ reason } onChange={ (e) => setReason(e.target.value) } />
                </div> }
            </Modal>
        </div>
    );
};

export default PendingInvoicesDetails;