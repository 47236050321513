import { FC, ReactElement, useState } from 'react';
import { Button } from 'antd';
import { findPermissions } from '../../../../../../../../api/permiss';
import AuthorList from './authorList/authorList';
import AuthorSet from './authorSet/authorSet';
import styles from './single.less';


const Single: FC = (): ReactElement => {
    const [active, setActive]: [number, Function] = useState(1);

    const handleClick: Function = (value: number): void => {
        setActive(value);
    };
    
    return (<div className={ styles.single }>
        <p className={ styles.title }>license (一对一授权模式)</p>
        <div className={ styles.authorization }>
            <Button onClick={ () => handleClick(1) } className={ [styles.author, active === 1 && styles.active].join(' ') }>授权设置</Button>
            { findPermissions(11234) && <Button onClick={ () => handleClick(2) } className={ [styles.author, active === 2 && styles.active].join(' ') }>授权列表</Button> }
        </div>
        { active === 1 && <AuthorSet /> }
        { active === 2 && <AuthorList /> }
    </div>);
};

export default Single;