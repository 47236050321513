import { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Input, Select, Button, Upload, message } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Option } from '../../../../axios/axios-types';
import { AID, TOKEN } from '../../../../store/reducer';
import { uploadUrl, scriptUrl } from '../../../../axios/baseUrl';
import { ContentUtils } from 'braft-utils';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import axios from '../../../../axios/axios';
import styles from './dealEmail.less';
namespace Antd {
    export const { Option } = Select;
};

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    aid?: AID;
    token: TOKEN;
};

const DealEmail: FC<IProps> = (props): ReactElement => {
    let hide: any = null;
    const location: any = useLocation();
    const history: any = useHistory();
    const [title, setTitle]: [string, Function] = useState('');
    const [pageTitle, setPageTitle]: [string, Function] = useState('Add the mail');
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [editorState, seteDitorState]: [any, Function] = useState(BraftEditor.createEditorState(null));
    const [eType, setEType]: [number, Function] = useState(1);
    const [editor, setEditor]: [any, Function] = useState(false);
    const extendControlsContent: any = [
        {
            key: 'antd-uploader',
            type: 'component',
            component: (
                <Upload 
                accept="*"
                className={ styles.upload }
                showUploadList={ false }
                name="file"
                listType="picture-card"
                onChange={ (info) => handleImageContentChange(info) }
                action={ uploadUrl + 'anyrtc' }
                headers={{
                    'Artc-Token': props.token
                }}
                >
                    <IconFont type="icon-f-img" className="control-item button upload-button" data-title="插入图片"/>
                </Upload>
            )
        }
    ]

    useEffect((): void => {
        if (location.state) {
            setEditor(location.state);
            seteDitorState(BraftEditor.createEditorState(location.state.content));
            setEType(+location.state.eType);
            setTitle(location.state.title);
            setPageTitle('Edit the mail');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleImageContentChange: Function = (info: any) => {
        if (!hide) {
            hide = message.loading('图片正在上传，请稍等', 0);
        }
        if (info.file.status === 'done') {
            hide();
            if (info.file.response.code === 0) {
                message.success('图片上传成功');
                seteDitorState(ContentUtils.insertMedias(editorState, [{
                    type: 'IMAGE',
                    url: info.file.response.data.url
                }]));
            } else {
                message.error('图片上传失败');
            }
        }
    };

    const back: Function = (): void => {
        history.goBack();
    };

    const saveEmail: Function = async (): Promise<void> => {
        const content = editorState.toHTML();
        if (title === '') {
            message.error("请输入邮件标题");
            return;
        } else if (content === '') {
            message.error("请输入邮件内容");
            return;
        }
        const option: Option = {
            url: '',
            data: {
                adminId: props.aid,
                content,
                title,
                etype: eType,
                emailId: ''
            },
            method: 'POST'
        };
        setLoading(true);
        if (editor) {
            (option.data as any).emailId = editor.emailid;
            option.url = '/developers/updateEmail';
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                message.success("邮件编辑成功");
                back();
            } else {
                message.error("邮件编辑失败");
            }
        } else {
            option.url = '/developers/insertEmail';
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                message.success("邮件添加成功");
                back();
            } else {
                message.error("邮件添加失败");
            }
        }
        setLoading(false);
    };
    
    return (
        <div className={ styles.dealEmail }>
            <div className={ styles.modal }>
                <p className={ styles.title }>{ pageTitle }</p>
                <div className={ styles.content }>
                    <div className={ styles.box }>
                        <span>标题：</span>
                        <Input placeholder="请输入邮件标题" maxLength={ 100 } value={ title } onChange={ (e) => setTitle(e.target.value) } />
                    </div>
                    <BraftEditor
                        className={ styles.editor }
                        value={ editorState }
                        extendControls={ extendControlsContent }
                        onChange={ (value) => seteDitorState(value) }
                    />
                    <div className={ styles.box }>
                        <span>文章类型：</span>
                        <Select
                        style={{ width: 200 }}
                        value={ eType }
                        onChange={ (value) => setEType(value) }>
                            <Antd.Option value={ 1 }>产品</Antd.Option>
                            <Antd.Option value={ 2 }>活动</Antd.Option>
                        </Select>
                    </div>
                    <div className={ styles.btnBox }>
                        <Button className={ styles.btn } onClick={ () => saveEmail() } loading={ loading } type="primary">Save Email</Button>
                        <Button className={ styles.btn } onClick={ () => back() }>Back</Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

type MapStateToProps = (state: object) => object;
const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps, null)(DealEmail);
