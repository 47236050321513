import { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Tooltip, Switch, Slider } from 'antd';
import { connect } from 'react-redux';
import { MONITORDATA } from '../../../../store/reducer';
import { Option } from '../../../../axios/axios-types';
import { timeTransformation, durationCompute } from '../../../../api/time';
import { dataReconstitutionFn } from '../../../../api/monitor';
import axios from '../../../../axios/axios';
import CallAdduser from './callAddus/callAddus';
import CallEcharts from './callEcharts/callEcharts';
import styles from './monitoring.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    MONITORDATA?: Function;
    monitorData?: MONITORDATA;
}

const Content: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();
    const chanSId: string = location.state? location.state.chanSId : decodeURI(location.search.split('?')[1]);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [sliderMin, setSliderMin]: [number, Function] = useState(0);
	const [sliderMax, setSliderMax]: [number, Function] = useState(100);
	const [durationSearchShow, setDurationSearchShow]: [boolean, Function] = useState(false);
	const [callAdduserShow, setCallAdduserShow]: [boolean, Function] = useState(false);
	const [searchTime, setSearchTime]: [[number, number], Function] = useState([0, 20]);
	const [callQualityTableData, setCallQualityTableData]: [Array<any>, Function] = useState([]);
	const [callEchartsData, setCallEchartsData]: [any, Function] = useState({
        echartsDataLites: [],
        sendDataLites: [],
        searchTime: []
    });
	const [callAddUserData, setCallAddUserData]: [any, Function] = useState({});
	const [first, setFirst]: [boolean, Function] = useState(true);

    
    useEffect((): void => {
        getChannelInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect((): void => {
        if (props.monitorData && tableData[0]) {
            const channelList: any = tableData[0];
            const oEchartsData: Array<any> = [];
            const oSendLine: Array<any> = [];
            props.monitorData.forEach((item: any): void => {
                if (item.uPub === 1) {
                    oSendLine.push({
                        uId: item.uId,
                        color: item.color
                    });
                }
                oEchartsData.push({
                    appId: channelList.appId,
                    chanId: channelList.chanName,
                    chanSId: channelList.chanSId,
                    sid: item.uSid,
                    uId: item.uId,
                    sdkVersion: item.sdkVersion,
                    os: item.os,
                    echarShow: item.echarShow
                });
            });
            setCallEchartsData({
                echartsDataLites: oEchartsData,
                sendDataLites: oSendLine,
                searchTime
            });
            setCallAddUserData({
                appId: channelList.appId,
                chanId: channelList.chanName,
                chanSId: channelList.chanSId,
                startTs: searchTime[0],
                stopTs: searchTime[1],
                duration: channelList.duration,
                chanSTs: channelList.chanSTs
            });
            setCallQualityTableData(props.monitorData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.monitorData]);

    useEffect((): void => {
        first? setFirst(false) : getChanUserLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliderMin, sliderMax]);

    useEffect((): void => {
        if (first) {
            setFirst(false);
        } else {
            restrictTime();
            restrictUser();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData]);

    const getChannelInfo: Function = async (): Promise<void> => {
        setTableLoading(true);
        const option: Option = {
            url: '/v2/project/getChannelInfo',
            data: {
                chanSId: chanSId, 
                gType: 1
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            result.data.data.forEach((item: any): void => {
                item.startTime = timeTransformation(item.chanSTs);
                item.chanSId = chanSId;
                if (item.chanCTs > 0) {
                    item.endTime = timeTransformation(item.chanCTs);
                    item.durationTime = durationCompute(item.chanSTs, item.chanCTs).character;
                    item.duration = durationCompute(item.chanSTs, item.chanCTs).duration;
                } else {
                    item.endTime = "进行中";
                    item.newTime = timeTransformation(new Date().getTime());
                    item.durationTime = durationCompute(item.chanSTs, new Date().getTime()).character;
                    item.duration = durationCompute(item.chanSTs, new Date().getTime()).duration;
                }
            });
            setTableLoading(false);
            setTableData(result.data.data);
        }
    };

    const getChanUserLists: Function = async (): Promise<void> => {
        setLoading(true);
        const option: Option = {
            url: '/v2/project/getChanPublishUserList',
            data: {
                appId: tableData[0].appId,
                chanId: tableData[0].chanName,
                chanSId: chanSId,
                startTs: searchTime[0],
                stopTs: searchTime[1]
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const { reconstitutionData } = await dataReconstitutionFn(result.data.data.list, tableData[0]);
            setCallQualityTableData(await reconstitutionData);
            props.MONITORDATA && props.MONITORDATA(reconstitutionData);
            restrictUser();
        }
        setLoading(false);
    };

    const restrictUser: Function = (): void => {
        setCallAdduserShow(tableData[0].chanUNum > 6? true : false);
    };

    const restrictTime: Function = (): void => {
        const oObj: any = tableData[0];
        if (oObj.chanCTs > 0) {
            setSliderMin(oObj.chanSTs);
            setSliderMax(oObj.chanCTs);
            const bool: boolean = oObj.chanCTs - oObj.chanSTs > 60 * 60 * 3? true : false;
            setDurationSearchShow(bool);
            setSearchTime(bool? [oObj.chanCTs - 60 * 60 * 3, oObj.chanCTs] : [oObj.chanSTs, oObj.chanCTs]);
        } else {
            const oT: number = parseInt((new Date().getTime() / 1000).toString());
            setSliderMin(oObj.chanSTs);
            setSliderMax(oT);
            const bool: boolean = oT - oObj.chanSTs > 60 * 60 * 3 ? true : false;
            setDurationSearchShow(bool);
            setSearchTime(bool? [oT - 60 * 60 * 3, oT] : [oObj.chanSTs, oT]);
        }
    };

    const sliderTooltipFn: Function = (value: number): string => {
        return value ? timeTransformation(value) : "";
    };

    const durationSearchFn: Function = (val: [number, number]): void => {
        if (val[1] - val[0] < 60 * 60 * 3) {
            setSearchTime([val[0], val[1]]);
        }
    };

    const handleSelectChange: Function = (val: boolean, index: number): void => {
        const target = JSON.parse(JSON.stringify(callQualityTableData));
        target[index].echarShow = val;
        props.MONITORDATA && props.MONITORDATA(target);
    };
    return (
        <div className={ styles.monitoring }>
            <Table
				loading={ tableLoading }
				pagination={ false }
				rowKey={(record) => `${record.chanName}${new Date().getTime()}`}
				sticky={ true }
				dataSource={ tableData }>
				<Column align='center' title="项目名称"  dataIndex="appName" />
				<Column align='center' title="频道名称"  dataIndex="chanName" />
				<Column align='center' title="频道时间" render={ (email: any, record: any): ReactElement => (
					<div className={ styles.renderDiy }>
						<p>{ record.startTime }</p>
                        <span className={ styles.span }>-</span>
                        <div className={ record.endTime === '进行中'? styles.proceed : '' }>
                            <span className={ styles.proceedDot }></span>
                            <span>{ record.endTime }</span>
                        </div>
					</div>
				) } />
                <Column align='center' title="用户数" dataIndex="chanUNum" />
				<Column align='center' title="时长" dataIndex="durationTime" />
			</Table>
            <div className={ styles.titleBox }>
                <p className={ styles.title }>通话体验质量 </p>
                <span>(接收端视角)</span>
            </div>
            { durationSearchShow && <div className={ styles.durationSearch}>
                <div className={ styles.durationSearchSlider }>
                    <div className={ styles.justify }>
                    <span>{ tableData[0].startTime }</span>
                    <span>{
                        tableData[0].newTime? tableData[0].newTime : tableData[0].endTime
                    }</span>
                    </div>
                    <Slider 
                    range 
                    value={ searchTime } 
                    min={ sliderMin }
                    max={ sliderMax }
                    tipFormatter={ (val) => sliderTooltipFn(val) }
                    onAfterChange={ () => getChanUserLists() }
                    onChange={ (val) => durationSearchFn(val) } />
                </div>
            </div> }
            { callAdduserShow && <CallAdduser calladduserdata={ callAddUserData }/> }
            <Table
				loading={ loading } 
				className={ styles.table }
				pagination={ false }
				rowKey={(record) => `${record.uId}${new Date().getTime()}`}
				sticky={ true }
				dataSource={ callQualityTableData }>
				<Column align='center' title="用户id"  dataIndex="uId" />
				<Column align='center' title="通话角色" render={ (email: any, record: any): ReactElement => (
					<Tooltip placement="top" color='green' title={ record.uSub === 1? '主播' : '游客' }>
                        { (record.uPub === 1 || record.uSub === 1) && <span className={ [styles.shangxiaxing,
                            record.uPub === 1 && record.uSub === 1 ? styles.shangxia : '',
                            record.uSub === 1 && record.uPub !== 1 ? styles.xia : '',
                            record.uPub === 1 && record.uSub !== 1 ? styles.shang : ''
                        ].join(' ')}
                        ></span> }
                    </Tooltip>
				) } />
				<Column align='center' title="区域"  dataIndex="area" />
				<Column align='center' title="相对频道时间" render={ (email: any, record: any): ReactElement => (
					<div className={ styles.oTsList } dangerouslySetInnerHTML = {{__html: record.html}}></div>
				) } />
                <Column align='center' title="用户进出房时间" render={ (email: any, record: any): ReactElement => (
					<div className={ styles.render }>
						<p>{ record.sartsTime }</p>
                        <div className={ record.endTime === '进行中'? styles.proceed : '' }>
                            <span className={ styles.proceedDot }></span>
                            <span>{ record.endTime }</span>
                        </div>
					</div>
				) } />
				<Column align='center' title="在线频道时长" dataIndex="durationAll" />
				<Column align='center' title="SDK" dataIndex="sdkVersion" />
				<Column align='center' title="平台"  dataIndex="os" />
				<Column align='center' title="网络" render={ (email: any, record: any): ReactElement => (
                    <Tooltip placement="top" color='green' title={ record.netType }>{ record.netType.substr(0, 10) }</Tooltip>
                ) } />
                <Column align='center' title="设备" render={ (email: any, record: any): ReactElement => (
                    <Tooltip placement="top" color='green' title={ record.deviceModel }>{ record.deviceModel.substr(0, 10) }</Tooltip>
                ) } />
                <Column align='center' title="图表" render={ (email: any, record: any, index: number): ReactElement => (
                    <Switch checked={ record.echarShow } onChange={ (val) => handleSelectChange(val, index) } />
                ) } />
			</Table>
            <CallEcharts calleChartData={ callEchartsData } />
        </div>
    )
};

type MapDispatchToProps = (dispatch: Function) => object
type MapStateToProps = (state: object) => object;

const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

const mapDispatchToProps: MapDispatchToProps = (dispatch: Function): object => {
	return {
		MONITORDATA: (value: MONITORDATA): object => dispatch({ type: 'MONITORDATA', value })
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);