import { ChangeEvent, Fragment, FC, ReactElement, useState, useEffect } from 'react';
import { Input, Button, message, Table, Modal } from 'antd';
import { userMoudleViewPath } from '../../../../api/permiss';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { TableDataAttr } from '../tableColumns';
import { AuthType } from '../../../../types/enum';
import { Option } from '../../../../axios/axios-types';
import { getTimestampToTime, moneyComma } from '../../../../api';
import axios from '../../../../axios/axios';
import styles from './receive.less';
const { Column } = Table;


interface Data {
	code: number;
	data: {
		list: Array<TableDataAttr>;
		totalNumber: number;
	};
};

interface Result {
	data: Data;
};

interface SearchResult {
	data: {
		code: number;
		data: TableDataAttr;
	}
};

interface UserNumType {
	couponUserNum: number;
	usedCouponUserNum: number;
};

interface IProps {
    cacheLifecycles: any;
};


const Receive: FC<IProps> = (props): ReactElement => {
	const history: any = useHistory();
	const [loading, setLoading]: [boolean, Function] = useState(false);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [searchValue, setSearchValue]: [string, Function] = useState('');
	const [tableData, setTableData]: [Array<TableDataAttr>, Function] = useState([]);
	const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [sortField, setSortField]: [string, Function] = useState('startTs');
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [active, setActive]: [number, Function] = useState(1);
	const [total, setTotal]: [number, Function] = useState(0);
	const [record, setRecord]: [any, Function] = useState({});
	const [visible, setVisible]: [boolean, Function] = useState(false);
    const [visibleLoading, setVisibleLoading]: [boolean, Function] = useState(false);
	const [effect, setEffect]: [boolean, Function] = useState(false);
	const [userNum, setUserNum]: [UserNumType, Function] = useState({
		couponUserNum: 0,
		usedCouponUserNum: 0
	});
	const [url, setUrl]: [string, Function] = useState('/v2/developers/getCouponDevpList');
	const [sortedInfo, setSortedInfo]: [any, Function] = useState({
		order: 'descend',
		field: 'startTs',
	});
    
	useEffect((): void => {
		getCouponDevpList();
		getNewDevpArrNum();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize, current, sortRule, sortField, effect]);

	props.cacheLifecycles.didRecover(() => {
        getCouponDevpList();
		getNewDevpArrNum();
    });

	useEffect((): void => {
		setCurrent(1);
	}, [sortRule, sortField]);

	const getCouponDevpList: Function = async (): Promise<void> => {
		setTableLoading(true);
		const option: Option = {
			url,
			method: 'POST',
			data: {
				sortRule,
				sortField,
				pageNum: current,
				pageSize,
			}
		};
		const result: Result = await axios(option);
		if (result) {
			if (result.data.code === 0) {
				result.data.data.list.forEach((item: TableDataAttr): void => {
					item.regTime = getTimestampToTime(item.regTime);
					item.startTs && (item.startTs = getTimestampToTime(item.startTs));
					item.usedTime && (item.usedTime = getTimestampToTime(item.usedTime));
					item.totalAmount = moneyComma(item.totalAmount);
					item.authType = AuthType[item.authType as number];
				});
				setTotal(result.data.data.totalNumber);
				setTableData(result.data.data.list);
			} else if (result.data.code === 1000) {
                message.error('开发者不存在');
            } else {
				message.error('数据获取失败');
			}
		}
		setTableLoading(false);
	};

	const getNewDevpArrNum: Function = async (): Promise<void> => {
		const option: Option = {
			url: '/v2/developers/getCouponArrNum',
			method: 'GET',
		};
		const result: {
			data: {
				code: number;
				data: UserNumType;
			}
		} = await axios(option);
		result.data.code === 0 && setUserNum(result.data.data);
	};

    const handleSearch: Function = async (): Promise<void> => {
        if (searchValue.trim()) {
			const option: Option = {
				url: '/v2/developers/searchCouponDevpInfo',
				data: { searchParam: searchValue },
				method: 'POST'
			};
			setLoading(true);
			const result: SearchResult = await axios(option);
            setLoading(false);
			if (result.data.code === 0) {
				setSearchValue('');
				message.success('数据查询成功');
				result.data.data.regTime = getTimestampToTime(result.data.data.regTime);
				result.data.data.startTs && (result.data.data.startTs = getTimestampToTime(result.data.data.startTs));
				result.data.data.usedTime && (result.data.data.usedTime = getTimestampToTime(result.data.data.usedTime));
				result.data.data.totalAmount = moneyComma(result.data.data.totalAmount);
				result.data.data.authType = AuthType[result.data.data.authType as number];
				setActive(result.data.data.userType as number);
				setTableData([result.data.data]);
			} else {
				message.error('Data lookup failure');
			}
		}
    }

    const handleChange: Function = (e: ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(e.target.value);
	};

	const handleKeydown = (e: KeyboardEvent): void => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	const handleClick: Function = (record: TableDataAttr): void => {
		history.push(userMoudleViewPath(), { uid: record.uid, private: {
			name: '领取用户',
			url: '/content/user/receive'
		} });
	};

	const resetData: Function = (type: number): void => {
		setCurrent(1);
		setSortRule(0);
		setSortField(type === 1? 'startTs' : 'usedTime');
		setSortedInfo({
			order: 'descend',
			field: type === 1? 'startTs' : 'usedTime',
		});
	};

	const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
		setSortedInfo(sorter);
	};

	const handleBtnSwitch: Function = (type: number): void => {
		setActive(type);
		if (type === 1) {
			setUrl('/v2/developers/getCouponDevpList');
		} else if (type === 2) {
			setUrl('/v2/developers/getUsedCouponDevpList');
		}
		setEffect(!effect);
		resetData(type);
	};

	const handleClickReplacement: Function = (record: any): void => {
		setRecord(record);
		setVisible(true);
	}; 

	const handleOnOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/developers/insertDevpNewCoupon',
            data: { uid: record.uid },
            method: 'POST'
        };
        setVisibleLoading(true);
        const result: Result = await axios(option);
        setVisibleLoading(false);
        message[result.data.code === 0? "success" : "error"](
            result.data.code === 0? "补发成功" : "补发失败"
        );
        if (result.data.code === 0) {
            setVisible(false);
            setEffect(!effect);
        }
    };

    useEffect(() => {
		window.addEventListener('keydown', handleKeydown);		
		return () => {
			window.removeEventListener('keydown', handleKeydown);		
		}
	});

    return (
        <div className={ styles.receive }>
            <div className={ styles.serach }>
				<Fragment>
					<Input value={ searchValue } onChange={ (e) => handleChange(e) } className={ styles.input } placeholder="请输入用户的邮箱或手机号或AppID或CID" prefix={<SearchOutlined />} />
					<Button className={ styles.button } loading={ loading } disabled={ searchValue === '' } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
				</Fragment>
				<div className={ styles.total }>
					<Button className={ styles.button } onClick={ () => handleBtnSwitch(1) } type={ active === 1? 'primary' : 'default' }>领取用户：{ userNum.couponUserNum }</Button>
				</div>
				<div className={ styles.total }>
					<Button className={ styles.button } onClick={ () => handleBtnSwitch(2) } type={ active === 2? 'primary' : 'default' }>兑换用户：{ userNum.usedCouponUserNum }</Button>
				</div>
			</div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='uid'
				sticky={ true }
				sortDirections={['ascend', 'descend', 'ascend']} 
				dataSource={ tableData }>
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'regTime' && sortedInfo.order} sorter={ true } title="注册时间" dataIndex="regTime" />
				<Column align='center' title="邮箱/手机号" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<div className={ styles.render }>
						<p>{ record.email }</p>
						<p>{ record.cellphone }</p>
					</div>
				) } />
				<Column align='center' title="兑换码"  dataIndex="code" />
				{ active === 1 && <Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'startTs' && sortedInfo.order} sorter={ true } title="领取时间" dataIndex="startTs" /> }
				{ active === 2 && <Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'usedTime' && sortedInfo.order} sorter={ true } title="兑换时间" dataIndex="usedTime" /> }
				<Column align='center' title="认证类型" dataIndex="authType" />
				<Column align='center' title="备注" dataIndex="remark" />
				<Column align='center' title="操作" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<div className={ styles.operationBox }>
						{ active === 1 && <p onClick={ () => handleClickReplacement(record) } className={ styles.operation }>补发</p> }
						<p onClick={ () => handleClick(record) } className={ styles.operation }>查看</p>
					</div>
				) } />
			</Table>

			<Modal title='补发兑换码' width={ 550 }
            confirmLoading={ visibleLoading }
            getContainer={ false } 
            centered visible={ visible } 
            onCancel={ () => setVisible(false)}
            onOk={ () => handleOnOk() }>
                <p>确定为该用户补发?</p>
            </Modal>
        </div>
    )
};


export default Receive;