import { FC, ReactElement } from 'react';
import { CacheRoute, CacheSwitch } from 'react-router-cache-route';
import { Route } from 'react-router-dom';
import Index from './index/index';
import CustomerAdd from './customerAdd/customerAdd';
import CustomerAddNext from './customerAddNext/customerAddNext';
import AuditCustomer from './auditCustomer/auditCustomer';
import Detail from './detail/detail';

const Private: FC = (): ReactElement => {
    return (
        <div>
            <CacheSwitch>
                <Route path='/content/private/customerAdd' component={ CustomerAdd } exact />
                <Route path='/content/private/customerAddNext' component={ CustomerAddNext } exact />
                <Route path='/content/private/auditCustomer' component={ AuditCustomer } exact />
                <Route path='/content/private/detail' component={ Detail } />
                <CacheRoute path='/content/private' component={ Index } />
            </CacheSwitch>
        </div>
    )
};

export default Private;