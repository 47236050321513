import { FC, ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../api/permiss';
import { Button, message, Table, Upload, Modal, InputNumber } from 'antd';
import { getTimestampToTime } from '../../../../../../api/index';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Option } from '../../../../../../axios/axios-types';
import { uploadUrl } from '../../../../../../axios/baseUrl';
import { connect } from 'react-redux';
import { TOKEN } from '../../../../../../store/reducer';
import axios from '../../../../../../axios/axios';
import styles from './package.less';
const { Column } = Table;


interface TableDataAttr {
    state: number | string;
    type: number | string;
    creatTs: number | string;
    expireTs: number | string;
    leftAmount: number;
    pkgName: string;
    pkgTypeName: string;
    resourceId: string;
    totalAmount: string;
};

interface Data {
	code: number;
	data: { 
        list: Array<TableDataAttr>;
        totalNumber: number;
    };
};

interface Result {
	data: Data;
};

interface Filters {
    text: string;
    value: number;
};

interface IProps {
	token: TOKEN;
};

enum State {never, '正常', '过期', '已用完', '未激活'};

const stateFilters: Array<Filters> = [
    { text: '全部', value: 0 },
    { text: '正常', value: 1 },
    { text: '过期', value: 2 },
    { text: '已用完', value: 3 },
    { text: '未激活', value: 4 },
    { text: '添加未付款', value: -1 }
];

const filters: Array<Filters> = [
    { text: '全部', value: 0 },
    { text: '实时音视频', value: 1 },
    { text: '云录制', value: 2 }
];

const Package: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();
    
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [screenshot, setScreenshot]: [string, Function] = useState('');
	const [time, setTime]: [string, Function] = useState('');
	const [minutes, setMinutes]: [number, Function] = useState(1);
	const [loading, setLoading]: [boolean, Function] = useState(false);
    const [tableData, setTableData]: [Array<TableDataAttr>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [sortField, setSortField]: [string, Function] = useState('creatTs');
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [pkgType, setPkgType]: [Array<string>, Function] = useState(['0']);
    const [state, setState]: [Array<string>, Function] = useState(['0']);
    const [effect, setEffect]: [boolean, Function] = useState(false);
    const [visibleLoading, setVisibleLoading]: [boolean, Function] = useState(false);


    useEffect((): void => {
        const getTime: Function = (): void => {
            var year: number = new Date().getFullYear() + 1;
            var month: number = new Date().getMonth() + 1;
            var day: number = new Date(year, month, 0).getDate();
            setTime(`${year}/${month}/${day}`);
        };
        getTime();
    }, []);

    useEffect((): void => {
		const getDevpBillList: Function = async (): Promise<void> => {
			setTableLoading(true);
            const option: Option = {
				url: '/developers/getResourceList',
				method: 'POST',
				data: {
                    sortRule,
					sortField,
                    sPkgType: +pkgType[0],
                    state: +state[0],
					pageNum: current,
					pageSize,
                    uid: location.state.uid
				}
			};
			const result: Result = await axios(option);
			if (result.data.code === 0) {
                result.data.data.list.forEach((item: TableDataAttr): void => {
                    if (item.state > 0 && item.state < 5) {
                        item.state = State[item.state as number];
                    } else if (item.state === -1) {
                        item.state = '添加未付款';
                    }
                    item.type = item.type === 0? "充值" : "赠送";
                    item.creatTs = getTimestampToTime(item.creatTs);
                    item.expireTs = getTimestampToTime(item.expireTs);
                });
                setTotal(result.data.data.totalNumber);
				setTableData(result.data.data.list);
            } else {
                message.error('数据获取失败');
            }
			setTableLoading(false);
		};
		getDevpBillList();
	}, [effect, current, location.state.uid, pageSize, pkgType, sortField, sortRule, state]);


    const handleChange: Function = (info: any, type: string): void => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return
        };
        if (info.file.status === 'done') {
            if (info.file.response.code === 0) {
                message.success('图片上传成功');
                setScreenshot(info.file.response.data.url);
            } else {
                message.error('图片上传失败');
            }
            setLoading(false);
        }
    };

    const handleComplimentary: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/insertResource',
            data: {
                amount: minutes * 10000,
                uid: location.state.uid,
                url: screenshot
            },
            method: 'POST'
        };
        setVisibleLoading(true);
        const result: Result = await axios(option);
        setVisibleLoading(false);
        if (result.data.code === 0) {
            message.success("The package is presented successfully");
            setScreenshot('');
            setTime('');
            setMinutes(1);
            setIsModalVisible(false);
            setEffect(!effect);
        } else {
            statusJudge(result.data.code);
        }
    };

    const statusJudge: Function = (code: number): void => {
        var msg = '';
        if (code === 1044) {
            msg = 'The user is a monthly subscription user';
        } else if (code === 1045) {
            msg = 'The user is the lowest consumption user';
        } else if (code === 1046) {
            msg = 'The user has an unused package';
        } else if (code === 1047) {
            msg = 'The user added tiered pricing';
        }
        message.error(msg);
    };

    const checkScreenshot: Function = (): void => {
        if (screenshot) {
            setIsModalVisible(true);
        } else {
            message.error('Please upload screenshots of signature of leaders');
        }
    };
    
    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
        setPkgType(filters.pkgTypeName || [0]);
        setState(filters.state || [0]);
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
	};

    const computedClass: Function = (state: number | string): string => {
        return state === "正常"? styles.normal : state === "过期"? styles.overdue : styles.finished;
    };
    return (
        <div className={ styles.package }>
            <p className={ styles.title }>套餐包管理</p>
            <div className={ styles.asOfDate }>
                <span>现在赠送套餐包有效期截至 { time }</span>
            </div>
            <div className={ styles.bag }>
                <div className={ styles.comiter }>
                    <div className={ styles.bagTitle }>{ minutes } 万分钟</div>
                    <div className={ styles.bagContent }>
                        <div className={ styles.inputNumberBox }>
                            <InputNumber className={ styles.inputNumber } min={ 1 } max={ 300 } value={ minutes } onChange={ (value) => setMinutes(value) } />
                        </div>
                        <p className={ styles.signature }>领导签字截图</p>
                        <div className={ styles.uploadBox }>
                            <Upload
                                className={ [styles.upload, 'avatar-uploader'].join(' ') }
                                name="file"
                                listType="picture-card"
                                showUploadList={ false }
                                action={ uploadUrl + 'anyrtc' }
                                headers={{
                                    'Artc-Token': props.token
                                }}
                                onChange={ (info) => handleChange(info, 'setTup') }
                            >
                                { screenshot ? 
                                    <img className={ styles.uploadImg } src={ screenshot } alt="avatar" style={{ width: '100%' }} /> : 
                                    <div>
                                        { loading ? <LoadingOutlined /> : <PlusOutlined /> }
                                        <div>Upload</div>
                                    </div>
                                }
                            </Upload>
                        </div>
                        <div className={ styles.btnBox }>
                            <Button type="primary" disabled={ !screenshot || !findPermissions(11462) } onClick={ () => checkScreenshot() }>赠送</Button>
                        </div>
                    </div>
                </div>
            </div>

            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='resourceId'
				sticky={ true }
				sortDirections={['ascend', 'descend', 'ascend']} 
				dataSource={ tableData }>
				<Column align='center' title="类型" filteredValue={ pkgType } filterMultiple={ false } filters={ filters } dataIndex="pkgTypeName" />
				<Column align='center' title="产品名称" dataIndex="pkgName" />
				<Column align='center' showSorterTooltip={ false } sorter={ true } title="套餐包时长(分钟)" dataIndex="totalAmount" />
				<Column align='center' showSorterTooltip={ false } sorter={ true } title="剩余时长(分钟)" dataIndex="leftAmount" />
				<Column align='center' showSorterTooltip={ false } sorter={ true } defaultSortOrder='descend' title="购买日期" dataIndex="creatTs" />
				<Column align='center' title="过期日期" dataIndex="expireTs" />
				<Column align='center' title="状态" filteredValue={ state } filterMultiple={ false } filters={ stateFilters } dataIndex="state" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<span className={ computedClass(record.state) }>{ record.state }</span>
				) } />
				<Column align='center' title="类别" dataIndex="type" />
			</Table>

            <Modal title='赠送套餐包' confirmLoading={ visibleLoading } width={ 600 } getContainer={ false } centered visible={ isModalVisible } onOk={ () => handleComplimentary() } onCancel={ () => setIsModalVisible(false) }>
                <p>确定为该客户赠送 <span className={ styles.minutes }>{ minutes }W</span> 分钟套餐包？</p>
            </Modal>
        </div>
    );
};

type MapStateToProps = (state: object) => object;

const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps)(Package);