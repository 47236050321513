import { Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import { message } from 'antd';
import storage from 'redux-persist/lib/storage';

export type PERMISSIONS = Array<{ pid: number }>;
export type ROLEID = string | number;
export type TOKEN = string;
export type AID = string | number;
export type MONITORDATA = any;
export type ADDMONITODATA = Function;
export type REMOVEMONITODATA = Function;

interface InitState {
    collapsed: boolean;
    permissions: PERMISSIONS;
    monitorData: MONITORDATA;
    roleId: ROLEID;
    token: TOKEN;
    aid: AID;
};

interface Action {
    type: string;
    value?: any;
};

const initState: InitState = {
    collapsed: true,
    monitorData: {},
    permissions: [],
    roleId: '',
    token: '',
    aid: '',
};

const reducer: Reducer = (state: InitState = initState, action:Action): InitState => {
    switch(action.type) {
        case 'COLLAPSED':
            return Object.assign({}, state, { collapsed: !state.collapsed });
        case 'PERMISSIONS':
            return Object.assign({}, state, { permissions: action.value });
        case 'ROLEID':
            return Object.assign({}, state, { roleId: action.value });
        case 'TOKEN':
            return Object.assign({}, state, { token: action.value });
        case 'AID':
            return Object.assign({}, state, { aid: action.value });
        case 'MONITORDATA':
            return Object.assign({}, state, { monitorData: action.value });
        case 'ADDMONITODATA':
            const oData: MONITORDATA = state.monitorData.filter((item: any): boolean => {
                return action.value.uId === item.uId;
            });
            if (oData.length > 0) {
                message.warning('Adding a user repeatedly is invalid');
                return state;
            } else {
                const target: MONITORDATA = JSON.parse(JSON.stringify(state.monitorData));
                target.push(action.value);
                return Object.assign({}, state, { monitorData: target });
            }
        case 'REMOVEMONITODATA':
            const data: Array<any> = state.monitorData.filter((item: any): boolean => {
                return action.value.uId !== item.uId;
            });
            return Object.assign({}, state, { monitorData: data });
        default:
            return state;
    }
};

const config = {
    key: 'root',
    storage,
};

export default persistReducer(config, reducer);