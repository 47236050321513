import React, { ReactElement, RefObject } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Option } from '../../axios/axios-types';
import { Modal, message } from 'antd';
import axios from '../../axios/axios';
import IBreadcrumb from '../breadcrumb/breadcrumb';
import logoDown from '../../assets/images/logoDown.png';
import logoOpen from '../../assets/images/logoOpen.png';
import styles from './index.less';

interface IProps extends RouteComponentProps {
    COLLAPSED?: Function;
};

interface IState {
    collapsed: boolean;
    visible: boolean;
};

interface Result {
	data: {
        code: number;
        data: any;
    };
};

class Header extends React.Component<IProps, IState> {
    public logoBtn: RefObject<HTMLDivElement>;
    
    constructor(props: IProps) {
        super(props);
        this.logoBtn = React.createRef<HTMLDivElement>();
    };

    componentDidMount() {
        const width: number | undefined = document.getElementById('navMenu')?.clientWidth;
        this.setState({
            collapsed: (width as number) < 100
        }, (): void => {
            this.logoBtn.current!.style.width = this.state.collapsed? '80px' : '231px';
        });
    };

    public state: IState = {
        collapsed: false,
        visible: false
    };

    public toggleCollapsed: Function = (): void => {
        this.setState({
            collapsed: !this.state.collapsed
        }, (): void => {
            this.props.COLLAPSED && this.props.COLLAPSED(this.state);
            this.logoBtn.current!.style.width = this.state.collapsed? '80px' : '231px';
        });
	};

    public handleLogOut: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/logOut',
            method: 'GET'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            this.props.history.push('/');
            message.success('退出登录成功');
        } else {
            message.error('退出登录失败');
        }
    };

    public handleModal: Function = (): void => {
        this.setState({ visible: true });
    };

    public render(): ReactElement {
        const { collapsed, visible } = this.state;
        return (
            <div className={ styles.header }>
                <div className={ styles.content }>
                    <div className={ styles.logoOrBread }>
                        <div ref={ this.logoBtn } className={ styles.logoBtn } onClick={ () => this.toggleCollapsed() }>
                            { collapsed || <img className={ styles.logoOpen } src={ logoOpen } alt="" /> }
                            { collapsed && <img className={ styles.logoDown } src={ logoDown } alt="" /> }
                        </div>
                        <IBreadcrumb />
                    </div>
                    <div className={ styles.logOut }>
                        <p onClick={ () => this.handleModal() }>Log out</p>
                    </div>
                </div>
                <Modal title='退出登录' width={ 500 } getContainer={ false } centered visible={ visible } 
                onOk={ () => this.handleLogOut() } onCancel={ () => this.setState({ visible: false }) }>
                    <p>确定退出此账号吗?</p>
                </Modal>
            </div>
        )
    };
};

type MapDispatchToProps = (dispatch: Function) => object;

const mapDispatchToProps: MapDispatchToProps = (dispatch: Function): object => {
    return {
        COLLAPSED: (): object => dispatch({ type: 'COLLAPSED' })
    }
};

export default connect(null, mapDispatchToProps)(withRouter(Header));