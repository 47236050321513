import { FC, Fragment,ReactElement, useEffect, useState } from 'react';
import { Button, Modal, Table, Input, message } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { useHistory } from 'react-router-dom';
import { moneyComma } from '../../../../api/index';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './invoice.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

enum InvType {'未知类型', '个人发票', '企业普票', '企业专票'};
enum InvState {'申请中', '已同意', '已拒绝', '已发送'};
enum InvStateT {'未扣费', '已扣费'};

const Invoice: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const typeList: string[] = ["待处理", "已处理", "待申请"];
    const permList: number[] = [24211, 24221, 24231];
    const [current, setCurrent]: [number, Function] = useState(1);
    const [invType, setInvType]: [number | unknown, Function] = useState(null); // 0
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [url, setUrl]: [string, Function] = useState('getFinInvoiceList');
    const [invoiceVisible, setInvoiceVisible]: [boolean, Function] = useState(false);
    const [addressVisible, setAddressVisible]: [boolean, Function] = useState(false);
    const [invoiceTitle, setInvoiceTitle]: [string, Function] = useState('');
    const [record, setRecord]: [any, Function] = useState({
        invVBRKItem: {}
    });
    const [offon, setOffon]: [boolean, Function] = useState(false);
    const [reason, setReason]: [string, Function] = useState('');
    const [order, setOrder]: [string, Function] = useState('');
    const [note, setNote]: [string, Function] = useState('');
    
    useEffect((): void => {
        invoiceType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect((): void => {
        invType !== null && getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current, invType, url]);

    props.cacheLifecycles.didRecover(() => {
        getList();
    });

    const invoiceType: Function = (): void => {
        permList.some((id: number, index: number): boolean => {
            if (findPermissions(id)) {
                setInvType(index);
                return true;
            }
            return false;
        });
    };

    const getList: Function = async (): Promise<void> => {
        const option: Option = {
            url: `/finance/${url}`,
            data: {
                invType: (invType as number) + 1,
                pageNum: current,
                pageSize,
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.amount = moneyComma(item.amount);
                item.dLeftCash = moneyComma(item.dLeftCash);
                item.state = invType === 2? InvStateT[item.state] : InvState[item.state];
                item.type = InvType[item.type];
            });
            setTableData(result.data.data.list);
            setTotal(result.data.data.totalNumber);
        }
    };

    const handleCatTypeClick: Function = (index: number): void => {
		setCurrent(1);
        setUrl(index > 1? 'getFinBillList' : 'getFinInvoiceList');
        setInvType(index);
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const computedClass: Function = (record: any): string => {
        return record.state === "已完成"? styles.complete : record.state === "已拒绝"? styles.refused : styles.agree;
    };

    const handleClickDecision: Function = (record: any, offon: boolean): void => {
        if (offon) {
            setInvoiceTitle('同意开票');
        } else {
            setInvoiceTitle('拒绝开票');
        };
        setInvoiceVisible(true);
        setOrder('');
        setNote('');
        setReason('');
        setRecord(record);
        setOffon(offon);
    };

    const handleInvoiceModalOk: Function = async (): Promise<void> => {
        offon? handleAagree() : handleRefused();
    };

    const handleRefused: Function = async (): Promise<void> => {
        if (reason === "") {
            message.error("请输入拒绝开票原因");
            return;
        }
        const option: Option = {
            url: '/developers/refuseUnHandledInvoice',
            data: {
                invoiceid: record.invoiceid,
                detail: reason,
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("拒绝开票申请成功");
            getList();
            setInvoiceVisible(false);
        } else {
            message.error("拒绝开票申请失败");
        }
    };

    const handleAagree: Function = async (): Promise<void> => {
        if (order === "" || note === "") {
            message.error("请输入快递单号或备注");
            return;
        };
        const option: Option = {
            url: '/developers/accessUnHandledInvoice',
            data: {
                invoiceid: record.invoiceid,
                remarks: note,
                waybillNo: order,
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("同意开票申请成功");
            getList();
            setInvoiceVisible(false);
        } else {
            message.error("同意开票申请失败");
        }
    };

    const handleAddress: Function = (record: any): void => {
        setRecord(record);
        setAddressVisible(true);
    };

    const handleView: Function = (record: any): void => {
        const url: string = invType === 2? '' : invType === 0? '/pendingInvoicesDetails' : '/detail';
        const state: any = {
            private: {
                name: '财务管理',
                url: '/content/financial/invoice'
            }
        };
        if (invType === 2) {
            Object.assign(state, { selectValue: 2 })
        }
        history.push(`/content/user/detail/financial/invoice${url}`, Object.assign(state, record));
    };

    const computedPerm: Function = (type: string): boolean => {
        var perm: number = 0;
        if (type === 'view') {
            perm = invType === 2? 24232 : invType === 0? 24215 : 24222;
        } else if (type === 'table') {
            perm = invType === 2? 24231 : invType === 0? 24211 : 24221;
        }
        return findPermissions(perm);
    };
    return (
        <div className={ styles.invoice }>
            <div className={ styles.linkBtnCont }>
                {
                    typeList.map((item: any, index: number): ReactElement => {
                        return <Fragment key={ index }>{ findPermissions(permList[index]) && <Button className={ [styles.linkBtn, invType === index && styles.catType].join(' ') }
                            onClick={ () => handleCatTypeClick(index) }>{ item }</Button> }</Fragment>
                    })
                }
            </div>
            { computedPerm('table') && <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                { invType !== 2 && <Column align='center' title="申请日期"  dataIndex="ts" /> }
                <Column align='center' title="账期" dataIndex="month" />
                <Column align='center' title="交易金额" dataIndex="amount" />
                <Column align='center' title="客户名称"  dataIndex="name" />
                <Column align='center' title="客户邮箱" dataIndex="email" />
                <Column align='center' title="联系方式"  dataIndex="cellphone" />
                { invType !== 2 && <Column align='center' title="发票类型" dataIndex="type" /> }
                { invType !== 1 && <Column align='center' title="状态" dataIndex="state" /> }
                { invType === 1 && <Column align='center' title="状态" render={ (operation: any, record: any): ReactElement => (
                    <span className={ computedClass(record) }>{ record.state }</span>
                ) } /> }
                <Column align='center' width={ 300 } title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { (invType === 0 && findPermissions(24212)) && <p onClick={ () => handleAddress(record) } className={ styles.operation }>地址</p> }
                        { (invType === 0 && findPermissions(24213)) && <p onClick={ () => handleClickDecision(record, true) } className={ [styles.operation, styles.agree].join(' ') }>同意</p> }
                        { (invType === 0 && findPermissions(24214)) && <p onClick={ () => handleClickDecision(record, false) } className={ [styles.operation, styles.refused].join(' ') }>拒绝</p> }
                        { computedPerm('view') && <p onClick={ () => handleView(record) } className={ styles.operation }>查看</p> }
                    </div>
                ) } />
            </Table> }

            <Modal title={ invoiceTitle } width={ 600 } getContainer={ false } centered visible={ invoiceVisible } onOk={ () => handleInvoiceModalOk() } onCancel={ () => setInvoiceVisible(false) }>
                { offon && <>
                        <div className={ styles.modalLine }>
                            <span>快递单号：</span>
                            <Input placeholder="请输入快递单号" value={ order } onChange={ (e) => setOrder(e.target.value) } />
                        </div>
                        <div className={ styles.modalLine }>
                            <span>备注：</span>
                            <Input placeholder="请输入备注" value={ note } onChange={ (e) => setNote(e.target.value) } />
                        </div>
                </> }
                { offon || <div className={ styles.modalLine }>
                        <span>拒绝原因：</span>
                        <Input placeholder="请输入拒绝原因" value={ reason } onChange={ (e) => setReason(e.target.value) } />
                </div> }
            </Modal>

            <Modal title='地址' footer={ null } width={ 500 } getContainer={ false } centered visible={ addressVisible } onOk={ () => handleInvoiceModalOk() } onCancel={ () => setAddressVisible(false) }>
                <div className={ styles.adddressItem }>
                    <span>收件人：</span>
                    <span>{ record.type === '个人发票'? record.invVBRKItem.pIdCnee : record.invVBRKItem.comCnee }</span>
                </div>
                <div className={ styles.adddressItem }>
                    <span>联系方式：</span>
                    <span>{ record.type === '个人发票'? record.invVBRKItem.pIdCneePhone : record.invVBRKItem.comCneePhone }</span>
                </div>
                <div className={ styles.adddressItem }>
                    <span>收件地址：</span>
                    <span>{ record.type === '个人发票'? record.invVBRKItem.pIdCneeAddr : record.invVBRKItem.comCneeAddr }</span>
                </div>
            </Modal>
        </div>
    )
};

export default Invoice;