
import { ChangeEvent, FC, ReactElement, useState, useEffect } from 'react';
import { Button, Input, message } from 'antd';
import { findPermissions } from '../../../../../../../../api/permiss';
import { useLocation } from 'react-router-dom';
import { Option } from '../../../../../../../../axios/axios-types';
import axios from '../../../../../../../../axios/axios';
import styles from './invoices.less';


interface Data {
	code: number;
	data: { 
        list: Array<any>;
        userComAuthState: number;
    };
};

interface Result {
	data: Data;
};

const Invoices: FC = (): ReactElement => {
    const location: any = useLocation();
    const [disabled, setDisabled]: [boolean, Function] = useState(true);
    const [invoice, setInvoice]: [any, Function] = useState({});
    const [invoiceCopy, setInvoiceCopy]: [any, Function] = useState({});
    const [haveInvoice, setHaveInvoice]: [boolean, Function] = useState(false);
    const [certificationState, setCertificationState]: [boolean, Function] = useState(false);

    
    useEffect((): void => {
        getInvoice();
        getCertificationState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCertificationState: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/getUserComAuthState',
            data: { uid: location.state.uid },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setCertificationState(result.data.data.userComAuthState === 3);
        }
    };

    const  getInvoice: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/getVotSpVbrkInfo',
            data: { uid: location.state.uid },
            method: 'POST'
        };
        let result: Result = await axios(option);
        if (result.data.code === 0) {
            setInvoice(result.data.data);
            setInvoiceCopy(JSON.parse(JSON.stringify(result.data.data)));
            setHaveInvoice(true);
        };
    };

    const handleInputChange: Function = (e: ChangeEvent<HTMLInputElement>, type: string): void => {
        const invoiceCopyc = JSON.parse(JSON.stringify(invoiceCopy));
        invoiceCopyc[type] = e.target.value;
        setInvoiceCopy(invoiceCopyc);
    };

    const handleCancel: Function = (): void => {
        setDisabled(true);
        setInvoiceCopy(JSON.parse(JSON.stringify(invoice)));
    };

    const handleSave: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/insertSpecialInvoice',
            data: Object.assign(invoiceCopy, { uid: location.state.uid }),
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("发票抬头信息上传成功");
            setDisabled(true);
        } else {
            message.error("发票抬头信息上传失败");
        }
        getInvoice();
    };

    return (
        <div className={ styles.invoices }>
            <div className={ styles.enterprise }>
                <div className={ styles.addInvoice }>
                    <p className={ styles.title }>企业专用发票</p>
                    { (!haveInvoice && certificationState && disabled && findPermissions(11452)) && <Button className={ styles.button } onClick={ () => setDisabled(false) } type="primary">添加</Button> }
                    { (haveInvoice && disabled && findPermissions(11452)) && <Button className={ styles.button } onClick={ () => setDisabled(false) } type="primary">编辑</Button> }
                </div>
                { (haveInvoice || !disabled) && <>
                    <p className={ styles.smTitle }>发票抬头</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>企业名称：</span>
                        <Input className={ styles.input } placeholder='请输入企业名称' onChange={ (e) => handleInputChange(e, 'title') } disabled={ disabled } value={ invoiceCopy.title } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>社会信用代码：</span>
                        <Input className={ styles.input } placeholder='请输入社会信用代码' onChange={ (e) => handleInputChange(e, 'comSocialId') } disabled={ disabled } value={ invoiceCopy.comSocialId } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>企业注册地址：</span>
                        <Input className={ styles.input } placeholder='请输入企业注册地址' onChange={ (e) => handleInputChange(e, 'comAddr') } disabled={ disabled } value={ invoiceCopy.comAddr } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>企业固定电话：</span>
                        <Input className={ styles.input } placeholder='请输入企业固定电话' onChange={ (e) => handleInputChange(e, 'comPhone') } disabled={ disabled } value={ invoiceCopy.comPhone } />
                    </div>

                    <p className={ styles.smTitle }>开户银行信息</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>银行名称：</span>
                        <Input className={ styles.input } placeholder='请输入银行名称' onChange={ (e) => handleInputChange(e, 'bankName') } disabled={ disabled } value={ invoiceCopy.bankName } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>开户银行：</span>
                        <Input className={ styles.input } placeholder='请输入开户银行' onChange={ (e) => handleInputChange(e, 'bankDeposit') } disabled={ disabled } value={ invoiceCopy.bankDeposit } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>银行账户：</span>
                        <Input className={ styles.input } placeholder='请输入银行账户' onChange={ (e) => handleInputChange(e, 'bankNum') } disabled={ disabled } value={ invoiceCopy.bankNum } />
                    </div>

                    <p className={ styles.smTitle }>收件地址</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>收件人：</span>
                        <Input className={ styles.input } placeholder='请输入收件人' onChange={ (e) => handleInputChange(e, 'comCnee') } disabled={ disabled } value={ invoiceCopy.comCnee } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>联系方式：</span>
                        <Input className={ styles.input } placeholder='请输入联系方式' onChange={ (e) => handleInputChange(e, 'comCneePhone') } disabled={ disabled } value={ invoiceCopy.comCneePhone } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>收件地址：</span>
                        <Input className={ styles.input } placeholder='请输入收件地址' onChange={ (e) => handleInputChange(e, 'comCneeAddr') } disabled={ disabled } value={ invoiceCopy.comCneeAddr } />
                    </div>
                </> }
                { (!haveInvoice && disabled ) && <p className={ styles.enterprise }>暂无企业专用发票</p> }
                { disabled || <div className={ styles.operation }>
                    <Button className={ styles.button } onClick={ () => handleSave() } type="primary">保存</Button>
                    <Button className={ styles.button } onClick={ () => handleCancel() }>取消</Button>
                </div> }
            </div>
        </div>
    );
};

export default Invoices;