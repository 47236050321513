import { FC, ReactElement, useEffect, useState } from 'react';
import { Button, Table, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROLEID } from '../../../../../store/reducer';
import { findPermissions } from '../../../../../api/permiss';
import { Option } from '../../../../../axios/axios-types';
import axios from '../../../../../axios/axios';
import styles from './index.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    roleId?: ROLEID;
};

const Index: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);

    useEffect((): void => {
		getRoleList();
	}, []);

    const getRoleList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/perm/getRoleList',
            method: 'GET',
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.forEach((item: any): void => {
                if (item.perm.indexOf("等相关权限") === -1) {
                    item.perm = item.perm + "等相关权限";
                }
            });
            setTableData(result.data.data);
        } else {
            message.error('数据获取失败');
        }
    };

    const handleAddRole: Function = (): void => {
        history.push('/content/permiss/role/addRole');
    };

    const handleEditRole: Function = (record: any): void => {
        history.push('/content/permiss/role/addRole', record);
    };
    return (
        <div className={ styles.index }>
            <div className={ styles.addRole }>
                <p className={ styles.title }>角色管理</p>
                <Button type="primary" disabled={ !findPermissions(25211) } onClick={ () => handleAddRole() }>添加角色</Button>
            </div>
            <Table
                loading={ tableLoading } 
                className={ styles.table }
                pagination={ false }
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' width={ 150 } title="编号"  dataIndex="roleId" />
                <Column align='center' title="角色名称" dataIndex="roleName" />
                <Column align='center' title="角色描述" dataIndex="roleDesc" />
                <Column align='center' title="角色权限" dataIndex="perm" />
                <Column align='center' width={ 150 } title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { ((record.roleId === 1? props.roleId === 1 : true) && findPermissions(25212)) && <p className={ styles.operation } onClick={ () => handleEditRole(record) }>编辑</p> }
                    </div>
                ) } />
            </Table>
        </div>
    )
};

type MapStateToProps = (state: object) => object;
const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps, null)(Index);