import { FC, ReactElement, useEffect, useState } from 'react';
import { Button, Modal, Table, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { findPermissions } from '../../../../../../api/permiss';
import { getTimestampToTime } from '../../../../../../api/index';
import { Option } from '../../../../../../axios/axios-types';
import axios from '../../../../../../axios/axios';
import styles from './detail.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const Detail: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();

    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [sendEmail, setSendEmail]: [boolean, Function] = useState(false);
    const [current, setCurrent]: [number, Function] = useState(1);
    const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [visible, setVisible]: [boolean, Function] = useState(false);
	const [record, setRecord]: [any, Function] = useState({});
    const [confirmLoading, setConfirmLoading]: [boolean, Function] = useState(false);

    
    useEffect((): void => {
        getMachList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current]);

    const getMachList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/getMachDetail',
            data: {
                machId: location.state.machId,
                oem: location.state.oem,
                pageNum: current,
                pageSize: pageSize
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.ts = getTimestampToTime(item.ts);
                item.stopTs = getTimestampToTime(item.stopTs);
            });
            setSendEmail(result.data.data.iSendEmail === 1);
            setTotal(result.data.data.totalNumber);
            setTableData(result.data.data.list);
        } else {
            message.error('数据获取失败');
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleOperation: Function = (record: any): void => {
        setRecord(record);
        setVisible(true);
    };

    const handleOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/deleteServiceByRid',
            data: { rId: record.rid, catId: record.catId },
            method: 'POST'
        };
        setConfirmLoading(true);
        const result: Result = await axios(option);
        setConfirmLoading(false);
        if (result.data.code === 0) {
            getMachList();
            setVisible(false);
            message.success('删除成功');
        } else {
            message.error('删除失败');
        }
    };

    const handleBack: Function = (): void => {
        history.goBack();
    };

    const handleReminding: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/sendEmailByOem',
            data: { oem: location.state.oem },
            method: 'POST'
        };
        setLoading(true);
        const result: Result = await axios(option);
        setLoading(false);
        if (result.data.code === 0) {
            getMachList();
            message.success("提醒成功");
        } else {
            message.error("提醒失败");
        }
    };
    return (
        <div className={ styles.detail }>
            <div className={ styles.title }>
                <span className={ styles.information } onClick={ () => handleBack() }>server information</span>
                <span className={ styles.slash }>/</span>
                <span>server detail</span>
            </div>
            <Button loading={ loading } disabled={ sendEmail } onClick={ () => handleReminding() } type="primary">Reminding</Button>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="服务类型"  dataIndex="serviceType" />
                <Column align='center' title="一级服务" dataIndex="catName" />
                <Column align='center' title="二级服务" dataIndex="sType" />
                <Column align='center' title="使用时间"  dataIndex="ts" />
                <Column align='center' title="截止时间" dataIndex="stopTs" />
                <Column align='center' title="有效期/天"  dataIndex="period" />
                <Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { findPermissions(location.state.sc) && <p onClick={ () => handleOperation(record) } className={ [styles.operation, styles.delete].join(' ') }>delete</p> }
                    </div>
                ) } />
            </Table>

            <Modal title='删除服务' width={ 560 } getContainer={ false } centered 
            confirmLoading={ confirmLoading }
            visible={ visible } onOk={ () => handleOk() } onCancel={ () => setVisible(false)}>
                <p>确认删除此服务吗?</p>
            </Modal>
        </div>
    )
};

export default Detail;