import { FC, ReactElement, Fragment, useEffect, useState } from 'react';
import { Table } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { Option } from '../../../../axios/axios-types';
import { getTimestampToTime } from '../../../../api/index';
import { useLocation, useHistory } from 'react-router-dom';
import axios from '../../../../axios/axios';
import styles from './problemSee.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};
const Problem: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [searchData, setSearchData]: [any, Function] = useState(location.state? location.state.listData[0] : JSON.parse(decodeURI(location.search).split('?')[1]));
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [chanResList, setChanResList]: [Array<any>, Function] = useState([]);

    useEffect((): void => {
        init();
        getChanResList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/project/getChannelInfo',
            data: { chanSId: searchData.chanSId, gType: 2 },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const tableData: any = JSON.parse(JSON.stringify(searchData));
            [tableData].forEach((item: any, index: number): void => {
                result.data.data[index] && (item.appName = result.data.data[index].appName);
                result.data.data[index] && (item.chanUNum = result.data.data[index].chanUNum);
            });
            setSearchData(tableData);
        }
    };

    const getChanResList: Function = async (): Promise<void> => {
        setTableLoading(true);
        const option: Option = {
            url: '/project/getChanResList',
            data: { chanSId: searchData.chanSId },
            method: 'POST'
        };
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.forEach((item: any): void => {
                item.chanSTs = getTimestampToTime(item.chanSTs);
            });
        }
        setChanResList(result.data.data);
    };

    const handleClick: Function = (record: any): void => {
        history.push("/content/problem/problemSeeDetails", { reSId: record.reSId, listData: [searchData] });
    };
    return (
        <div className={ styles.problemSee }>
            <Table
				className={ styles.table }
				pagination={ false }
				rowKey={() => `${Math.floor(Math.random() * 123333)}`}
				sticky={ true }
				dataSource={ [searchData] }>
				<Column align='center' title="项目名称"  dataIndex="appName" />
				<Column align='center' title="频道名称"  dataIndex="chanName" />
				<Column align='center' title="用户数"  dataIndex="chanUNum" />
				<Column align='center' title="频道时间" render={ (email: any, record: any): ReactElement => (
					<div className={ styles.render }>
						<p>{ record.chanSTs }</p>
                        <span className={ styles.span }>-</span>
						{ record.spamStyle && <p>{ record.chanCTs }</p> }
                        { record.spamStyle || <span className={ styles.ongoing }>进行中</span> }
					</div>
				) } />
                <Column align='center' title="时长" dataIndex="timerLength" />
			</Table>

            <Table
				loading={ tableLoading } 
				className={ styles.table }
				pagination={ false }
				rowKey={() => `${Math.floor(Math.random() * 123333)}`}
				sticky={ true }
				dataSource={ chanResList }>
				<Column title="Resourced"  dataIndex="reSId" />
				<Column align='center' title="开始时间"  dataIndex="chanSTs" />
				<Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
                    <Fragment>{ findPermissions(20132) && <p onClick={ () => handleClick(record) } className={ styles.operation }>查看</p> }</Fragment>
				) } />
			</Table>
        </div>
    )
};

export default Problem;