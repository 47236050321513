import { FC, ReactElement, useState, useEffect } from 'react';
import { Table, Radio, Button, Tooltip, Modal, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { timeTransformation, durationCompute } from '../../../../api/time';
import { createFromIconfontCN } from '@ant-design/icons';
import { Option } from '../../../../axios/axios-types';
import { scriptUrl } from '../../../../axios/baseUrl';
import { P2pCall } from "../../../../api/reconsitution/p2pJson";
import { initEcharts, restoreEcharts } from "../../../../api/echarts/monitor/p2pCharts";
import { p2pDataReconstitutionFn } from "../../../../api/reconsitution/monitor";
import axios from '../../../../axios/axios';
import MonitoringHorse from './monitoringHorse/monitoringHorse';
import Examine from './examine/examine';
import styles from './monitoringDetails.less';
const { Column } = Table;
const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

interface Result {
	data: {
        code: number;
        data: any;
    };
};
const MonitoringDetails: FC = (): ReactElement => {
    const location: any = useLocation();
    const searchData: any = JSON.parse(decodeURI(location.search).split('?')[1]);
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
    const [publishEventData, setPublishEventData]: [Array<any>, Function] = useState([]);
	const [subscribeEventData, setSubscribeEventData]: [Array<any>, Function] = useState([]);
	const [channelTableData, setChannelTableData]: [Array<any>, Function] = useState([]);
	const [sendingInfo, setSendingInfo]: [Array<any>, Function] = useState([]);
	const [receivingInfo, setReceivingInfo]: [Array<any>, Function] = useState([]);
	const [radioButton, setRadioButton]: [string, Function] = useState('视频');
	const [sendingUid, setSendingUid]: [string, Function] = useState('');
	const [receivingPeerid, setReceivingPeerid]: [string, Function] = useState('');
    const [sendingDatas, setSendingDatas]: [any, Function] = useState(P2pCall.video.sending);
    const [receivingDatas, setReceivingDatas]: [any, Function] = useState(P2pCall.video.receiving);
    const [dialogTableVisible, setDialogTableVisible]: [boolean, Function] = useState(false);
    const [infoLoding, setInfoLoding]: [boolean, Function] = useState(true);
	const [gridSendData, setGridSendData]: [Array<any>, Function] = useState([]);
	const [gridReceivData, setGridReceivData]: [Array<any>, Function] = useState([]);
    const [dialogExamine, setDialogExamine]: [Array<any>, Function] = useState([
        {
            dialogExamineInfo: false,
            info: "api上报信息"
        },
        {
            dialogExamineInfo: false,
            info: "端到端服务器状态"
        }
    ]);
    const [transitionSendReceiv, setTransitionSendReceiv]: [any, Function] = useState({
        convertible: false,
        transition: false
    });
    const [lianluData, setLianluData]: [any, Function] = useState({
        routerInfo: {}
    });


    useEffect((): void => {
		setSendingUid(searchData.uId);
		setReceivingPeerid(searchData.peerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	useEffect((): void => {
		getChannelInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const examineFn: Function = async (index: number): Promise<void> => {
        let uIdArray: any = null;
        if (index === 0) {
            setPublishEventData([]);
            setSubscribeEventData([]);
            if (transitionSendReceiv.convertible && transitionSendReceiv.transition) {
                uIdArray = [searchData.peerId, searchData.uId];
            } else {
                uIdArray = [searchData.uId, searchData.peerId];
            }
            const option: Option = {
                url: '/project/getEventData',
                data: {
                    appId: searchData.appId,
                    chanId: channelTableData[0].chanId,
                    startTs: channelTableData[0].startTs,
                    stopTs: channelTableData[0].stopTs,
                    uIdArray
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                const publishEvent = JSON.parse(JSON.stringify(publishEventData));
                const subscribeEvent = JSON.parse(JSON.stringify(subscribeEventData));
                const dialogExamin = JSON.parse(JSON.stringify(dialogExamine));
                result.data.data.forEach((item: any): void => {
                    item.eventType = apiType(item);
                    item.ts = timeTransformation(item.ts);
                    if (item.uId === uIdArray[0]) {
                        publishEvent.push(item);
                    } else {
                        subscribeEvent.push(item);
                    }
                });
                dialogExamin[index].dialogExamineInfo = true;
                setPublishEventData(publishEvent);
                setSubscribeEventData(subscribeEvent);
                setDialogExamine(dialogExamin);
            } else {
                message.error('数据获取失败');
            }
        } else {
            const option: Option = {
                url: '/v2/project/getP2PStreamRouter',
                data: {
                    appId: searchData.appId,
                    chanSId: searchData.chanSId,
                    peerUId: transitionSendReceiv.convertible && transitionSendReceiv.transition? searchData.peerId : searchData.uId,
                    uId: transitionSendReceiv.convertible && transitionSendReceiv.transition? searchData.uId : searchData.peerId,
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                setLianluData(result.data.data);
                const dialogExamin = JSON.parse(JSON.stringify(dialogExamine));
                dialogExamin[index].dialogExamineInfo = true;
                setDialogExamine(dialogExamin);
            } else {
                message.error('流路由器不存在');
            }
        }
    };
    const setDialog: Function = (index: number): void => {
        const dialogExamin = JSON.parse(JSON.stringify(dialogExamine));
        dialogExamin[index].dialogExamineInfo = false;
        setDialogExamine(dialogExamin);
    };

    const apiType: Function = (event: any): string | undefined => {
        const details: any = event.detail? JSON.parse(event.detail) : "";
        switch (event.eventType) {
			case 1: //session_init 初始化
				return "session_init";
			case 2: //join_choose_server 选择网关
				return "join_choose_server";
			case 3: //join_gateway 加入房间（成功之后才上报）
				return "join_gateway";
			case 4: //join_choose_server 选择网关
				return `join_choose_server`;
			case 5: //publish 发布成功
				return `publish${details.video ? "Video " : details.audio ? "Audio" : ""}`;
			case 6: //server_connection_state 网络连接状态
				return "server_connection_state";
			case 7: //local_net_state 本地网络发生变化 !!--
				return `local_net_state`;
			case 8: //first_remote_frame 订阅者第一帧出图
				return `first_remote_frame`;
			case 9: //first_remote_audio 订阅者声音第一帧
				return `first_remote_audio`;
			case 10: //first_local_frame 本地第一帧视频发送
				return `first_local_frame`;
			case 11: //first_local_audio 本地第一帧音频发送
				return `first_local_audio`;
			case 12: //app_enter_background app进入后台事件
				return `app_enter_background`;
			case 13: //leave 离开频道事件
				return `leave`;
				// ====================
			case 14: //server_connection_state 网络连接状态
				return "publish_audio";
			case 15: //local_net_state 本地网络发生变化 !!--
				return `publish_video`;
			case 16: //first_remote_frame 订阅者第一帧出图
				return `subscribe_audio`;
			case 17: //first_remote_audio 订阅者声音第一帧
				return `subscribe_video`;
			case 18: //first_local_frame 本地第一帧视频发送
				return `audio_route_change`;
			case 19: //first_local_audio 本地第一帧音频发送
				return `audio_disable`;
			case 20: //app_enter_background app进入后台事件
				return `video_disable`;
			case 21: //leave 离开频道事件
				return `video_profile_change`;
			case 22: //server_connection_state 网络连接状态
				return "video_local_disable";
			case 23: //local_net_state 本地网络发生变化 !!--
				return `audio_local_disable`;
			case 24: //first_remote_frame 订阅者第一帧出图
				return `mute_local_video`;
			case 25: //first_remote_audio 订阅者声音第一帧
				return `mute_local_audio`;
			case 26: //first_local_frame 本地第一帧视频发送
				return `remote_subscribe_fallback_to_audio_only`;
			case 27: //first_local_audio 本地第一帧音频发送
				return `audio_device_open_result`;
			case 28: //app_enter_background app进入后台事件
				return `video_device_open_result`;
			case 29: //leave 离开频道事件
				return `audio_encoder_faild`;
			case 30: //server_connection_state 网络连接状态
				return "video_encoder_faild";
			case 31: //local_net_state 本地网络发生变化 !!--
				return `set_default_mute_all_remote_audio`;
			case 32: //first_remote_frame 订阅者第一帧出图
				return `set_default_mute_all_remote_video`;
			case 33: //first_remote_audio 订阅者声音第一帧
				return `unsubscribe_audio`;
			case 34: //first_local_frame 本地第一帧视频发送
				return `unsubscribe_video`;
			case 35: //first_local_audio 本地第一帧音频发送
				return `role_change`;
			case 36: //app_enter_background app进入后台事件
				return `disconnect_server`;
        }
    };

    const restoreFn: Function = (): void => {
        restoreEcharts();
    };

    const dialogTableVisibleFn: Function = (): void => {
        setDialogTableVisible(true);
    };

    const closeFn: Function = (): void => {
        setDialogTableVisible(false);
    };

    const swopFn: Function = async (type: any): Promise<void> => {
		const transitionSendRecei: any = JSON.parse(JSON.stringify(transitionSendReceiv));
        transitionSendRecei.transition = type;
		setTransitionSendReceiv(transitionSendRecei);
		setSendingInfo(receivingInfo);
		setReceivingInfo(sendingInfo);
		setInfoLoding(true);
        const ouId: string = searchData.uId;
        const oPeerId: string = searchData.peerId;
        setSendingUid(type? oPeerId : ouId,);
		setReceivingPeerid(type? ouId : oPeerId);
        const oData: any = Object.assign(channelTableData[0], {
			uId: type? oPeerId : ouId,
			peerId: type? ouId : oPeerId
        });
        const { sengInfo, receivInfo }: any = await initEcharts(
			radioButton,
			oData,
			receivingInfo,
			sendingInfo
        );
		setInfoLoding(false);
        setGridSendData(sengInfo);
        setGridReceivData(receivInfo);
    };

    const radioButtonChangeFn: Function = async (value: string): Promise<void> => {
        setInfoLoding(true);
        setRadioButton(value);
        if (value === "音频") {
			setSendingDatas(P2pCall.voice.sending);
			setReceivingDatas(P2pCall.voice.receiving);
        } else {
			setSendingDatas(P2pCall.video.sending);
			setReceivingDatas(P2pCall.video.receiving);
        }
        const { sengInfo, receivInfo }: any = await initEcharts(
            value,
            Object.assign(searchData, channelTableData[0]),
            sendingInfo,
            receivingInfo
        );
		setInfoLoding(false);
        setGridSendData(sengInfo);
        setGridReceivData(receivInfo);
    };

    const getChannelInfo: Function = async (): Promise<void> => {
        let send: any[] = [];
        let receiv: any[] = [];
        const option: Option = {
            url: '/v2/project/getChannelInfo',
            data: { chanSId: searchData.chanSId, gType: 1 },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setTableLoading(true);
            await result.data.data.forEach((item: any): void => {
                item.startTime = timeTransformation(item.chanSTs);
                item.chanSId = searchData.chanSId;
                item.chanId = item.chanName;
                item.startTs = item.chanSTs;
                item.stopTs = item.chanCTs;
                if (item.chanCTs > 0) {
                    item.endTime = timeTransformation(item.chanCTs);
                    item.durationTime = durationCompute(
                        item.chanSTs,
                        item.chanCTs
                    ).character;
                    item.duration = durationCompute(
                        item.chanSTs,
                        item.chanCTs
                    ).duration;
                } else {
                    item.endTime = "进行中";
                    item.durationTime = durationCompute(
                        item.chanSTs,
                        new Date().getTime()
                    ).character;
                    item.duration = durationCompute(
                        item.chanSTs,
                        new Date().getTime()
                    ).duration;
                }
            });
            setTableLoading(false);
            setChannelTableData(result.data.data);
        }
        const option1: Option = {
            url: '/v2/project/getP2PChanUser',
            data: Object.assign(searchData, result.data.data[0]),
            method: 'POST'
        };
        const result1: Result = await axios(option1);
        if (result1.data.code === 0) {
            const { Send, Receiv, convertible }: any = await p2pDataReconstitutionFn(
                result1.data.data.list,
                searchData.uId,
                searchData.peerId
            );
            send = Send;
            receiv = Receiv;
            setSendingInfo(Send);
            setReceivingInfo(Receiv);
            const transitionSendRecei = JSON.parse(JSON.stringify(transitionSendReceiv));
            transitionSendRecei.convertible = convertible;
            setTransitionSendReceiv(transitionSendRecei);
        }
        const { sengInfo, receivInfo }: any = await initEcharts(
			radioButton,
			Object.assign(searchData, result.data.data[0]),
			send,
			receiv
        );
		setInfoLoding(false);
        setGridSendData(sengInfo);
        setGridReceivData(receivInfo);
    };
    return (
        <div className={ styles.monitoringDetails }>
            <Table
				loading={ tableLoading } 
				className={ styles.table }
				pagination={ false }
				rowKey={(record) => `${record.chanName}${new Date().getTime()}`}
				sticky={ true }
				dataSource={ channelTableData }>
				<Column align='center' title="项目名称"  dataIndex="appName" />
				<Column align='center' title="频道名称"  dataIndex="chanName" />
				<Column align='center' title="频道时间" render={ (email: any, record: any): ReactElement => (
					<div className={ styles.renderDiy }>
						<p>{ record.startTime }</p>
                        <span className={ styles.span }>-</span>
                        <div className={ record.endTime === '进行中'? styles.proceed : '' }>
                            <span className={ styles.proceedDot }></span>
                            <span>{ record.endTime }</span>
                        </div>
					</div>
				) } />
                <Column align='center' title="用户数" dataIndex="chanUNum" />
				<Column align='center' title="时长" dataIndex="durationTime" />
			</Table>
            <MonitoringHorse show={ transitionSendReceiv.convertible } change={ swopFn } />
            <div className={ styles.echarts }>
                <div className={ styles.operation }>
                    <Radio.Group onChange={ (value) => radioButtonChangeFn(value.target.value) } value={ radioButton }>
                        <Radio value='视频'>视频</Radio>
                        <Radio value='音频'>音频</Radio>
                    </Radio.Group>
                    <Button type="primary" className={ styles.button } onClick={ () => dialogTableVisibleFn() }>查看详情事件</Button>
                    {
                        dialogExamine.map((dialog: any, index: number): ReactElement => {
                            return <Button type="primary" className={ styles.button } key={ index } onClick={ () => examineFn(index) }><p>{ dialog.info }</p></Button>

                        })
                    }
                </div>
                <div className={ styles.echartsContent }>
                    <div className={ styles.echartsItem }>
                        <div className={ styles.echartsTitle }>
                            <img src={ require('../../../../assets/images/fasong.svg').default } alt='' />
                            <span className={ [styles.fontBlod, styles.send].join(' ') }>{ sendingUid }</span>
                        </div>
                        <div className={ styles.echartsTitleTwo }>
                            {
                                sendingInfo.map((sendinfo: any, index: number): ReactElement => {
                                    return (<div key={ index } className={ styles.echartsTitleTwoMain }>
                                        <p>{ sendinfo.name }：</p>
                                        <span>{ sendinfo.type }</span>
                                    </div>)
                                })
                            }
                        </div>
                        <div className={ styles.echartsMap }>
                            {
                                sendingDatas.map((sending: any, index: number): ReactElement => {
                                    return (<div key={ index } className={ styles.mapItem }>
                                            <div className={ styles.mapTitle }>
                                                <div className={ styles.div }>
                                                    <h4 className={ styles.echartsContentTitke }>{ sending.title }</h4>
                                                    <Tooltip placement="top" color='green' className={ styles.item } title={ sending.tooltip }>
                                                        <img className={ styles.adduserSingleAddIcn }
                                                        src={ require('../../../../assets/images/tishi.svg').default }
                                                        alt='' />
                                                    </Tooltip>
                                                </div>
                                                <div id={ sending.id + 'restore' } className={ styles.restore } onClick={ () => restoreFn() }>
                                                    <Button type="primary" icon={<IconFont type="icon-zhongzhi" />}>reset</Button>
                                                </div>
                                            </div>
                                            <div id={ sending.id } className={ styles.echartsData }></div>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                    <div className={ styles.echartsItem }>
                        <div className={ styles.echartsTitle }>
                            <img src={ require('../../../../assets/images/jieshou.svg').default } alt='' />
                            <span className={ [styles.fontBlod, styles.ordinary].join(' ') }>{ receivingPeerid }</span>
                        </div>
                        <div className={ styles.echartsTitleTwo }>
                            {
                                receivingInfo.map((receivinfo: any, index: number): ReactElement => {
                                    return (<div key={ index } className={ styles.echartsTitleTwoMain }>
                                            <p>{ receivinfo.name }：</p>
                                            <span className={ styles.fontBlod }>{ receivinfo.type }</span>
                                    </div>)
                                })
                            }
                        </div>
                        <div className={ styles.echartsMap }>
                            {
                                receivingDatas.map((receiving: any, index: number): ReactElement => {
                                    return (<div key={ index } className={ styles.mapItem }>
                                        <div className={ styles.mapTitle }>
                                            <div className={ styles.div }>
                                                <h4 className={ styles.echartsContentTitke }>{ receiving.title }</h4>
                                                <Tooltip placement="top" color='green' className={ styles.item } title={ receiving.tooltip }>
                                                    <img className={ styles.adduserSingleAddIcn }
                                                    src={ require('../../../../assets/images/tishi.svg').default }
                                                    alt='' />
                                                </Tooltip>
                                            </div>
                                            <div id={ receiving.id + 'restore' } className={ styles.restore } onClick={ () => restoreFn() }>
                                                <Button type="primary" icon={<IconFont type="icon-zhongzhi" />}>reset</Button>
                                            </div>
                                        </div>
                                        <div id={ receiving.id } className={ styles.echartsData }></div>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="事件详情" className={ styles.modal } centered footer={ null } getContainer={ false } width='70%' visible={ dialogTableVisible } onCancel={ () => closeFn() }>
                <div className={ styles.modalTitle }>
                    <div>
                        <span className={ [styles.dit, styles.normalBgcolor].join(' ') }></span>
                        <span>正常事件</span>
                    </div>
                    <div>
                        <span className={ [styles.dit, styles.ordinaryBgcolor].join(' ') }></span>
                        <span>一般事件</span>
                    </div>
                    <div>
                        <span className={ [styles.dit, styles.severityBgcolor].join(' ') }></span>
                        <span>严重事件</span>
                    </div>
                </div>
                <div className={ styles.content }>
                    <div className={ styles.item }>
                        <div className={ styles.echartsTitleSend }>
                            <img src={ require('../../../../assets/images/fasong.svg').default } alt='' />
                            <span className={ [styles.send, styles.name].join(' ') }>
                                {
                                transitionSendReceiv.convertible && transitionSendReceiv.transition && true
                                ? searchData.peerId
                                : searchData.uId
                                }
                            </span>
                        </div>
                        <div className={ styles.echartsDetail }>
                            {
                                sendingInfo.map((sendinfo: any, index: number): ReactElement => {
                                    return (<div key={ index } className={ styles.echartsDetailMain }>
                                            <span>{ sendinfo.name }：</span>
                                            <span className={ styles.type }>{ sendinfo.type }</span>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                    <div className={ styles.item }>
                        <div className={ styles.echartsTitleReq }>
                            <img src={ require('../../../../assets/images/jieshou.svg').default } alt='' />
                            <span className={ [styles.ordinary, styles.name].join(' ') }>
                                {
                                transitionSendReceiv.convertible && transitionSendReceiv.transition && true
                                ? searchData.uId
                                : searchData.peerId
                                }
                            </span>
                        </div>
                        <div className={ styles.echartsDetail }>
                            {
                                receivingInfo.map((receivinfo: any, index: number): ReactElement => {
                                    return (<div key={ index } className={ styles.echartsDetailMain }>
                                            <span>{ receivinfo.name }：</span>
                                            <span className={ styles.type }>{ receivinfo.type }</span>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={ styles.tableContent }>
                    <Table
                    loading={ infoLoding } 
                    className={ styles.table }
                    pagination={ false }
                    rowKey={() => `${Math.floor(Math.random() * 1233333)}`}
                    sticky={ true }
                    dataSource={ gridSendData }>
                        <Column align='center' title="时间" render={ (email: any, record: any): ReactElement => (
                            <div>
                                { record.type === 1 && <span className={ [styles.dit, styles.normalBgcolor].join(' ') }></span> }
                                { record.type === 2 && <span className={ [styles.dit, styles.ordinaryBgcolor].join(' ') }></span> }
                                { record.type === 3 && <span className={ [styles.dit, styles.severityBgcolor].join(' ') }></span> }
                                { record.type === 0 && <span className={ styles.dit }></span> }
                                <span>{ record.time }</span>
                            </div>
                        ) } />
                        <Column align='center' title="事件" dataIndex="desc" />
                    </Table>
                    <Table
                    loading={ infoLoding } 
                    className={ styles.table }
                    pagination={ false }
                    rowKey={() => `${Math.floor(Math.random() * 1233333)}`}
                    sticky={ true }
                    dataSource={ gridReceivData }>
                        <Column align='center' title="时间" render={ (email: any, record: any): ReactElement => (
                            <div>
                                { record.type === 1 && <span className={ [styles.dit, styles.normalBgcolor].join(' ') }></span> }
                                { record.type === 2 && <span className={ [styles.dit, styles.ordinaryBgcolor].join(' ') }></span> }
                                { record.type === 3 && <span className={ [styles.dit, styles.severityBgcolor].join(' ') }></span> }
                                { record.type === 0 && <span className={ styles.dit }></span> }
                                <span>{ record.time }</span>
                            </div>
                        ) } />
                        <Column align='center' title="事件" dataIndex="desc" />
                    </Table>
                </div>
            </Modal>
            <Examine 
            dialogExamine={ dialogExamine }
            chanSId={ searchData.chanSId }
            appId={ searchData.appId }
            lianluData={ lianluData }
            publishEventData={ publishEventData }
            subscribeEventData={ subscribeEventData }
            setDialog={ setDialog }
            publish={{
                uId: transitionSendReceiv.convertible && transitionSendReceiv.transition && true
                    ? searchData.peerId
                    : searchData.uId,
                data: sendingInfo
            }}
            subscribe={{
                uId: transitionSendReceiv.convertible && transitionSendReceiv.transition && true
                    ? searchData.uId
                    : searchData.peerId,
                data: receivingInfo
            }}
            />
        </div>
    )
};

export default MonitoringDetails;