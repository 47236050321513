import { ChangeEvent, FC, ReactElement, useState, useEffect } from 'react';
import { Input, Button, message } from 'antd';
import { Option } from '../../axios/axios-types';
import { contentModule } from '../../api/permiss';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PERMISSIONS, ROLEID, TOKEN, AID } from '../../store/reducer';
import styles from './index.less';
import axios from '../../axios/axios';
import md5 from 'js-md5';

interface Data {
	code: number;
	data: {
		arrPerm: PERMISSIONS;
		roleId: ROLEID;
		token: TOKEN;
		aid: AID;
	};
};

interface LoginResult {
	data: Data;
	headers: {
		'artc-token': string
	};
};

interface IProps {
	PERMISSIONS?: Function;
	ROLEID?: Function;
	TOKEN?: Function;
	AID?: Function;
};


const Login: FC<IProps> = (props): ReactElement => {
	const [loading, setLoading]: [boolean, Function] = useState(false);
	const [email, setEmail]: [string, Function] = useState('');
	const [pwd, setPwd]: [string, Function] = useState('');
	const history: any = useHistory();

	const handleClick: Function = async (): Promise<void> => {
		if (email.trim() && pwd.trim()) {
			const option: Option = {
				url: '/developers/login',
				data: {
					email, 
					pwd: md5(pwd)
				},
				method: 'POST'
			};
			setLoading(true);
			const result: LoginResult = await axios(option);
			setLoading(false);
			if (result.data.code === 0) {
				setPwd('');
				setEmail('');
				props.PERMISSIONS && props.PERMISSIONS(result.data.data.arrPerm);
				props.ROLEID && props.ROLEID(result.data.data.roleId);
				props.TOKEN && props.TOKEN(result.headers["artc-token"]);
				props.AID && props.AID(result.data.data.aid);
				message.success('登录成功');
				history.push(contentModule());
			} else if (result.data.code === 1102) {
				message.error('该帐户已禁用');
			} else if (result.data.code !== 0) {
				message.error("帐号或密码错误");
			}
		} else {
			message.warning('请输入账号密码');
		}
	};

	const handleChange: Function = (e: ChangeEvent<HTMLInputElement>, type: string): void => {
		if (type === 'email') {
			setEmail(e.target.value);
		} else {
			setPwd(e.target.value);
		}
	};

	const handleKeydown = (e: KeyboardEvent): void => {
		if (e.key === 'Enter') {
			handleClick();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeydown);
		return function() {
			window.removeEventListener('keydown', handleKeydown);
		}
	});

	return (
		<div className={ styles.login }>
			<div className={ styles.loginContent }>
				<p>anyRTC User Management Background</p>
				<Input value={ email } onChange={ (e) => handleChange(e, 'email') } className={ styles.input } placeholder="Please enter email address"/>
				<Input value={ pwd } type='password' onChange={ (e) => handleChange(e, 'pwd') } className={ styles.input } placeholder="Please enter your password"/>
				<Button className={ styles.button } type="primary" loading={ loading } onClick={ () => handleClick() }>login</Button>
			</div>
		</div>
	)
};

type MapDispatchToProps = (dispatch: Function) => object

const mapDispatchToProps: MapDispatchToProps = (dispatch: Function): object => {
	return {
		PERMISSIONS: (value: PERMISSIONS): object => dispatch({ type: 'PERMISSIONS', value }),
		ROLEID: (value: ROLEID): object => dispatch({ type: 'ROLEID', value }),
		TOKEN: (value: TOKEN): object => dispatch({ type: 'TOKEN', value }),
		AID: (value: AID): object => dispatch({ type: 'AID', value }),
	}
};

export default connect(null, mapDispatchToProps)(Login);