import { FC, ReactElement, useEffect, useState } from 'react';
import { Button, Table, Modal, message } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { Option } from '../../../../axios/axios-types';
import { getTimestampToTime } from '../../../../api/index';
import { useLocation, useHistory } from 'react-router-dom';
import axios from '../../../../axios/axios';
import styles from './detail.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

const Detail: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [effect, setEffect]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [result, setResult]: [any, Function] = useState({});
	const [record, setRecord]: [any, Function] = useState({});
    const [deleteVisible, setDeleteVisible]: [boolean, Function] = useState(false);

    useEffect((): void => {
        getSdkDetailInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current, effect]);

    props.cacheLifecycles.didRecover(() => {
        getSdkDetailInfo();
    });

    const getSdkDetailInfo: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/sdk/getSdkDetailInfo',
            method: 'POST',
            data: {
                typeId: location.state.typeId,
                softId: location.state.softId,
                pageNum: current,
                pageSize,
            }
        };
        setLoading(true);
        const result: Result = await axios(option);
        setLoading(false);
        if (result.data.code === 0) {
            result.data.data.uploadAt = result.data.data.uploadAt? getTimestampToTime(result.data.data.uploadAt) : '--';
            result.data.data.list.forEach((item: any): void => {
                item.createAt = getTimestampToTime(item.createAt);
            });
            setTotal(result.data.data.totalNumber);
            setTableData(result.data.data.list);
            setResult(result.data.data);
        } else {
            message.error('数据获取失败');
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleBack: Function = (): void => {
		history.goBack();
	};

    const handleDownload: Function = (record: any): void => {
        const link: any = document.createElement('a');
        const url: string = record.url;
        fetch(url).then((res: any): any => res.blob()).then((blob: any): void => {
            link.href = URL.createObjectURL(blob);
            link.download = record.fileName;
            document.body.appendChild(link);
            link.click();
        });
    };

    const handleDelete: Function = (record: any): void => {
        setRecord(record);
        setDeleteVisible(true);
    };

    const handleRemove: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/sdk/deleteSdkVersion',
            data: {
                versionId: record.versionId
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("SDK删除成功");
            setDeleteVisible(false);
            setEffect(!effect);
        } else {
            message.error("SDK删除失败");
        }
    };

    const handleEdit: Function = (record: any): void => {
        history.push('/content/version/dealVersion', record);
    };

    const handleUpdate: Function = (): void => {
        history.push('/content/version/dealVersion', location.state);
    };

    return (
        <div className={ styles.detail }>
            <div className={ styles.inforContent }>
                <div className={ styles.infor }>
                    <span>类型：</span>
                    <span>{ result.softName }</span>
                </div>
                <div className={ styles.infor }>
                    <span>SDK：</span>
                    <span>{ result.typeName }</span>
                </div>
                <div className={ styles.infor }>
                    <span>上传日期：</span>
                    <span>{ result.uploadAt }</span>
                </div>
                <div className={ styles.btnBox }>
                    <Button onClick={ () => handleUpdate() } disabled={ !findPermissions(22201) } className={ styles.btn } type="primary">Update</Button>
                    <Button onClick={ () => handleBack() } className={ styles.btn }>Back</Button>
                </div>
            </div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ loading } 
                className={ styles.table }
                pagination={{
                    defaultCurrent: 1,
                    current,
                    showSizeChanger: true,
                    pageSize,
                    total 
                }}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="SDK版本"  dataIndex="bigVersion" />
                <Column align='center' title="更新内容" dataIndex="updateDesc" />
                <Column align='center' title="更新时间" dataIndex="createAt" />
                <Column align='center' width={ 230 } title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { findPermissions(22111) && <p onClick={ () => handleEdit(record) } className={ styles.operation }>编辑</p> }
                        { findPermissions(22121) && <p onClick={ () => handleDownload(record) } className={ styles.operation }>下载</p> }
                        { findPermissions(22131) && <p onClick={ () => handleDelete(record) } className={ [styles.operation, styles.delete].join(' ') }>删除</p> }
                    </div>
                ) } />
            </Table>
            <Modal title='删除SDK类型' width={ 500 } getContainer={ false } centered visible={ deleteVisible } onOk={ () => handleRemove() } onCancel={ () => setDeleteVisible(false)}>
                <p>删除SDK类型后，以下所有版本信息将一并删除</p>
            </Modal>
        </div>
    )
};

export default Detail;