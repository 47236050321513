import { CSSProperties, FC, ReactElement, useEffect, useState } from 'react';
import { Descriptions, Form, Button, Table, message, Modal, Radio, Select, Input } from 'antd';
import { getTimestampToTime } from '../../../../api/index';
import { findPermissions } from '../../../../api/permiss';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import md5 from 'js-md5';
import styles from './member.less';
const { Column } = Table;

namespace Antd {
    export const { Option } = Select;
};

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const labelStyle: CSSProperties = { 
    width: '70px', 
    textAlign: 'right', 
    display: 'inline-block',
    flexShrink: 0
};

const Member: FC = (): ReactElement => {
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
    const [record, setRecord]: [any, Function] = useState({});
    const [addVisible, setAddVisible]: [boolean, Function] = useState(false);
    const [insertAdmin, setInsertAdmin]: [any, Function] = useState({});
    const [options, setOptions]: [any[], Function] = useState([]);
    const [nextLoading, setNextLoading]: [boolean, Function] = useState(false);
    const [visible, setVisible]: [boolean, Function] = useState(false);
    const [modalTitle, setModalTitle]: [string, Function] = useState('');
    const [modalType, setModalType]: [string, Function] = useState('');
    const [pwd, setPwd]: [string, Function] = useState('');

    
    useEffect((): void => {
		getAdminList();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize, current]);

    const getSimpleRoleList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/perm/getSimpleRoleList',
            method: 'GET'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setOptions(result.data.data);
        }
    };

    const getAdminList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/perm/getAdminList',
            data: {
                pageNum: current,
                pageSize,
            },
            method: 'POST',
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                if (item.perm.indexOf("等相关权限") === -1) {
                    item.perm = item.perm + "等相关权限";
                }
                item.createTs = getTimestampToTime(item.createTs);
                item.loginTs = getTimestampToTime(item.loginTs);
            });
            setTableData(result.data.data.list);
            setTotal(result.data.data.totalNumber);
        } else {
            message.error('数据获取失败');
        }
    };

    const onFinish: any = async (): Promise<void> => {
        const option: Option = {
            url: '/perm/insertAdmin',
            data: {
                email: insertAdmin.email,
                name: insertAdmin.name,
                pwd: md5(insertAdmin.pwd),
                roleId: insertAdmin.roleId
            },
            method: 'POST'
        };
        setNextLoading(true);
        const result: Result = await axios(option);
        setNextLoading(false);
        if (result.data.code === 0) {
            message.success("成员添加成功");
            getAdminList();
            handleAddOnCancel();
        } else if (result.data.code === 1101) {
            message.error(`只能创建一个超级管理员`);
        } else if (result.data.code === 1002) {
            message.error(`该邮箱已经存在`);
        } else {
            message.error("成员添加失败");
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleAddMember: Function = (): void => {
        getSimpleRoleList();
        setAddVisible(true);
    };

    const handleAddOnCancel: Function = (): void => {
        setInsertAdmin({});
        setAddVisible(false);
    };

    const handleChange: Function = (value: any, type: string): void => {
        const insertAdmi: any = JSON.parse(JSON.stringify(insertAdmin));
        insertAdmi[type] = value;
        setInsertAdmin(insertAdmi);
    };

    const handleRecordChange: Function = (value: any, type: string): void => {
        const recor: any = JSON.parse(JSON.stringify(record));
        recor[type] = value;
        setRecord(recor);
    };

    const validateLimit: any = async (rule: any, value: any): Promise<void> => {
        if (rule.field === "name") {
            if (!value || value.replace(/\s/g, "") === "") {
                throw new Error('请输入成员名称');
            }
        } else if (rule.field === "email") {
            const reg: RegExp = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
            if (!reg.test(value)) {
                throw new Error("请输入要创建的帐户");
            }
        } else if (rule.field === "pwd") {
            if (!value || value.replace(/\s/g, "") === "") {
                throw new Error("请输入您的登录密码");
            }
        } else if (rule.field === "roleId") {
            if (!value) {
                throw new Error("选择成员的角色");
            }
        }
    };

    const handleOperation: Function = (record: any, type: string): void => {
        setRecord(record);
        setPwd('');
        setVisible(true);
        setModalType(type);
        if (type === 'edit') {
            getSimpleRoleList();
            setModalTitle('编辑成员');
        } else if (type === 'delete') {
            setModalTitle('删除成员');
        }
    };

    const handleModalOk: Function = (): void => {
        if (modalType === 'edit') {
            handleAxios({
                url: '/perm/updateAdmin',
                data: {
                    adminId: record.adminId,
                    roleId: record.roleId,
                    pwd: pwd === ''? '' : md5(pwd),
                    state: record.state
                },
                success: '成员修改成功',
                error: '成员修改失败'
            });
        } else if (modalType === 'delete') {
            handleAxios({
                url: '/perm/deleteAdmin',
                data: { adminId: record.adminId },
                success: '成员删除成功',
                error: '成员删除失败'
            });
        }
    };

    const handleAxios: Function = async (config: any): Promise<void> => {
        const option: Option = {
            url: config.url,
            data: config.data,
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success(config.success);
            setVisible(false);
            getAdminList();
        } else {
            message.error(config.error);
        }
    };

    return (
        <div className={ styles.member }>
            <div className={ styles.addMember }>
                <p className={ styles.title }>成员管理</p>
                <Button type="primary" disabled={ !findPermissions(25111) } onClick={ () => handleAddMember() }>添加成员</Button>
            </div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
                    defaultCurrent: 1,
                    current,
                    showSizeChanger: true,
                    pageSize,
                    total
                }}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="成员名称"  dataIndex="name" />
                <Column align='center' title="成员账号" dataIndex="email" />
                <Column align='center' title="角色" dataIndex="roleName" />
                <Column align='center' title="创建时间" dataIndex="createTs" />
                <Column align='center' title="上次登录时间" dataIndex="loginTs" />
                <Column align='center' title="权限" dataIndex="perm" />
                <Column align='center' title="状态" render={ (operation: any, record: any): ReactElement => (
                    <span>{ record.state === 1? '启用' : '禁用' }</span>
                ) } />
                <Column align='center' title="访问次数" dataIndex="loginCount" />
                <Column align='center' width={ 200 } title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { findPermissions(25113) && <p onClick={ () => handleOperation(record, 'edit') } className={ styles.operation }>编辑</p> }
                        { (findPermissions(25112) && record.roleId !== 1) && <p onClick={ () => handleOperation(record, 'delete') } className={ [styles.operation, styles.delete].join(' ') }>删除</p> }
                    </div>
                ) } />
            </Table>

            <Modal title='添加成员' width={ 778 } getContainer={ false } 
            visible={ addVisible } centered footer={ null }
            onCancel={ () => handleAddOnCancel() }>
                <Form
                name="basic"
                layout="inline"
                onFinish={ onFinish }
                initialValues={ insertAdmin }
                autoComplete="off"
                >
                    <Form.Item label="成员名称" className={ styles.formItem } name='name' rules={[{ required: true }, { validator: validateLimit }]}>
                        <Input className={ styles.input } value={ insertAdmin.name } onChange={ (e) => handleChange(e.target.value, 'name') } placeholder="请输入成员名称" />
                    </Form.Item>
                    <Form.Item label="创建账号" className={ styles.formItem } name='email' rules={[{ required: true }, { validator: validateLimit }]}>
                        <Input className={ styles.input } value={ insertAdmin.email } onChange={ (e) => handleChange(e.target.value, 'email') } placeholder="请输入要创建的帐户" />
                    </Form.Item>
                    <Form.Item label="登录密码" className={ styles.formItem } name='pwd' rules={[{ required: true }, { validator: validateLimit }]}>
                        <Input className={ styles.input } value={ insertAdmin.pwd } onChange={ (e) => handleChange(e.target.value, 'pwd') } placeholder="请输入您的登录密码" />
                    </Form.Item>
                    <Form.Item label="成员角色" className={ styles.formItem } name='roleId' rules={[{ required: true }, { validator: validateLimit }]}>
                        <Select
                        value={ insertAdmin.roleId || undefined }
                        style={{ width: 268 }}
                        placeholder="选择成员的角色"
                        onChange={ (value) => handleChange(value, 'roleId') }
                        >
                            {
                                options.map((item: any, index: number): ReactElement => {
                                    return <Antd.Option key={ index } value={ item.roleId }>{ item.roleName }</Antd.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <div className={ styles.btnBox }>
                            <Button className={ styles.btn } onClick={ () => handleAddOnCancel() }>Cancel</Button>
                            <Button className={ styles.btn } htmlType="submit" disabled={ nextLoading } loading={ nextLoading } type="primary">Ok</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title={ modalTitle } width={ 700 } getContainer={ false } centered visible={ visible } onOk={ () => handleModalOk() } onCancel={ () => setVisible(false)}>
                { modalType === 'delete' && <p>您确定要删除该成员 ({ record.name }) 吗?</p> }
                { modalType === 'edit' && <Descriptions column={ 2 }>
                    <Descriptions.Item labelStyle={ labelStyle } label="成员名称">{ record.name }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="账号">{ record.email }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="成员角色">
                        <Select
                        value={ record.roleId || undefined }
                        style={{ width: 240 }}
                        placeholder="选择成员的角色"
                        onChange={ (value) => handleRecordChange(value, 'roleId') }
                        >
                            {
                                options.map((item: any, index: number): ReactElement => {
                                    return <Antd.Option key={ index } value={ item.roleId }>{ item.roleName }</Antd.Option>
                                })
                            }
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="状态">
                        <Radio.Group onChange={ (e) => handleRecordChange(e.target.value, 'state') } value={ record.state }>
                            <Radio value={ 1 }>启用</Radio>
                            <Radio value={ 2 }>禁用</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="登录密码">
                        <Input placeholder="请输入新密码" className={ styles.DescrInput } value={ pwd } onChange={ (e) => setPwd(e.target.value) } />
                    </Descriptions.Item>
                </Descriptions> }
            </Modal>
        </div>
    )
};

export default Member;