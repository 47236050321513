import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { MenuInfo } from 'rc-menu/lib/interface';
import { dropByCacheKey, getCachingKeys, CacheRoute } from 'react-router-cache-route';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import pubsub from 'pubsub-js';
import Formal from './formal/formal';
import Applyfor from './applyfor/applyfor';
import Review from './review/review';
import Expiring from './expiring/expiring';
import Dueformally from './dueformally/dueformally';
import Period from './period/period';
import styles from './index.less';

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

const Index: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const location: any = useLocation();
    const [options, setOptions]: [any[], Function] = useState([
        {
            perm: 30001,
            key: 'private',
            num: 0,
            icon: 'icon-kehu',
            label: '正式客户'
        },
        {
            perm: 32001,
            key: 'applyfor',
            num: 0,
            icon: 'icon-ceshishenqing',
            label: '申请'
        },
        {
            perm: 33001,
            key: 'review',
            num: 0,
            icon: 'icon-shenhe',
            label: '审核'
        },
        {
            perm: 34001,
            key: 'expiring',
            num: 0,
            icon: 'icon-shalou',
            label: '快到期正式用户'
        },
        {
            perm: 35001,
            key: 'dueformally',
            num: 0,
            icon: 'icon-daoqi',
            label: '到期正式用户'
        },
        {
            perm: 31001,
            key: 'period',
            num: 0,
            icon: 'icon-kehu',
            label: '测试期客户'
        },
    ]);
    const [selectedKeys, setSelectedKeys]: [string, Function] = useState('private');
    
    useEffect((): void => {
        getSummaryUserNum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    props.cacheLifecycles.didRecover(() => {
        getSummaryUserNum();
    });

    const getSummaryUserNum: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/getSummaryUserNum',
            method: 'GET'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const optio: any[] = JSON.parse(JSON.stringify(options));
            optio.forEach((item: any): void => {
                switch (item.key) {
                    case "private":
                        item.num = result.data.data.officalUserNum;
                        break;
                    case "applyfor":
                    case "review":
                        item.num = result.data.data.applyVerifyUserNum;
                        break;
                    case "expiring":
                        item.num = result.data.data.nearExpireUserNum;
                        break;
                    case "dueformally":
                        item.num = result.data.data.expireUserNum;
                        break;
                    case "period":
                        item.num = result.data.data.testUserNum;
                        break;
                }
            });
            setOptions(optio);
        }
    };

    const handleClick: Function = (e: MenuInfo): void => {
        setSelectedKeys(e.key);
        getSummaryUserNum();
        getCachingKeys().forEach((item: string): void => {			
			dropByCacheKey(item);
		});
        setTimeout(() => {
            if (e.key === 'private') {
                history.push(`/content/${e.key}`, location.state);
            } else {
                history.push(`/content/private/${e.key}`, location.state);
            }
        }, 0);
    };

    useEffect((): void => {
        const path: Array<string> = location.pathname.split('/');
        setSelectedKeys(path[path.length - 1]);
    }, [location.pathname]);

    useEffect((): void => {
        pubsub.unsubscribe('getSummaryUserNum');
        pubsub.subscribe('getSummaryUserNum', (): void => {
            getSummaryUserNum();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <div>
            <Menu onClick={ (e) => handleClick(e) } selectedKeys={ [selectedKeys] } mode="horizontal">
                {options.map((item: any, index: number): ReactElement => {
                    return (<Fragment key={ index }>
                        { findPermissions(item.perm) && <Menu.Item key={ item.key } className={ styles.menuItem }>{ item.label } { item.num }</Menu.Item> }
                    </Fragment>)
                })}
            </Menu>
            { findPermissions(30001) && <CacheRoute path='/content/private' cacheKey='private' component={ Formal } exact /> }
            { findPermissions(32001) && <CacheRoute path='/content/private/applyfor' cacheKey='applyfor' component={ Applyfor } exact /> }
            { findPermissions(33001) && <CacheRoute path='/content/private/review' cacheKey='review' component={ Review } exact /> }
            { findPermissions(34001) && <CacheRoute path='/content/private/expiring' cacheKey='expiring' component={ Expiring } exact /> }
            { findPermissions(35001) && <CacheRoute path='/content/private/dueformally' cacheKey='dueformally' component={ Dueformally } exact /> }
            { findPermissions(31001) && <CacheRoute path='/content/private/period' cacheKey='period' component={ Period } exact /> }
        </div>
    )
};

export default Index;