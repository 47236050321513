import { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Option } from '../../../../../../axios/axios-types';
import { findPermissions } from '../../../../../../api/permiss';
import { getTimestampToTime } from '../../../../../../api';
import { Table } from 'antd';
import { State, Stage, AuthType } from '../../../../../../types/enum';
import axios from '../../../../../../axios/axios';
import TableDataAttr from '../tableDataAttr';
import styles from './appTable.less';

const { Column } = Table;

interface Data {
	code: number;
	data: {
		respUserAppS: Array<TableDataAttr>;
		amount: number;
	};
};

interface Result {
	data: Data;
};

const AppTable: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [tableData, setTableData]: [Array<TableDataAttr>, Function] = useState([]); 
    const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);

    useEffect((): void => {
        const getUserAppList: Function = async (): Promise<void> => {
            setTableLoading(true);
			const option: Option = {
				url: '/developers/getUserAppList',
				method: 'POST',
				data: {
					sortRule,
					pageNum: current,
					pageSize,
                    uid: location.state.uid 
				}
			};
            const result: Result = await axios(option);
            result.data.data.respUserAppS.forEach((item: TableDataAttr): void => {
                item.auth_type = AuthType[item.auth_type as number];
                item.stage = Stage[item.stage as number];
                item.state = State[item.state as number];
                item.create_at = getTimestampToTime(item.create_at);
            });
            setTableLoading(false);
            setTotal(result.data.data.amount);
            setTableData(result.data.data.respUserAppS);
        };
        getUserAppList();
    }, [pageSize, current, sortRule, location.state.uid]);

    useEffect((): void => {
		setCurrent(1);
	}, [sortRule]);

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
	};

    const handleClickEditor: Function = (record: TableDataAttr): void => {
        history.push('/content/user/detail/appInformation/projectEditor', Object.assign(location.state, record));
    };

    const handleClickDosage: Function = (record: TableDataAttr): void => {
        history.push('/content/user/detail/dosage', Object.assign(location.state, record));
    };

    return (
        <div className={ styles.appTable }>
            <p className={ styles.title }>应用信息</p>
            <Table dataSource={ tableData } rowKey='appid'
            loading={ tableLoading } 
            className={ styles.table }
            pagination={{
                defaultCurrent: 1,
				showSizeChanger: true,
                current,
                pageSize,
                total
            }}
            sticky={ true }
			sortDirections={['ascend', 'descend', 'ascend']}
            onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }>
                <Column align='center' title="阶段"
                render={(operation: any, record: TableDataAttr): ReactElement => (
                    <p className={ record.stage === '已上线'? styles.stageEnable : styles.stageNoEnable }>{ record.stage }</p>
                )}
                />
                <Column align='center' title="项目" dataIndex="name" />
                <Column align='center' showSorterTooltip={ false } sorter={ true } defaultSortOrder='descend' title="创建日期" dataIndex="create_at" />
                <Column align='center' title="状态" dataIndex="state" 
                render={(operation: any, record: TableDataAttr): ReactElement => (
                    <p className={ record.state === '启用'? styles.stateEnable : styles.stateNoEnable }>{ record.state }</p>
                )}
                />
                { findPermissions(11211) && <Column
                title="用量"
                align='center'
                render={(operation: any, record: TableDataAttr): ReactElement => (
                    <p className={ styles.operation } onClick={ () => handleClickDosage(record)} >用量</p>
                )}
                /> }
                { findPermissions(11221) && <Column
                title="操作"
                align='center'
                render={(operation: any, record: TableDataAttr): ReactElement => (
                    <p className={ styles.operation } onClick={ () => handleClickEditor(record) }>编辑</p>
                )}
                /> }
            </Table>
        </div>
    );
};


export default AppTable;