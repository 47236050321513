import { FC, ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { CacheRoute } from 'react-router-cache-route';
import Index from './index/index';
import Detail from './detail/detail';
import DealVersion from './dealVersion/dealVersion';

const Version: FC = (): ReactElement => {
    return (
        <div>
            <CacheRoute path='/content/version' component={ Index } exact />
            <CacheRoute path='/content/version/detail' component={ Detail } exact />
            <Route path='/content/version/dealVersion' component={ DealVersion } exact />
        </div>
    )
};

export default Version;