const unique: Function = (arr: any[], sole: string): any[] => {
	let obj: any = {};
	let oArr = arr.reduce((cur, next): void => {
		if (obj[next[sole]]) {
			cur.forEach((item: any): void => {
				if (next[sole] === item[sole]) {
					item = Object.assign(item, next)
				}
			})
		} else {
			obj[next[sole]] = true;
			cur.push(next);
		}
		return cur;
	}, [])
	return oArr;
}

export { unique };
