import { timeTransformation, durationComputeAll } from "./time";

interface Data { 
    category: any; 
    mAppANum: any; 
    mAppVNum: any; 
    audNum: any; 
    hdNum: any; 
    hhdNum: any; 
    sAudNum: any; 
    sVidNum: any; 
    audioNum: any; 
    tkNum: any; 
    tkpNum: any; 
}

const callDetailsReconstitutionFn: Function = (data: Data, catName: any): any => {
    var oObj: any = {};
    const chartsColor: Array<string> = ["#1380FF", "#FF8723", "#1BBC72", "#FE4545", "#4554FE"];
    const oCategory: any = data.category || catName;
    var oName: string = "";
    switch (catName) {
		case "rtc":
			oName = "基础通讯";
			break;
		case "miniapp":
			oName = "小程序";
			break;
		case "push":
			oName = "旁路推流";
			break;
		case "cloudrec":
			oName = "云端录制";
			break;
    }
	if (oCategory === "miniapp") {
		oObj = {
			'Audio': data.mAppANum,
			'视频': data.mAppVNum
		};
	} else if (oCategory === "push") {
		oObj = {
			'Audio': data.audNum,
			'HD': data.hdNum,
			'Full HD': data.hhdNum,
			'单主播音频': data.sAudNum,
			'单主播视频': data.sVidNum
		};
	} else {
		oObj = {
			'Audio': data.audioNum,
			'HD': data.hdNum,
			'Full HD': data.hhdNum,
			'2K': data.tkNum,
			'2K+': data.tkpNum
		};
	}

	const oArray: Array<any> = [];
	let amount: number = 0;
	Object.keys(oObj).forEach((key: string, index: number): void => {
		amount = amount + Number(oObj[key]);
		oArray.push({
			name: key,
			minute: oObj[key],
			color: chartsColor[index]
		});
	});
	oObj.category = oCategory;
	oObj.amount = amount;
	oObj.catName = oName;
	return {
		obj: oObj,
		array: oArray
	};
};

const renderUserActiveTimeInChannel: Function = (list: any[], durationAll: number, startTime: number): string => {
  	var oDiv: string = ``;
	list.forEach((time: any): void => {
		time.leaveTs = time.leaveTs > 0? time.leaveTs : parseInt((Date.parse((new Date()).toString()) / 1000).toString());
		const oLeft: number = ((time.joinTs - startTime) / durationAll) * 100;
		const oWidth: number = ((time.leaveTs - time.joinTs) / durationAll) * 100;
		const oDivChild = `<div style="position: absolute;height: 22px;background: #1380FF;left:${oLeft + "%"};width:${oWidth + "%"};"></div>`;
		oDiv = oDiv + oDivChild;
	});
	return oDiv;
};

const dataReconstitutionFn: Function = (data: any[], channelList: { duration: number; chanSTs: number; appId: any; chanName: any; chanSId: any; }, echarShow = true, adduser = true): any => {
	const oEchartsData: Array<any> = [];
	const oSendLine: Array<any> = [];
	data.forEach((item: any): void => {
		const networkType: Array<string> = ["NETWORK_UNKNOWN", "LAN", "WIFI", "2G", "3G", "4G","5G"];
		item.netType = networkType[item.netType];
		const deviceType: Array<string> = ["其他", "Android", "iOS", "Web", "WeChat", "Windows", "Linux", "macOS"];
		item.os = item.os !== -1? deviceType[item.os] : "未知";
		item.sartsTime = timeTransformation(item.joinTs);
		item.endTime = item.leaveTs > 0 ? timeTransformation(item.leaveTs) : "进行中";
		// eslint-disable-next-line no-eval
		item.uTsList = eval(item.uTsList);
		item.durationAll = durationComputeAll(item.uTsList, "joinTs", "leaveTs").character;
		item.echarShow = echarShow;
		item.adduser = adduser;
		item.html = renderUserActiveTimeInChannel(
			item.uTsList,
			channelList.duration,
			channelList.chanSTs
		);

		if (item.uPub === 1) {
			oSendLine.push({
				uId: item.uId,
				color: item.color
			});
		}

		oEchartsData.push({
			appId: channelList.appId,
			chanId: channelList.chanName, 
			chanSId: channelList.chanSId,
			sid: item.uSid,
			uId: item.uId,
			sdkVersion: item.sdkVersion,
			os: item.os,
			echarShow: item.echarShow
		});
	});
	return { reconstitutionData: data, EchartData: oEchartsData, Send: oSendLine };
};

const dataComparison: Function = (data1: any[], data2: any[]): Array<any> => {
	data2.forEach((itemX: any): void => {
		data1.forEach((itemY: any): void => {
			itemY.uId === itemX.uId && (itemX.adduser = true);
		});
	});
	return data2;
};

const p2pDataReconstitutionFn: Function = async (data: any[], send: any, receiv: any): Promise<any> => {
	var oSend: any = {};
	var oSendArr: any = [];
	var oReceiv: any = {};
	var oReceivArr: Array<any> = [];

	var convertible: boolean = false;
	var convertibleSend: boolean = false;
	var convertibleReceiv: boolean = false;
	await data.forEach((item: any): void => {
		const networkType: Array<string> = ["NETWORK_UNKNOWN", "LAN", "WIFI", "2G", "3G", "4G", "5G"];
		item.netType = networkType[item.netType];
		const deviceType: Array<string> = ["其他", "Android", "iOS", "Web", "小程序", "Windows", "Linux", "macOS"];
		item.os = item.os !== -1? deviceType[item.os] : "未知";

		const obj: any = {
			area: item.area,
			sdkVersion: item.sdkVersion,
			os: item.os,
			netType: item.netType,
			deviceModel: item.deviceModel
		};

		if (item.uId === send) {
			oSend = obj;
			if (item.uPub === 1 && item.uSub === 1) {
				convertibleSend = true;
			}
		} else if (item.uId === receiv) {
			oReceiv = obj;
			if (item.uPub === 1 && item.uSub === 1) {
				convertibleReceiv = true;
			}
		}
	});
	for (let i in oSend) {
		const o: any = {};
		o.type = oSend[i];
		switch (i) {
			case "area":
				o.name = "区域";
				break;
			case "sdkVersion":
				o.name = "SDK版本号";
				break;
			case "os":
				o.name = "平台";
				break;
			case "netType":
				o.name = "网络";
				break;
			case "deviceModel":
				o.name = "设备";
				break;
		}
		oSendArr.push(o);
	}
	for (let i in oReceiv) {
		const o: any = {};
		o.type = oReceiv[i];
		switch (i) {
			case "area":
				o.name = "区域";
				break;
			case "sdkVersion":
				o.name = "SDK版本号";
				break;
			case "os":
				o.name = "平台";
				break;
			case "netType":
				o.name = "网络";
				break;
			case "deviceModel":
				o.name = "设备";
				break;
		}
		oReceivArr.push(o);
  	}
	if (convertibleSend && convertibleReceiv) {
		convertible = true;
	}
	return {
		Send: oSendArr,
		Receiv: oReceivArr,
		convertible
	};
};

export {
	renderUserActiveTimeInChannel,
	dataReconstitutionFn,
	dataComparison,
	callDetailsReconstitutionFn,
	p2pDataReconstitutionFn
};
