import { FC, Fragment, ReactElement, useState, useEffect } from 'react';
import { findPermissions } from '../../../../../api/permiss';
import { useHistory, useLocation, Route } from 'react-router-dom';
import { Button } from 'antd';
import Topup from './topup/topup';
import Billing from './billing/billing';
import Bill from './bill/bill';
import Record from './record/record';
import Invoice from './invoice/invoice';
import Package from './package/package';

import styles from './financial.less';

interface CatTypeList {
    label: string;
    path: string;
    perm: number;
}

const Financial: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
	const [catType, setCatType]: [number, Function] = useState(0);
    const [catTypeList]: [Array<CatTypeList>, Function] = useState([
        {
            perm: 11411,
            label: '充值',
            path: '/content/user/detail/financial',
        },
        {
            perm: 11421,
            label: '计费规划',
            path: '/content/user/detail/financial/billing',
        },
        {
            perm: 11431,
            label: '帐单列表',
            path: '/content/user/detail/financial/bill',
        },
        {
            perm: 11441,
            label: '交易记录',
            path: '/content/user/detail/financial/record',
        },
        {
            perm: 11451,
            label: '发票管理',
            path: '/content/user/detail/financial/invoice',
        },
        {
            perm: 11461,
            label: '套餐包管理',
            path: '/content/user/detail/financial/package',
        }
    ]);

    useEffect((): void => {
        const path = location.pathname.split('/');
        switch (path[path.length - 1]) {
            case 'financial':
                setCatType(0);
                break;
            case 'billing':
                setCatType(1);
                break;
            case 'bill':
                setCatType(2);
                break;
            case 'record':
                setCatType(3);
                break;
            case 'invoice':
            case 'detail':
            case 'pendingInvoicesDetails':
                setCatType(4);
                break;
            case 'package':
                setCatType(5);
                break;
        }
    }, [location.pathname]);

    const handleCatTypeClick: Function = (item: CatTypeList, index: number): void => {
        setCatType(index);
        history.push(item.path, location.state);
    };

    return (
        <div className={ styles.financial }>
            <p className={ styles.title }>财务</p>
            <div className={ styles.linkBtnCont }>
                {
                    catTypeList.map((item: CatTypeList, index: number): ReactElement => {
                        return <Fragment key={ index }>
                            { findPermissions(item.perm) && <Button className={ [styles.linkBtn, catType === index && styles.catType].join(' ') }
                                onClick={ () => handleCatTypeClick(item, index) }>{ item.label }</Button> }
                        </Fragment>
                    })
                }
            </div>
            { findPermissions(11411) && <Route path='/content/user/detail/financial' component={ Topup } exact /> }
            { findPermissions(11421) && <Route path='/content/user/detail/financial/billing' component={ Billing } /> }
            { findPermissions(11431) && <Route path='/content/user/detail/financial/bill' component={ Bill } /> }
            { findPermissions(11441) && <Route path='/content/user/detail/financial/record' component={ Record } /> }
            { findPermissions(11451) && <Route path='/content/user/detail/financial/invoice' component={ Invoice } /> }
            { findPermissions(11461) && <Route path='/content/user/detail/financial/package' component={ Package } /> }
        </div>
    );
};

export default Financial;

