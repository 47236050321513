import React, { ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route';
import { createFromIconfontCN } from '@ant-design/icons';
import { findPermissions, directoriesPath, financialPath, privateIndexPath, permissPath } from '../../api/permiss';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { scriptUrl } from '../../axios/baseUrl';
import styles from './index.less';

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

interface IProps extends RouteComponentProps {
	collapsed?: boolean;
};

interface IState {
	selectedKeys: Array<string>
};

class NavMenu extends React.Component<IProps, IState> {
	public state: IState = {
		selectedKeys: ['/content/user']
	};

	componentDidMount() {
		this.computedSelect();
		this.props.history.listen((): void => {
			this.computedSelect();
		})
	};

	public computedSelect: Function = (): void => {
		const path: Array<string> = window.location.pathname.split('/');
		if (path.includes('user')) {
			this.setState({ selectedKeys: ['/content/user'] });
		} else if (path.includes('problem')) {
			this.setState({ selectedKeys: ['/content/problem'] });
		} else if (path.includes('email')) {
			this.setState({ selectedKeys: ['/content/email'] });
		} else if (path.includes('version')) {
			this.setState({ selectedKeys: ['/content/version'] });
		} else if (path.includes('directories')) {
			this.setState({ selectedKeys: ['/content/directories'] });
		} else if (path.includes('financial')) {
			this.setState({ selectedKeys: ['/content/financial'] });
		} else if (path.includes('private')) {
			this.setState({ selectedKeys: ['/content/private'] });
		} else if (path.includes('permiss')) {
			this.setState({ selectedKeys: ['/content/permiss'] });
		}
	};

	public handleSelect: Function = (data: any): void => {
		getCachingKeys().forEach((item: string): void => {			
			dropByCacheKey(item);
		});
		setTimeout(() => {
			if (data.key === '/content/directories') {
				this.props.history.push(directoriesPath());
			} else if (data.key === '/content/financial') {
				this.props.history.push(financialPath());
			} else if (data.key === '/content/private') {
				this.props.history.push(privateIndexPath());
			} else if (data.key === '/content/permiss') {
				this.props.history.push(permissPath());
			} else {
				this.props.history.push(data.key);
			}
		}, 0);
	};

	public render(): ReactElement {
		return (
			<div className={ styles.navMenu }>
				<Menu
					id='navMenu'
					className={ [styles.menu, !this.props.collapsed && styles.openMenu].join(' ') }
					defaultSelectedKeys={ ['/content/user'] }
					mode="inline"
					theme="light"
					selectedKeys={ this.state.selectedKeys }
					onSelect={ (data) => this.handleSelect(data) }
					inlineCollapsed={ this.props.collapsed }
				>
					{ findPermissions(10001) && <Menu.Item className={ styles.menuItem } key="/content/user" icon={ <IconFont type="icon-yonghuguanli" /> }>
					用户管理
					</Menu.Item> }
					{ findPermissions(20001) && <Menu.Item className={ styles.menuItem } key="/content/problem" icon={ <IconFont type="icon-wenti-" /> }>
					查找问题
					</Menu.Item> }
					{ findPermissions(21001) && <Menu.Item className={ styles.menuItem } key="/content/email" icon={ <IconFont type="icon-youjian1" /> }>
					邮件管理
					</Menu.Item> }
					{ findPermissions(22001) && <Menu.Item className={ styles.menuItem } key="/content/version" icon={ <IconFont type="icon-huojian" /> }>
					SDK版本管理
					</Menu.Item> }
					{ findPermissions(23001) && <Menu.Item className={ styles.menuItem } key="/content/directories" icon={ <IconFont type="icon-chakan1" /> }>
					名单管理
					</Menu.Item> }
					{ findPermissions(24001) && <Menu.Item className={ styles.menuItem } key="/content/financial" icon={ <IconFont type="icon-qian1" /> }>
					财务管理
					</Menu.Item> }
					{ findPermissions(30000) && <Menu.Item className={ styles.menuItem } key="/content/private" icon={ <IconFont type="icon-privateClouds" /> }>
					私有云管理
					</Menu.Item> }
					{ findPermissions(25001) && <Menu.Item className={ styles.menuItem } key="/content/permiss" icon={ <IconFont type="icon-permissions" /> }>
					权限管理
					</Menu.Item> }
				</Menu>
			</div>
		);
	};
};

type MapStateToProps = (state: object) => object;

const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps)(withRouter(NavMenu));