import { FC, ReactElement, useEffect, useState } from 'react';
import { Select, Input, AutoComplete, Pagination, Button, Radio, message } from 'antd';
import { Option } from '../../../../../axios/axios-types';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { scriptUrl } from '../../../../../axios/baseUrl';
import { getDate, getTimestampToTime } from '../../../../../api/index';
import axios from '../../../../../axios/axios';
import FnAndCb from './fnAndCb/fnAndCb';
import styles from './media.less';

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

namespace Antd {
    export const { Option } = Select;
};

interface SelectData {
    value: string | number;
    label: string;
}

interface Data {
	code: number;
	data: {
		list: any[];
		totalNumber: number;
	};
};

interface Result {
	data: Data;
};
const Media: FC = (): ReactElement => {
    const [selectData, setSelectData]: [Array<SelectData>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [tableData, setTableData]: [any[], Function] = useState([]);
	const [appId, setAppId]: [string, Function] = useState('');
	const [startTs, setStartTs]: [number, Function] = useState(getDate(1) * 1000);
	const [idType, setIdType]: [number, Function] = useState(1);
	const [day, setDay]: [number, Function] = useState(1);
	const [first, setFirst]: [boolean, Function] = useState(true);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [value, setValue]: [string, Function] = useState('');
    const [radioValue, setRadioValue]: [string, Function] = useState('event');

    useEffect((): void => {
        getRedisAppId();
    }, []);

    useEffect((): void => {
		first? setFirst(false) : getV2ChannelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current]);

    const getRedisAppId: Function = async (): Promise<void> => {
        const option: Option = {
            url: 'stream/getStreamCacheAppId',
            method: 'POST'
        };
        const result: {
            data: {
                code: number;
                data: Array<{ appId: string }>;
            }
        } = await axios(option);
        if (result.data.code === 0) {
            const target: Array<SelectData> = [];
            result.data.data.forEach((item: { appId: string }): void => {
                target.push({
                    value: item.appId,
                    label: item.appId
                });
            });
            setSelectData(target);
        } else {
            message.error('获取appId失败');
        }
    };

    const getV2ChannelList: Function = async (): Promise<void> => {
        if (appId) {
            let stopTs: number = 0;
            day === 2 && (stopTs = (getDate(2) + 86399) * 1000);
            day !== 2 && (stopTs = parseInt((((new Date(new Date().toLocaleDateString()).getTime() / 1000) + 60 * 60 * 24 - 1) * 1000).toString()));
            setLoading(true);
            let url: string = '';
            if (idType === 1) {
                url = '/stream/getApiListByReqId';
            } else {
                url = radioValue === 'event'? '/stream/getApiListByPlayerId' : '/stream/getCbListByPlayerId';
            };
            const option: Option = {
                url,
                data: {
                    appId,
                    requestId: value,
                    playerId: value,
                    startTs,
                    stopTs,
                    pageNum: current,
					pageSize,
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            setLoading(false);
            if (result.data.code === 0) {
                result.data.data.list.forEach((item: any): void => {
                    if (item.apiReqTs) {
                        item.apiReqTs = getTimestampToTime(item.apiReqTs);
                    } else {
                        item.cbTs = getTimestampToTime(item.cbTs);
                    }
                });
                setTotal(result.data.data.totalNumber);
                setTableData(result.data.data.list);
            } else {
                message.error('数据获取失败');
            }
        } else {
            message.error('请选择appId');
        } 
    };

    const handlePagChange: Function = (page: number, pageSize: number): void => {
		setCurrent(page);
		setPageSize(pageSize);
	};

    const handleSelectChange: Function = (val: number): void => {
		setDay(val); 
        setStartTs(getDate(val === 3? 7 : val) * 1000);
	};

    const handleBtnClick: Function = (): void => {
        current === 1? getV2ChannelList() : setCurrent(1);
    };

    const handleRadioChange: Function = (value: string): void => {
        setRadioValue(value);
        setTableData([]);
        setTotal(0);
        setIdType(value === 'event'? 1 : 2);
    };

    const handleIconClick: Function = async (e: any, appId: string): Promise<void> => {
        e.stopPropagation();
        const option: Option = {
            url: 'stream/deleteStreamCacheAppId',
            data: { appId },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            getRedisAppId();
            message.success('appId删除成功');
        } else {
            message.error('appId删除失败');
        };
    };
    return (
        <div className={ styles.media }>
            <div className={ styles.search }>
                <AutoComplete
                placeholder='请输入appId'
                className={ styles.select }
                style={{ width: 200 }}
                onChange={ (val) => setAppId(val) }
                >
                    { selectData.map((item: any, index: number) => (
                        <AutoComplete.Option key={ index } value={ item.value }>
                            <div>{ item.value }</div>
                            <IconFont onClick={ (e) => handleIconClick(e, item.value) } type="icon-close" />
                        </AutoComplete.Option>
                    )) }
                </AutoComplete>
                <Select
                    className={ styles.select }
                    style={{ width: 200 }}
                    value={ day }
                    onChange={ (val) => handleSelectChange(val) }
                >
                    <Antd.Option value={ 1 }>今天</Antd.Option>
                    <Antd.Option value={ 2 }>昨天</Antd.Option>
                    <Antd.Option value={ 3 }>近七天</Antd.Option>
                </Select>
                <Select
                    className={ styles.select }
                    style={{ width: 200 }}
                    value={ idType }
                    onChange={ (val) => setIdType(val) }
                >
                    { radioValue === 'event' && <Antd.Option value={ 1 }>X-Request-ID</Antd.Option> }
                    <Antd.Option value={ 2 }>Player ID</Antd.Option>
                </Select>
                <Input placeholder="请输入查询条件" 
                className={ styles.select } 
                onChange={ (e) => setValue(e.target.value) }
                style={{ width: 200 }} />
                <Button 
                className={ styles.button } 
                loading={ loading }
                onClick={ () => handleBtnClick() } 
                type="primary" 
                icon={ <SearchOutlined /> }>
                Search
                </Button>
            </div>

            <div className={ styles.content }>
                <Radio.Group value={ radioValue } onChange={ (e) => handleRadioChange(e.target.value) } buttonStyle="solid">
                    <Radio.Button value="event">事件</Radio.Button>
                    <Radio.Button value="callback">回调</Radio.Button>
                </Radio.Group>

                <FnAndCb
                tableData={ tableData }
                type={ radioValue }
                />

                <Pagination 
                current={ current } 
                pageSize={ pageSize } 
                total={ total }
                hideOnSinglePage
                className={ styles.pagination }
                onChange={ (page, pageSize) => 
                handlePagChange(page, pageSize) } 
                />
            </div>
        </div>
    )
};

export default Media;