import { ChangeEvent, Fragment, FC, ReactElement, useState, useEffect } from 'react';
import { Input, Button, message, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { findPermissions, userMoudleAppPath } from '../../../../api/permiss';
import { useHistory } from 'react-router-dom';
import { TableAppDataAttr } from '../tableColumns';
import { AuthType, AppState } from '../../../../types/enum';
import { Option } from '../../../../axios/axios-types';
import { getTimestampToTime, moneyComma } from '../../../../api';
import axios from '../../../../axios/axios';
import styles from './tokenAuth.less';
const { Column } = Table;

interface Data {
	code: number;
	data: {
		list: Array<TableAppDataAttr>;
		totalNumber: number;
	};
};

interface Result {
	data: Data;
};

interface SearchResult {
	data: {
		code: number;
		data: Array<TableAppDataAttr>;
	}
};


const TokenAuth: FC = (): ReactElement => {
	const history: any = useHistory();
    const [isSearch, setIsSearch]: [boolean, Function] = useState(false);
	const [loading, setLoading]: [boolean, Function] = useState(false);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [searchValue, setSearchValue]: [string, Function] = useState('');
	const [tableData, setTableData]: [Array<TableAppDataAttr>, Function] = useState([]);
	const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [sortField, setSortField]: [string, Function] = useState('appCreateTs');
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [effect, setEffect]: [boolean, Function] = useState(false);
    
	useEffect((): void => {
		const getTokenUserList: Function = async (): Promise<void> => {
			setTableLoading(true);
			const option: Option = {
				url: '/app/getTokenAppList',
				method: 'POST',
				data: {
					sortRule,
					sortField,
					pageNum: current,
					pageSize,
				}
			};
			const result: Result = await axios(option);
			if (result) {
				if (result.data.code === 0) {
					result.data.data.list.forEach((item: TableAppDataAttr): void => {
						dealData(item);
					});
					setTotal(result.data.data.totalNumber);
					setTableData(result.data.data.list);
				} else {
					message.error('数据获取失败');
				}
			}
			setTableLoading(false);
		};
		getTokenUserList();
	}, [pageSize, current, sortRule, sortField, effect]);


	useEffect((): void => {
		setCurrent(1);
	}, [sortRule, sortField]);

	const dealData: Function = (item: TableAppDataAttr): void => {
		item.appCreateTs = getTimestampToTime(item.appCreateTs);
		item.totalAmount = moneyComma(item.totalAmount);
		item.userAuthType = AuthType[item.userAuthType as number];
		item.appState = AppState[item.appState as number];
		if (item.appOnLineTs !== 0) {
			item.appOnLineTs = getTimestampToTime(item.appOnLineTs);
		} else {
			item.appOnLineTs = '测试阶段';
		}
	};

    const handleSearch: Function = async (): Promise<void> => {
        if (searchValue.trim()) {
			const option: Option = {
				url: '/app/searchTokenAppInfo',
				data: {
					searchParam: searchValue
				},
				method: 'POST'
			};
			setLoading(true);
			const result: SearchResult = await axios(option);
            setLoading(false);
			if (result.data.code === 0) {
				message.success('数据查询成功');
				result.data.data.forEach((item: TableAppDataAttr): void => {
					dealData(item);
				});
				setIsSearch(true);
				setTableData(result.data.data);
				setTotal(1);
			} else if (result.data.code === 1032) {
				message.error('应用不存在');
			} else {
				message.error('数据查询失败');
			}
		}
    }

    const handleChange: Function = (e: ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(e.target.value);
	};

	const handleKeydown = (e: KeyboardEvent): void => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	const handleClick: Function = (record: TableAppDataAttr): void => {
		history.push(userMoudleAppPath(), { uid: record.uid, appid: record.appId, private: {
			name: 'Token验证项目',
			url: '/content/user/tokenAuth'
		} });
	};

	const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
	};

    useEffect(() => {
		window.addEventListener('keydown', handleKeydown);		
		return () => {
			window.removeEventListener('keydown', handleKeydown);		
		}
	});

	const handleSearchBack: Function = (): void => {
        setSearchValue('');
        setIsSearch(false);
		setEffect(!effect);
    };

    return (
        <div className={ styles.tokenAuth }>
            <div className={ styles.serach }>
                <Input value={ searchValue } onChange={ (e) => handleChange(e) } className={ styles.input } placeholder="请输入项目名称或AppID" prefix={<SearchOutlined />} />
                <Button className={ styles.button } loading={ loading } disabled={ searchValue === '' } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
				{ isSearch && <Button className={ styles.button } onClick={ () => handleSearchBack() } type="primary">Back</Button> }
				<div className={ styles.total }>
					<span>Token验证项目：</span>
					<span>{ total }</span>
				</div>
			</div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='appId'
				sticky={ true }
				sortDirections={['ascend', 'descend', 'ascend']} 
				dataSource={ tableData }>
				<Column align='center' showSorterTooltip={ false } sorter={ true } defaultSortOrder='descend' title="创建时间" dataIndex="appCreateTs" />
				<Column align='center' title="项目名称"  dataIndex="appName" />
				<Column align='center' showSorterTooltip={ false } sorter={ true } title="项目用量" dataIndex="appCons" />
				<Column align='center' defaultSortOrder='descend' title="项目状态" dataIndex="appState" />
				<Column align='center' showSorterTooltip={ false } sorter={ true } title="上线时间" dataIndex="appOnLineTs" />
				<Column align='center' title="认证类型" dataIndex="userAuthType" />
				<Column align='center' title="操作" render={ (operation: any, record: TableAppDataAttr): ReactElement => (
					<Fragment>{ findPermissions(11002) && <p onClick={ () => handleClick(record) } className={ styles.operation }>查看</p> }</Fragment>
				) } />
			</Table>
        </div>
    )
};


export default TokenAuth;