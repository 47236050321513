import { CSSProperties, ChangeEvent, FC, ReactElement, useEffect, useState } from 'react';
import { Descriptions, message, Button, Modal, Input, Select } from 'antd';
import { findPermissions } from '../../../../../api/permiss';
import { useLocation } from 'react-router-dom';
import { Option } from '../../../../../axios/axios-types';
import { getTimeFormate } from '../../../../../api/index';
import { EditOutlined } from '@ant-design/icons';
import axios from '../../../../../axios/axios';
import styles from './basicInformation.less';
import Authentication from './authentication/authentication';
const { TextArea } = Input;

namespace Antd {
    export const { Option } = Select;
};

interface Data {
	code: number;
	data: any;
};

interface Result {
	data: Data;
};

interface Options {
    [attr: string]: string
};

const labelStyle: CSSProperties = { 
    width: '150px', 
    textAlign: 'right', 
    display: 'inline-block'
};

const BasicInformation: FC = (): ReactElement => {
    const location: any = useLocation();
    const [options, setOptions]: [Array<Options>, Function] = useState([]);
    const [userDetail, setUserDetail]: [any, Function] = useState({});
    const [remarks, setRemarks]: [string, Function] = useState('');
    const [remarksCopy, setRemarksCopy]: [string, Function] = useState('');
    const [contractid, setContractid]: [string, Function] = useState('');
    const [effect, setEffect]: [boolean, Function] = useState(false);
    const [isModalVisible, setIsModalVisible]: [boolean, Function] = useState(false);
    const [isNotesModalVisible, setIsNotesModalVisible]: [boolean, Function] = useState(false);
    const [modalTitle, setModalTitle]: [string, Function] = useState('');
    const [contractidDisabled, setContractidDisabled]: [boolean, Function] = useState(true);
    const [contractidSetLoading, setContractidSetLoading]: [boolean, Function] = useState(false);
    const [visibleLoading, setVisibleLoading]: [boolean, Function] = useState(false);


    useEffect((): void => {
        const getDevpInfo: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/developers/getDevpInfo',
                data: { uid: location.state.uid },
				method: 'POST',
            };
            const result: Result = await axios(option);

            if (result) {
                if (result.data.code === 0) {
                    result.data.data.userDetail.state = result.data.data.userDetail.state === -1? "信息未完善" : result.data.data.userDetail.state === 0? "异常" : "正常";
                    result.data.data.userDetail.emailState = result.data.data.userDetail.emailState === 0? "未验证" : "已验证";
                    result.data.data.userDetail.userCreateAt = getTimeFormate(result.data.data.userDetail.userCreateAt);
                    if (result.data.data.restfulAPInfo !== null && result.data.data.restfulAPInfo !== undefined) {
                        result.data.data.userDetail.customerCertificate = result.data.data.restfulAPInfo.customerCertificate;
                        result.data.data.userDetail.customerId = result.data.data.restfulAPInfo.customerId;
                    }
                    if (result.data.data.respAdminInfos != null && result.data.data.respAdminInfos !== undefined) {
                        setOptions([]);
                        result.data.data.respAdminInfos.forEach((item: any): void => {
                            options.push({
                                value: item.aid,
                                label: item.name
                            });
                        });
                        let obj = {};
                        options.forEach((item: any, index: number): void => {
                            if (item.label === result.data.data.userDetail.adminName) {
                                obj = item;
                                options.splice(index, 1);
                            }
                        });
                        options.unshift(obj);
                        setOptions(options);
                    }
                    options.forEach((item: any): void => {
                        if (item.label === result.data.data.userDetail.adminName) {
                            result.data.data.userDetail.adminName = item.value;
                        }
                    });
                    setUserDetail(result.data.data.userDetail);
                }
            } else {
                message.error('数据获取失败');
            }
        };

        const queryUserRemarks: Function = async (): Promise<void> => {
            const remarksOption: Option = {
                url: '/developers/queryUserRemarks',
                data: { uId: location.state.uid },
				method: 'POST',
            };
            const result: Result = await axios(remarksOption);
            if (result.data.code === 0 && result.data.data) {
                setRemarks(result.data.data.remark);
            };
        };
      
        getDevpInfo();
        queryUserRemarks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect]);


    const handleAddClick: Function = async (type: string): Promise<void> => {
        let appNum = userDetail.appNum;
        if (type === 'add') {
            appNum += 1;
        } else if (type === 'reduction') {
            appNum -= 1;
        }
        const option: Option = {
            url: '/developers/updateAppNum',
            data: { userID: location.state.uid, appNum },
            method: 'POST',
        };
        const result: Result = await axios(option);
        if (result && result.data.code === 0) {
            setEffect(!effect);
            message.success('应用数更新成功');
        } else {
            message.error('应用数更新失败');
        }
    };

    const handleSelectChange: Function = async (value: string): Promise<void> => {
        const option: Option = {
            url: '/developers/updateUserSales',
            data: { uId: location.state.uid, salesId: value },
            method: 'POST',
        };
        const result: Result = await axios(option);
        if (result && result.data.code === 0) {
            setEffect(!effect);
            message.success('销售更新成功');
        } else {
            message.error('销售更新失败');
        }
    };

    const handleSwitchClick: Function = (): void => {
        setIsModalVisible(true);
        setModalTitle(userDetail.state === '正常'? '冻结用户' : '解锁用户');
    };

    const handleOk: Function = async (): Promise<void> => {
        const uState = userDetail.state === '正常'? 0 : 1;
        const option: Option = {
            url: '/developers/updateUserState',
            data: { uId: location.state.uid, uState },
            method: 'POST',
        };
        setVisibleLoading(true);
        const result: Result = await axios(option);
        setVisibleLoading(false);
        if (result && result.data.code === 0) {
            setEffect(!effect);
            if (uState === 0) {
                message.success('账户冻结成功');
            } else {
                message.success('账户解锁成功');
            }
        } else {
            if (uState === 0) {
                message.error('账户冻结失败');
            } else {
                message.error('帐户解锁失败');
            }
        }
        setIsModalVisible(false);
    };

    const handleContractidClick: Function = (): void => {
        setContractidDisabled(false);
        setContractid(userDetail.contractid);
    };

    const handleContractidOnCancel: Function = (): void => {
        userDetail.contractid = contractid;
        setUserDetail(userDetail);
        setContractidDisabled(true);
    };

    const handleContractidOnOk: Function = async (): Promise<void> => {
        if (userDetail.contractid.trim() === '') {
            message.error('合同号不能为空');
        } else {
            const option: Option = {
                url: '/developers/updateUserContractId',
                data: { uId: location.state.uid, contractId: userDetail.contractid },
                method: 'POST',
            };
            setContractidSetLoading(true);
            const result: Result = await axios(option);
            if (result && result.data.code === 0) {
                message.success('销售更新成功');
            } else {
                message.error('销售更新失败');
            }
            setEffect(!effect);
            setContractidSetLoading(false);
            setContractidDisabled(true);
        }
    };

    const handleInputChange: Function = (e: ChangeEvent<HTMLInputElement>): void => {
        const userDetailCopy: any = JSON.parse(JSON.stringify(userDetail));
        userDetailCopy.contractid = e.target.value;
        setUserDetail(userDetailCopy);
    };

    const handleRemarksClick: Function = (): void => {
        setRemarksCopy(remarks);
        setIsNotesModalVisible(true);
    };

    const handleRemarksOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/insertUserRemark',
            data: { uId: location.state.uid, remarks: remarksCopy },
            method: 'POST',
        };
        setVisibleLoading(true);
        const result: Result = await axios(option);
        setVisibleLoading(false);
        if (result && result.data.code === 0) {
            message.success('备注添加成功');
        } else {
            message.error('备注添加失败');
        }
        setIsNotesModalVisible(false);
        setEffect(!effect);
    };

    return (
        <div className={ styles.basicInformation }>
            <p className={ styles.title }>基本信息</p>
            <Descriptions>
                <Descriptions.Item labelStyle={ labelStyle } label="昵称">{ userDetail.name }</Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="注册时间">{ userDetail.userCreateAt }</Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="应用最大数">
                    <div className={ styles.line }>
                        <Button onClick={ () => handleAddClick('reduction') } type="primary">-</Button>
                        <span className={ styles.appNum }>{ userDetail.appNum }</span>
                        <Button onClick={ () => handleAddClick('add') } type="primary">+</Button>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="邮箱">{ userDetail.email }</Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="注册地区">{ userDetail.county } - { userDetail.province } - { userDetail.city }</Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="所属销售">
                    <Select value={ userDetail.adminName } style={{ width: 120 }} onChange={ (value) => handleSelectChange(value) }>
                        {
                            options.map((item: any, index: number): ReactElement => {
                                return (
                                    <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                                );
                            })
                        }
                    </Select>    
                </Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="手机号">{ userDetail.cellphone }</Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="公司名称">{ userDetail.company }</Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="合同编号">
                    <div className={ styles.line }>
                        <Input className={ styles.contractid } onChange={ (e) => handleInputChange(e) } disabled={ contractidDisabled } value={ userDetail.contractid } />
                        { (contractidDisabled && findPermissions(11104)) && <Button onClick={ () => handleContractidClick() } type="primary" shape="circle" icon={ <EditOutlined /> } /> }
                        { (contractidDisabled && findPermissions(11104)) || <Button className={ styles.contractidBtn } onClick={ () => handleContractidOnOk() } loading={ contractidSetLoading } disabled={ contractidSetLoading } type="primary">OK</Button> }
                        { (contractidDisabled && findPermissions(11104)) || <Button className={ styles.contractidBtn } onClick={ () => handleContractidOnCancel() }>Cancel</Button> }
                    </div>
                </Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="CID"><span>{ location.state.uid }</span></Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="行业/场景">{ userDetail.industy }</Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="帐号状态">
                    <div className={ styles.line }>
                        <span className={ styles.state }>{ userDetail.state }</span>
                        { findPermissions(11105) && <Button onClick={ () => handleSwitchClick() } disabled={ userDetail.state === '信息未完善' } type="primary" danger={ userDetail.state === '正常' }>切换状态</Button> }
                    </div>
                </Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="Customer ID"><span>{ userDetail.customerId }</span></Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="岗位">{ userDetail.job }</Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="备注信息">
                    <div className={ styles.line }>
                        <span>{ remarks }</span>
                        { findPermissions(11106) && <Button className={ remarks && styles.remarksBtn } onClick={ () => handleRemarksClick() } type="primary">添加备注</Button> }
                    </div>
                </Descriptions.Item>
                <Descriptions.Item labelStyle={ labelStyle } label="Customer Certificate"><span>{ userDetail.customerCertificate }</span></Descriptions.Item>
            </Descriptions>
            <Authentication />
            <Modal centered title={ modalTitle } visible={ isModalVisible } confirmLoading={ visibleLoading } onOk={ () => handleOk() } onCancel={ () => setIsModalVisible(false) }>
                { userDetail.state === '正常' && <span>您确定要冻结该用户吗?</span> }
                { userDetail.state === '异常' && <span>您确定要解除对该用户的封锁吗?</span> }
            </Modal>
            <Modal centered title='Add notes' confirmLoading={ visibleLoading } width='50%' maskClosable={ false } visible={ isNotesModalVisible } onOk={ () => handleRemarksOk() } onCancel={ () => setIsNotesModalVisible(false) }>
                <TextArea value={ remarksCopy } onChange={ (e) => setRemarksCopy(e.target.value) } rows={ 10 } />
            </Modal>
        </div>
    );
};


export default BasicInformation;