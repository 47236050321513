import { FC, ReactElement } from 'react';
import { CacheRoute } from 'react-router-cache-route';
import Index from './index/index';
import Detail from './detail/detail';
import pendingInvoicesDetails from './pendingInvoicesDetails/pendingInvoicesDetails';
import styles from './invoice.less';


const Invoice: FC = (): ReactElement => {
    
    return (
        <div className={ styles.invoice }>
            <CacheRoute cacheKey='index' path='/content/user/detail/financial/invoice' component={ Index } exact />
            <CacheRoute cacheKey='detail' path='/content/user/detail/financial/invoice/detail' component={ Detail } exact />
            <CacheRoute cacheKey='pendingInvoicesDetails' path='/content/user/detail/financial/invoice/pendingInvoicesDetails' component={ pendingInvoicesDetails } />
        </div>
    );
};

export default Invoice;