import { FC, Fragment, ReactElement, useState, useEffect, ChangeEvent } from 'react';
import { Input, Button, message, Table, Select } from 'antd';
import { findPermissions, userMoudleAppPath } from '../../../../api/permiss';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { TableAppDataAttr } from '../tableColumns';
import { AuthType } from '../../../../types/enum';
import { Option } from '../../../../axios/axios-types';
import { getTimestampToTime } from '../../../../api';
import axios from '../../../../axios/axios';
import styles from './totalApp.less';
const { Column } = Table;

namespace Antd {
	export const { Option } = Select;
};

interface Data {
	code: number;
	data: {
		onlineAppInfos: Array<TableAppDataAttr>;
		onlineAppAmount: number;
	};
};

interface SearchResult {
	data: {
		code: number;
		data: Array<TableAppDataAttr>;
	}
};

interface Result {
	data: Data;
};

const TotalApp: FC = (): ReactElement => {
	const history: any = useHistory();
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<TableAppDataAttr>, Function] = useState([]);
	const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [sortField, setSortField]: [string, Function] = useState('createTime');
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [authType, setAuthType]: [number, Function] = useState(3);
	const [total, setTotal]: [number, Function] = useState(0);
	const [searchValue, setSearchValue]: [string, Function] = useState('');
	const [loading, setLoading]: [boolean, Function] = useState(false);
    const [isSearch, setIsSearch]: [boolean, Function] = useState(false);
	const [effect, setEffect]: [boolean, Function] = useState(false);
	const [sortedInfo, setSortedInfo]: [any, Function] = useState({
		order: 'descend',
		field: 'createTime',
	});
    
	useEffect((): void => {
		const getOnlineTestAppList: Function = async (): Promise<void> => {
			setTableLoading(true);
			const option: Option = {
				url: '/developers/getOnlineTestAppList',
				method: 'POST',
				data: {
					sortRule,
					sortField,
					pageNum: current,
					pageSize,
					appStage: authType,
				}
			};
			const result: Result = await axios(option);
			if (result) {
				if (result.data.code === 0) {
					result.data.data.onlineAppInfos.forEach((item: TableAppDataAttr): void => {
						dealData(item);
					});
					setTotal(result.data.data.onlineAppAmount);
					setTableData(result.data.data.onlineAppInfos);
				} else {
					message.error('数据获取失败');
				}
			}
			setTableLoading(false);
		};
		getOnlineTestAppList();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize, current, sortRule, sortField, effect]);


	useEffect((): void => {
		setCurrent(1);
	}, [sortRule, sortField]);

	const handleClick: Function = (record: TableAppDataAttr): void => {
		history.push(userMoudleAppPath(), { uid: record.uid, appid: record.appId, private: {
			name: '总应用',
			url: '/content/user/totalApp'
		} });
	};

	const resetData: Function = (): void => {
		setCurrent(1);
		setSortRule(0);
		setSortField('createTime');
		setSortedInfo({
			order: 'descend',
			field: 'createTime',
		});
	};

	const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
		setSortedInfo(sorter);
	};

	const handleSelectChange: Function = async (value: number): Promise<void> => {
		setAuthType(value);
		setEffect(!effect);
        setIsSearch(false);
		setSearchValue('');
		resetData();
	};

	const handleSearch: Function = async (): Promise<void> => {
        if (searchValue.trim()) {
			const option: Option = {
				url: '/app/searchAllAppList',
				data: { searchParam: searchValue },
				method: 'POST'
			};
			setLoading(true);
			const result: SearchResult = await axios(option);
            setLoading(false);
			if (result.data.code === 0) {
				message.success('数据查询成功');
				result.data.data.forEach((item: TableAppDataAttr): void => {
					dealData(item);
				});
				setIsSearch(true);
				setTableData(result.data.data);
				setAuthType(3);
				setTotal(1);
			} else if (result.data.code === 1032) {
				message.error('应用不存在');
			} else {
				message.error('数据查询失败');
			}
		}
    };

	const dealData: Function = (item: TableAppDataAttr): void => {
		item.createTime = getTimestampToTime(item.createTime);
		if (item.onlineTime !== 0) {
			item.onlineTime = getTimestampToTime(item.onlineTime);
		} else {
			item.onlineTime = '测试阶段';
		}
		item.authType = AuthType[item.authType as number];
	};

    const handleChange: Function = (e: ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(e.target.value);
	};

	const handleSearchBack: Function = (): void => {
        setSearchValue('');
        setIsSearch(false);
		setEffect(!effect);
    };

    return (
        <div className={ styles.totalApp }>
            <div className={ styles.serach }>
				<Input value={ searchValue } onChange={ (e) => handleChange(e) } className={ styles.input } placeholder="请输入项目名称或AppID" prefix={<SearchOutlined />} />
                <Button className={ styles.button } loading={ loading } disabled={ searchValue === '' } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
				{ isSearch && <Button className={ styles.button } onClick={ () => handleSearchBack() } type="primary">Back</Button> }
				<Select
					className={ styles.select }
					style={{ width: 200 }}
					value={ authType }
					onChange={ (value) => handleSelectChange(value) }
				>
					<Antd.Option value={ 3 }>全部</Antd.Option>
					<Antd.Option value={ 2 }>已上线</Antd.Option>
					<Antd.Option value={ 1 }>测试中</Antd.Option>
				</Select>
				<div className={ styles.total }>
					<span>总应用数：</span>
					<span>{ total }</span>
				</div>
			</div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='appId'
				sticky={ true }
				sortDirections={['ascend', 'descend', 'ascend']} 
				dataSource={ tableData }>
				<Column align='center' title="应用名称" dataIndex="appName" />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'createTime' && sortedInfo.order} sorter={ true } title="创建时间" dataIndex="createTime" />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'onlineTime' && sortedInfo.order} sorter={ true } title="上线时间" dataIndex="onlineTime" />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'comsTime' && sortedInfo.order} sorter={ true } title="总消费时长(分钟)" dataIndex="comsTime" />
				<Column align='center' title="认证类型" dataIndex="authType" />
				<Column align='center' title="操作" render={ (operation: any, record: TableAppDataAttr): ReactElement => (
					<Fragment>{ findPermissions(11002) && <p onClick={ () => handleClick(record) } className={ styles.operation }>查看</p> }</Fragment>
				) } />
			</Table>
        </div>
    )
};


export default TotalApp;