import { FC, Fragment, ReactElement, useState, useEffect } from 'react';
import option, { ItemList, SearchType } from './state';
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route';
import { message, Button, DatePicker } from 'antd';
import { getDate } from '../../../../api/index';
import { findPermissions } from '../../../../api/permiss';
import { useHistory } from 'react-router-dom';
import { scriptUrl } from '../../../../axios/baseUrl';
import { Option } from '../../../../axios/axios-types';
import { createFromIconfontCN } from '@ant-design/icons';
import axios from '../../../../axios/axios';
import moment from 'moment';
import styles from './index.less';
import * as echarts from 'echarts';
const { RangePicker } = DatePicker;

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

interface Data {
	code: number;
	data: {[type: string]: number};
};

interface Result {
	data: Data;
};

interface EchartsResult {
	data: {
		code: number;
		data: {
			dailyNum: Array<number>;
			date: Array<string>;
		};
	};
};

interface EchartsList {
	id: string;
	perm: number;
	url: string;
	color: string;
	name: string;
	title: string;
	dates: Array<any>;
	rangeValue: any;
	stopTs: number;
	startTs: number;
	activeTime: number;
};

const Index: FC = (): ReactElement => {
	const history: any = useHistory();

	const [echartsList, setEchartsList]: [Array<EchartsList>, Function] = useState([
		{
			id: 'registered',
			perm: 12021,
			url: '/developers/getDailySignUpData',
			color: '#87DCE2',
			name: '注册用户',
			title: '注册用户趋势图',
			dates: [],
			rangeValue: [],
			stopTs: parseInt(((new Date(new Date().toLocaleDateString()).getTime() / 1000) + (60 * 60 * 24 - 1)).toString()),
			startTs: getDate(7),
			activeTime: 7,
		},
		{
			id: 'application',
			perm: 12022,
			url: '/developers/getDailyAppData',
			color: '#57B4FF',
			name: '应用创建',
			title: '应用创建趋势图',
			dates: [],
			rangeValue: [],
			stopTs: parseInt(((new Date(new Date().toLocaleDateString()).getTime() / 1000) + (60 * 60 * 24 - 1)).toString()),
			startTs: getDate(7),
			activeTime: 7,
		}
	]);


	const [typeList]: [Array<string>, Function] = useState<Array<string>>([
		'User related',
		'Application Review',
		'Charges related to',
		'Application of the relevant',
		'Activity zone'
	]);
	const [typeNameList]: [Array<string>, Function] = useState<Array<string>>([
		'用户相关',
		'申请审核相关',
		'资费相关',
		'应用相关',
		'活动专区'
	]);
	const [options, setOptions]: [ItemList, Function] = useState<ItemList>(option);

	useEffect((): void => {
		getCachingKeys().forEach((item: string): void => {
			dropByCacheKey(item);
		});
	});

	useEffect((): any => {
        window.addEventListener("resize", windowResize);
        return (): void => {
            window.removeEventListener("resize", windowResize);
        };
    });

    const windowResize = (): void => {
		echartsList.forEach((item: EchartsList): void => {
			getElementById(item.id).resize();
		});
    };

	const getElementById: Function = (id: string): any => {
		const element: HTMLElement | null = document.getElementById(id);
		if (element) {
			return echarts.init(element);
		}
    };

	useEffect((): void => {
		const getDevpSummary: Function = async (): Promise<void> => {
			const option: Option = {
				url: '/developers/getDevpSummary',
				method: 'POST'
			};
			const result: Result = await axios(option);
			if (result.data.code === 0) {
				const list: ItemList = JSON.parse(JSON.stringify(options));
				Object.keys(result.data.data).forEach((key): void => {
					typeList.forEach((type): void => {
						list[type].forEach((item): void => {
							item.type === key && (item.number = result.data.data[key]);
						});
					});
				});
				setOptions(list);
			} else {
				message.error('数据获取失败');
			}
		};
		getDevpSummary();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	useEffect((): void => {
		const getEchartsData: Function = (): void => {
			echartsList.forEach(async (item: EchartsList): Promise<void> => {
				const option: Option = {
					url: item.url,
					data: {
						endTs: item.stopTs,
						startTs: item.startTs,
					},
					method: 'POST'
				};
				const result: EchartsResult = await axios(option);
				if (result.data.code === 0) {
					initEcharts(item.id, item.name, item.color, result);
				}
			});
		};
		getEchartsData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [echartsList]);

	
	const initEcharts: Function = (id: string, tip: string, color: string, result: EchartsResult): void => {
		const target: any = getElementById(id);
		if (!target) return
		target.setOption({
			xAxis: {
				type: "category",
				data: result.data.data.date,
				axisLine: { show: false },
				axisLabel: {
					show: true,
					textStyle: { color: "#666" }
				}
			},
			grid: {
				left: 50,
				right: 50,
				bottom: 50,
				top: 50
			},
			yAxis: {
				type: "value",
				axisLine: { show: false },
				axisLabel: {
					show: true,
					textStyle: { color: "#666" }
				},
				splitLine: {
					show: true,
					lineStyle: {
						color: ["#eee"],
						width: 1,
						type: "solid"
					}
				}
			},
			tooltip: {
				trigger: "axis",
				backgroundColor: "#FFF",
				extraCssText: "box-shadow:0 0 18px #ccc",
				textStyle: {
					fontSize: "12px",
					width: "40px",
					fontFamily: "Microsoft YaHei Regular, Microsoft YaHei Regular-Regular",
					fontWeight: 400,
					color: "#606060"
				},
				formatter: (params: any): string => {
					var res = `<div style='padding:2px 10px;'>${params[0].name}</div>`;
					for (var i = 0, l = params.length; i < l; i++) {
						res += `
						<div style='display: flex; 
						align-items:center; 
						justify-content: space-between;
						padding: 4px 10px;
						width: 150px'>
							<div style='display: flex;
							align-items:center'>
								<span style='width: 16px;
								height: 3px; 
								display: inline-block; 
								background: ${color}; 
								margin-right: 10px'>
								</span>
								<span>${params[i].seriesName}</span>
							</div>
							<span>${params[i].value}</span>
						</div>`;
					}
					return res;
				},
				position: ["4%", "20%"]
			},
			series: [
				{
					name: tip,
					color,
					data: result.data.data.dailyNum,
					type: "line"
				}
			]
		});
	};

	const searchType: Function = (type: string): Array<SearchType> => {
		return options[type];
	};

	const jump: Function = (url: string): void => {
		history.push(url);
	};

	const handleBtnClickTimeChange: Function = (type: number, index: number): void => {
		const target = JSON.parse(JSON.stringify(echartsList));
		target[index].stopTs = parseInt(((new Date(new Date().toLocaleDateString()).getTime() / 1000) + (60 * 60 * 24 - 1)).toString());
		target[index].startTs = getDate(type);
		target[index].activeTime = type;
		target[index].rangeValue = [];
		setEchartsList(target);
	};

	const disabledDate: Function = (current: any, index: number): boolean => {
		if (!echartsList[index].dates || echartsList[index].dates.length === 0) {
			return current > moment().endOf('day');
		}
		// 只能选择当前日期之前, 并且日期范围不超过90天
		const tooLate = echartsList[index].dates[0] && current.diff(echartsList[index].dates[0], 'days') > 90;
		const tooEarly = echartsList[index].dates[1] && echartsList[index].dates[1].diff(current, 'days') > 90;
		return tooEarly || tooLate || current > moment().endOf('day');
	};

	const handleRangeChange: Function = (dates: Array<any>, dateStrings: Array<string>, index: number): void => {
		if (dateStrings[0] && dateStrings[1]) {
			const target = JSON.parse(JSON.stringify(echartsList));
			target[index].rangeValue = dates;
			target[index].activeTime = 100;
			target[index].startTs = parseInt((new Date(new Date(dateStrings[0]).toLocaleDateString()).getTime() / 1000).toString());
			target[index].stopTs = parseInt(((new Date(new Date(dateStrings[1]).toLocaleDateString()).getTime() / 1000)  + (60 * 60 * 24 - 1)).toString());
			setEchartsList(target);
		} else {
			handleBtnClickTimeChange(7, index);
		}
	};

	const handleCalChange: Function = (val: Array<any>, index: number): void => {
		const target = JSON.parse(JSON.stringify(echartsList));
		target[index].dates = val;
		setEchartsList(target);
	};

	const computedShow: Function = (arr: SearchType[]): boolean => {
		return arr.some((item: any): boolean => {
			return findPermissions(item.perm) || item.perm === -1;
		});
	};
	return (
		<div className={ styles.index }>
			<div>
				{
					typeList.map((type: string, index: number): ReactElement => {
						return (
							<div key={ index } className={ styles.list }>
								{ computedShow(searchType(type)) && <p className={ styles.title }>{ typeNameList[index] }</p> }
								{
									searchType(type).map((item: SearchType, index: number): ReactElement => {
										return (<Fragment key={ index }>
											{ (findPermissions(item.perm) || item.perm === -1) && <div className={ styles.item } onClick={ () => jump(item.url) }>
												<IconFont className={ styles.icon } type={ item.icon } />
												<div className={ styles.infor }>
													<span>{ item.name }</span>
													<span>{ item.number }</span>
												</div>
											</div> }
										</Fragment>)
									})
								}
							</div>
						)
					})
				}
			</div>

			{
				echartsList.map((item: EchartsList, index: number): ReactElement => {
					return (<Fragment key={ item.id }>
						{ findPermissions(item.perm) && <div>
							<div className={ styles.RangePicker }>
								<p className={ styles.title }>{ item.title }</p>
								<Button onClick={ () => handleBtnClickTimeChange(7, index) } className={ [styles.item, item.activeTime === 7 && styles.activeTime].join(' ') }>7天</Button>
								<Button onClick={ () => handleBtnClickTimeChange(15, index) } className={ [styles.item, item.activeTime === 15 && styles.activeTime].join(' ') }>15天</Button>
								<Button onClick={ () => handleBtnClickTimeChange(30, index) } className={ [styles.item, item.activeTime === 30 && styles.activeTime].join(' ') }>近一个月</Button>
								<Button onClick={ () => handleBtnClickTimeChange(90, index) } className={ [styles.item, item.activeTime === 90 && styles.activeTime].join(' ') }>近三个月</Button>
								<RangePicker
								value={ item.rangeValue }
								disabledDate={ (current) => disabledDate(current, index) }
								onChange={ (dates, dateStrings) => handleRangeChange(dates, dateStrings, index) }
								onCalendarChange={ val => handleCalChange(val, index) }
								/>
							</div>
							<div id={ item.id } className={ styles.echartsLine }></div>
						</div> }
					</Fragment>)
				})
			}
		</div>
	);
};


export default Index;