import { FC, ReactElement } from 'react';
import styles from './index.less';

const Login: FC = (): ReactElement => {

	return (
		<div className={ styles.notPage }>
            <img src={ require('../../assets/images/perm.jpg').default } alt="" />
		</div>
	)
};

export default Login;