import * as Echarts from "echarts"; 
import { timeTransformation } from "../../reconsitution/time";
import { unique } from "../../reconsitution/unti";
import { chartLodingFn, chartFinishFn, createDeletion } from "../configuration";
import axios from '../../../axios/axios';
import { Option } from '../../../axios/axios-types';

interface Result {
	data: {
		code: number;
		data: any;
		timestamp: any;
	}
}

let Store: { name: any; myChart: Echarts.ECharts | null; }[] = [];
let StoreSeries: any[] = [];
let StoreOptions: { name: any; options: Echarts.EChartsCoreOption }[] = [];
let StoreDataZoom: any = {
    startValue: 0,
    endValue: 0
};

const echartsList: Array<any> = [];

const chartSeriesData: Function = async (item: any, sendDatas: string | any[], searchTime: any[]): Promise<any> => {
	let oX: string[] = [];
	let oPeerLists: any[] = [];
	let seriesAllData: any[] = [];
	let oAudiodeleAlltion: string[] = [];
	let oVideodeleAlltion: string[] = [];
	let oAudioHasAll: string[] = [];
	let oVideoHasAll: string[] = [];
	let deletionAll: any = {
		audiodeletion: false,
		videodeletion: false
	};
	let hasAll: any = {
		audiohas: true,
		videohas: true
	};
	let olineSizeObj: any = {
		max: 0,
		min: 0,
		interval: 0
	};
	let obarSizeObj: any = {
		max: 0,
		min: 0,
		interval: 0
	};
  	let ocde: number = 0;
	if (sendDatas.length > 0) {
		const parmas: any = {
			appId: item.appId,
			chanId: item.chanId,
			chanSId: item.chanSId,
			pIdArray: sendDatas,
			startTs: searchTime[0],
			stopTs: searchTime[1],
			uId: item.uId
		};
		const option: Option = {
			url: '/v2/project/getMultiPullAVData',
			data: parmas,
			method: 'POST'
		};
		const result: Result = await axios(option);
		ocde = result.data.code;
    	if (result.data.code === 0) {
			if (result.data.timestamp.length > 0) {
				await result.data.timestamp.forEach((ox: any) :void => {
					oX.push(timeTransformation(ox, false));
				});
			};
			await result.data.data.forEach(({aData, aTft, color, peer, vData, vTft}: any): void => {
				const oseriesvData: any = vData;
				const oseriesvLoss: any = vTft;
				const oseriesaData: any[] = Array.from(aData, (x: any) => (x !== null? -x : x));
				const oseriesaLoss: any[] = Array.from(aTft, (x: any) => (x !== null? -x : x));
				let oAudiodeletion: string = "";
				let oVideodeletion: string = "";
				let oAudioHas: string = "";
				let oVideoHas: string = "";

				if (vData.length > 0 && vTft.length > 0) {
					const vDatanull: any[] = vData.filter((value: any): boolean => {
						return value === null;
					});
					vDatanull.length === vData.length && (oVideoHas = "无视频");
				} else {
					oVideodeletion = "视频缺失";
				}

				if (aData.length > 0 && aTft.length > 0) {
					let aDatanull: any[] = aData.filter((value: any): boolean => {
						return value === null;
					});
					aDatanull.length === aData.length && (oAudioHas = "无音频");
				} else {
					oAudiodeletion = "音频缺失";
				}

				if ((oAudioHas.length === 0 && oAudiodeletion.length === 0) || (oVideoHas.length === 0 && oVideodeletion.length === 0)) {
					oPeerLists.push(peer);
					seriesAllData.push({
						data: oseriesvData,
						type: "line",
						name: "视频=" + peer,
						z: 2,
						symbolSize: 0,
						areaStyle: {
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									{
										offset: 0,
										color: color
									},
									{
										offset: 1,
										color: "white"
									}
								],
								global: false // 缺省为 false
							}
						},
						smooth: true,
						lineStyle: { width: 1 },
						itemStyle: { color }
					});

					seriesAllData.push({
						data: oseriesvLoss,
						type: "bar",
						stack: "bar",
						name: "视频丢包率=" + peer,
						yAxisIndex: 1,
						areaStyle: {},
						barWidth: 1,
						z: 4,
						itemStyle: { color: "#FF0000" }
					});

					seriesAllData.push({
						data: oseriesaData,
						type: "line",
						name: "音频=" + peer,
						z: 2,
						symbolSize: 0,
						areaStyle: {
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									{
										offset: 0,
										color: "white"
									},
									{
										offset: 1,
										color: color
									}
								],
								global: false
							}
						},
						smooth: true,
						lineStyle: { width: 1 },
						itemStyle: { color: color }
					});

					seriesAllData.push({
						data: oseriesaLoss,
						type: "bar",
						stack: "bar",
						name: "音频丢包率=" + peer,
						yAxisIndex: 1,
						z: 4,
						areaStyle: {},
						itemStyle: { color: "#FF0000" }
					});
				}
				oAudiodeleAlltion.push(oAudiodeletion);
				oVideodeleAlltion.push(oVideodeletion);
				oAudioHasAll.push(oAudioHas);
				oVideoHasAll.push(oVideoHas);
      		});
			const oAd: any[] = oAudiodeleAlltion.filter((value: any): boolean => {
				return value === "音频缺失";
			});
			if (oAd.length === result.data.data.length) {
				deletionAll.audiodeletion = true;
			};
			const oVd: any[] = oVideodeleAlltion.filter((value: any): boolean => {
				return value === "视频缺失";
			});
			if (oVd.length === result.data.data.length) {
				deletionAll.videodeletion = true;
			};
			let oAh: any[] = oAudioHasAll.filter((value: any): boolean => {
				return value === "无音频";
			});
			if (oAh.length === result.data.data.length) {
				hasAll.audiohas = false;
			};
			let oVh: any[] = oVideoHasAll.filter((value: any): boolean => {
				return value === "无视频";
			});
			if (oVh.length === result.data.data.length) {
				hasAll.videohas = false;
			};

			seriesAllData.forEach((value: any): void => {
				const oMax: number = Math.max(...value.data);
				const oMin: number = Math.min(...value.data);
				if (value.type === "line") {
					olineSizeObj.max = olineSizeObj.max > oMax? olineSizeObj.max : oMax;
					olineSizeObj.min = olineSizeObj.min > oMin? oMin : olineSizeObj.min;
				} else {
					obarSizeObj.max = obarSizeObj.max > oMax? obarSizeObj.max : oMax;
					obarSizeObj.min = obarSizeObj.min > oMin? oMin : obarSizeObj.min;
				}
			});

			olineSizeObj = {
				max: Math.abs(olineSizeObj.max),
				min: Math.abs(olineSizeObj.min)
			};
			obarSizeObj = {
				max: Math.abs(obarSizeObj.max),
				min: Math.abs(obarSizeObj.min)
			};

			if (!hasAll.videohas && hasAll.audiohas && true) {
				olineSizeObj = {
					min: -Math.ceil(olineSizeObj.min / 3) * 3,
					max: Math.ceil(olineSizeObj.min / 3) * 3,
					interval: Math.ceil(olineSizeObj.min / 3)
				};
				obarSizeObj = {
					min: -Math.ceil(obarSizeObj.min / 3) * 3,
					max: Math.ceil(obarSizeObj.min / 3) * 3,
					interval: Math.ceil(obarSizeObj.min / 3)
				};
			} else {
				if (olineSizeObj.min * 5 >= olineSizeObj.max) {
					olineSizeObj.max = olineSizeObj.min * 5;
					olineSizeObj.min = -olineSizeObj.min;
				} else {
					olineSizeObj.max = Math.ceil(olineSizeObj.max / 5) * 5;
					olineSizeObj.min = -Math.ceil(olineSizeObj.max / 5);
				}
				olineSizeObj.interval = Math.abs(olineSizeObj.min);

				if (obarSizeObj.min * 5 >= obarSizeObj.max) {
					obarSizeObj.max = obarSizeObj.min * 5;
					obarSizeObj.min = -obarSizeObj.min;
				} else {
					obarSizeObj.max = Math.ceil(obarSizeObj.max / 5) * 5;
					obarSizeObj.min = -Math.ceil(obarSizeObj.max / 5);
				}
				obarSizeObj.interval = Math.abs(obarSizeObj.min);
			}
		}
  	} else {
		hasAll = {
			audiohas: false,
			videohas: false
		};
	}
	
	return {
		code: ocde,
		oX,
		peerLists: oPeerLists,
		series: seriesAllData,
		deletion: deletionAll,
		has: hasAll,
		lineSize: olineSizeObj,
		barSize: obarSizeObj
	};
};

// let oIcon = require("@/assets/images/zhongzhi.svg");
// oIcon = "image://" + oIcon; // 添加ECharts需要的前缀
let echartOptions: any = {
	grid: {
		containLabel: true,
		left: 0,
		top: 40,
		bottom: 20,
		right: 0
	},
	toolbox: {
		itemSize: 0,
		show: true,
		feature: {
			dataZoom: {
				yAxisIndex: "none",
				filterMode: "none",
				iconStyle: {
					opacity: 0,
					textPadding: 0
				},
				emphasis: {
					opacity: 0,
					textPadding: 0
				},
				title: {
					zoom: "",
					back: ""
				}
			},
			restore: {}
		}
	},
	tooltip: {
		show: true,
		trigger: "axis",
		confine: true,
		extraCssText: "box-shadow: 0px 2px 10px 0px rgba(19,128,255,0.50);padding:0;border:0;max-width:320px;white-space: normal;max-height:220px;overflow: auto;background-color: #fff", // 额外附加到浮层的 css 样式
		axisPointer: {
			type: "line",
			lineStyle: { type: "solid" }
		},
		position: function(pt: any): any[] {
			return [pt[0], "10%"];
		},
		formatter: function(params: any): string {
			const oArray: any[] = [];
			params.forEach((item: any): void => {
				let ovLoss: string = "";
				let oaLoss: string = "";
				const oStr: string = item.seriesName;
				const oType: string = oStr.substring(0, oStr.indexOf("="));
				const oUser: string = oStr.substring(oStr.indexOf("=") + 1);
				if (oType === "视频丢包率") {
					Math.abs(item.data) > 0
						? (ovLoss = "视频卡顿时长:" + Math.abs(item.data) + "ms")
						: item.data === 0
						? (ovLoss = "体验良好")
						: (ovLoss = "暂无数据");
					oArray.push({
						user: oUser,
						vLoss: ovLoss
					});
				} else if (oType === "音频丢包率") {
					Math.abs(item.data) > 0
						? (oaLoss = "音频卡顿时长:" + Math.abs(item.data) + "ms")
						: item.data === 0
						? (oaLoss = "体验良好")
						: (oaLoss = "暂无数据");
					oArray.push({
						user: oUser,
						aLoss: oaLoss
					});
				}
			});
			const oM: any = unique(oArray, "user");
			let oLi: string = "";
			oM.forEach((item: any, index: number): void => {
				if ((!item.vLoss && item.aLoss === "暂无数据") || (item.vLoss === "暂无数据" && item.aLoss === "暂无数据")) {
					oLi = oLi + `<div key={ ${index} } style="margin: 10px 0;font-size: 12px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;color: #FF9A45;"> <span style="margin-right: 6px;">来自 ${item.user}:</span> 暂无数据</div>`;
				} else if ((item.vLoss && item.vLoss.indexOf("视频卡顿时长") !== -1) || (item.aLoss && item.aLoss.indexOf("音频卡顿时长") !== -1)) {
					oLi = oLi + `<div key={ ${index} } style="margin: 10px 0;font-size: 12px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;color: #FE4545;word-wrap: break-word;">
						<span style="margin-right: 6px;">来自 ${item.user}:</span>
						<div>
						${item.vLoss.indexOf("视频卡顿时长") !== -1 ? item.vLoss : "视频卡顿时长: (无)"}
						<span style="margin-left: 10px;"> ${
						item.aLoss.indexOf("音频卡顿时长") !== -1 ? item.aLoss : "音频卡顿时长: (无)"
						}</span>
						</div>
					</div>`;
				} else {
					oLi = oLi + `<div key={ ${index} } style="margin: 10px 0;font-size: 12px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;color: #1380ff;">
					<span style="margin-right: 6px;">来自 ${item.user}:</span>
					体验良好
					</div>`;
				}
			});
			const oTime: string = `<div style="font-size: 16px;color: #1380ff;font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
				<span style="margin-right: 10px;">Time</span>
				${params[0].name}
			</div>`;
			return `<div style="border-top: 5px solid #1380FF;padding:20px">${oTime}<div>${oLi}</div></div>`;
    	}
  	},
	series: [
		{
			data: [null, null, null, null, null, null, 0],
			type: "line",
			name: "line1",
			z: 2,
			areaStyle: {
				color: {
					type: "linear",
					x: 0,
					y: 0,
					x2: 0,
					y2: 1,
					colorStops: [
						{
							offset: 0,
							color: "#38a4fc"
						},
						{
							offset: 1,
							color: "white"
						}
					],
					global: false
				}
			},
			smooth: true,
			lineStyle: { width: 1 },
			emphasis: {}
		},
		{
			data: [-820, -932, -901, -934, -1290, -1330, -1320],
			type: "line",
			name: "line2",
			z: 2,
			areaStyle: {
				color: {
					type: "linear",
					x: 0,
					y: 0,
					x2: 0,
					y2: 1,
					colorStops: [
						{
							offset: 0,
							color: "white"
						},
						{
							offset: 1,
							color: "#67c23a"
						}
					],
					global: false
				}
			},
			smooth: true
		},
		{
			data: [502, 635, 809, 947, 1402, 3634, 5268],
			type: "line",
			name: "line3",
			z: 2,
			areaStyle: {
				color: {
					type: "linear",
					x: 0,
					y: 0,
					x2: 0,
					y2: 1,
					colorStops: [
						{
							offset: 0,
							color: "#67c23a"
						},
						{
							offset: 1,
							color: "white"
						}
					],
					global: false
				}
			},
			smooth: true, 
			lineStyle: { width: 1 },
			emphasis: {}
		},
		{
			data: [null, null, null, null, null, null, 0],
			type: "bar",
			stack: "bar",
			name: "bar1",
			yAxisIndex: 1,
			areaStyle: {},
			barWidth: 1,
			z: 4,
			itemStyle: { color: "#FF0000" }
		},
		{
			data: [-80, -150, -90, -93, -129, -33, -132],
			type: "bar",
			stack: "bar",
			name: "bar2",
			yAxisIndex: 1,
			z: 4,
			areaStyle: {},
			itemStyle: { color: "#FF0000" }
		}
  	]
};

const chartLinkageFn: Function = async (charts: any): Promise<void> => {
	const oArray: any[] = [];
	await charts.forEach((item: any): void => {
		item.myChart && oArray.push(item.myChart);
	});
	await charts.forEach((item: any): void => {
		if (item.myChart) {
			item.myChart.dispatchAction({
				type: "takeGlobalCursor",
				key: "dataZoomSelect",
				dataZoomSelectActive: true
			});
			item.myChart.on("dataZoom", async (parm: any): Promise<void> => {
				item.myChart.group = "groupid1";
				await Echarts.connect(oArray);
				charts.forEach((div: any) :void => {
					if (div.myChart) {
						const oId: any = document.getElementById(div.myChart.getDom().id + "restore");
						oId && (oId.style.display = "block");
					}
				});
				item.myChart.group = "";
				StoreDataZoom = {
					startValue: parm.batch[0].startValue,
					endValue: parm.batch[0].endValue
				};
			});
			item.myChart.on("restore", async (parm: any): Promise<void> => {
				item.myChart.group = "groupid1";
				await Echarts.connect(oArray);
				charts.forEach((div: any) :void => {
					if (div.myChart) {
						const oId: any = document.getElementById(div.myChart.getDom().id + "restore");
						oId && (oId.style.display = "none");
					}
				});
				item.myChart.group = "";
				StoreOptions.forEach((option: any): void => {
					if (option.name === item.name) {
						option.options.dataZoom = [];
						item.myChart.setOption(option.options, true);
						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						item.myChart.setOption;
					}
				});
				await chartFinishFn(item.myChart);
				await item.myChart.dispatchAction({
					type: "takeGlobalCursor",
					key: "dataZoomSelect",
					dataZoomSelectActive: true
				});
				StoreDataZoom = {
					startValue: 0,
					endValue: 0
				};
      		});
    	}
  	});
};

const eliminateUser: Function = async (sendDatas: any): Promise<void> => {
  	StoreOptions = [];
	Store.forEach(async (item: any, index: number): Promise<void> => {
		if (item.myChart) {
			const oEchartData: any = await StoreSeries[index];
			const oArray: any[] = [];
			await oEchartData.forEach((data: any): void => {
				const oLine: string = data.name.substring(data.name.indexOf("=") + 1);
				sendDatas.forEach((peerid: any): void => {
					oLine === peerid && oArray.push(data);
				});
      		});

			let options: any = await Object.assign(item.myChart.getOption(), { series: oArray });
			await item.myChart.setOption(options, true);
			StoreOptions.push({ name: item.name, options: item.myChart.getOption() });
			if (StoreDataZoom.startValue >= 0 && StoreDataZoom.endValue > 0) {
				options = await Object.assign(options, {
					dataZoom: {
						startValue: StoreDataZoom.startValue,
						endValue: StoreDataZoom.endValue,
						show: false
					}
				});
				await item.myChart.setOption(options, true);
			}
			await item.myChart.dispatchAction({
				type: "takeGlobalCursor",
				key: "dataZoomSelect",
				dataZoomSelectActive: true
			});
			if (StoreDataZoom.startValue >= 0 && StoreDataZoom.endValue > 0) {
				await item.myChart.setOption({
					toolbox: {
						feature: {
							restore: {
								iconStyle: { opacity: 1 }
							}
						}
					}
				});
			}
		}
  });
};

const chartInitFn: Function = (echartData: any[], sendDatas: any, searchTime: any): any => {
	return new Promise(async (resolve): Promise<any> => {
		try {
			const oArrayPeerLists: any[] = [];
			const NoAudioAndVideo: any[] = [];
			const echarShowData: any[] = [];
			echartData.forEach((item: any): void => {
				item.echarShow && echarShowData.push(item);
			});
			Store.length > 0 && Store.forEach((chart: any): void => (chart.myChart? chart.myChart.dispose() : ""));
			Store = [];
			StoreSeries = [];
			StoreOptions = [];
			if (echarShowData.length > 0) {
				echarShowData.forEach(async (item: any): Promise<void> => {
					let elem: any = document.getElementById("echarts" + item.uId);
					let myChart: any = Echarts.init(elem);
					await chartLodingFn(myChart);
					const {
						code,
						oX,
						peerLists,
						series,
						deletion,
						has,
						lineSize,
						barSize
					} = await chartSeriesData(item, sendDatas, searchTime);
					oArrayPeerLists.push({
						uId: item.uId,
						peerLists
					});
					NoAudioAndVideo.push({
						uId: item.uId,
						has
					});					
					if (true && deletion.audiodeletion && deletion.videodeletion) {
						myChart.dispose();
						createDeletion("Lack of data", elem, "The data is missing and cannot be displayed");
						Store.push({ name: item.uId, myChart: null });
					} else if (true && !has.audiohas && !has.videohas) {
						myChart.dispose();
						createDeletion("No data", elem, "No audio and video data is available");
						Store.push({ name: item.uId, myChart: null });
					} else if (code === 1036) {
						myChart.dispose();
						createDeletion("No data", elem, "The user does not subscribe to media streams");
						Store.push({ name: item.uId, myChart: null });
					} else if (code === 0 && oX.length > 0) {
						StoreSeries.push(series);
						let options = await Object.assign(echartOptions, {
							xAxis: {
								data: oX,
								show: true,
								type: "category",
								boundaryGap: true,
								splitNumber: 6,
								position: "bottom",
								splitLine: { show: false },
								splitArea: {  show: false },
								axisLine: {
									onZero: false,
									lineStyle: { color: "#DCDCDC" }
								},
								axisLabel: {
									margin: 20,
									color: "#2D2D2D",
									fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium"
								},
								axisTick: { show: false }
							},
							yAxis: [
								{
									type: "value",
									minInterval: 1,
									splitNumber: 6,
									show: true,
									axisLine: {
										onZero: false,
										lineStyle: { color: "#DCDCDC" }
									},
									axisLabel: {
										margin: 20,
										color: "#2D2D2D",
										fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium",
										formatter: function(value: any): string {
											return `${Math.abs(value)}kbp`;
										}
									},
									min: lineSize.min,
									max: lineSize.max,
									interval: lineSize.interval,
									splitLine: {
										show: true,
										lineStyle: { type: "dashed" }
									}
								},
								{
									type: "value",
									show: false,
									splitNumber: 6,
									min: barSize.min,
									max: barSize.max,
									interval: barSize.interval
								}
							],
							series: series
						});
						myChart.setOption(options, true);
						await chartFinishFn(myChart);
						Store.push({ name: item.uId, myChart });
						StoreOptions.push({ name: item.uId, options: myChart.getOption() });
						myChart.group = "";
						echartsList.push(myChart);
					};
					if (echarShowData.length === Store.length) {
						chartLinkageFn(Store);
						resolve({
							has: NoAudioAndVideo,
							peerlists: oArrayPeerLists
						});
					}
				});
				window.onresize = (): void => {
					echartsList.forEach((echart: any): void => {
						echart.resize();
					});
				}
			}
		} catch (err) {}
	});
};

const restoreEcharts: Function = (): void => {
	Store.forEach((chart: any): void => {
    if (chart.myChart) {
		chartLodingFn(chart.myChart);
			setTimeout(() => {
				chart.myChart.dispatchAction({ type: "restore" });
			}, 50);
		}
  	});
};

export { chartInitFn, eliminateUser, restoreEcharts };
