const ConnectionState: string[] = ["连接中", "连接成功", "重连中", "重连成功", "连接失败"];
const NetworkState: string[] = ["NETWORK_UNKNOWN", "LAN", "WIFI", "2G", "3G", "4G", "5G"];
const AudioRoutingChange: any = {
    Headset: "耳机",
    Earpiece: "听筒",
    HeadsetNoMic: "不带麦的耳机",
    Speakerphone: "扬声器",
    Loudspeaker: "外接扬声器",
    HeadsetBluetooth: "蓝牙耳机"
};
const DisconnectedState: any = {
    "token expire": "token 过期",
    "wss faild": "WSS 错误",
    "rtc faild": "RTC 错误",
    "kicked": "被踢"
};

const eventTypeFn: Function = (event: any): any => {
	let details: any = event.detail? JSON.parse(event.detail) : "";
	let extend: any = event.extend? JSON.parse(event.extend) : "";

	let tooltipDesc: string = "";
	switch (event.eventType) {
		case 1:
			tooltipDesc = `初始化。网络类型：${NetworkState[details.netType]},角色：${
				details.role === 1 ? "主播" : "观众"
			}`;
			break;
		case 2:
			tooltipDesc = "选择网关";
			break;
		case 3:
			tooltipDesc = `加入频道:${event.errCode === 0 ? "成功" : "失败"}`;
			break;
		case 4:
			tooltipDesc = `发布成功`;
			break;
		case 5:
			tooltipDesc = `订阅成功`;
			break;
		case 6:
			tooltipDesc = `网络连接状态： ${ConnectionState[extend.currentState]}`;
			break;
		case 7:
			tooltipDesc = `本地网络发生变化：${NetworkState[extend.netType]}`;
			break;
		case 8:
			tooltipDesc = `订阅来自 ${event.peerId} 的视频第一帧`;
			break;
		case 9:
			tooltipDesc = `订阅来自 ${event.peerId} 的声音第一帧`;
			break;
		case 10:
			tooltipDesc = `本地第一帧视频发送${event.errCode === 0 ? "成功" : "失败"}`;
			break;
		case 11:
			tooltipDesc = `本地第一帧音频发送${event.errCode === 0 ? "成功" : "失败"}`;
			break;
		case 12:
			tooltipDesc = `app ${extend.isBackGround ? "进入后台" : "回到前台"}`;
			break;
		case 13:
			tooltipDesc = `离开频道事件`;
			break;
		case 14:
			tooltipDesc = `发布音频${event.errCode === 0 ? "成功" : "失败"}`;
			break;
		case 15:
			tooltipDesc = `发布视频${event.errCode === 0 ? "成功" : "失败"}`;
			break;
		case 16:
			tooltipDesc = `订阅成功来自 ${event.peerId} 的音频`;
			break;
		case 17:
			tooltipDesc = `订阅成功来自 ${event.peerId} 的视频`;
			break;
		case 18:
			tooltipDesc = `音频路由输出为：${AudioRoutingChange[extend.route]}`;
			break;
		case 19:
			tooltipDesc = `音频模块禁用：disableAudio`;
			break;
		case 20:
			tooltipDesc = `视频模块禁用:disableVideo`;
			break;
		case 21:
			tooltipDesc = `分辨率更改：${extend.width + "×" + extend.height}`;
			break;
		case 22:
			tooltipDesc = `本地视频模块禁用:disableLocalVideo`;
			break;
		case 23:
			tooltipDesc = `本地音频模块禁用:disableLocalAudio`;
			break;
		case 24:
			tooltipDesc = `本地视频禁用发送：mute`;
			break;
		case 25:
			tooltipDesc = `本地音频禁用发送：mute`;
			break;
		case 26:
			tooltipDesc = `音视频订阅回退通知：${extend.isFallbackOrRecover ? "音频" : "音视频"}`;
			break;
		case 27:
			tooltipDesc = `音频设备开启结果：${event.errCode === 0 ? "成功" : "失败"}`;
			break;
		case 28:
			tooltipDesc = `视频设备开启结果：${event.errCode === 0 ? "成功" : "失败"}`;
			break;
		case 29:
			tooltipDesc = `音频编码失败`;
			break;
		case 30:
			tooltipDesc = `视频编码失败`;
			break;
		case 31:
			tooltipDesc = `打开默认不接收音频方法`;
			break;
		case 32:
			tooltipDesc = `打开默认不接收视频方法`;
			break;
		case 33:
			tooltipDesc = `取消订阅 ${event.peerId} 的音频`;
			break;
		case 34:
			tooltipDesc = `取消订阅 ${event.peerId} 的视频`;
			break;
		case 35:
			extend = extend ? extend : "";
			tooltipDesc = `用户角色更改为${extend.role === 1 ? "主播" : "观众"}`;
			break;
		case 36:
			tooltipDesc = `断开连接：${DisconnectedState[extend.reason]}`;
			break;
	}
	return {
		tooltipDesc,
		errType: event.errType
	};
};

export { eventTypeFn };