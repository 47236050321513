import * as Echarts from "echarts";
import axios from '../../../axios/axios';
import { Option } from '../../../axios/axios-types';
import {
    P2pCall,
    EquipmentStatusOptions,
    NetworkAndLossOptions,
    EventOptions,
    AudioVolumeOptions,
    AudioDecodingLagOptions,
    ResolutionRatioOptions,
    FrameRateOptions,
    FrameRateAndLagOptions
} from "../../reconsitution/p2pJson";
import { chartLodingFn, chartFinishFn, createDeletion } from "../../echarts/configuration";
import { timeTransformation } from "../../reconsitution/time";
import { eventTypeFn } from "../../reconsitution/p2pEvent";

interface Result {
	data: {
        code: number;
        data: any;
    };
};

let Store: any = {
	chartsData: null,
	chartInstance: [],
	oX: []
};

const echartsList: Array<any> = [];
const barColor: string[] = ["#1BBC72", "#1BBC72", "#FF8723", "#FE4545"];
const getIdFn: Function = (select: string, sendInfo: any[], receivingInfo: any[]): any => {
	const oData: any = select === "视频"? P2pCall.video : P2pCall.voice;
	const oId: { type: string; name: any; oId: any; platform: any; VGA: string }[] = [];
	const oslimitSend: any[] = sendInfo.filter((item: any): boolean => {
		return item.name === "平台";
	});
	const oslimitReceiv: any[] = receivingInfo.filter((item: any): boolean => {
		return item.name === "平台";
	});
	oData.sending.forEach((item: any): void => {
		oId.push({
			type: "The sender",
			name: item.title,
			oId: item.id,
			platform: oslimitSend[0].type,
			VGA: select
		});
	});

	oData.receiving.forEach((item: any): void => {
		oId.push({
			type: "接收端",
			name: item.title,
			oId: item.id,
			platform: oslimitReceiv[0].type,
			VGA: select
		});
	});
  	return oId;
};
const requestDataFn: Function = async (type: string, demandData: { appId: any; chanId: any; uId: any; startTs: any; stopTs: any; peerId: any; chanSId: any; }, dType: number, verdict: string): Promise<any> => {
	let oMiddleData: any = {
		appId: demandData.appId,
		chanId: demandData.chanId,
		peerId: demandData.uId,
		startTs: demandData.startTs,
		stopTs: demandData.stopTs,
		uId: verdict === "The sender"? demandData.uId : demandData.peerId,
		chanSId: demandData.chanSId,
		dType
	};
	let oM: any = null;
	const option: Option = {
		url: '',
		data: oMiddleData,
		method: 'POST'
	};
	if (type === "视频") {
		oM = verdict === "The sender"
			? await axios(Object.assign(option, { url: '/v2/project/getP2PPushVidData' }))
			: await axios(Object.assign(option, { url: '/v2/project/getP2PPullVidData' }));
	} else {
		oM = verdict === "The sender"
			? await axios(Object.assign(option, { url: '/v2/project/getP2PPushAudData' }))
			: await axios(Object.assign(option, { url: '/v2/project/getP2PPullAudData' }));
	}
	let { code, data } = oM.data;
	let deficiency: any = {};
	let uprelevan: any = {};
	if (code === 0) {
		if (Store.oX.length === 0) {
			data.ts.forEach((item: any): void => {
				Store.oX.push(timeTransformation(item, false));
			});
		} else if (Store.oX.length !== data.ts.length) {
			Store.oX = [];
			data.ts.forEach((item: any): void => {
				Store.oX.push(timeTransformation(item, false));
			});
		}
		let oMiddle: any = data.data;
		for (const i in oMiddle) {
			if (oMiddle[i].length > 0) {
				let oLocalCau = oMiddle[i].filter((item: any): boolean => {
					return item === null;
				});
				deficiency[i] = oLocalCau.length === oMiddle[i].length? true : false;
				uprelevan[i] = true;
			} else {
				uprelevan[i] = false;
			}
		}
		if (type === "视频") {
			if (oMiddle.vw && oMiddle.vw.length > 0) {
				let ochartDivision: any = chartDivisionFn(oMiddle.vwh, "分辨率");
				let oMaxvw: number = Math.max(...oMiddle.vw);
				let oMaxvh: number = Math.max(...oMiddle.vh);
				let resolutionlocal: any = [];
				if (oMaxvw <= oMaxvh) {
					await oMiddle.vw.forEach((item: any, index: number): void => {
						resolutionlocal.push({
							value: item,
							h: oMiddle.vh[index],
							w: item
						});
					});
				} else {
					await oMiddle.vh.forEach((item: any, index: number): void => {
						resolutionlocal.push({
							value: item,
							h: item,
							w: oMiddle.vw[index]
						});
					});
				}
				data.data.vwh = resolutionlocal;
				data.data.chartdivisio = ochartDivision;
			}

			if (oMiddle.w && oMiddle.w.length > 0) {
				let ochartDivision: any = chartDivisionFn(oMiddle.vwh, "分辨率");
				let oMaxvw: number = Math.max(...oMiddle.w);
				let oMaxvh: number = Math.max(...oMiddle.h);
				let resolutionremote: any = [];
				if (oMaxvw <= oMaxvh) {
					await oMiddle.w.forEach((item: any, index: number) => {
						resolutionremote.push({
							value: item,
							h: oMiddle.h[index],
							w: item
						});
					});
				} else {
					await oMiddle.h.forEach((item: any, index: number) => {
						resolutionremote.push({
							value: item,
							h: item,
							w: oMiddle.w[index]
						});
					});
				}
				data.data.vwh = resolutionremote;
				data.data.chartdivisio = ochartDivision;
			}
		}
  	}
	return {
		code,
		data: data.data,
		deficiency,
		uprelevan
	};
};
const requestEventFn: Function = async (type: string, demandData: { appId: any; chanId: any; chanSId: any; peerId: any; uId: any; }, oX: string[]): Promise<any> => {
	let eventList: any = null;
	let oMiddle: any = {
		appId: demandData.appId,
		chanId: demandData.chanId,
		chanSId: demandData.chanSId,
		peerId: type === "The sender"? demandData.peerId : demandData.uId,
		uId: type === "The sender"? demandData.uId : demandData.peerId
	};
	const option: Option = {
		url: '',
		data: oMiddle,
		method: 'POST'
	};
	const result: Result = await axios(Object.assign(option, { url: '/v2/project/getP2PEventData' }));
	if (result.data.code === 0 && result.data.data && result.data.data.length > 0) {
		eventList = await assemblyEvenFn(
			result.data.data,
			oX,
			type === "The sender"? demandData.peerId : demandData.uId
		);
	}
	return eventList;
};
const auxiliaryFn: Function = (type: any[], deficiency: { [x: string]: any; }, uprelevan: { [x: string]: any; }): any => {
	let oArray: Array<any> = [];
	type.forEach((item: any): void => {
		if (deficiency[item]) {
			oArray.push("Lack of data");
		} else if (!uprelevan[item]) {
			oArray.push("Temporarily no data");
		}
	});
	let decide: boolean = true;
	let cause: string = "";
	if (oArray.length === type.length) {
		decide = false;
		let o = oArray.filter((item: any): boolean => {
			return item === "Lack of data";
		});
		cause = o.length > 0? "Lack of data" : "Temporarily no data";
	} else {
		decide = true;
	}
	return {
		decide,
		cause
	};
};
const installEventTimesFn: Function = ({ starTime, endTime }: any): Array<any> => {
	if (endTime === 0) {
		endTime = parseInt((new Date().getTime() / 1000).toString());
	}
	let oArray: Array<any> = [];
	for (let index = starTime - 5; index <= endTime; index++) {
		if (index <= endTime) {
			oArray.push(timeTransformation(index, false));
		}
	}
  	return oArray;
};
const assemblyEvenFn: Function = (data: any[], ox: any[], id: any): any => {
	return new Promise(async resolve => {
		let xData: Array<any> = [];
		let yData: Array<any> = [];
		let oIfo: Array<any>= [];
		data.forEach((event: any): void => {
			if (event.peerId.length === 0 || event.peerId === id) {
				let ets: number = parseInt((event.lts / 1000).toString());
				!~xData.indexOf(ets) && xData.push(ets);
				let tooltipDesc: any = eventTypeFn(event);
				oIfo.push({
					time: timeTransformation(event.lts),
					desc: tooltipDesc.tooltipDesc,
					type: tooltipDesc.errType
				});
				yData[xData.indexOf(ets)] = yData[xData.indexOf(ets)] || {
					value: 0,
					eventList: [],
					itemStyle: {
						color: "#a90000"
					}
				};
				yData[xData.indexOf(ets)].eventList.push({
					lts: event.lts,
					etype: tooltipDesc
				});
				yData[xData.indexOf(ets)].value++;
				let oColor: string = yData[xData.indexOf(ets)].itemStyle.color;
				if (oColor !== barColor[3] && oColor !== barColor[2]) {
					yData[xData.indexOf(ets)].itemStyle.color = barColor[event.errType];
				} else if (oColor === barColor[2] && event.errType === 3) {
					yData[xData.indexOf(ets)].itemStyle.color = barColor[event.errType];
				}
			}
    	});
		let yDataCopy: Array<any> = [];
		ox.forEach((time: any): void => {
			let oA: boolean = false;
			xData.forEach((item: any, itex: number): void => {
				if (timeTransformation(item, false) === time) {
					yDataCopy.push(yData[itex]);
					oA = true;
				}
			});
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			oA? "" : yDataCopy.push({ value: 0 });
		});
		resolve({ yDataCopy, eventInfo: oIfo });
  	});
};
const chartDivisionFn: Function = (data: any, type = ""): any => {
	let oMax: number = Math.max(...data);
	let oMin: number = Math.min(...data);
	let chartdivision: any = {
		max: 0,
		min: 0,
		interval: 0
	};
	if (type === "分辨率") {
		if (oMax <= 1920 * 1080) {
			chartdivision = {
				max: 1080,
				min: 0,
				interval: 360
			};
		} else if (oMax > 1920 * 1080 && oMax <= 2560 * 1440) {
			chartdivision = {
				max: 1440,
				min: 0,
				interval: Math.ceil(1440 / 3)
			};
		} else if (oMax > 2560 * 1440 && oMax <= 3840 * 2160) {
			chartdivision = {
				max: 2160,
				min: 0,
				interval: Math.ceil(2160 / 3)
			};
		}
	} else {
		if (oMin * 3 <= oMax) {
			if (oMin === 0 && oMax === 0) {
				chartdivision = {
					max: 3,
					interval: 1,
					min: 0
				};
			} else {
				chartdivision = {
					max: Math.ceil(oMax / 3) * 3,
					interval: Math.ceil(oMax / 3),
					min: oMin
				};
			}
		} else {
			chartdivision = {
				max: Math.abs(oMin * 3),
				interval: Math.abs(oMin),
				min: oMin
			};
		}
	}
	return chartdivision;
};
const createEcharts: Function = async (sort: { oId: string; }, demandData: any): Promise<any> => {
	let oDiv: any = document.getElementById(sort.oId);
	let oldChart: any = Echarts.getInstanceByDom(oDiv);
	oldChart && Echarts.dispose(oldChart);
	Echarts.dispose(oDiv);
	let myChart: any = Echarts.init(oDiv);
	echartsList.push(myChart);
	await chartLodingFn(myChart);
	let oA: any = await EchartsStructure(myChart, sort, demandData, oDiv);
	Store.chartInstance.push({ myChart: oA.oEcharts, oDiv });
	return oA.eventInfo;
};
const EchartsStructure: Function = async (myChart: any, sort: any, demandData: any, oDiv: any): Promise<any> => {
	let oEcharts: any = null;
	let oEventInfo: any = {
		sengInfo: [],
		receivInfo: [],
		id: 1
	};
	let verdict: any = sort.type;
	let oName: any = sort.name;
	let platform: any = sort.platform;
	let oVGC: any = sort.VGA;
	switch (oName) {
		case "设备状态":
			if (platform === "Web" || platform === "小程序") {
				chartFinishFn(myChart);
				myChart.dispose();
				createDeletion("Data to the CPU", oDiv, "WebRTC does not have CPU data");
			} else {
				let {
					data,
					deficiency,
					uprelevan
				}: any = await requestDataFn(oVGC, demandData, 1, verdict);
				let { decide, cause }: any = await auxiliaryFn(["cau", "cs"], deficiency, uprelevan);
				if (decide) {
					EquipmentStatusOptions.xAxis.data = Store.oX;
					EquipmentStatusOptions.series[0].data = data.cau;
					EquipmentStatusOptions.series[1].data = data.cs;
					myChart.setOption(EquipmentStatusOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "视频发送分辨率":
			{
				let {
					data,
					deficiency,
					uprelevan
				}: any = await requestDataFn(oVGC, demandData, 2, verdict);
				let { decide, cause }: any = await auxiliaryFn(["vw", "vh"], deficiency, uprelevan);
				if (decide) {
					ResolutionRatioOptions.xAxis.data = Store.oX;
					ResolutionRatioOptions.yAxis.interval = data.chartdivisio.interval;
					ResolutionRatioOptions.yAxis.min = data.chartdivisio.min;
					ResolutionRatioOptions.yAxis.max = data.chartdivisio.max;
					ResolutionRatioOptions.series[0].data = data.vwh;
					ResolutionRatioOptions.series[0].name = "视频发送分辨率";
					myChart.setOption(ResolutionRatioOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "视频发送帧率":
			{
				let {
					data,
					deficiency,
					uprelevan
				}: any = await requestDataFn(oVGC, demandData, 3, verdict);
				let { decide, cause }: any = await auxiliaryFn(["efps"], deficiency, uprelevan);
				if (decide) {
					FrameRateOptions.xAxis.data = Store.oX;
					FrameRateOptions.series[0].data = data.efps;
					FrameRateOptions.series[0].name = "视频发送帧率";
					let odivision = await chartDivisionFn(data.efps);
					FrameRateOptions.yAxis.interval = odivision.interval;
					FrameRateOptions.yAxis.min = odivision.min;
					FrameRateOptions.yAxis.max = odivision.max;
					myChart.setOption(FrameRateOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "视频上行和网络丢包":
			{
				let {
					data,
					deficiency,
					uprelevan
				} = await requestDataFn(oVGC, demandData, 4, verdict);
				let { decide, cause } = await auxiliaryFn(["ebr", "ploss"], deficiency, uprelevan);
				if (decide) {
					NetworkAndLossOptions.xAxis.data = Store.oX;
					NetworkAndLossOptions.legend.data[0].name = "视频发送码率";
					NetworkAndLossOptions.legend.data[1].name = "视频上行丢包率";
					let odivision0 = await chartDivisionFn(data.ebr);
					NetworkAndLossOptions.yAxis[0].interval = odivision0.interval;
					NetworkAndLossOptions.yAxis[0].min = odivision0.min;
					NetworkAndLossOptions.yAxis[0].max = odivision0.max;
					let odivision1 = await chartDivisionFn(data.ploss);
					NetworkAndLossOptions.yAxis[1].interval = odivision1.interval;
					NetworkAndLossOptions.yAxis[1].min = odivision1.min;
					NetworkAndLossOptions.yAxis[1].max = odivision1.max;

					NetworkAndLossOptions.series[0].data = data.ebr;
					NetworkAndLossOptions.series[0].name = "视频发送码率";
					NetworkAndLossOptions.series[1].data = data.ploss;
					NetworkAndLossOptions.series[1].name = "视频上行丢包率";
					myChart.setOption(NetworkAndLossOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "用户事件":
			let oTime: any = {
				starTime: demandData.startTs,
				endTime: demandData.stopTs
			};
			let ox: any = await installEventTimesFn(oTime);
			let oEvent: any = await requestEventFn(verdict, demandData, ox);
			if (oEvent) {
				let { yDataCopy, eventInfo }: any = oEvent;
				EventOptions.series[0].data = yDataCopy;
				EventOptions.xAxis.data = ox;
				myChart.setOption(EventOptions, true);
				chartFinishFn(myChart);
				oEcharts = myChart;
				verdict === "The sender"
				? (oEventInfo.sengInfo = eventInfo)
				: (oEventInfo.receivInfo = eventInfo);
			} else {
				chartFinishFn(myChart);
				myChart.dispose();
				createDeletion("Temporarily no data", oDiv, "No user data is available");
				verdict === "The sender"? (oEventInfo.sengInfo = []) : (oEventInfo.receivInfo = []);
			}
			break;
		case "视频接收分辨率":
			{
				let {
					data,
					deficiency,
					uprelevan
				} = await requestDataFn(oVGC, demandData, 2, verdict);
				let { decide, cause } = await auxiliaryFn(["w", "h"], deficiency, uprelevan);
				if (decide) {
					ResolutionRatioOptions.xAxis.data = Store.oX;
					ResolutionRatioOptions.series[0].data = data.vwh;
					ResolutionRatioOptions.yAxis.interval = data.chartdivisio.interval;
					ResolutionRatioOptions.yAxis.min = data.chartdivisio.min;
					ResolutionRatioOptions.yAxis.max = data.chartdivisio.max;
					ResolutionRatioOptions.series[0].name = "视频接收分辨率";
					myChart.setOption(ResolutionRatioOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "视频帧率和卡顿":
			{
				let {
					data,
					deficiency,
					uprelevan
				} = await requestDataFn(oVGC, demandData, 3, verdict);
				let { decide, cause } = await auxiliaryFn(["rofps", "tft"], deficiency, uprelevan);
				if (decide) {
					if (platform === "Web" || platform === "小程序") {
						FrameRateOptions.xAxis.data = Store.oX;
						FrameRateOptions.series[0].data = data.rofps;
						FrameRateOptions.series[0].name = "视频接收帧率";
						let odivision = await chartDivisionFn(data.rofps);
						FrameRateOptions.yAxis.interval = odivision.interval;
						FrameRateOptions.yAxis.min = odivision.min;
						FrameRateOptions.yAxis.max = odivision.max;
						myChart.setOption(FrameRateOptions, true);
					} else {
						FrameRateAndLagOptions.xAxis.data = Store.oX;
						FrameRateAndLagOptions.series[0].data = data.rofps;
						FrameRateAndLagOptions.series[1].data = data.tft;
						let odivision0 = await chartDivisionFn(data.rofps);
						FrameRateAndLagOptions.yAxis[0].interval = odivision0.interval;
						FrameRateAndLagOptions.yAxis[0].min = odivision0.min;
						FrameRateAndLagOptions.yAxis[0].max = odivision0.max;
						let odivision1 = await chartDivisionFn(data.tft);
						FrameRateAndLagOptions.yAxis[1].interval = odivision1.interval;
						FrameRateAndLagOptions.yAxis[1].min = odivision1.min;
						FrameRateAndLagOptions.yAxis[1].max = odivision1.max;
						myChart.setOption(FrameRateAndLagOptions, true);
					}
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "视频下行和端到端丢包":
			{
				let {
					data,
					deficiency,
					uprelevan 
				} = await requestDataFn(oVGC, demandData, 4, verdict);
				let { decide, cause } = await auxiliaryFn(["rbr", "ploss"], deficiency, uprelevan);
				if (decide) {
					NetworkAndLossOptions.xAxis.data = Store.oX;
					NetworkAndLossOptions.legend.data[0].name = "视频接收码率";
					NetworkAndLossOptions.legend.data[1].name = "视频端到端丢包率";
					let odivision0 = await chartDivisionFn(data.rbr);
					NetworkAndLossOptions.yAxis[0].interval = odivision0.interval;
					NetworkAndLossOptions.yAxis[0].min = odivision0.min;
					NetworkAndLossOptions.yAxis[0].max = odivision0.max;
					let odivision1 = await chartDivisionFn(data.ploss);
					NetworkAndLossOptions.yAxis[1].interval = odivision1.interval;
					NetworkAndLossOptions.yAxis[1].min = odivision1.min;
					NetworkAndLossOptions.yAxis[1].max = odivision1.max;
					NetworkAndLossOptions.series[0].name = "视频接收码率";
					NetworkAndLossOptions.series[1].name = "视频端到端丢包率";
					NetworkAndLossOptions.series[0].data = data.rbr;
					NetworkAndLossOptions.series[1].data = data.ploss;
					myChart.setOption(NetworkAndLossOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "音频采集音量":
			{
				let {
					data,
					deficiency,
					uprelevan
				} = await requestDataFn(oVGC, demandData, 2, verdict);
				let { decide, cause } = await auxiliaryFn(["vol"], deficiency, uprelevan);
				if (decide) {
					AudioVolumeOptions.xAxis.data = Store.oX;
					let odivision = await chartDivisionFn(data.vol);
					AudioVolumeOptions.yAxis.interval = odivision.interval;
					AudioVolumeOptions.yAxis.min = odivision.min;
					AudioVolumeOptions.yAxis.max = odivision.max;
					AudioVolumeOptions.series[0].name = "音频采集音量";
					AudioVolumeOptions.series[0].data = data.vol;
					myChart.setOption(AudioVolumeOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "音频上行和网络丢包":
			{
				let {
					data,
					deficiency,
					uprelevan
				} = await requestDataFn(oVGC, demandData, 3, verdict);
				let { decide, cause } = await auxiliaryFn(["sbr", "ploss"], deficiency, uprelevan);
				if (decide) {
					NetworkAndLossOptions.xAxis.data = Store.oX;
					NetworkAndLossOptions.legend.data[0].name = "音频发送码率";
					NetworkAndLossOptions.legend.data[1].name = "音频上行丢包率";
					let odivision0 = await chartDivisionFn(data.sbr);
					NetworkAndLossOptions.yAxis[0].interval = odivision0.interval;
					NetworkAndLossOptions.yAxis[0].min = odivision0.min;
					NetworkAndLossOptions.yAxis[0].max = odivision0.max;
					let odivision1 = await chartDivisionFn(data.ploss);
					NetworkAndLossOptions.yAxis[1].interval = odivision1.interval;
					NetworkAndLossOptions.yAxis[1].min = odivision1.min;
					NetworkAndLossOptions.yAxis[1].max = odivision1.max;
					NetworkAndLossOptions.series[0].name = "音频发送码率";
					NetworkAndLossOptions.series[1].name = "音频上行丢包率";
					NetworkAndLossOptions.series[0].data = data.sbr;
					NetworkAndLossOptions.series[1].data = data.ploss;
					myChart.setOption(NetworkAndLossOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "音频播放音量":
			{
				let {
					data,
					deficiency,
					uprelevan
				} = await requestDataFn(oVGC, demandData, 2, verdict);
				let { decide, cause } = await auxiliaryFn(["vol"], deficiency, uprelevan);
				if (decide) {
					AudioVolumeOptions.xAxis.data = Store.oX;
					let odivision = await chartDivisionFn(data.vol);
					AudioVolumeOptions.yAxis.interval = odivision.interval;
					AudioVolumeOptions.yAxis.min = odivision.min;
					AudioVolumeOptions.yAxis.max = odivision.max;
					AudioVolumeOptions.series[0].name = "音频播放音量";
					AudioVolumeOptions.series[0].data = data.vol;
					myChart.setOption(AudioVolumeOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "音频下行和端到端丢包":
			{
				let {
					data,
					deficiency,
					uprelevan
				} = await requestDataFn(oVGC, demandData, 3, verdict);
				let { decide, cause } = await auxiliaryFn(["rb", "ploss"], deficiency, uprelevan);
				if (decide) {
					NetworkAndLossOptions.xAxis.data = Store.oX;
					NetworkAndLossOptions.legend.data[0].name = "音频接收码率";
					NetworkAndLossOptions.legend.data[1].name = "音频端到端丢包率";
					let odivision0 = await chartDivisionFn(data.rb);
					NetworkAndLossOptions.yAxis[0].interval = odivision0.interval;
					NetworkAndLossOptions.yAxis[0].min = odivision0.min;
					NetworkAndLossOptions.yAxis[0].max = odivision0.max;
					let odivision1 = await chartDivisionFn(data.ploss);
					NetworkAndLossOptions.yAxis[1].interval = odivision1.interval;
					NetworkAndLossOptions.yAxis[1].min = odivision1.min;
					NetworkAndLossOptions.yAxis[1].max = odivision1.max;
					NetworkAndLossOptions.series[0].name = "音频接收码率";
					NetworkAndLossOptions.series[1].name = "音频端到端丢包率";
					NetworkAndLossOptions.series[0].data = data.rb;
					NetworkAndLossOptions.series[1].data = data.ploss;
					myChart.setOption(NetworkAndLossOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
		case "音频解码卡顿":
			{
				let {
				data,
				deficiency,
				uprelevan
				} = await requestDataFn(oVGC, demandData, 4, verdict);
				let { decide, cause } = await auxiliaryFn(["tft"], deficiency, uprelevan);
				if (decide) {
					let oTft: Array<any> = [];
					data.tft.forEach((item: any): void => {
						oTft.push({
							value: item,
							itemStyle: {
								color: item >= 500 ? "#FE4545" : "#FF8723"
							}
						});
					});
					AudioDecodingLagOptions.xAxis.data = Store.oX;
					let odivision = await chartDivisionFn(data.tft);
					AudioDecodingLagOptions.yAxis.interval = odivision.interval;
					AudioDecodingLagOptions.yAxis.min = odivision.min;
					AudioDecodingLagOptions.yAxis.max = odivision.max;
					AudioDecodingLagOptions.series[0].data = oTft;
					myChart.setOption(AudioDecodingLagOptions, true);
					chartFinishFn(myChart);
					oEcharts = myChart;
				} else {
					chartFinishFn(myChart);
					myChart.dispose();
					createDeletion(cause, oDiv, cause);
				}
			}
			break;
	}
	return { oEcharts, eventInfo: oEventInfo };
};
const Echartslinkage: Function = async (charts: any): Promise<any> => {
	let oArray: Array<any> = [];
	await charts.forEach(async (item: any): Promise<void> => {
		if (item.myChart) {
			oArray.push(item.myChart);
		}
	});
	await charts.forEach(async (item: any): Promise<void> => {
		item.myChart.dispatchAction({
			type: "takeGlobalCursor",
			key: "dataZoomSelect",
			dataZoomSelectActive: true
		});
		item.myChart.on("dataZoom", async (): Promise<void> => {
			item.myChart.group = "groupid1";
			await Echarts.connect(oArray);
			charts.forEach((div: any): void => {
				if (div.myChart) {
					let oId = document.getElementById(div.oDiv.id + "restore");
					oId && (oId.style.display = "block");
				}
			});
			item.myChart.group = "";
		});
		item.myChart.on("restore", async (): Promise<void> => {
			item.myChart.group = "groupid1";
			await Echarts.connect(oArray);
			charts.forEach((div: any): void => {
				if (div.myChart) {
					let oId = document.getElementById(div.oDiv.id + "restore");					
					oId && (oId.style.display = "none");
				}
			});
			item.myChart.group = "";
			await chartFinishFn(item.myChart);
			await item.myChart.dispatchAction({
				type: "takeGlobalCursor",
				key: "dataZoomSelect",
				dataZoomSelectActive: true
			});
		});
	});
};
const initEcharts = (radio: any, demandData: any, sendInfo: any, receivingInfo: any): any => {
	return new Promise(async (resolve, reject) => {
		try {
			let oData: any = await getIdFn(radio, sendInfo, receivingInfo);
			Store.chartInstance = [];
			Store.oX = [];
			let EventInfo: any = {
				sengInfo: [], 
				receivInfo: []
			};
			oData.forEach(async (id: any): Promise<void> => {
				let oa: any = await createEcharts(id, demandData);
				oa.sengInfo.length > 0 && (EventInfo.sengInfo = oa.sengInfo);
				oa.receivInfo.length > 0 && (EventInfo.receivInfo = oa.receivInfo);
			});
			let oTime = setInterval(() => {
				if (Store.chartInstance.length >= oData.length) {
					clearInterval(oTime);
					let oMidel: any[] = Store.chartInstance.filter((chart: any): boolean => {
						return chart.myChart !== null;
					});
					Echartslinkage(oMidel);
					resolve(EventInfo);
				}
			}, 100);
			window.onresize = (): void => {
				echartsList.forEach((echart: any): void => {
					echart.resize();
				});
			}
		} catch (error) {}
	});
};
const restoreEcharts = (): void => {
	Store.chartInstance.forEach((chart: any): void => {
		if (chart.myChart) {
			chartLodingFn(chart.myChart);
			setTimeout(() => {
				chart.myChart.dispatchAction({ type: "restore" });
			}, 50);
		}
	});
};
export { initEcharts, restoreEcharts };
