import { FC, ReactElement, useState, useEffect } from 'react';
import { Checkbox, Button, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './customerAddNext.less';
const CheckboxGroup: any = Checkbox.Group;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const yspOptions: any[] = [
    { label: "管理服务", value: 2, disabled: false },
    { label: "拉流服务", value: 7, disabled: false },
    { label: "推流服务", value: 5, disabled: false },
    { label: "中转服务", value: 6, disabled: false },
    { label: "网关服务", value: 3, disabled: false },
    { label: "认证服务", value: 1, disabled: false }
]; // 音视频
const ylzOptions: any[] = [
    { label: "管理服务", value: 11, disabled: false },
    { label: "录制服务", value: 12, disabled: false },
    { label: "转码服务", value: 13, disabled: false },
    { label: "录像上传服务", value: 14, disabled: false },
    { label: "Restful服务", value: 15, disabled: false }
]; // 云录制
const xcxOptions: any[] = [
    { label: "小程序接入网关服务器", value: 19, disabled: false },
    { label: "小程序节点服务器", value: 20, disabled: false }
]; // 小程序
const pltlOptions: any[] = [
    { label: "网关服务", value: 16, disabled: false },
    { label: "跨媒体转码服务", value: 18, disabled: false },
    { label: "旁路推流服务", value: 17, disabled: false }
]; // 旁路推流
const ssxxOptions: any[] = [
    { label: "节点服务器", value: 10, disabled: false },
    { label: "管理服务器", value: 9, disabled: false },
    { label: "网关服务", value: 8, disabled: false }
]; // 实时消息

const CustomerAddNext: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [yspOptionsCheckedCities, setYspOptionsCheckedCities]: [number[], Function] = useState([]);
    const [ylzOptionsCheckedCities, setYlzOptionsCheckedCities]: [number[], Function] = useState([]);
    const [xcxOptionsCheckedCities, setXcxOptionsCheckedCities]: [number[], Function] = useState([]);
    const [pltlOptionsCheckedCities, setPltlOptionsCheckedCities]: [number[], Function] = useState([]);
    const [ssxxOptionsCheckedCities, setSsxxOptionsCheckedCities]: [number[], Function] = useState([]);
    const [yspOptionsIsIndeterminate, setYspOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [ylzOptionsIsIndeterminate, setYlzOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [xcxOptionsIsIndeterminate, setXcxOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [pltlOptionsIsIndeterminate, setPltlOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [ssxxOptionsIsIndeterminate, setSsxxOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [yspOptionsCheckAll, setYspOptionsCheckAll]: [boolean, Function] = useState(false);
    const [ylzOptionsCheckAll, setYlzOptionsCheckAll]: [boolean, Function] = useState(false);
    const [xcxOptionsCheckAll, setXcxOptionsCheckAll]: [boolean, Function] = useState(false);
    const [pltlOptionsCheckAll, setPltlOptionsCheckAll]: [boolean, Function] = useState(false);
    const [ssxxOptionsCheckAll, setSsxxOptionsCheckAll]: [boolean, Function] = useState(false);
    const [loading, setLoading]: [boolean, Function] = useState(false);

    const checkList: any[] = [
        {
            label: '音视频',
            indeterminate: yspOptionsIsIndeterminate,
            checked: yspOptionsCheckAll,
            options: yspOptions,
            value: yspOptionsCheckedCities,
            setCheckedCities: setYspOptionsCheckedCities,
            setIsIndeterminate: setYspOptionsIsIndeterminate, 
            setCheckAll: setYspOptionsCheckAll,
        },
        {
            label: '云录制',
            indeterminate: ylzOptionsIsIndeterminate,
            checked: ylzOptionsCheckAll,
            options: ylzOptions,
            value: ylzOptionsCheckedCities,
            setCheckedCities: setYlzOptionsCheckedCities,
            setIsIndeterminate: setYlzOptionsIsIndeterminate, 
            setCheckAll: setYlzOptionsCheckAll,
        },
        {
            label: '小程序',
            indeterminate: xcxOptionsIsIndeterminate,
            checked: xcxOptionsCheckAll,
            options: xcxOptions,
            value: xcxOptionsCheckedCities,
            setCheckedCities: setXcxOptionsCheckedCities,
            setIsIndeterminate: setXcxOptionsIsIndeterminate, 
            setCheckAll: setXcxOptionsCheckAll,
        },
        {
            label: '旁路推流',
            indeterminate: pltlOptionsIsIndeterminate,
            checked: pltlOptionsCheckAll,
            options: pltlOptions,
            value: pltlOptionsCheckedCities,
            setCheckedCities: setPltlOptionsCheckedCities,
            setIsIndeterminate: setPltlOptionsIsIndeterminate, 
            setCheckAll: setPltlOptionsCheckAll,
        },
        {
            label: '实时消息',
            indeterminate: ssxxOptionsIsIndeterminate,
            checked: ssxxOptionsCheckAll,
            options: ssxxOptions,
            value: ssxxOptionsCheckedCities,
            setCheckedCities: setSsxxOptionsCheckedCities,
            setIsIndeterminate: setSsxxOptionsIsIndeterminate, 
            setCheckAll: setSsxxOptionsCheckAll,
        },
    ];

    useEffect((): void => {
        yspOptions[5].disabled = +location.state.licType === 2;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dealWith: Function = (arr: any[]): number[] => {
        const list: number[] = [];
        arr.forEach((item: any): void => {
            item.disabled || list.push(item.value);
        });
        return list;
    };

    const onChange: Function = (list: any[], item: any): void => {
        item.setCheckedCities(list);
        item.setIsIndeterminate(!!list.length && list.length < item.options.length);
        item.setCheckAll(list.length === item.options.length);
    };

    const onCheckAllChange: Function = (e: any, item: any): void => {
        item.setCheckedCities(e.target.checked? dealWith(item.options) : []);
        item.setIsIndeterminate(false);
        item.setCheckAll(e.target.checked);
    };

    const insertService: Function = async (): Promise<void> => {
        setLoading(true);
        const arrSType: number[] = [];
        checkList.forEach((item: any): void => {
            item.value.forEach((value: any): void => {
                arrSType.push(value);
            });
        });
        const option: Option = {
            url: '/primgr/insertService',
            data: {
                uid: location.state.uid,
                licType: +location.state.licType,
                license: location.state.license,
                arrSType
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        setLoading(false);
        if (result.data.code === 0) {
            handleTo();
            message.success("服务提交成功");
        } else if (result.data.code === 5007) {
            message.error("请勾选音视频认证服务");
        } else if (result.data.code === 5012) {
            message.error("请取消勾选音视频认证服务");
        } else if (result.data.code === -4) {
            message.error("至少选择一个服务");
        } else {
            message.error("服务提交失败");
        }
    };

    const handleTo: Function = (): void => {
        if (location.state.devp === 1) {
            history.push('/content/private');
        } else if (location.state.devp === 2) {
            history.push('/content/private/period');
        }
    };

    return (
        <div className={ styles.customerAddNext }>
            <div className={ styles.modal }>
                <p className={ styles.title }>添加客户</p>
                {
                    checkList.map((item: any, index: number): ReactElement => {
                        return (
                            <div className={ styles.fragment } key={ index }>
                                <Checkbox indeterminate={ item.indeterminate } 
                                onChange={ (e) => onCheckAllChange(e, item) } 
                                checked={ item.checked }>{ item.label }</Checkbox>
                                <div className={ styles.line }></div>
                                <CheckboxGroup options={ item.options } value={ item.value } 
                                onChange={ (list: any) => onChange(list, item) } />
                            </div>
                        )
                    })
                }
                <div className={ styles.btnBox }>
                    <Button className={ styles.btn } onClick={ () => handleTo() }>Cancel</Button>
                    <Button className={ styles.btn } type="primary" loading={ loading } disabled={ loading } onClick={ () => insertService() }>Submit</Button>
                </div>
            </div>
        </div>
    )
};

export default CustomerAddNext;