import { FC, ReactElement, useEffect, useState } from 'react';
import { Checkbox, Button, Input, Select, Upload, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Option } from '../../../../../axios/axios-types';
import { uploadUrl } from '../../../../../axios/baseUrl';
import { connect } from 'react-redux';
import { TOKEN } from '../../../../../store/reducer';
import axios from '../../../../../axios/axios';
import styles from './added.less'; 
const CheckboxGroup: any = Checkbox.Group;
const { TextArea } = Input;
namespace Antd {
    export const { Option } = Select;
}

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
	token: TOKEN;
};

const yspOptions: any[] = [
    { label: "管理服务", value: 2, disabled: false },
    { label: "拉流服务", value: 7, disabled: false },
    { label: "推流服务", value: 5, disabled: false },
    { label: "中转服务", value: 6, disabled: false },
    { label: "网关服务", value: 3, disabled: false },
    { label: "认证服务", value: 1, disabled: false }
]; // 音视频
const ylzOptions: any[] = [
    { label: "管理服务", value: 11, disabled: false },
    { label: "录制服务", value: 12, disabled: false },
    { label: "转码服务", value: 13, disabled: false },
    { label: "录像上传服务", value: 14, disabled: false },
    { label: "Restful服务", value: 15, disabled: false }
]; // 云录制
const xcxOptions: any[] = [
    { label: "小程序接入网关服务器", value: 19, disabled: false },
    { label: "小程序节点服务器", value: 20, disabled: false }
]; // 小程序
const pltlOptions: any[] = [
    { label: "网关服务", value: 16, disabled: false },
    { label: "跨媒体转码服务", value: 18, disabled: false },
    { label: "旁路推流服务", value: 17, disabled: false }
]; // 旁路推流
const ssxxOptions: any[] = [
    { label: "节点服务器", value: 10, disabled: false },
    { label: "管理服务器", value: 9, disabled: false },
    { label: "网关服务", value: 8, disabled: false }
]; // 实时消息

const Added: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [signature, setSignature]: [string, Function] = useState('');
    const [sigLoading, setSigLoading]: [boolean, Function] = useState(false);
    const [yspOptionsCheckedCities, setYspOptionsCheckedCities]: [number[], Function] = useState([]);
    const [ylzOptionsCheckedCities, setYlzOptionsCheckedCities]: [number[], Function] = useState([]);
    const [xcxOptionsCheckedCities, setXcxOptionsCheckedCities]: [number[], Function] = useState([]);
    const [pltlOptionsCheckedCities, setPltlOptionsCheckedCities]: [number[], Function] = useState([]);
    const [ssxxOptionsCheckedCities, setSsxxOptionsCheckedCities]: [number[], Function] = useState([]);
    const [yspOptionsIsIndeterminate, setYspOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [ylzOptionsIsIndeterminate, setYlzOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [xcxOptionsIsIndeterminate, setXcxOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [pltlOptionsIsIndeterminate, setPltlOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [ssxxOptionsIsIndeterminate, setSsxxOptionsIsIndeterminate]: [boolean, Function] = useState(false);
    const [yspOptionsCheckAll, setYspOptionsCheckAll]: [boolean, Function] = useState(false);
    const [ylzOptionsCheckAll, setYlzOptionsCheckAll]: [boolean, Function] = useState(false);
    const [xcxOptionsCheckAll, setXcxOptionsCheckAll]: [boolean, Function] = useState(false);
    const [pltlOptionsCheckAll, setPltlOptionsCheckAll]: [boolean, Function] = useState(false);
    const [ssxxOptionsCheckAll, setSsxxOptionsCheckAll]: [boolean, Function] = useState(false);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [textareaValue, setTextareaValue]: [string, Function] = useState('');
    const [options, setOptionns]: [any[], Function] = useState([]);
    const [machId, setMachId]: [string, Function] = useState('');
    const [arrSType, setArrSType]: [number[], Function] = useState([]);
    const [license, setLicense]: [string, Function] = useState('');


    const checkList: any[] = [
        {
            label: '音视频',
            indeterminate: yspOptionsIsIndeterminate,
            checked: yspOptionsCheckAll,
            options: yspOptions,
            value: yspOptionsCheckedCities,
            setCheckedCities: setYspOptionsCheckedCities,
            setIsIndeterminate: setYspOptionsIsIndeterminate, 
            setCheckAll: setYspOptionsCheckAll,
        },
        {
            label: '云录制',
            indeterminate: ylzOptionsIsIndeterminate,
            checked: ylzOptionsCheckAll,
            options: ylzOptions,
            value: ylzOptionsCheckedCities,
            setCheckedCities: setYlzOptionsCheckedCities,
            setIsIndeterminate: setYlzOptionsIsIndeterminate, 
            setCheckAll: setYlzOptionsCheckAll,
        },
        {
            label: '小程序',
            indeterminate: xcxOptionsIsIndeterminate,
            checked: xcxOptionsCheckAll,
            options: xcxOptions,
            value: xcxOptionsCheckedCities,
            setCheckedCities: setXcxOptionsCheckedCities,
            setIsIndeterminate: setXcxOptionsIsIndeterminate, 
            setCheckAll: setXcxOptionsCheckAll,
        },
        {
            label: '旁路推流',
            indeterminate: pltlOptionsIsIndeterminate,
            checked: pltlOptionsCheckAll,
            options: pltlOptions,
            value: pltlOptionsCheckedCities,
            setCheckedCities: setPltlOptionsCheckedCities,
            setIsIndeterminate: setPltlOptionsIsIndeterminate, 
            setCheckAll: setPltlOptionsCheckAll,
        },
        {
            label: '实时消息',
            indeterminate: ssxxOptionsIsIndeterminate,
            checked: ssxxOptionsCheckAll,
            options: ssxxOptions,
            value: ssxxOptionsCheckedCities,
            setCheckedCities: setSsxxOptionsCheckedCities,
            setIsIndeterminate: setSsxxOptionsIsIndeterminate, 
            setCheckAll: setSsxxOptionsCheckAll,
        },
    ];

    useEffect(() => {
        getMachIdList();
        getDevpInfoDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDevpInfoDetail: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/getDevpInfoDetail',
            data: { 
                oem: location.state.oem, 
                uid: location.state.uid 
            },
            method: 'POST',
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setLicense(result.data.data.licenseInfo.license);
        } else {
            message.error('数据获取失败');
        }
    };

    const getMachIdList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/getMachIdList',
            data: { oem: location.state.oem },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const list: any[] = [];
            result.data.data.forEach((item: any): void => {
                list.push({
                  value: item.machId,
                  label: item.machId
                });
            });
            setOptionns(list);
        }
    };

    const dealWith: Function = (option: any, checked: boolean): number[] => {
        let list: number[] = [];
        if (checked) {
            option.options.forEach((item: any): void => {
                item.disabled || list.push(item.value);
            });
            list = Array.from(new Set(list.concat(option.value)));
        } else {
            option.options.forEach((item: any): void => {
                if (item.disabled && option.value.includes(item.value)) {
                    list.push(item.value);
                }
            });
        }
        return list;
    };

    const onChange: Function = (list: any[], item: any): void => {
        item.setCheckedCities(list);
        item.setIsIndeterminate(!!list.length && list.length < item.options.length);
        item.setCheckAll(list.length === item.options.length);
    };

    const onCheckAllChange: Function = (e: any, item: any): void => {
        item.setCheckedCities(dealWith(item, e.target.checked));
        item.setIsIndeterminate(false);
        item.setCheckAll(e.target.checked);
    };

    const insertService: Function = async (): Promise<void> => {
        setLoading(true);
        const arrSTyp: number[] = [];
        const arr: number[] = [];
        checkList.forEach((item: any): void => {
            item.value.forEach((value: any): void => {
                arrSTyp.push(value);
            });
        });
        arrSTyp.forEach((item: any): void => {
            !arrSType.includes(item) && arr.push(item);
        });
        if (arr.length === 0) {
            message.error("请选择服务项目");
        } else if (signature === "") {
            message.error("请上传领导签字截图");
        } else if (machId === "") {
            message.error("请选择machId");
        } else {
            const option: Option = {
                url: '/primgr/insertServiceByMachId',
                data: {
                    remark: textareaValue,
                    machId: machId,
                    detail: signature,
                    oem: location.state.oem,
                    license, 
                    arrSType: arr
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            setLoading(false);
            if (result.data.code === 0) {
                handleTo();
                message.success("操作成功");
            } else if (result.data.code === 5014) {
                message.error("混合云不允许添加mnodem");
            } else {
                message.error("操作失败");
            }
        }
        setLoading(false);
    };

    const handleTo: Function = (): void => {
        history.push('/content/private/detail/server', location.state);
    };

    const handleChange: Function = (info: any): void => {
        if (info.file.status === 'uploading') {
            setSigLoading(true);
            return
        };
        if (info.file.status === 'done') {
            if (info.file.response.code === 0) {
                message.success('图片上传成功');
                setSignature(info.file.response.data.url);
            } else {
                message.error('图片上传失败');
            }
            setSigLoading(false);
        }
    };

    const handleSelectChange: Function = async (value: string): Promise<void> => {
        setMachId(value);
        const option: Option = {
            url: '/primgr/getServiceListByMid',
            data: { machId: value },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (Array.isArray(result.data.data) && result.data.code === 0) {
            const arr: number[] = [];
            result.data.data.forEach((item: any): void => {
                arr.push(item.sType);
            });
            setArrSType(arr);
            reset(arr);
        }
    };

    const reset: Function = (arr: number[]): void => {
        checkList.forEach((item: any): void => {
            item.setIsIndeterminate(false);
            item.setCheckAll(false);
            item.setCheckedCities([]);
            item.options.forEach((value: any): void => {
                value.disabled = false;
            });
            const list: any[] = [];
            arr.forEach((id: number): void => {
                item.options.forEach((op: any, index: number): void => {
                    if (op.value === id) {
                        list.push(id);
                        item.options[index].disabled = true;
                    }
                });
            });
            item.setCheckedCities(list);
            item.setIsIndeterminate(!!list.length && list.length < item.options.length);
            item.setCheckAll(list.length === item.options.length);
        });
    };

    return (
        <div className={ styles.added }>
            <div className={ styles.modal }>
                <p className={ styles.title }>增值服务</p>
                {
                    checkList.map((item: any, index: number): ReactElement => {
                        return (
                            <div className={ styles.fragment } key={ index }>
                                <Checkbox indeterminate={ item.indeterminate } 
                                onChange={ (e) => onCheckAllChange(e, item) } 
                                checked={ item.checked }>{ item.label }</Checkbox>
                                <div className={ styles.checkLine }></div>
                                <CheckboxGroup options={ item.options } value={ item.value } 
                                onChange={ (list: any) => onChange(list, item) } />
                            </div>
                        )
                    })
                }
                <div className={ styles.line }>
                    <span className={ styles.span }>Mach ID：</span>
                    <Select
                    style={{ width: 300 }}
                    value={ machId || undefined }
                    onChange={ (value) => handleSelectChange(value) }
                    placeholder="请选择Mach ID"
                    >
                        {
                            options.map((item: any, index: number): ReactElement => {
                                return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                            })
                        }
                    </Select>
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>备注：</span>
                    <TextArea
                    value={ textareaValue }
                    className={ styles.input }
                    maxLength={ 30 }
                    onChange={ (e) => setTextareaValue(e.target.value) }
                    placeholder="请输入备注"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                </div>
                <div className={ [styles.line, styles.baseline].join(' ') }>
                    <span className={ styles.span }>领导签字截图：</span>
                    <Upload
                        className={ [styles.upload, 'avatar-uploader'].join(' ') }
                        name="file"
                        listType="picture-card"
                        showUploadList={ false }
                        action={ uploadUrl + 'anyrtc' }
                        headers={{
                            'Artc-Token': props.token
                        }}
                        onChange={ (info) => handleChange(info) }
                    >
                        { signature ? 
                            <img className={ styles.uploadImg } src={ signature } alt="avatar" style={{ width: '100%' }} /> : 
                            <div>
                                { sigLoading ? <LoadingOutlined /> : <PlusOutlined /> }
                                <div>Upload</div>
                            </div>
                        }
                    </Upload>
                </div>
                <div className={ styles.btnBox }>
                    <Button className={ styles.btn } onClick={ () => handleTo() }>Cancel</Button>
                    <Button className={ styles.btn } type="primary" 
                    loading={ loading } disabled={ loading } 
                    onClick={ () => insertService() }>Submit</Button>
                </div>
            </div>
        </div>
    )
};

type MapStateToProps = (state: object) => object;

const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps)(Added);