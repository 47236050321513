import { FC, ReactElement } from 'react';
import { Route } from 'react-router-dom';
import AppTable from './appTable/appTable';
import ProjectEditor from './projectEditor/projectEditor';
import styles from './appInformation.less';

const AppInformation: FC = (): ReactElement => {
    return (
        <div className={ styles.appInformation }>
            <Route path='/content/user/detail/appInformation' exact component={ AppTable } />
            <Route path='/content/user/detail/appInformation/projectEditor' component={ ProjectEditor } />
        </div>
    );
};


export default AppInformation;