import { FC, ReactElement } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Login from './views/login';
import Content from './views/content';
import NotPage from './views/notPage';
import './App.css';

const App: FC = (): ReactElement => {
  return (
    <Router>
      <Provider store={ store }>
        <PersistGate 
          persistor={ persistor }>
          <div className="App">
            <Route path='/' component={ Login } exact />
            <Route path='/content' component={ Content } />
            <Route path='/notPage' component={ NotPage } />
          </div>
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default App;
