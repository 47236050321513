import { FC, ReactElement, useEffect, useState } from 'react';
import { Menu } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { scriptUrl } from '../../../axios/baseUrl';
import { findPermissions } from '../../../api/permiss';
import { Route, useHistory, useLocation } from 'react-router-dom';
import Overview from './overview/overview';
import AecList from './aecList/aecList';
import Brandwhite from './brandwhite/brandwhite';
import Brandblack from './brandblack/brandblack';

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

const Directories: FC = (): ReactElement => {
    const history: any = useHistory();
    const location: any = useLocation();
    const [selectedKeys, setSelectedKeys]: [string, Function] = useState('directories');

    useEffect((): void => {
        const path: Array<string> = location.pathname.split('/');
        setSelectedKeys(path[path.length - 1]);
    }, [location.pathname]);

    const handleClick: Function = (e: MenuInfo): void => {
        setSelectedKeys(e.key);
        if (e.key === 'directories') {
            history.push(`/content/${e.key}`);
        } else {
            history.push(`/content/directories/${e.key}`);
        }
    };

    return (
        <div>
            <Menu onClick={ (e) => handleClick(e) } selectedKeys={ [selectedKeys] } mode="horizontal">
                { findPermissions(23101) && <Menu.Item key="directories" icon={ <IconFont type="icon-shujugailan" /> }>
                概览
                </Menu.Item> }
                { findPermissions(23201) && <Menu.Item key="aecwhitelist" icon={ <IconFont type="icon-tianjiabaimingdan" /> }>
                Aec白名单
                </Menu.Item> }
                { findPermissions(23301) && <Menu.Item key="brandwhitelist" icon={ <IconFont type="icon-pinpai" /> }>
                品牌白名单
                </Menu.Item> }
                { findPermissions(23401) && <Menu.Item key="brandblacklist" icon={ <IconFont type="icon-heimingdan" /> }>
                品牌黑名单
                </Menu.Item> }
            </Menu>
            { findPermissions(23101) && <Route path='/content/directories' component={ Overview } exact /> }
            { findPermissions(23201) && <Route path='/content/directories/aecwhitelist' component={ AecList } exact /> }
            { findPermissions(23301) && <Route path='/content/directories/brandwhitelist' component={ Brandwhite } exact /> }
            { findPermissions(23401) && <Route path='/content/directories/brandblacklist' component={ Brandblack } exact /> }
        </div>
    )
};

export default Directories;