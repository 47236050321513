import { CSSProperties, FC, ReactElement, useState, useEffect } from 'react';
import { Descriptions, Switch, Button, Table, InputNumber, Modal, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { getTimestampToTime } from '../../../../../../../../../api';
import { Option } from '../../../../../../../../../axios/axios-types';
import axios from '../../../../../../../../../axios/axios';
import styles from './detail.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const labelStyle: CSSProperties = { 
    width: '70px', 
    textAlign: 'right', 
    display: 'inline-block',
    flexShrink: 0
};
const Detail: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
    const [tableData, setTableData]: [any[], Function] = useState([]);
    const [increaseLoading, setIncreaseLoading]: [boolean, Function] = useState(false);
    const [tableIncreaseData, setTableIncreaseData]: [any[], Function] = useState([]);
    const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [visible, setVisible]: [boolean, Function] = useState(false);
	const [period, setPeriod]: [number, Function] = useState(36);
	const [modalLoading, setModalLoading]: [boolean, Function] = useState(false);
	const [modalContent, setModalContent]: [string, Function] = useState('');
	const [modalTitle, setModalTitle]: [string, Function] = useState('');
	const [modalType, setModalType]: [string, Function] = useState('');
	const [checked, setChecked]: [boolean, Function] = useState(false);
	const [info, setInfo]: [any, Function] = useState({});

    
    useEffect((): void => {
        getLicenseDetailInfo();
        getLicenseRenewalList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect((): void => {
        getLicenseUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortRule]);


    const getLicenseDetailInfo: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/lic/getLicenseDetailInfo',
            data: { licDid: location.state.licDid },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            result.data.data.licActTs = getTimestampToTime(result.data.data.licActTs, 'yyyy-MM-DD');
            result.data.data.licExpirTs = getTimestampToTime(result.data.data.licExpirTs, 'yyyy-MM-DD');
            setInfo(result.data.data);
        }
    };

    const getLicenseRenewalList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/lic/getLicenseRenewalList',
            data: { licDid: location.state.licDid },
            method: 'POST'
        };
        setIncreaseLoading(true);
        const result: Result = await axios(option);
        setIncreaseLoading(false);
        if (result.data.code === 0) {
            result.data.data.forEach((item: any): void => {
                item.licPeriod = item.licPeriod + "个月";
                item.licActTs = getTimestampToTime(item.licActTs, 'yyyy-MM-DD');
            });
            setTableIncreaseData(result.data.data);
        }
    };

    const getLicenseUserList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/lic/getLicenseUserList',
            data: { 
                licUniqueId: location.state.licUniqueId,
                license: location.state.license,
                sortRule
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0 && Array.isArray(result.data.data)) {
            result.data.data.forEach((item: any): void => {
                item.licLoginTs = getTimestampToTime(item.licLoginTs, 'yyyy-MM-DD');
            });
            setTableData(result.data.data);
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setSortRule(sorter.order === 'ascend'? 1 : 0);
	};

    const handleModalCancel: Function = (): void => {
        setVisible(false);
        setPeriod(36);
    };

    const handleSwitchChange: Function = (checked: boolean): void => {
        setVisible(true);
        setChecked(checked);
        setModalType('switch');
        setModalTitle(checked? '禁用License' : '解禁License');
        if (checked) {
            setModalContent('禁用后使用该License的设备将不可用');
        } else {
            setModalContent('解禁后使用该License的设备将恢复使用');
        }
    };

    const handleRenewal: Function = (): void => {
        setVisible(true);
        setModalType('renewal');
        setModalTitle('确定续期');
        setModalContent(`为设备标识符 ${info.licUniqueId} 续期`);
    };

    const handleModalOk: Function = (): void => {
        if (modalType === 'switch') {
            updateLicenseStatus();
        } else if (modalType === 'renewal') {
            updateLicPeriod();
        }
    };

    const updateLicPeriod: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/lic/updateLicPeriod',
            data: {
                devpId: location.state.uid,
                licDid: location.state.licDid,
                period
            },
            method: 'POST'
        };
        setModalLoading(true);
        const result: Result = await axios(option);
        setModalLoading(false);
        if (result.data.code === 0) {
            handleModalCancel();
            getLicenseDetailInfo();
            getLicenseRenewalList();
        } else if (result.data.code === 4015) {
            message.error('禁用状态的license不能续期');
        };
    };

    const updateLicenseStatus: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/lic/updateLicenseStatus',
            data: {
                licDid: location.state.licDid,
                licStatus: checked? -1 : 2
            },
            method: 'POST'
        };
        setModalLoading(true);
        const result: Result = await axios(option);
        setModalLoading(false);
        if (result.data.code === 0) {
            message.success('状态修改成功');
            getLicenseDetailInfo();
            handleModalCancel();
        } else {
            message.error('状态修改失败');
        }
    };

    const handleSorter: Function = (a: any, b: any, type: string): boolean => {
        return a[type].localeCompare(b[type]);
    };
    return (<div className={ styles.detail }>
        <div className={ styles.title }>
            <span className={ styles.span }>基本信息</span>
            <Button type="primary" onClick={ () => history.goBack() }>Back</Button>
        </div>
        <Descriptions column={ 2 }>
            <Descriptions.Item labelStyle={ labelStyle } label="设备标识">{ info.licUniqueId }</Descriptions.Item>
            <Descriptions.Item labelStyle={ labelStyle } label="是否禁用">
                <Switch checkedChildren="已禁用" checked={ 1 > info.licStatus } onChange={ (checked) => handleSwitchChange(checked) } unCheckedChildren="未禁用" defaultChecked />
            </Descriptions.Item>
            <Descriptions.Item labelStyle={ labelStyle } label="续期次数">{ info.licRenewalNum }</Descriptions.Item>
            <Descriptions.Item labelStyle={ labelStyle } label="激活时间">{ info.licActTs }</Descriptions.Item>
            <Descriptions.Item labelStyle={ labelStyle } label="IMEI">{ info.licImei }</Descriptions.Item>
            <Descriptions.Item labelStyle={ labelStyle } label="过期时间">
                <span>{ info.licExpirTs }</span> 
                <span className={ styles.renewal } onClick={ () => handleRenewal() }>续期</span>
            </Descriptions.Item>
            <Descriptions.Item labelStyle={ labelStyle } label="licCert">{ info.licCert }</Descriptions.Item>
        </Descriptions>

        <div className={ styles.title }>
            <span className={ styles.span }>续期记录</span>
        </div>
        <Table
            loading={ increaseLoading } 
            className={ styles.table }
            pagination={ false }
            rowKey={() => `${Math.floor(Math.random() * 123333)}`}
            sticky={ true }
            sortDirections={['ascend', 'descend', 'ascend']} 
            dataSource={ tableIncreaseData }>
            <Column align='center' 
            showSorterTooltip={ false } 
            sorter={ (a, b) => handleSorter(a, b, 'licActTs') } 
            defaultSortOrder='ascend' 
            title="续期时间" 
            dataIndex="licActTs" />
            <Column align='center' title="有效期" dataIndex="licPeriod" />
		</Table>

        <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
            loading={ tableLoading } 
            className={ styles.table }
            pagination={ false }
            rowKey={() => `${Math.floor(Math.random() * 123333)}`}
            sticky={ true }
            sortDirections={['ascend', 'descend', 'ascend']} 
            dataSource={ tableData }>
            <Column align='center' title="用户ID" dataIndex="licUid" />
            <Column align='center' defaultSortOrder='descend' sorter={ true } showSorterTooltip={ false } title="登录日期"  dataIndex="licLoginTs" />
            <Column align='center' title="使用次数" dataIndex="licUseCount" />
		</Table>

        <Modal centered title={ modalTitle } getContainer={ false } confirmLoading={ modalLoading } visible={ visible } onOk={ () => handleModalOk() } onCancel={ () => handleModalCancel() }>
            { modalType === 'renewal' && <div className={ styles.modal }>
                <p className={ styles.modalContent }>{ modalContent }</p>
                <div className={ styles.line }>
                    <span>续期时长：</span>
                    <InputNumber className={ styles.inputNumber } min={ 1 } value={ period } onChange={ (value) => setPeriod(value) } />
                </div>
            </div> }
            { modalType === 'switch' && <p>{ modalContent }</p> }
        </Modal>
    </div>);
};

export default Detail;