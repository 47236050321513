import { FC, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table, message, Modal, Input } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { getTimestampToTime } from '../../../../api/index';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './index.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

const Index: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [awaitEmail, setAwaitEmail]: [Array<any>, Function] = useState([]);
    const [awaitEmailLoading, setAwaitEmailLoading]: [boolean, Function] = useState(false);
    const [awaitSendAccount, setAwaitSendAccount]: [string, Function] = useState('');
    const [awaitSendVisible, setAwaitSendVisible]: [boolean, Function] = useState(false);
    const [record, setRecord]: [any, Function] = useState({});
    const [sendVisible, setSendVisible]: [boolean, Function] = useState(false);
    const [deleteVisible, setDeleteVisible]: [boolean, Function] = useState(false);
    const [effect, setEffect]: [boolean, Function] = useState(false);

    
    useEffect((): void => {
        getWaitSendEmail();
    }, [effect]);

    useEffect((): void => {
		getSendEmailList();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize, current]);

    const getSendEmailList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/getSendEmailList',
            data: {
                pageNum: current,
                pageSize,
            },
            method: 'POST',
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {

                item.createAt = getTimestampToTime(item.createAt);
            });
            setTableData(result.data.data.list);
            setTotal(result.data.data.totalNum);
        } else {
            message.error('数据获取失败');
        }
    };

    const getWaitSendEmail: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/getWaitSendEmail',
            method: 'POST'
        };
        setAwaitEmailLoading(true);
        const result: Result = await axios(option);
        setAwaitEmailLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.createAt = getTimestampToTime(item.createAt);
            });
            setAwaitEmail(result.data.data.list);
        } else {
            message.error('数据获取失败');
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const testSend: Function = (record: any): void => {
        setAwaitSendVisible(true);
        setRecord(record);
    };

    const send: Function = (record: any): void => {
        setSendVisible(true);
        setRecord(record);
    };

    const deleteEmail: Function = (record: any): void => {
        setDeleteVisible(true);
        setRecord(record);
    };

    const sendAwaitEmail: Function = async (): Promise<void> => {
        if (awaitSendAccount === '') {
            message.error('请输入测试帐号');
        } else {
            const option: Option = {
                url: '/developers/testSendEmail',
                data: { email: awaitSendAccount, emailId: record.emailid },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                message.success('邮件发送成功');
                sendEmailCancel();
                setEffect(!effect);
            } else {
                message.error('邮件发送失败');
            }
        }
    };

    const sendEmailCancel: Function = (): void => {
        setAwaitSendVisible(false);
        setAwaitSendAccount('');
    };

    const sendEmail: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/sendEmail',
            data: { emailId: record.emailid },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success('邮件发送成功');
            setSendVisible(false);
            setEffect(!effect);
        } else {
            message.error('邮件发送失败');
        }
    };

    const remove: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/deleteWaitEmail',
            data: { emailId: record.emailid },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success('邮件删除成功');
            setDeleteVisible(false);
            setEffect(!effect);
        } else {
            message.error('邮件删除失败');
        }
    };

    const edit: Function = (record: any): void => {
        history.push('/content/email/dealEmail', record);
    };

    const view: Function = (record: any): void => {
        history.push('/content/email/viewEmail', record);
    };

    const addEmail: Function = (): void => {
        history.push('/content/email/dealEmail');
    };
    
    props.cacheLifecycles.didRecover(() => {
        getWaitSendEmail();
        getSendEmailList();
    });

    return (
        <div className={ styles.index }>
            <div className={ styles.addAndTable }>
                <div className={ styles.addEmail }>
                    { findPermissions(21200) && <span>待发送邮件</span> }
                    <Button type="primary" disabled={ awaitEmail.length >= 4 || !findPermissions(21101) } onClick={ () => addEmail() }>添加邮件</Button>
                </div>
                { findPermissions(21200) && <Table
                    loading={ awaitEmailLoading } 
                    className={ styles.table }
                    pagination={ false }
                    rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                    sticky={ true }
                    dataSource={ awaitEmail }>
                    <Column align='center' title="邮件主题"  dataIndex="title" />
                    <Column align='center' title="编辑时间" dataIndex="createAt" />
                    <Column align='center' title="类别" render={ (operation: any, record: any): ReactElement => (
                        <span>{ record.eType === '1'? '产品' : '活动' }</span>
                    ) } />
                    <Column align='center' width={ 300 } title="操作" render={ (operation: any, record: any): ReactElement => (
                        <div className={ styles.operationBox }>
                            { findPermissions(21201) && <p onClick={ () => testSend(record) } className={ styles.operation }>测试发送</p> }
                            { findPermissions(21301) && <p onClick={ () => send(record) } className={ styles.operation }>发送</p> }
                            { findPermissions(21401) && <p onClick={ () => edit(record) } className={ styles.operation }>编辑</p> }
                            { findPermissions(21501) && <p onClick={ () => deleteEmail(record) } className={ [styles.operation, styles.delete].join(' ') }>删除</p> }
                        </div>
                    ) } />
                </Table> }
            </div>

            { findPermissions(21600) && <div className={ styles.addAndTable }>
                <span>已发送邮件</span> 
                <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
                    loading={ tableLoading } 
                    className={ styles.table }
                    pagination={{
                        defaultCurrent: 1,
                        current,
                        showSizeChanger: true,
                        pageSize,
                        total
                    }}
                    rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                    sticky={ true }
                    dataSource={ tableData }>
                    <Column align='center' title="邮件主题"  dataIndex="title" />
                    <Column align='center' title="发送时间" dataIndex="createAt" />
                    <Column align='center' title="类别" render={ (operation: any, record: any): ReactElement => (
                        <span>{ record.eType === '1'? '产品' : '活动' }</span>
                    ) }  dataIndex="eType" />
                    <Column align='center' width={ 300 } title="操作" render={ (operation: any, record: any): ReactElement => (
                        <div className={ styles.operationBox }>
                            { findPermissions(21601) && <p className={ styles.operation } onClick={ () => view(record) }>查看</p> }
                        </div>
                    ) } />
                </Table>
            </div> }

            <Modal title='测试发送邮件' width={ 500 } getContainer={ false } centered visible={ awaitSendVisible } onOk={ () => sendAwaitEmail() } onCancel={ () => sendEmailCancel() }>
                <Input placeholder="请输入测试邮箱" onChange={ (e) => setAwaitSendAccount(e.target.value) } />
            </Modal>

            <Modal title='发送邮件' width={ 500 } getContainer={ false } centered visible={ sendVisible } onOk={ () => sendEmail() } onCancel={ () => setSendVisible(false) }>
                <p>确认群发送该邮件?</p>
            </Modal>

            <Modal title='删除邮件' width={ 500 } getContainer={ false } centered visible={ deleteVisible } onOk={ () => remove() } onCancel={ () => setDeleteVisible(false) }>
                <p>确定删除该邮件?</p>
            </Modal>
        </div>
    )
};

export default Index;