import { FC, ReactElement, useEffect, useState } from "react";
import { Table } from "antd";
import { ResultData } from "../resultTypes";
import axios from "../../../../../../axios/axios";
import { Option } from "../../../../../../axios/axios-types";
import pubsub from "pubsub-js";
import * as echarts from "echarts";
import styles from "./intercom.less";
const { Column } = Table;

interface Result {
  data: {
    code: number;
    data: any;
  };
}

const Intercom: FC = (): ReactElement => {
  const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
  const [tableData, setTableData]: [Array<ResultData>, Function] = useState([]);
  // const [rtmNums, setRtmNums]: [number, Function] = useState(0);
  const [talkAudMin, setTalkAudMin]: [number, Function] = useState(0);

  useEffect((): any => {
    window.addEventListener("resize", windowResize);
    return (): void => {
      window.removeEventListener("resize", windowResize);
    };
  });

  const windowResize = (): void => {
    echarts
      .init(document.getElementById("echartsLine") as HTMLElement)
      .resize();
  };

  useEffect((): void => {
    pubsub.unsubscribe("dosage");
    pubsub.subscribe(
      "dosage",
      async (name: string, data: any): Promise<void> => {
        getUserConsumption(data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserConsumption: Function = async (data: any): Promise<void> => {
    const option: Option = {
      url: "/v3/developers/getUserConsumption",
      data: {
        catType: 9,
        appId: data.appid,
        devpId: data.uid,
        gType: data.gType,
        sType: data.sType,
        startTs: data.startTs,
        stopTs: data.stopTs,
      },
      method: "POST",
    };
    setTableLoading(true);
    const result: Result = await axios(option);
    if (result.data.code === 0 && result.data.data.list) {
      initCharts(result.data.data.list);
      setTalkAudMin(result.data.data.list[0].talkAudMin)
    }
    setTableLoading(false);
  };

  const initCharts: Function = (data: Array<ResultData>): void => {
    setTableData(data);
    const timeList: Array<string> = [];
    const dataList: Array<number> = [];
    data.forEach((item: ResultData): void => {
      timeList.push(item.date);
      dataList.push(item.talkAudMin as number);
    });
    const element: HTMLElement | null = document.getElementById("echartsLine");
    if (!element) return;
    echarts.init(element).setOption({
      tooltip: {
        trigger: "axis",
        backgroundColor: "#FFF",
        extraCssText: "box-shadow:0 0 18px #ccc",
        textStyle: {
          fontSize: "12px",
          width: "40px",
          fontFamily:
            "Microsoft YaHei Regular, Microsoft YaHei Regular-Regular",
          fontWeight: 400,
          color: "#606060",
        },
        formatter: function (params: any): string {
          var res = `<div style='padding:2px 10px;'>${params[0].name}</div>`;
          for (var i = 0, l = params.length; i < l; i++) {
            if (params[i].seriesName === "对讲分钟数") {
              res += `
                        <div style='display: flex;align-items:center;justify-content: space-between;padding:4px 10px;width: 150px'>
                            <div style='display: flex;align-items:center;'>
                                <span style='width: 16px;height: 3px; display: inline-block; background: #2FA2FF;margin-right: 10px'></span>
                                <span>${params[i].seriesName}</span>
                            </div>
                            <span>${params[i].value}</span>
                        </div>`;
            }
            setTalkAudMin(params[i].value)
          }
          return res;
        },
        position: ["6%", "30%"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      color: ["#38A4FC"],
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: timeList,
        axisLine: {
          show: false, //不显示坐标轴线
        },
        axisLabel: {
          show: true,
          textStyle: { color: "#666" },
        },
      },
      yAxis: {
        type: "value",
        axisLine: { show: false },
        axisLabel: {
          show: true,
          textStyle: { color: "#666" },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#eee"],
            width: 1,
            type: "solid",
          },
        },
      },
      series: [
        {
          name: "对讲分钟数",
          type: "line",
          data: dataList,
        },
      ],
    });
  };

  const handleSorter: Function = (a: any, b: any): boolean => {
    return a.date > b.date;
  };

  return (
    <div className={styles.realTime}>
      <div className={styles.top}>
        <img
          src={require("../../../../../../assets/images/talkback.png").default}
          alt=""
        />
        <div className={styles.number}>
          <span>{talkAudMin}</span>
          <span>实时对讲分钟数</span>
        </div>
      </div>
      <div id="echartsLine" className={styles.echartsLine}></div>
      <Table
        loading={tableLoading}
        className={styles.table}
        rowKey="date"
        sticky={true}
        pagination={false}
        sortDirections={["ascend", "descend", "ascend"]}
        dataSource={tableData}
        summary={(pageData) => {
          let talkAudMin: number = 0;
          pageData.forEach((item: any) => {
            item.talkAudMin > talkAudMin && (talkAudMin = item.talkAudMin);
          });
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell
                align="center"
                className={styles.cell}
                index={1}
              >
                最大数
              </Table.Summary.Cell>
              <Table.Summary.Cell
                align="center"
                className={styles.cell}
                index={2}
              >
                {talkAudMin}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      >
        <Column
          align="center"
          defaultSortOrder="descend"
          showSorterTooltip={false}
          sorter={(a, b) => handleSorter(a, b)}
          title="日期"
          dataIndex="date"
        />
        <Column align="center" title="实时对讲分钟数" dataIndex="talkAudMin" />
      </Table>
      <div className={styles.instructions}>
        <ul>
          <li>
            <span>*</span> 当天数据会有大约2小时延迟。
          </li>
          <li>
            <span>*</span> 最终实际用量需要查看准确帐单列表的账单详情。
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Intercom;
