import { FC, ReactElement, useEffect, useState } from 'react';
import { Table, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { getTimestampToTime } from '../../../../../api/index';
import { Option } from '../../../../../axios/axios-types';
import axios from '../../../../../axios/axios';
import styles from './addedDetails.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const AddedDetails: FC = (): ReactElement => {
    const location: any = useLocation();

    const [current, setCurrent]: [number, Function] = useState(1);
    const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);

    
    useEffect((): void => {
        getMachList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current]);

    const getMachList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/getServiceRecordList',
            data: {
                oem: location.state.oem,
                pageNum: current,
                pageSize: pageSize
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.ts = getTimestampToTime(item.ts);
            });
            setTotal(result.data.data.totalNumber);
            setTableData(result.data.data.list);
        } else {
            message.error('数据获取失败');
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    return (
        <div className={ styles.addedDetails }>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="服务类型"  dataIndex="serviceType" />
                <Column align='center' title="一级服务" dataIndex="catName" />
                <Column align='center' title="二级服务" dataIndex="sType" />
                <Column align='center' title="申请时间"  dataIndex="ts" />
                <Column align='center' title="Mach-ID" dataIndex="machId" />
                <Column align='center' title="备注"  dataIndex="remark" />
                <Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.render }>
                        <a className={ record.detail? styles.operation : styles.disable } href={ record.detail } target="_brank">查看图片</a>
                    </div>
                ) } />
            </Table>
        </div>
    )
};

export default AddedDetails;