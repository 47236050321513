import { CSSProperties, FC, ReactElement, useEffect, useState } from 'react';
import { Descriptions, Button, Input, Checkbox, Radio, message } from 'antd';  
import { useLocation, useHistory } from 'react-router-dom';
import { getTimestampToTime } from '../../../../api/index';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './auditCustomer.less';
const CheckboxGroup: any = Checkbox.Group;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const yspOptions: any[] = [
    { label: "管理服务", value: 2,disabled: true },
    { label: "拉流服务", value: 7,disabled: true },
    { label: "推流服务", value: 5,disabled: true },
    { label: "中转服务", value: 6,disabled: true },
    { label: "网关服务", value: 3,disabled: true },
    { label: "认证服务", value: 1,disabled: true }
]; // 音视频
const ylzOptions: any[] = [
    { label: "管理服务", value: 11, disabled: true },
    { label: "录制服务", value: 12, disabled: true },
    { label: "转码服务", value: 13, disabled: true },
    { label: "录像上传服务", value: 14, disabled: true },
    { label: "Restful服务", value: 15, disabled: true }
]; // 云录制
const xcxOptions: any[] = [
    { label: "小程序接入网关服务器", value: 19, disabled: true },
    { label: "小程序节点服务器", value: 20, disabled: true }
]; // 小程序
const pltlOptions: any[] = [
    { label: "网关服务", value: 16, disabled: true },
    { label: "跨媒体转码服务", value: 17, disabled: true },
    { label: "旁路推流服务", value: 18, disabled: true }
]; // 旁路推流
const ssxxOptions: any[] = [
    { label: "节点服务器", value: 10, disabled: true },
    { label: "管理服务器", value: 9, disabled: true },
    { label: "网关服务", value: 8, disabled: true }
]; // 实时消息

const labelStyle: CSSProperties = { 
    width: '70px', 
    textAlign: 'right', 
    fontSize: '12px',
    display: 'inline-block'
};

const contentStyle: CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const AuditCustomer: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [refusedLoading, setRefusedLoading]: [boolean, Function] = useState(false);
    const [throughLoading, setThroughLoading]: [boolean, Function] = useState(false);
    const [devpInfo, setDevpInfo]: [any, Function] = useState({});
    const [licenseInfo, setLicenseInfo]: [any, Function] = useState({});
    const [devpAuthInfo, setDevpAuthInfo]: [any, Function] = useState({});
    const [yspOptionsCheckedCities, setYspOptionsCheckedCities]: [number[], Function] = useState([]);
    const [ylzOptionsCheckedCities, setYlzOptionsCheckedCities]: [number[], Function] = useState([]);
    const [xcxOptionsCheckedCities, setXcxOptionsCheckedCities]: [number[], Function] = useState([]);
    const [pltlOptionsCheckedCities, setPltlOptionsCheckedCities]: [number[], Function] = useState([]);
    const [ssxxOptionsCheckedCities, setSsxxOptionsCheckedCities]: [number[], Function] = useState([]);
    const checkList: any[] = [
        {
            label: '音视频',
            options: yspOptions,
            value: yspOptionsCheckedCities,
            setCheckedCities: setYspOptionsCheckedCities,
        },
        {
            label: '云录制',
            options: ylzOptions,
            value: ylzOptionsCheckedCities,
            setCheckedCities: setYlzOptionsCheckedCities,
        },
        {
            label: '小程序',
            options: xcxOptions,
            value: xcxOptionsCheckedCities,
            setCheckedCities: setXcxOptionsCheckedCities,
        },
        {
            label: '旁路推流',
            options: pltlOptions,
            value: pltlOptionsCheckedCities,
            setCheckedCities: setPltlOptionsCheckedCities,
        },
        {
            label: '实时消息',
            options: ssxxOptions,
            value: ssxxOptionsCheckedCities,
            setCheckedCities: setSsxxOptionsCheckedCities,
        }
    ];
    
    useEffect(() => {
        location.state.active === 1? getVerifyDevpInfo() : getVerifyRenewalDevpInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleVerifyDevp: Function = async (bool: boolean, setLoading: Function): Promise<void> => {
        const isAct: boolean = location.state.active === 1;
        const option: Option = {
            url: `/primgr/${isAct? 'verifyDevp' : 'verifyRenewal'}`,
            data: {
                license: licenseInfo.license,
                period: +licenseInfo.period,
                oem: location.state.oem,
                uid: location.state.uid,
                rid: +location.state.rid,
                vState: bool? 2 : 3,
                state: bool? 2 : 3
            },
            method: 'POST'
        };
        setLoading(true);
        const result: Result = await axios(option);
        setLoading(false);
        if (result.data.code === 0) {
            message.success("审核成功");
            history.goBack();
        } else {
            message.error("审核失败");
        }
    };

    const getVerifyDevpInfo: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/getVerifyDevpInfo',
            data: {
                oem: location.state.oem,
                uid: location.state.uid
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            dewl(result);
            computed(result.data.data.licenseInfo);
        } else {
            message.error("获取审核信息失败");
        }
    };

    const getVerifyRenewalDevpInfo: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/getVerifyRenewalDevpInfo',
            data: {
                rid: +location.state.rid,
                uid: location.state.uid
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            dewl(result);
        } else {
            message.error("审核续期信息获取失败");
        }
    };

    const computed: Function = (licenseInfo: any): void => {
        checkList.forEach((item: any): void => {
            const v: any[] = []; 
            item.options.forEach((option: any): void => {                    
                if (licenseInfo.arrSType.includes(option.value)) {
                    v.push(option.value);
                }
            });
            item.setCheckedCities(v);
        });
    };

    const dewl: Function = (result: any): void => {
        result.data.data.licenseInfo.licType = result.data.data.licenseInfo.licType === 2? "混合云" : "私有云";
        result.data.data.licenseInfo.dType = result.data.data.licenseInfo.dType === 1? "正式期" : "测试期";
        result.data.data.licenseInfo.ts = getTimestampToTime(result.data.data.licenseInfo.ts, 'yyyy-MM-DD');
        if (location.state.active === 1) {
            result.data.data.devpInfo.userCreateAt = getTimestampToTime(result.data.data.devpInfo.userCreateAt, 'yyyy-MM-DD');
        }
        setDevpInfo(result.data.data.devpInfo);
        setLicenseInfo(result.data.data.licenseInfo);
        setDevpAuthInfo(result.data.data.devpAuthInfo);
    };

    const handleChange: Function = (value: any, type: string): void => {
        const info: any = JSON.parse(JSON.stringify(licenseInfo));
        info[type] = value;
        setLicenseInfo(info);
    };

    return (
        <div className={ styles.auditCustomer }>
            <div className={ styles.modal }>
                <div className={ styles.titleBox }>
                    <p className={ styles.title }>审核详情</p>
                    <Button className={ styles.btn } onClick={ () => history.goBack() }>Back</Button>
                </div>
                <Descriptions contentStyle={ contentStyle } className={ styles.descriptions } title={ location.state.active === 1? "添加客户" : "续期客户" }>
                    <Descriptions.Item labelStyle={ labelStyle } label="客户类型">{ licenseInfo.dType }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="部署类型">{ licenseInfo.licType }</Descriptions.Item>
                </Descriptions>
                <Descriptions contentStyle={ contentStyle } className={ styles.descriptions } title="基本信息">
                    { location.state.active === 1 && <Descriptions.Item labelStyle={ labelStyle } label="注册时间">{ devpInfo.userCreateAt }</Descriptions.Item> }
                    <Descriptions.Item labelStyle={ labelStyle } label="申请时间">{ licenseInfo.ts }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="邮箱">{ devpInfo.email }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="客户名称">{ devpInfo.name }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="公司名称">{ devpAuthInfo.comName }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="厂商">{ licenseInfo.oem }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="并发数量">{ licenseInfo.concurrentNum }</Descriptions.Item>
                    { location.state.active === 1 && <Descriptions.Item labelStyle={ labelStyle } label="Appid">{ licenseInfo.appId }</Descriptions.Item> }
                    <Descriptions.Item labelStyle={ labelStyle } label="所属销售">{ devpInfo.adminName }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label={ location.state.active === 1? "使用时长" : "续期时长" }>
                        <Input className={ styles.input } addonAfter='天' placeholder="请输入时长" value={ licenseInfo.period } onChange={ (e) => handleChange(e.target.value, 'period') } />
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="手机号">{ devpInfo.cellphone }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="公司地址">{ devpAuthInfo.comAddr }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="认证信息">{ devpAuthInfo.uAuthType === 1 && <div className={ styles.hoverbox }>
                            <a href={ devpAuthInfo.idFront } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.idFront.substr(devpAuthInfo.idFront.length - 15) }</a>
                            <a href={ devpAuthInfo.idBack } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.idBack.substr(devpAuthInfo.idBack.length - 15) }</a>
                        </div> }
                        { devpAuthInfo.uAuthType === 2 && <div className={ styles.hoverbox }>
                            <a href={ devpAuthInfo.licUrl } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.licUrl.substr(devpAuthInfo.licUrl.length - 15) }</a>
                        </div> }
                        { !devpAuthInfo.uAuthType && <span>暂未认证</span> }
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="备注">{ licenseInfo.remark }</Descriptions.Item>
                </Descriptions>
                <Descriptions title="服务类型" className={ styles.descriptions } column={ 1 }>
                    { location.state.active === 1 && <>
                        {
                            checkList.map((item: any, index: number): ReactElement => {
                                return (
                                    <Descriptions.Item key={ index } labelStyle={ labelStyle } label={ item.label }>
                                        <div className={ styles.fragment } >
                                            <div className={ styles.line }></div>
                                            <CheckboxGroup options={ item.options } value={ item.value } />
                                        </div>
                                    </Descriptions.Item>
                                )
                            })
                        }
                    </> }
                    { location.state.active === 2 && <Descriptions.Item labelStyle={ labelStyle }>
                        <Radio defaultChecked={ true } disabled={ true }>音视频</Radio>
                    </Descriptions.Item> }
                </Descriptions>
                <div className={ styles.btnBox }>
                    <Button className={ styles.btn } onClick={ () => handleVerifyDevp(false, setRefusedLoading) } 
                    disabled={ refusedLoading } loading={ refusedLoading } 
                    type="primary" danger>不通过</Button>
                    <Button className={ styles.btn } onClick={ () => handleVerifyDevp(true, setThroughLoading) }
                    disabled={ throughLoading || (licenseInfo.arrSType && licenseInfo.arrSType.length <= 0) } loading={ throughLoading } 
                    type="primary">通过</Button>
                </div>
            </div>
        </div>
    )
};

export default AuditCustomer;