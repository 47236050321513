import { message } from 'antd';

//获取年月日时分秒
const getTimestampToTime: Function = (time: number, format: string, dateSplitStr: string = "-", timeSplitStr: string = ":"): string => {
    let oDatetimer = new Date(time.toString().length <= 10? time * 1000 : time);
    let oMonth: string | number = oDatetimer.getMonth() + 1;
    let oDay: string | number = oDatetimer.getDate();
    let oH: string | number = oDatetimer.getHours();
    let oM: string | number = oDatetimer.getMinutes();
    let oS: string | number = oDatetimer.getSeconds();
    oMonth = oMonth < 10 ? "0" + oMonth : oMonth;
    oDay = oDay < 10 ? "0" + oDay : oDay;
    oH = oH < 10 ? "0" + oH : oH;
    oM = oM < 10 ? "0" + oM : oM;
    oS = oS < 10 ? "0" + oS : oS;
    let currentdate: string = oDatetimer.getFullYear() + dateSplitStr + oMonth + dateSplitStr + oDay + " " + oH + timeSplitStr + oM + timeSplitStr + oS;
    if (format === 'yyyy-MM') {
        currentdate = oDatetimer.getFullYear() + dateSplitStr + oMonth;
    } else if (format === 'yyyy-MM-DD') {
        currentdate = oDatetimer.getFullYear() + dateSplitStr + oMonth + dateSplitStr + oDay;
    } else {
        currentdate = oDatetimer.getFullYear() + dateSplitStr + oMonth + dateSplitStr + oDay + " " + oH + timeSplitStr + oM + timeSplitStr + oS;
    }
    return currentdate;
};


//金额加逗号
const moneyComma: Function = (data: number, bool: boolean = false): string => {
    data = data / 1000;
    if (bool) {
        return (data.toString().indexOf('.') !== -1) ? data.toLocaleString() : data.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    } else {
        return (data.toString().indexOf('.') !== -1) ? '￥' + data.toLocaleString() : '￥' + data.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    }
};

//获取时间日期(年月日 时间戳10位) 
const getTimeFormate: Function = (timeStamp: number, format: string): string => {
    var date: Date = new Date(timeStamp * 1000); 
    var Y: number = date.getFullYear();
    var M: number | string = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D: number | string = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    if (format === 'yyyy-MM') {
        return Y + '-' + M;
    } else {
        return Y + '-' + M + '-' + D;
    };
};

//获取时间日期(年月日 时间戳13位) 
const getTimeFormateTwo: Function = (timeStamp: number): string => {
    var date: Date = new Date(timeStamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y: string = date.getFullYear() + '-';
    var M: string = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D: string = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    return Y + M + D;
};

//计算x天前的时间戳
const getDate: Function = (data: number): number => {
    const start: Date = new Date(new Date().toLocaleDateString());
    start.setTime(start.getTime() - 3600 * 1000 * 24 * (data - 1));
    return parseInt((start.getTime() / 1000) + '');
};

//计算x天前的时间戳
const getDateOne: Function = (data: number): number => {
    const start: Date = new Date(new Date().toLocaleDateString());
    data !== 0 && start.setTime(start.getTime() - 3600 * 1000 * 24 * (data - 1));
    data === 0 && start.setTime(start.getTime() - 3600 * 1000 * 24);
    const end = parseInt((start.getTime() / 1000).toString());
    return end;
};

//获取本月第一天0时0分时间戳
const getMonth: Function = (): number => {
    var data = new Date();
    data.setDate(1);
    data.setHours(0);
    data.setSeconds(0);
    data.setMinutes(0);
    return parseInt((data.getTime() / 1000).toString());
};

//按小时查询 计算选择当天的结束时间
const serachTime: Function = (data: string): number => {
    let d = new Date(data);
    if (d.toDateString() === new Date().toDateString()) {
        return parseInt((new Date().getTime() / 1000).toString());
    } else {
        const nowTimeDate = new Date(data)
        return parseInt((nowTimeDate.setHours(23, 59, 59, 999) / 1000).toString());
    }
};

// 分钟数加逗号
const minutes: Function = (data: number): string => {
    return (data.toString().indexOf('.') !== -1) ? data.toLocaleString() : 
    data.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
};

//计算6个月前的日期
const getHalfyear: Function = (): number => {
    let curDate = new Date().getTime();
    let halfYear = (365 / 2) * 24 * 3600 * 1000;
    let pastResult = parseInt((curDate - halfYear).toString());
    pastResult = parseInt((pastResult / 1000).toString());
    return pastResult
};

//时间戳转化为yy/mm/dd hh:mm:ss
const transformDataDataFn: Function = (data: number, dateSplitStr = "-", timeSplitStr = ":"): string => {
    let oDatetimer: Date = new Date(data);
    let oMonth: number | string = oDatetimer.getMonth() + 1;
    let oDay: number | string = oDatetimer.getDate();
    let oH: number | string = oDatetimer.getHours();
    let oM: number | string = oDatetimer.getMinutes();
    let oS: number | string = oDatetimer.getSeconds();
    oMonth = oMonth < 10? "0" + oMonth : oMonth;
    oDay = oDay < 10 ? "0" + oDay : oDay;
    oH = oH < 10 ? "0" + oH : oH;
    oM = oM < 10 ? "0" + oM : oM;
    oS = oS < 10 ? "0" + oS : oS;
    return oDatetimer.getFullYear() + dateSplitStr + oMonth + dateSplitStr + oDay + " " + oH + timeSplitStr + oM + timeSplitStr + oS;
};

// 数字转字符串  10000 => 一万
const numberToString: Function = (number: number): string => {
    const num2char: Array<string> = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const key2char: Array<string> = ['', '十', '百', '千', '万', '十万'];
    const numArr: Array<any> = [];
    while (number) {
        numArr.push(number % 10);
        number = Math.floor(number / 10);
    }
    let resStr: string = '';
    for (let key in numArr) {
        const val: number = numArr[key];
        if (key2char[key] !== undefined) {
            let curr: string = '';
            if (val === 0) {
                resStr[0] !== '零' && (curr = num2char[val]);
            } else {
                curr = num2char[val] + key2char[key];
            }
            resStr = curr + resStr;
        }
    }
    if (resStr.indexOf('零') === resStr.length - 1) {
        resStr = resStr.slice(0, -1);
    }
    return resStr;
};

// 获取本月第一天的日期
const getCurrentMonthOneDay: Function = (): string => {
    var date: Date = new Date();
    date.setDate(1);
    var months: any = parseInt((date.getMonth() + 1).toString());
    var days: any = date.getDate();
    months < 10 && (months = '0' + months);
    days < 10 && (days = '0' + days);
    return date.getFullYear() + '-' + months + '-' + days;
};

//获取今天是几号,并算出前 x 天的日期  (这里要比实际少写1天) 假设要获取7天前  data写6就可以
const getToday: Function = (data: any): any[] => { 
    var myDate: Date = new Date();
    myDate.setDate(myDate.getDate() - data);
    var dateArray: any[] = [];
    var dateTemp: any;
    var flag: number = 1;
    for (var i = 0; i < data + 1; i++) {
        dateTemp = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
    }
    return dateArray;
};

// 获取当天结束时间戳
const todayS: Function = (): number => {
    var eleven: Date = new Date();
    eleven.setHours(23);
    eleven.setMinutes(59);
    eleven.setSeconds(59);
    return parseInt((eleven.getTime() / 1000).toString());
};

// 获取今天日期 （年-月-日）
const getNowFormatDate: Function = (): string => {
    const date: Date = new Date();
    const seperator: string = "-";
    const year: number = date.getFullYear();
    var month: number | string = date.getMonth() + 1;
    var strDate: number | string = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    return year + seperator + month + seperator + strDate;
};

// 复制
const handleCopy: Function = async (msg: string): Promise<void> => {
    if ('https:' === document.location.protocol) {
        message.success('复制成功');
        return navigator.clipboard.writeText(msg);
    } else {
        let textArea: any = document.createElement("textarea");
        textArea.value = msg;
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // 执行复制命令并移除文本框
            document.execCommand('copy') ? res() : rej();
            message.success('复制成功');
            textArea.remove();
        });
    };
};

export {
    getTimestampToTime,
    moneyComma,
    getTimeFormate,
    getDate,
    getMonth,
    serachTime,
    minutes,
    getHalfyear,
    transformDataDataFn,
    numberToString,
    getCurrentMonthOneDay,
    getToday,
    getTimeFormateTwo,
    todayS,
    getDateOne,
    getNowFormatDate,
    handleCopy
};