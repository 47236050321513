import { FC, ReactElement, useState, useEffect } from 'react';
import { Select, Input, Table, Button, Modal, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { findPermissions } from '../../../../api/permiss';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './brandblack.less';
const { Column } = Table;

namespace Antd {
    export const { Option } = Select;
};

interface Result {
	data:  {
        code: number;
        data: any;
    };
};


const Brandblack: FC = (): ReactElement => {
    const [brandList, setBrandList]: [any, Function] = useState([{
        value: "品牌",
        label: "品牌"
    }]);
    const [modelList, setModelList]: [any, Function] = useState([{
        value: "model",
        label: "model"
    }]);
    const [brand, setBrand]: [any, Function] = useState('品牌');
    const [model, setModel]: [any, Function] = useState('model');
    const [value, setValue]: [string, Function] = useState('');
    const [brandValue, setBrandValue]: [string, Function] = useState('');
    const [modelValue, setModelValue]: [string, Function] = useState('');
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [visibleLoading, setVisibleLoading]: [boolean, Function] = useState(false);
    const [addVisibleLoading, setAddVisibleLoading]: [boolean, Function] = useState(false);
    const [visible, setVisible]: [boolean, Function] = useState(false);
    const [addVisible, setAddVisible]: [boolean, Function] = useState(false);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [any[], Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [record, setRecord]: [any, Function] = useState({});
	const [effect, setEffect]: [boolean, Function] = useState(false);
	const [result, setResult]: [any, Function] = useState({});


    useEffect((): void => {
		const getAecList: Function = async (): Promise<void> => {
			const option: Option = {
				url: '/device/getBlkBrandList',
				data: {
					pageNum: current,
					pageSize,
                    brand: brand === '品牌'? 'all' : brand,
                    model: model === 'model'? 'all' : model
				},
				method: 'POST'
			};
			setTableLoading(true);
			const result: Result = await axios(option);
            if (result.data.code === 0) {
                setTableData(result.data.data.list);
                setTotal(result.data.data.totalNumber);
            } else {
                message.error('数据获取失败');
            }
			setTableLoading(false);
		};
		getAecList();
	}, [pageSize, current, brand, model, effect]);


    useEffect((): void => {
        const getBrandModelList: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/device/getBrandModelList',
                method: 'GET'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                if (Array.isArray(result.data.data)) {
                    const brandLis: any[] = [{
                        value: "品牌",
                        label: "品牌"
                    }];
                    const list: any = {};
                    result.data.data.forEach((item: any): void => {
                        brandLis.push({
                            value: item.brand,
                            label: item.brand
                        });
                        list[item.brand] = item.modelList;
                    });
                    setBrandList(brandLis);
                    setBrand('品牌');
                    setModel('model');
                    setResult(list);
                    assembly(list);
                }
            }
        };
        getBrandModelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect]);

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const assembly: Function = (data: any): void => {
        const modelLis: any[] = JSON.parse(JSON.stringify(modelList));
        setModelList(forKeys(data, modelLis));
    };

    const forKeys: Function = (data: any, modelLis: any[]): any[] => {
        Object.keys(data).forEach((key: string): void => {
            data[key].forEach((item: any): void => {
                const target: any = modelLis.find((li: any): boolean => li.label === item);                
                if (target === undefined) {
                    modelLis.push({
                        value: item,
                        label: item
                    });
                }
            });
        });
        return modelLis;
    };

    const handleSearch: Function = async (): Promise<void> => {
        if (value === '') {
            message.error("请输入关键字");
        } else {
            const option: Option = {
                url: '/device/searchBlkBrandList',
                data: {
                    pageNum: current,
					pageSize,
                    searchParam: value
                },
                method: 'POST'
            };
            setLoading(true);
            const result: Result = await axios(option);
            setLoading(false);
            if (result.data.code === 0) {
                setTableData(result.data.data.list);
                setTotal(result.data.data.totalNumber);
            } else {
                message.error('数据获取失败');
            }
        }
    };

    const handleSelectChange: Function = (value: any, type: string, callBack: Function): void => {
        setValue('');
        callBack(value === 'all'? type : value);
        if (type === '品牌') {
            let modelLis: any[] = [{
                value: "model",
                label: "model"
            }];
            if (value === 'all') {
                modelLis = forKeys(result, modelLis);
            } else {
                result[value].forEach((item: any): void => {
                    const target: any = modelLis.find((li: any): boolean => li.label === item);                
                    if (target === undefined) {
                        modelLis.push({
                            value: item,
                            label: item
                        });
                    }
                });
            }
            setModelList(modelLis);
            setModel('model');
        }
    };

    const handleDelete: Function = (record: any): void => {
        setRecord(record);
        setVisible(true);
    };

    const handleOnOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/device/deleteBlkBrand',
            data: { id: record.id },
            method: 'POST'
        };
        setVisibleLoading(true);
        const result = await axios(option);
        setVisibleLoading(false);
        message[result.data.code === 0? "success" : "error"](
            result.data.code === 0? "删除成功" : "删除失败"
        );
        if (result.data.code === 0) {
            setVisible(false);
            setEffect(!effect);
        }
    };

    const handleAddOnOk: Function = async (): Promise<void> => {
        const reg: RegExp = /(^\s)|(\s$)/;
        if (brandValue === "") {
            message.error("请输入品牌");
        } else if (modelValue === "") {
            message.error("请输入型号");
        } else if (brandValue.match(/^[ ]*$/)) {
            message.error("品牌不能包含空格");
        } else if (modelValue.match(/^[ ]*$/)) {
            message.error("型号不能包含空格");
        } else if (reg.test(brandValue)) {
            message.error("品牌两边不能有空格");
        } else if (reg.test(modelValue)) {
            message.error("型号两边不能有空格");
        } else {
            const option: Option = {
                url: '/device/insertBlkBrand',
                data: { 
                    brand: brandValue, 
                    model: modelValue 
                },
                method: 'POST'
            };
            setAddVisibleLoading(true);
            const result = await axios(option);
            setAddVisibleLoading(false);
            message[result.data.code === 0? "success" : "error"](
                result.data.code === 0? "添加成功" : "添加失败"
            );
            if (result.data.code === 0) {
                setBrandValue('');
                setModelValue('');
                setAddVisible(false);
                setEffect(!effect);
            }
        }
    };

    const handleOnCancel: Function = (): void => {
        setAddVisible(false);
        setModelValue('');
        setBrandValue('');
    };

    const handleAdd: Function = (): void => {
        setAddVisible(true);
    };
    return (
        <div className={ styles.brandblack }>
            <div className={ styles.search }>
                <div className={ styles.brandAndModel }>
                    <div className={ styles.select }>
                        <span className={ styles.span }>品牌名称</span>
                        <Select
                        style={{ width: 200 }}
                        value={ brand }
                        onChange={ (value) => handleSelectChange(value, '品牌', setBrand) }
                        >
                            {brandList.map((item: any, index: number): ReactElement => {
                                return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                            })}
                        </Select>
                    </div>
                    <div className={ styles.select }>
                        <span className={ styles.span }>model</span>
                        <Select
                        style={{ width: 200 }}
                        value={ model }
                        onChange={ (value) => handleSelectChange(value, 'model', setModel) }
                        >
                            {modelList.map((item: any, index: number): ReactElement => {
                                return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                            })}
                        </Select>
                    </div>
                </div>
                <div className={ styles.addAndSerach }>
                    <div className={ styles.select }>
                        <Input className={ styles.input } value={ value } onChange={ (e) => setValue(e.target.value) } placeholder="请输入关键词" />
                        <Button type="primary" disabled={ !findPermissions(23431) } onClick={ () => handleSearch() } icon={<SearchOutlined />} loading={ loading }>Search</Button>
                    </div>
                    <div className={ styles.select }>
                        <Button type="primary" className={ styles.btn } onClick={ () => handleAdd() }>Add</Button>
                    </div>
                </div>
            </div>

            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey={() => `${Math.floor(Math.random() * 123333)}`}
				sticky={ true }
				dataSource={ tableData }>
				<Column align='center' title="品牌" dataIndex="brand" />
				<Column align='center' title="型号 / Model" dataIndex="model" />
				<Column align='center' width={ 200 } title="操作" render={ (operation: any, record: any): ReactElement => (
					<p className={ styles.operation } onClick={ () => handleDelete(record) }>delete</p>
				) } />
			</Table>

            <Modal title='删除品牌黑名单' width={ 550 }
            confirmLoading={ visibleLoading }
            getContainer={ false } 
            centered visible={ visible } 
            onCancel={ () => setVisible(false)}
            onOk={ () => handleOnOk() }>
                <p>确认删除?</p>
            </Modal>

            <Modal title='添加品牌黑名单' width={ 550 }
            confirmLoading={ addVisibleLoading }
            getContainer={ false } 
            centered visible={ addVisible } 
            onCancel={ () => handleOnCancel() }
            onOk={ () => handleAddOnOk() }>
                <div className={ styles.div }>
                    <span>品牌</span>
                    <Input value={ brandValue } onChange={ (e) => setBrandValue(e.target.value) } placeholder="请输入品牌" />
                </div>
                <div className={ styles.div }>
                    <span>model</span>
                    <Input value={ modelValue } onChange={ (e) => setModelValue(e.target.value) } placeholder="请输入型号" />
                </div>
            </Modal>
        </div>
    )
};

export default Brandblack;