import { FC, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Index from './index/index';
import Monitoring from './monitoring/monitoring';
import MonitoringDetails from './monitoringDetails/monitoringDetails';
import ProblemSee from './problemSee/problemSee';
import ProblemSeeDetails from './problemSeeDetails/problemSeeDetails';

const Problem: FC = (): ReactElement => {
    return (
        <Switch>
            <Route path='/content/problem/monitoring' component={ Monitoring } exact />
            <Route path='/content/problem/monitoringDetails' component={ MonitoringDetails } exact />
            <Route path='/content/problem/problemSee' component={ ProblemSee } exact />
            <Route path='/content/problem/problemSeeDetails' component={ ProblemSeeDetails } exact />
            <Route path='/content/problem' component={ Index } />
        </Switch>
    )
};

export default Problem;