import { FC, ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../api/permiss';
import { Button, Table, Select, DatePicker, message, Modal } from 'antd';
import { getHalfyear, getTimestampToTime, moneyComma } from '../../../../../../api/index';
import { Option } from '../../../../../../axios/axios-types';
import exportSCV from '../../../../../../api/excal';
import axios from '../../../../../../axios/axios';
import moment from 'moment';
import styles from './record.less';
const { Column } = Table;
const { RangePicker } = DatePicker;

namespace Antd {
    export const { Option } = Select;
}

interface List {
    aName: string;
    dAlipayNo: string;
    dAmount: string;
    dDetail: string;
    dLeftCash: number;
    dPayTs:	number;
    dState: number;
    dSubject: string;
    dType: string;
    dUId: string;
    dealId:	string;
    dTs: string | number;
};

interface Data {
	code: number;
	data: { 
        list: Array<List>;
        totalNumber: number;
    };
};

interface Result {
	data: Data;
};

enum Type {'支付宝充值', '银行转账', '月账单消费', '余额初始化', '一次性账单消费', '套餐包消费'};

const tHeader: Array<string> = ["日期", "交易号", "交易类型", "金额", "余额"];
const filterVal: Array<string> = ["dTs", "dealId", "dType", "dAmount", "dLeftCash"];
const fileName = "交易记录";

const selectOption = [
    {
        value: 99,
        label: "所有类型"
    },
    {
        value: 0,
        label: "支付宝充值"
    },
    {
        value: 1,
        label: "银行转账"
    },
    {
        value: 2,
        label: "月账单消费"
    },
    {
        value: 3,
        label: "余额初始化"
    },
    {
        value: 4,
        label: "一次性账单消费"
    },
    {
        value: 5,
        label: "套餐包消费"
    }
];

const Record: FC = (): ReactElement => {
    const location: any = useLocation();
	const [tableData, setTableData]: [Array<List>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [billVisible, setBillVisible]: [boolean, Function] = useState(false);
	const [record, setRecord]: [any, Function] = useState({});
	const [selectValue, setSelectValue]: [number, Function] = useState(99);
    const [rangeValue, setRangeValue]: [any, Function] = useState([]);
	const [dates, setDates]: [Array<any>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [startTime, setStartTime]: [number, Function] = useState(getHalfyear());
	const [stopTime, setStopTime]: [number, Function] = useState(parseInt((new Date().getTime() / 1000).toString()));

    useEffect((): void => {
		const getDevpDealList: Function = async (): Promise<void> => {
			setTableLoading(true);
			const option: Option = {
				url: '/developers/getDevpDealList',
				method: 'POST',
				data: {
                    dType: selectValue,
                    startTime,
                    stopTime,
					pageNum: current,
					pageSize,
                    uid: location.state.uid
				}
			};
			const result: Result = await axios(option);
			if (result.data.code === 0) {
                result.data.data.list.forEach((item: List): void => {
                    item.dType = Type[+item.dType];
                    item.dTs = getTimestampToTime(item.dTs, 'yyyy-MM-DD');
                    item.dAmount = moneyComma(item.dAmount);
                    item.dLeftCash = moneyComma(item.dLeftCash);
                });
                setTotal(result.data.data.totalNumber);
                setTableData(result.data.data.list);
            } else {
                message.error('数据获取失败');
            }
			setTableLoading(false);
		};
		getDevpDealList();
	}, [pageSize, current, selectValue, startTime, stopTime, location.state.uid]);

    const disabledDate: Function = (current: any): boolean => {
		if (!dates || dates.length === 0) {
			return current > moment().endOf('day');
		}
		// 只能选择当前日期之前, 并且日期范围不超过90天
		const tooLate = dates[0] && current.diff(dates[0], 'days') > 90;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') > 90;
		return tooEarly || tooLate || current > moment().endOf('day');
	};

    const handleRangeChange: Function = (dates: Array<any>, dateStrings: Array<string>): void => {
		setRangeValue(dates);
		if (dateStrings[0] && dateStrings[1]) {
			setStartTime(parseInt((new Date(new Date(dateStrings[0]).toLocaleDateString()).getTime() / 1000).toString()));
			setStopTime(parseInt(((new Date(new Date(dateStrings[1]).toLocaleDateString()).getTime() / 1000)  + (60 * 60 * 24 - 1)).toString()));
		} else {
            setStartTime(getHalfyear());
			setStopTime(parseInt((new Date().getTime() / 1000).toString()));
        }
	};

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};
    
    const handleExportSCV: Function = (): void => {
        exportSCV(tHeader, filterVal, tableData, fileName);
    };

    const judge: Function = (record: List): boolean => {
        return  record.dType === '银行转账' ||
                record.dType === '一次性账单消费' ||
                record.dType === '套餐包消费' ||
                record.dType === '余额初始化'
    };
    
    const handleClickView: Function = (record: List): void => {
        setBillVisible(true);
        setRecord(record);
    };

    return (
        <div className={ styles.record }>
            <p className={ styles.title }>交易记录</p>
            <div className={ styles.search }>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    value={ selectValue }
                    placeholder="Select a the"
                    onChange={ (value) => setSelectValue(value) }
                >   
                    {
                        selectOption.map((item): ReactElement => {
                            return <Antd.Option key={ item.value } value={ item.value }>{ item.label }</Antd.Option>
                        })
                    }
                </Select>
                <div>
                    <RangePicker
                    value={ rangeValue }
                    className={ styles.item }
                    disabledDate={ (current) => disabledDate(current) }
                    onChange={ (dates, dateStrings) => handleRangeChange(dates, dateStrings) }
                    onCalendarChange={ val => setDates(val) }
                    />
                    { findPermissions(11442) && <Button className={ styles.scv } type="primary" onClick={ () => handleExportSCV() }>Export SCV</Button> }
                </div>
            </div>
            <Table
                onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
                    defaultCurrent: 1,
                    current,
                    showSizeChanger: true,
                    pageSize,
                    total
                }}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="日期" dataIndex="dTs" />
                <Column align='center' title="交易号" dataIndex="dealId" />
                <Column align='center' title="交易类型" dataIndex="dType" />
                <Column align='center' title="金额" dataIndex="dAmount" />
                <Column align='center' title="余额" dataIndex="dLeftCash" />
                <Column align='center' title="操作" render={ (operation: any, record: List): ReactElement => (
                    <div>
                        { (judge(record) && findPermissions(11443)) && <p onClick={ () => handleClickView(record) } className={ styles.operation }>查看</p> }
                        { judge(record) || <p>--</p> }
                    </div>
                ) } />
            </Table>

            <Modal title='帐单详细信息' width={ 600 } getContainer={ false } centered visible={ billVisible } onOk={ () => setBillVisible(false) } onCancel={ () => setBillVisible(false) }>
                <div className={ styles.line }>
                    <span className={ styles.span }>交易类型：</span>
                    <span>{ record.dType }</span>
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>交易号：</span>
                    <span>{ record.dealId }</span>
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>交易日期：</span>
                    <span>{ record.dTs }</span>
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>处理人：</span>
                    <span>{ record.aName }</span>
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>截图：</span>
                    <a href={ record.dDetail } target="_blank" rel="noopener noreferrer">查看图片</a>
                </div>
            </Modal>
        </div>
    );
};

export default Record;