import { FC, ReactElement } from 'react';
import { CacheRoute, CacheSwitch } from 'react-router-cache-route';
import Index from './index/index';
import Detail from './detail/detail';

const Server: FC = (): ReactElement => {
    return (
        <div>
            <CacheSwitch>
                <CacheRoute path='/content/private/detail/server/detail' component={ Detail } />
                <CacheRoute path='/content/private/detail/server' component={ Index } />
            </CacheSwitch>
        </div>
    )
};

export default Server;