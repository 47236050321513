import { FC, ReactElement, useState } from 'react';
import { Table, Modal, message } from 'antd';
import { numberToString, getTimestampToTime } from '../../../../../api/index';
import { Option } from '../../../../../axios/axios-types';
import axios from '../../../../../axios/axios';
import * as echarts from 'echarts';
import styles from './examine.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    dialogExamine: Array<any>;
    publish: any;
    subscribe: any;
    subscribeEventData: Array<any>;
    publishEventData: Array<any>;
    lianluData: any;
    appId: string;
    chanSId: string;
    setDialog: Function;
};

enum Os {'其他', 'Android', 'iOS', 'Web', 'WeChat', 'Windows', 'Linux', 'macOS'};
const Examine: FC<IProps> = (props): ReactElement => {
    const [seeDialog, setSeeDialog]: [boolean, Function] = useState(false);
    const [seeTitle, setSeeTitle]: [string, Function] = useState('');


    const computedUInfo: Function = (): Array<any> => {
        const targetArr = JSON.parse(JSON.stringify(props.lianluData));
        if (targetArr.uInfo[0].uId === props.publish.uId) {
            return targetArr.uInfo;
        } else {
            return targetArr.uInfo.reverse();
        }
    };
    const computedOs: Function = (os: number): string => {
        return Os[os];
    };
    const dialogShow: Function = (item: any, infor: any): void => {
        setSeeTitle(`发布者 (${item.fromWanIp ||
        item.fromNodeId}) 到 ${item.toWanIp || item.toNodeId}(${item.toCity ||
        item.toRegion ||
        item.toArea}) 链路详情`);
        initChart(item, infor);
        setSeeDialog(true);
    };
    const initChart: Function = async (item: any, infor: any): Promise<void> => {
        const option: Option = {
            url: '/v2/project/getStreamStatusData',
            data: {
                appId: props.appId,
                chanSId: props.chanSId,
                routerCTs: infor.routerCTs,
                routerSTs: infor.routerSTs,
                streamId: props.publish.uId,
                toNodeId: item.toNodeId,
                fromNodeId: item.fromNodeId
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code !== 0) {
            message.error('未知错误');
            return
        }
        const time: Array<any> = [];
        result.data.data.ts.forEach((item: any): void => {
            item = getTimestampToTime(item);
            time.push(item.split(" ")[1]);
        });
        const myEcharts: any = echarts.init(document.getElementById("echarts") as HTMLElement);
        myEcharts.setOption({
            tooltip: {
                trigger: "axis",
                backgroundColor: "#FFF",
                extraCssText: "box-shadow:0 0 18px #ccc",
                textStyle: {
                    fontSize: "12px",
                    width: "40px",
                    fontWeight: 400,
                    color: "#606060"
                },
                formatter: (params: any): string => {
                    var res = `<div style='padding:2px 10px;'>${params[0].name}</div>`;
                    for (var i = 0, l = params.length; i < l; i++) {
                        var color: string = '';
                        if (params[i].seriesName === 'rtt') {
                            color = '#ea818e';
                        } else if (params[i].seriesName === "丢包率") {
                            color = '#5e6ce2';
                        } else if (params[i].seriesName === "码率") {
                            color = '#47bbf4';
                        }
                        res += `<div style='display: flex;align-items:center;justify-content: space-between;padding:4px 10px;width: 150px'>
                            <div style='display: flex;align-items:center;'>
                                <span style='width: 16px;height: 3px; display: inline-block; background: ${color};margin-right: 10px'></span>
                                <span>${params[i].seriesName}</span>
                            </div>
                            <span>${params[i].value}</span>
                        </div>`;
                    }
                    return res;
                },
                position: ["9%", "18%"]
            },
            grid: {
                left: 100,
                right: 100,
                bottom: 50,
                top: 50
            },
            color: ["#ea818e", "#5e6ce2", "#47bbf4"],
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: time,
                axisLine: { show: false },
                splitLine: { show: false }
            },
            yAxis: {
                type: "value",
                axisLine: { show: false },
                splitLine: { show: false }
            },
            series: [
                {
                    name: "rtt",
                    type: "line",
                    data: result.data.data.rtt
                },
                {
                    name: "丢包率",
                    type: "line",
                    data: result.data.data.pLost
                },
                {
                    name: "码率",
                    type: "line",
                    data: result.data.data.bytes
                }
            ]
        });
        window.onresize = (): void => {
            myEcharts.resize();
        };
    };
    return (
        <div className={ styles.examine }>
            <Modal width='70%' 
            className={ styles.modal } 
            centered footer={ null } 
            getContainer={ false } 
            title="Api上报事件" 
            visible={ props.dialogExamine[0].dialogExamineInfo }
            onCancel={ () => props.setDialog(0) }>
                <div className={ styles.modalItem }>
                    <div className={ styles.echartsItem }>
                        <div className={ styles.echartsTitle }>
                            <img src={ require('../../../../../assets/images/publish.svg').default } alt='' />
                            <span className={ [styles.fontBlod, styles.send].join(' ') }>{ props.publish.uId }</span>
                        </div>
                        <div className={ styles.echartsTitleTwo }>
                            {
                                props.publish.data.map((sendinfo: any, index: number): ReactElement => {
                                    return (<div key={ index } className={ styles.echartsTitleTwoMain }>
                                        <p>{ sendinfo.name }：</p>
                                        <span className={ styles.fontBlod }>{ sendinfo.type }</span>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                    <Table
                    className={ styles.table }
                    pagination={ false }
                    rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                    sticky={ true }
                    dataSource={ props.publishEventData }>
                        <Column align='center' title="时间"  dataIndex="ts" />
                        <Column align='center' title="Api"  dataIndex="eventType" />
                    </Table>
                </div>
                <div className={ styles.modalItem }>
                    <div className={ styles.echartsItem }>
                        <div className={ styles.echartsTitle }>
                            <img src={ require('../../../../../assets/images/subscribe.svg').default } alt='' />
                            <span className={ [styles.fontBlod, styles.ordinary].join(' ') }>{ props.subscribe.uId }</span>
                        </div>
                        <div className={ styles.echartsTitleTwo }>
                            {
                                props.subscribe.data.map((receivinfo: any, index: number): ReactElement => {
                                    return (<div key={ index } className={ styles.echartsTitleTwoMain }>
                                        <p>{ receivinfo.name }：</p>
                                        <span className={ styles.fontBlod }>{ receivinfo.type }</span>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                    <Table
                    pagination={ false }
                    className={ styles.table }
                    rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                    sticky={ true }
                    dataSource={ props.subscribeEventData }>
                        <Column align='center' title="时间"  dataIndex="ts" />
                        <Column align='center' title="Api"  dataIndex="eventType" />
                    </Table>
                </div>
            </Modal>

            { props.dialogExamine[1].dialogExamineInfo && <Modal title="查看链路" 
            width='70%' 
            className={ styles.endModal } 
            centered footer={ null } 
            getContainer={ false } 
            visible={ props.dialogExamine[1].dialogExamineInfo }
            onCancel={ () => props.setDialog(1) }>
                <div className={ styles.modalHead }>  
                    {
                        computedUInfo().map((item: any, index: number): ReactElement => {
                            return (<div key={ index } className={ styles.modalHeadItem }>
                                <div className={ styles.user }>
                                    { index === 0 && <img src={ require('../../../../../assets/images/publish.svg').default } alt='' /> }
                                    { index === 1 && <img src={ require('../../../../../assets/images/subscribe.svg').default } alt='' /> }
                                    <span className={ [styles.fontBlod, index === 0? styles.send : styles.ordinary].join(' ') }>{ item.uId } ({ item.area })</span>
                                </div>
                                <div className={ styles.modalHeadItemMain }>
                                    <div>
                                        <p>用户ID：</p>
                                        <span className={ styles.fontBlod }>{ item.uId }</span>
                                    </div>
                                    <div>
                                        <p>平台：</p>
                                        <span className={ styles.fontBlod }>{ computedOs(item.os) }</span>
                                    </div>
                                    <div>
                                        <p>SDK版本：</p>
                                        <span className={ styles.fontBlod }>{ item.sdkVersion }</span>
                                    </div>
                                </div>
                            </div>)
                        })
                    }
                </div>
                {
                    props.lianluData.routerInfo.map((item: any, index: number): ReactElement => {
                        return (<div key={ index } className={ styles.periodic }>
                            <div className={ styles.lianluTime }>
                                <p>链路数据{ numberToString(index + 1) }：</p>
                                <p>{ getTimestampToTime(item.routerSTs) }</p>
                                <span>-</span>
                                <p className={ [item.routerCTs === 0? styles.ongoing: ''].join(' ') }>{ item.routerCTs === 0? '进行中' : getTimestampToTime(item.routerCTs) }</p>
                            </div>
                            <div className={ styles.linkBox }>
                            {
                                item.subRouter.map((li: any, site: number): ReactElement => {
                                    return (<div className={ styles.link }>
                                        <div className={ styles.formUser }>
                                            { site === 0 && <img src={ require('../../../../../assets/images/userPublish.png').default } alt='' /> }
                                            <p>{ li.fromWanIp || li.fromNodeId }({ li.fromCity || li.fromRegion || li.fromArea })</p>
                                        </div>
                                        <div className={ styles.details }>
                                            <p onClick={ () => dialogShow(li, item) }>check the details</p>
                                        </div>
                                        <div className={ styles.toUser }>
                                            { site === item.subRouter.length - 1 && <img src={ require('../../../../../assets/images/userPublish.png').default } alt='' /> }
                                            <p>{ li.toWanIp || li.toNodeId }({ li.toCity || li.toRegion || li.toArea })</p>
                                        </div>
                                    </div>)
                                })
                            }
                            </div>
                        </div>)
                    })
                }
            </Modal> }

            <Modal title={ seeTitle } 
            visible={ seeDialog }
            width='70%' 
            className={ styles.echartsModal }
            centered footer={ null } 
            onCancel={ () => setSeeDialog(false) }
            getContainer={ false } >
                <div id="echarts" className={ styles.echarts }></div>
            </Modal>
        </div>
    )
};

export default Examine;