import { FC, ReactElement, useState, useEffect } from 'react';
import { Menu } from 'antd';
import { findPermissions, userFinancialPath } from '../../../../api/permiss';
import { UserOutlined, MoneyCollectOutlined, LineChartOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { MenuInfo } from 'rc-menu/lib/interface';
import BasicInformation from './basicInformation/basicInformation';
import AppInformation from './appInformation/appInformation';
import Dosage from './dosage/dosage';
import Financial from './financial/financial';
import styles from './detail.less';

const list: string[] = ['basicInformation', 'appInformation', 'dosage', 'financial'];

const Detail: FC = (): ReactElement => {
    const history: any = useHistory();
    const location: any = useLocation();
    const [selectedKeys, setSelectedKeys]: [string, Function] = useState('basicInformation');

    const handleClick: Function = (e: MenuInfo): void => {
        setSelectedKeys(e.key);
        if (e.key === 'financial') {
            history.push(userFinancialPath(), Object.assign(location.state, {appid: ''}));
        } else {
            history.push(`/content/user/detail/${e.key}`, Object.assign(location.state, {appid: ''}));
        }
    };

    useEffect((): void => {
        const path: Array<string> = location.pathname.split('/');
        list.forEach((item: string): void => {
            path.includes(item) && setSelectedKeys(item);
        });
    }, [location.pathname]);

    return (
        <div className={ styles.userDetail }>
            <Menu className={ styles.menu } onClick={ (e) => handleClick(e) } selectedKeys={ [selectedKeys] } mode="horizontal">
                { findPermissions(11101) && <Menu.Item key="basicInformation" icon={ <UserOutlined /> }>
                基本信息
                </Menu.Item> }
                { findPermissions(11201) && <Menu.Item key="appInformation" icon={ <AppstoreOutlined /> }>
                应用
                </Menu.Item> }
                { findPermissions(11301) && <Menu.Item key="dosage" icon={ <LineChartOutlined /> }>
                用量
                </Menu.Item> }
                { findPermissions(11401) && <Menu.Item key="financial" icon={ <MoneyCollectOutlined /> }>
                财务
                </Menu.Item> }
            </Menu>
            <div className={ styles.routeContent }>
                { findPermissions(11101) && <Route path='/content/user/detail/basicInformation' component={ BasicInformation } /> }
                { findPermissions(11201) && <Route path='/content/user/detail/appInformation' component={ AppInformation } /> }
                { findPermissions(11301) && <Route path='/content/user/detail/dosage' component={ Dosage } /> }
                { findPermissions(11401) && <Route path='/content/user/detail/financial' component={ Financial } /> }
            </div>
        </div>
    );
};


export default Detail;