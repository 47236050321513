import { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation, useHistory, Switch as SW, Route } from 'react-router-dom';
import { findPermissions } from '../../../../../../api/permiss';
import { createFromIconfontCN } from '@ant-design/icons';
import { Breadcrumb, Modal, Switch, Input, message, Button } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { Option } from '../../../../../../axios/axios-types';
import { scriptUrl } from '../../../../../../axios/baseUrl';
import { getTimestampToTime, handleCopy } from '../../../../../../api';
import { State, Stage } from '../../../../../../types/enum';
import TableDataAttr from '../tableDataAttr';
import axios from '../../../../../../axios/axios';
import License from './license/license';
import Single from './license/single/single';
import Mony from './license/mony/mony';
import styles from './projectEditor.less';

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

enum SwitchChangeType {
    tokenOn, 
    wechat_on, 
    mutiChanOn, 
    push_on, 
    vod_on, 
    rtc_notify_on,  
    push_notify_on, 
    vod_notify_on,
    wbOn,
    streamOn,
    streamNotifyOn
};

interface Result {
	data: {
        code: number
    };
};

interface GetAppInfoResult {
	data: {
        data: TableDataAttr;
        code: number;
    }
};

const ProjectEditor: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();

    const [isModalVisible, setIsModalVisible]: [boolean, Function] = useState(false);
    const [isModalModify, setIsModalModify]: [boolean, Function] = useState(false);
    const [modalTitle, setModalTitle]: [string, Function] = useState('');
    const [modalContent, setModalContent]: [string, Function] = useState('');
    const [cb, setCb]: [string, Function] = useState('');
    const [operationTp, setOperationTp]: [keyof SwitchChangeType | string, Function] = useState('');
    const [operationNum, setOperationNum]: [number, Function] = useState(0);
    const [callbackUrl, setCallbackUrl]: [string, Function] = useState('');
    const [secret, setSecret]: [string, Function] = useState('');
    const [effect, setEffect]: [boolean, Function] = useState(false);
    const [appInfo, setAppInfo]: [any, Function] = useState({});

    

    useEffect((): void => {
        const getAppInfo: Function = async (): Promise<void> => {
            const option: Option = {
				url: '/app/getAppInfo',
				method: 'POST',
				data: { aid: location.state.appid }
			};
            const result: GetAppInfoResult = await axios(option);
            result.data.data.stage = Stage[result.data.data.stage as number];
            result.data.data.state = State[result.data.data.state as number];
            result.data.data.create_at = getTimestampToTime(result.data.data.create_at);
            setAppInfo(result.data.data);
            queryNotifySecret();
        };
        getAppInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state.appid, effect]);

    const goAppInformation: Function = (): void => {
        history.push('/content/user/detail/appInformation', location.state);
    };

    const handleAddClick: Function = async (type: string): Promise<void> => {
        var vodQps = appInfo.vodQps;
        if (type === 'add') {
            vodQps += 1;
        } else if (type === 'reduction') {
            vodQps -= 1;
        }
        const option: Option = {
            url: '/developers/updateVodQps',
            data: { aid: location.state.appid, vodQps },
            method: 'POST',
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setEffect(!effect);
            message.success('录像接口调用频率更改成功');
        } else {
            message.error('录像接口调用频率更改失败');
        }
    };

    const handleClickSwitchChange: Function = (checked: boolean, type: keyof SwitchChangeType): void => {
        setOperationTp(type);
        setOperationNum(checked? 1 : 0);
        switch (type) {
            case SwitchChangeType[0]:
                setModalTitle(`${checked? '开启' : '关闭'}Token验证`);
                setModalContent(checked? '是否为用户打开Token验证?' : 
                '是否为用户关闭Token验证?');
                break;
            case SwitchChangeType[1]:
                setModalTitle(`${checked? '开启' : '关闭'}小程序`);
                setModalContent(checked? '确定开启小程序功能?' : '确定关闭小程序功能?');
                break;
            case SwitchChangeType[2]:
                setModalTitle(`${checked? '开启' : '关闭'}跨房间连麦`);
                setModalContent(checked? '确定开启跨房间连麦功能?' : '确定关闭跨房间连麦功能?');
                break;
            case SwitchChangeType[3]:
                setModalTitle(`${checked? '开启' : '关闭'}旁路推流`);
                setModalContent(checked? '确定开启旁路推流功能?' : '确定关闭旁路推流功能? 如果开启了CDN推流事件回调，该事件回调功能也将关闭!');
                break;
            case SwitchChangeType[4]:
                setModalTitle(`${checked? '开启' : '关闭'}云端录制`);
                setModalContent(checked? '确定开启云端录制功能?' : '确定关闭云端录制功能? 如果开启了云端录制事件回调，该事件回调功能也将关闭!');
                break;
            case SwitchChangeType[5]:
                setModalTitle(`${checked? '开启' : '关闭'}实时通信事件回调`);
                if (checked) {
                    setCb(true);
                } else {
                    setModalContent('是否为用户关闭实时通信事件回调功能?');
                }
                break;
            case SwitchChangeType[6]:
                setModalTitle(`${checked? '开启' : '关闭'}CDN事件回调`);
                if (checked) {
                    if (appInfo.push_on === 1) {
                        setCb(true);
                        setIsModalVisible(true);
                    } else {
                        message.error('开启CDN事件回调必须先打开旁路推流功能');
                    }
                } else {
                    setModalContent('是否为用户关闭CDN事件回调功能?');
                    setIsModalVisible(true);
                }
                break;
            case SwitchChangeType[7]:
                setModalTitle(`${checked? '开启' : '关闭'}云端录制事件回调`);
                if (checked) {
                    if (appInfo.vod_on === 1) {
                        setCb(true);
                        setIsModalVisible(true);
                    } else {
                        message.error('开启云端录制事件回调必须先打开云端录制功能');
                    }
                } else {
                    setModalContent('是否为用户关闭云端录制事件回调功能?');
                    setIsModalVisible(true);
                }
                break;
            case SwitchChangeType[8]:
                setModalTitle(`${checked? '开启' : '关闭'}互动白板`);
                setModalContent(checked? '确定开启互动白板功能?' : '确定关闭互动白板功能?');
                break;
            case SwitchChangeType[9]:
                setModalTitle(`${checked? '开启' : '关闭'}输入在线媒体流`);
                setModalContent(checked? '确定开启输入在线媒体流功能?' : '确定关闭输入在线媒体流功能? 如果开启了输入在线媒体流事件回调，该事件回调功能也将关闭!');
                break;
            case SwitchChangeType[10]:
                setModalTitle(`${checked? '开启' : '关闭'}输入在线媒体流事件回调`);
                if (checked) {
                    if (appInfo.streamOn === 1) {
                        setCb(true);
                        setIsModalVisible(true);
                    } else {
                        message.error('开启输入在线媒体流事件回调必须先打开输入在线媒体流功能');
                    }
                } else {
                    setModalContent('是否为用户关闭输入在线媒体流事件回调功能?');
                    setIsModalVisible(true);
                }
                break;
        };
        (type !== SwitchChangeType[7] && type !== SwitchChangeType[6] && type !== SwitchChangeType[10]) && setIsModalVisible(true);
    };

    const handleOk: Function = async (): Promise<void>  => {
        var option: Option;
        var result: Result;
        switch (operationTp) {
            case SwitchChangeType[0]:
                option = {
                    url: '/developers/updateAppTokenOn',
                    data: { aid: location.state.appid, wechat_on: operationNum },
                    method: 'POST',
                };
                result = await axios(option);
                if (result.data.code === 0) {
                    if (operationNum) {
                        const option: Option = {
                            url: '/developers/getAppToken',
                            data: { appId: location.state.appid },
                            method: 'POST',
                        };
                        const result = await axios(option);
                        if (result.data.code === 0) {
                            appInfo.token = result.data.data.token;
                        }
                    }
                    if (operationNum) {
                        message.success('证书启用成功');
                    } else {
                        message.success('证书禁用成功');
                    }
                } else {
                    if (operationNum) {
                        message.error('启用APP证书失败');
                    } else {
                        message.error('禁用APP证书失败');
                    }
                }
                break;
            case SwitchChangeType[1]:
                option = {
                    url: '/developers/updateWechat',
                    data: { aid: location.state.appid, wechat_on: operationNum },
                    method: 'POST',
                };
                result = await axios(option);
                if (result.data.code === 0) {
                    if (operationNum) {
                        message.success('小程序开启成功');
                    } else {
                        message.success('小程序关闭成功');
                    }
                } else {
                    if (operationNum) {
                        message.error('小程序开启失败');
                    } else {
                        message.error('小程序关闭失败');
                    }
                }
                break;
            case SwitchChangeType[2]:
                option = {
                    url: '/developers/updateMutiChanOn',
                    data: { aid: location.state.appid, vod_on: operationNum },
                    method: 'POST',
                };
                result = await axios(option);
                if (result.data.code === 0) {
                    if (operationNum) {
                        message.success('跨房间连麦开启成功');
                    } else {
                        message.success('跨房间连麦关闭成功');
                    }
                } else {
                    if (operationNum) {
                        message.error('跨房间连麦开启失败');
                    } else {
                        message.error('跨房间连麦关闭失败');
                    }
                }
                break;
            case SwitchChangeType[3]:
                option = {
                    url: '/developers/updatePushOn',
                    data: { 
                        aid: location.state.appid, 
                        pushOn: operationNum,
                    },
                    method: 'POST',
                };
                result = await axios(option);
                if (result.data.code === 0) {
                    if (operationNum) {
                        message.success('旁路推流开启成功');
                    } else {
                        message.success('旁路推流关闭成功');
                    }
                } else {
                    if (operationNum) {
                        message.error('旁路推流开启失败');
                    } else {
                        message.error('旁路推流关闭失败');
                    }
                }
                break;
            case SwitchChangeType[4]:
                option = {
                    url: '/developers/updateVodOn',
                    data: { 
                        aid: location.state.appid, 
                        vod_on: operationNum,
                    },
                    method: 'POST',
                };
                result = await axios(option);
                if (result.data.code === 0) {
                    if (operationNum) {
                        message.success('云端录制开启成功');
                    } else {
                        message.success('云端录制关闭成功');
                    }
                } else {
                    if (operationNum) {
                        message.error('云端录制开启失败');
                    } else {
                        message.error('云端录制关闭失败');
                    }
                }
                break;
            case SwitchChangeType[5]:
                if (operationNum && callbackUrl.trim() === '') {
                    message.error('回调地址不能为空');
                    return;
                } else {
                    option = {
                        url: '/app/updateRtcNotifyOn',
                        data: { 
                            aid: location.state.appid, 
                            vod_notify_on: operationNum,
                            vod_notify_url: callbackUrl,
                        },
                        method: 'POST',
                    };
                    result = await axios(option);
                    if (result.data.code === 1043) {
                        message.error("回调地址无效。请重新输入");
                    } else if (result.data.code === 0) {
                        if (operationNum) {
                            message.success('实时通信事件回调开启成功');
                        } else {
                            message.success('实时通信事件回调关闭成功');
                        }
                    } else {
                        if (operationNum) {
                            message.error('实时通信事件回调开启失败');
                        } else {
                            message.error('实时通信事件回调关闭失败');
                        }
                    }
                }
                break;
            case SwitchChangeType[6]:
                if (operationNum && callbackUrl.trim() === '') {
                    message.error('回调地址不能为空');
                    return;
                } else {
                    option = {
                        url: '/app/updatePushNotifyOn',
                        data: { 
                            aid: location.state.appid, 
                            vod_notify_on: operationNum,
                            vod_notify_url: callbackUrl,
                        },
                        method: 'POST',
                    };
                    result = await axios(option);
                    if (result.data.code === 1043) {
                        message.error("回调地址无效。请重新输入");
                    } else if (result.data.code === 0) {
                        if (operationNum) {
                            message.success('CDN事件回调开启成功');
                        } else {
                            message.success('CDN事件回调关闭成功');
                        }
                    } else {
                        if (operationNum) {
                            message.error('CDN事件回调开启失败');
                        } else {
                            message.error('CDN事件回调关闭失败');
                        }
                    }
                }
                break;
            case SwitchChangeType[7]:
                if (operationNum && callbackUrl.trim() === '') {
                    message.error('回调地址不能为空');
                    return;
                } else {
                    option = {
                        url: '/developers/updateVodNotifyOn',
                        data: { 
                            aid: location.state.appid, 
                            vod_notify_on: operationNum,
                            vod_notify_url: callbackUrl,
                        },
                        method: 'POST',
                    };
                    result = await axios(option);
                    if (result.data.code === 1043) {
                        message.error("回调地址无效。请重新输入");
                    } else if (result.data.code === 0) {
                        if (operationNum) {
                            queryNotifySecret();
                            message.success('云端录制事件回调开启成功');
                        } else {
                            message.success('云端录制事件回调关闭成功');
                        }
                    } else {
                        if (operationNum) {
                            message.error('云端录制事件回调开启失败');
                        } else {
                            message.error('云端录制事件回调关闭失败');
                        }
                    }
                }
                break;
            case SwitchChangeType[8]:
                option = {
                    url: '/app/updateWbOn',
                    data: { 
                        aid: location.state.appid, 
                        wbOn: operationNum,
                    },
                    method: 'POST',
                };
                result = await axios(option);
                if (result.data.code === 0) {
                    if (operationNum) {
                        message.success('白板功能开启成功');
                    } else {
                        message.success('白板功能关闭成功');
                    }
                } else {
                    if (operationNum) {
                        message.error('白板功能开启失败');
                    } else {
                        message.error('白板功能关闭失败');
                    }
                }
                break;
            case SwitchChangeType[9]:
                option = {
                    url: '/v2/app/updateAppStreamOn',
                    data: { 
                        appId: location.state.appid, 
                        isOn: operationNum,
                    },
                    method: 'POST',
                };
                result = await axios(option);
                if (result.data.code === 0) {
                    if (operationNum) {
                        message.success('输入在线媒体流开启成功');
                    } else {
                        message.success('输入在线媒体流关闭成功');
                    }
                } else {
                    if (operationNum) {
                        message.error('输入在线媒体流开启失败');
                    } else {
                        message.error('输入在线媒体流关闭失败');
                    }
                }
                break;
            case SwitchChangeType[10]:
                if (operationNum && callbackUrl.trim() === '') {
                    message.error('回调地址不能为空');
                    return;
                } else {
                    option = {
                        url: '/v2/app/updateAppStreamNotifyOn',
                        data: { 
                            appId: location.state.appid, 
                            isOn: operationNum,
                            notifyURL: callbackUrl,
                        },
                        method: 'POST',
                    };
                    result = await axios(option);
                    if (result.data.code === 1043) {
                        message.error("回调地址无效。请重新输入");
                    } else if (result.data.code === 0) {
                        if (operationNum) {
                            queryNotifySecret();
                            message.success('输入在线媒体流事件回调开启成功');
                        } else {
                            message.success('输入在线媒体流事件回调关闭成功');
                        }
                    } else {
                        if (operationNum) {
                            message.error('输入在线媒体流事件回调开启失败');
                        } else {
                            message.error('输入在线媒体流事件回调关闭失败');
                        }
                    }
                }
                break;
        }
        setEffect(!effect);
        handleCancel();
    };

    const queryNotifySecret: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/queryNotifySecret',
            data: { uid: location.state.uid },
            method: 'POST',
        };
        interface Result {
            data: {
                code: number;
                data: Array<any>
            }
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            if (result.data.data[0]) {
                setSecret(result.data.data[0].notify_secret);
            }
        } else {
            message.error('Secret获取失败');
        }
    };

    const handleCancel: Function = (): void => {
        setIsModalVisible(false);
        setCallbackUrl('');
        setCb(false);
    };

    const handleCancelModify: Function = (): void => {
        setIsModalModify(false);
        setCallbackUrl('');
    };

    const handleClickModify: Function = (type: keyof SwitchChangeType): void => {
        setOperationTp(type);
        switch (type) {
            case SwitchChangeType[5]: 
                setCallbackUrl(appInfo.rtcNotify);
                setModalTitle('修改实时通信事件回调');
                break;
            case SwitchChangeType[6]: 
                setCallbackUrl(appInfo.pushNotify);
                setModalTitle('修改CDN事件回调');
                break;
            case SwitchChangeType[7]: 
                setCallbackUrl(appInfo.vodNotify);
                setModalTitle('修改云端录制事件回调');
                break;
            case SwitchChangeType[10]: 
                setCallbackUrl(appInfo.vodNotify);
                setModalTitle('修改输入在线媒体流事件回调');
                break;
        }
        setIsModalModify(true);
    };

    const handleOkModify: Function = async (): Promise<void> => {
        var url: string = '';
        switch (operationTp) {
            case SwitchChangeType[5]: 
                url = '/app/updateRtcNotifyUrl';
                break;
            case SwitchChangeType[6]: 
                url = '/app/updatePushNotifyUrl';
                break;
            case SwitchChangeType[7]: 
                url = '/developers/updateNotifyURL';
                break;
            case SwitchChangeType[10]: 
                url = '/v2/app/updateAppStreamNotifyUrl';
                break;
        }
        const option: Option = {
            url,
            data: {
                aid: location.state.appid,
                appId: location.state.appid,
                vod_notify_url: callbackUrl,
                notifyUrl: callbackUrl,
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 1043) {
            message.error("回调地址无效。请重新输入");
        } else if (result.data.code === 0) {
            setIsModalModify(false);
            setCallbackUrl('');
            setEffect(!effect);
            message.success('回调地址修改成功');
        } else {
            message.error('回调地址修改失败');
        }
    };

    return (
        <div className={ styles.projectEditor }>
            <Breadcrumb className={ styles.breadcrumb }>
                <Breadcrumb.Item className={ styles.breadcrumbItem } onClick={ () => goAppInformation() }>
                    <AppstoreOutlined /> <span>应用信息</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{ appInfo.name }</Breadcrumb.Item>
            </Breadcrumb>
            <div className={ styles.basicInformation }>
                <p className={ styles.title }>基本信息</p>
                <div className={ styles.informationContent }>
                    <div className={ styles.line }>
                        <span className={ styles.span }>阶段：</span>
                        <p className={ appInfo.stage === '已上线'? styles.stageEnable : styles.stageNoEnable }>{ appInfo.stage }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>项目名称：</span>
                        <p>{ appInfo.name }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>AppId：</span>
                        <p>{ appInfo.appid }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>创建时间：</span>
                        <p>{ appInfo.create_at }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>项目状态：</span>
                        <p className={ appInfo.state === '启用'? styles.stateEnable : styles.stateNoEnable }>{ appInfo.state }</p>
                    </div>
                </div>
            </div>
            <div className={ styles.security }>
                <p className={ styles.title }>安全</p>
                <div className={ styles.informationContent }>
                    <div className={ styles.line }>
                        <span className={ styles.span }>App 证书：</span>
                        <Switch checkedChildren="open" disabled={ !findPermissions(11233) } unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'tokenOn') } checked={ appInfo.tokenOn === 1 } />
                        { appInfo.tokenOn === 1 && <p className={ styles.token }>{ appInfo.token }</p> }
                    </div>
                </div>
            </div>
            <div className={ styles.ability }>
                <p className={ styles.title }>功能</p>
                <div className={ styles.informationContent }>
                    <div className={ styles.line }>
                        <span className={ styles.span }>小程序：</span>
                        <Switch checkedChildren="open" disabled={ !findPermissions(11233) } unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'wechat_on') } checked={ appInfo.wechat_on === 1 } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>跨房间连麦功能：</span>
                        <Switch checkedChildren="open" disabled={ !findPermissions(11233) } unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'mutiChanOn') } checked={ appInfo.mutiChanOn === 1 } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>旁路推流：</span>
                        <Switch checkedChildren="open" disabled={ !findPermissions(11233) } unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'push_on') } checked={ appInfo.push_on === 1 } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>互动白板：</span>
                        <Switch checkedChildren="open" unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'wbOn') } checked={ appInfo.wbOn === 1 } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>云端录制：</span>
                        <Switch checkedChildren="open" disabled={ !findPermissions(11233) } unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'vod_on') } checked={ appInfo.vod_on === 1 } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>输入在线媒体流：</span>
                        <Switch checkedChildren="open" unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'streamOn') } checked={ appInfo.streamOn === 1 } />
                    </div>
                    { appInfo.vod_on === 1 && <div className={ styles.line }>
                        <span className={ styles.span }>录像接口调用频率：</span>
                        <Button disabled={ !findPermissions(11233) } onClick={ () => handleAddClick('reduction') } type="primary">-</Button>
                        <p className={ styles.vodQps }>{ appInfo.vodQps }</p>
                        <Button disabled={ !findPermissions(11233) } onClick={ () => handleAddClick('add') } type="primary">+</Button>
                        <p className={ styles.frequency }>T / S</p>
                    </div> }
                </div>
            </div>
            <div className={ styles.callback }>
                <p className={ styles.title }>回调</p>
                <div className={ styles.informationContent }>
                    <div className={ styles.line }>
                        <span className={ styles.span }>实时通信事件回调：</span>
                        <Switch checkedChildren="open" disabled={ !findPermissions(11233) } unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'rtc_notify_on') } checked={ appInfo.rtc_notify_on === 1 } />
                        { (appInfo.rtc_notify_on === 1 && findPermissions(11233)) && <p className={ styles.token }>{ appInfo.rtcNotify }</p> }
                        { (appInfo.rtc_notify_on === 1 && findPermissions(11233)) && <span onClick={ () => handleClickModify('rtc_notify_on') } className={ styles.modify }>Modify</span> }
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>CDN事件回调：</span>
                        <Switch checkedChildren="open" disabled={ !findPermissions(11233) } unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'push_notify_on') } checked={ appInfo.push_notify_on === 1 } />
                        { (appInfo.push_notify_on === 1 && findPermissions(11233)) && <p className={ styles.token }>{ appInfo.pushNotify }</p> }
                        { (appInfo.push_notify_on === 1 && findPermissions(11233)) && <span onClick={ () => handleClickModify('push_notify_on') } className={ styles.modify }>Modify</span> }
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>云端录制事件回调：</span>
                        <Switch checkedChildren="open" disabled={ !findPermissions(11233) } unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'vod_notify_on') } checked={ appInfo.vod_notify_on === 1 } />
                        { (appInfo.vod_notify_on === 1 && findPermissions(11233)) && <p className={ styles.token }>{ appInfo.vodNotify }</p> }
                        { (appInfo.vod_notify_on === 1 && findPermissions(11233)) && <span onClick={ () => handleClickModify('vod_notify_on') } className={ styles.modify }>Modify</span> }
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>插入媒体流回调：</span>
                        <Switch checkedChildren="open" unCheckedChildren="close" onChange={ (checked) => handleClickSwitchChange(checked, 'streamNotifyOn') } checked={ appInfo.streamNotifyOn === 1 } />
                        { appInfo.streamNotifyOn === 1 && <p className={ styles.token }>{ appInfo.streamNotify }</p> }
                        { appInfo.streamNotifyOn === 1 && <span onClick={ () => handleClickModify('streamNotifyOn') } className={ styles.modify }>Modify</span> }
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>secret：</span>
                        { appInfo.vod_notify_on === 1 && <p>{ secret }</p> }
                        { appInfo.vod_notify_on === 1 && <IconFont onClick={ () => handleCopy(secret) } className={ styles.copy } type="icon-fuzhi" /> }
                        { appInfo.vod_notify_on === 1 || <p>none</p> }
                    </div>
                </div>
            </div>
            <SW>
                <Route path='/content/user/detail/appInformation/projectEditor/single' component={ Single } />
                <Route path='/content/user/detail/appInformation/projectEditor/mony' component={ Mony } />
                <Route path='/content/user/detail/appInformation/projectEditor' component={ License } />
            </SW>
            <Modal centered title={ modalTitle } visible={ isModalVisible } onOk={ () => handleOk() } onCancel={ () => handleCancel() }>
                { cb || <p>{ modalContent }</p> }
                { cb && <Input placeholder='请输入回调地址' value={ callbackUrl } onChange={ (e) => setCallbackUrl(e.target.value) } /> }
            </Modal>
            <Modal centered title={ modalTitle } visible={ isModalModify } onOk={ () => handleOkModify() } onCancel={ () => handleCancelModify() }>
                <Input placeholder='请输入回调地址' value={ callbackUrl } onChange={ (e) => setCallbackUrl(e.target.value) } />
            </Modal>
        </div>
    );
};


export default ProjectEditor;