import { FC, ReactElement, useState, useEffect } from "react";
import { Select, Button, DatePicker } from "antd";
import { useLocation, useHistory, Route } from "react-router-dom";
import { Option } from "../../../../../axios/axios-types";
import TableDataAttr from "../../detail/appInformation/tableDataAttr";
import axios from "../../../../../axios/axios";
import { getDate, getMonth, serachTime } from "../../../../../api";
import moment from "moment";
import Vdad from "./vdad/vdad";
import pubsub from "pubsub-js";
import PushFlow from "./pushFlow/pushFlow";
import Program from "./program/program";
import Cloud from "./cloud/cloud";
import RealTime from "./realTime/realTime";
import Board from "./board/board";
import Stream from "./stream/stream";
import Intercom from "./intercom/intercom";

import styles from "./dosage.less";

const { RangePicker } = DatePicker;

namespace Antd {
  export const { Option } = Select;
}

interface Result {
  data: {
    code: number;
    data: {
      respUserAppS: Array<TableDataAttr>;
    };
  };
}

interface SelectOptions {
  value: string;
  label: string;
}

interface CatTypeList {
  label: string;
  path: string;
}

const Dosage: FC = (): ReactElement => {
  const location: any = useLocation();
  const history: any = useHistory();
  const [selectOptions, setSelectOptions]: [Array<SelectOptions>, Function] =
    useState([{ value: "", label: "全部项目" }]);
  const [selectValue, setSelectValue]: [string, Function] = useState("");
  const [rangeValue, setRangeValue]: [any, Function] = useState([]);
  const [activeTime, setActiveTime]: [number, Function] = useState(7);
  const [dates, setDates]: [Array<any>, Function] = useState([]);
  const [gType, setGType]: [number, Function] = useState(0);
  const [sType, setSType]: [number, Function] = useState(0);
  const [catType, setCatType]: [number, Function] = useState(0);
  const [startTs, setStartTs]: [number, Function] = useState(getDate(7));
  const [dateValue, setDateValue]: [any, Function] = useState();
  const [stopTs, setStopTs]: [number, Function] = useState(
    parseInt(
      (
        new Date(new Date().toLocaleDateString()).getTime() / 1000 +
        (60 * 60 * 24 - 1)
      ).toString()
    )
  );
  const [catTypeList]: [Array<CatTypeList>, Function] = useState([
    {
      label: "音视频",
      path: "/content/user/detail/dosage",
    },
    {
      label: "旁路推流",
      path: "/content/user/detail/dosage/pushFlow",
    },
    {
      label: "小程序",
      path: "/content/user/detail/dosage/program",
    },
    {
      label: "云端录制",
      path: "/content/user/detail/dosage/cloud",
    },
    {
      label: "实时消息",
      path: "/content/user/detail/dosage/realTime",
    },
    {
      label: "互动白板",
      path: "/content/user/detail/dosage/board",
    },
    {
      label: "输入在线媒体流",
      path: "/content/user/detail/dosage/stream",
    },
    {
      label: "实时对讲",
      path: "/content/user/detail/dosage/intercom",
    },
  ]);

  useEffect((): void => {
    const path = location.pathname.split("/");
    switch (path[path.length - 1]) {
      case "dosage":
        setCatType(0);
        break;
      case "pushFlow":
        setCatType(1);
        break;
      case "program":
        setCatType(2);
        break;
      case "cloud":
        setCatType(3);
        break;
      case "realTime":
        setCatType(4);
        break;
      case "board":
        setCatType(5);
        break;
    }
  }, [location.pathname]);

  useEffect((): void => {
    pubsub.publish("dosage", {
      ...location.state,
      appid: selectValue || location.state.appid,
      gType,
      sType,
      startTs,
      stopTs,
    });
  }, [location.state, selectValue, gType, sType, startTs, stopTs]);

  useEffect((): void => {
    const getUserAppList: Function = async (): Promise<void> => {
      const option: Option = {
        url: "/developers/getUserAppList",
        data: {
          pageNum: 1,
          pageSize: 30,
          uid: location.state.uid,
        },
        method: "POST",
      };
      const result: Result = await axios(option);
      if (result.data.code === 0) {
        setSelectOptions([{ value: "", label: "全部项目" }]);
        result.data.data.respUserAppS.forEach((item) => {
          selectOptions.push({
            value: item.appid,
            label: item.name,
          });
        });
        setSelectOptions(selectOptions);
      }
    };
    getUserAppList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.uid]);

  useEffect((): void => {
    setSelectValue(location.state.appid || "");
    setSType(location.state.appid ? 1 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledDate: Function = (current: any): boolean => {
    if (!dates || dates.length === 0) {
      return current > moment().endOf("day");
    }
    // 只能选择当前日期之前, 并且日期范围不超过90天
    const tooLate = dates[0] && current.diff(dates[0], "days") > 90;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 90;
    return tooEarly || tooLate || current > moment().endOf("day");
  };

  const handleRangeChange: Function = (
    dates: Array<any>,
    dateStrings: Array<string>
  ): void => {
    setRangeValue(dates);
    if (dateStrings[0] && dateStrings[1]) {
      setActiveTime(100);
      setStartTs(
        parseInt(
          (
            new Date(new Date(dateStrings[0]).toLocaleDateString()).getTime() /
            1000
          ).toString()
        )
      );
      setStopTs(
        parseInt(
          (
            new Date(new Date(dateStrings[1]).toLocaleDateString()).getTime() /
              1000 +
            (60 * 60 * 24 - 1)
          ).toString()
        )
      );
    } else {
      handleBtnClickTimeChange(7);
    }
  };

  const handleBtnClickTimeChange: Function = (type: number): void => {
    setStartTs(type === 0 ? getMonth() : getDate(type));
    setActiveTime(type);
    setGType(0);
    setRangeValue([]);
  };

  const handleCatTypeClick: Function = (
    item: CatTypeList,
    index: number
  ): void => {
    setCatType(index);
    handleBtnClickTimeChange(7);
    history.push(item.path, {
      ...location.state,
      appid: selectValue,
      gType,
      sType,
      startTs,
      stopTs,
    });
  };

  const handleChangeApp: Function = (value: string): void => {
    setSType(value ? 1 : 0);
    setSelectValue(value);
  };

  const handleDateChange: Function = (date: any, dateString: string): void => {
    setDateValue(date);
    if (dateString) {
      setActiveTime(100);
      setStartTs(
        parseInt(
          (
            new Date(new Date(dateString).toLocaleDateString()).getTime() / 1000
          ).toString()
        )
      );
      setStopTs(
        parseInt(
          (
            new Date(new Date(dateString).toLocaleDateString()).getTime() /
              1000 +
            (60 * 60 * 24 - 1)
          ).toString()
        )
      );
    } else {
      handleBtnClickTimeChange(7);
    }
  };

  const handleClickSelect: Function = (value: number): void => {
    setGType(value);
    if (value) {
      setActiveTime(100);
      setDateValue(moment());
      setStartTs(
        parseInt(
          (
            new Date(new Date().toLocaleDateString()).getTime() / 1000
          ).toString()
        )
      );
      setStopTs(
        serachTime(new Date(new Date().toLocaleDateString()).getTime())
      );
    } else {
      handleBtnClickTimeChange(7);
      setRangeValue([]);
    }
  };

  return (
    <div className={styles.dosage}>
      <p className={styles.title}>用量</p>
      <div className={styles.search}>
        <div className={styles.select}>
          <p>选择项目</p>
          <Select
            className={styles.aSelect}
            style={{ width: 200 }}
            value={selectValue}
            placeholder="Select a app"
            onChange={(value) => handleChangeApp(value)}
          >
            {selectOptions.map(
              (item: SelectOptions, index: number): ReactElement => {
                return (
                  <Antd.Option key={index} value={item.value}>
                    {item.label}
                  </Antd.Option>
                );
              }
            )}
          </Select>
        </div>
        <div className={styles.RangePicker}>
          <p className={styles.item}>选择时间</p>
          <Button
            onClick={() => handleBtnClickTimeChange(7)}
            className={[
              styles.item,
              activeTime === 7 && styles.activeTime,
            ].join(" ")}
          >
            7天
          </Button>
          <Button
            onClick={() => handleBtnClickTimeChange(30)}
            className={[
              styles.item,
              activeTime === 30 && styles.activeTime,
            ].join(" ")}
          >
            30天
          </Button>
          <Button
            onClick={() => handleBtnClickTimeChange(0)}
            className={[
              styles.item,
              activeTime === 0 && styles.activeTime,
            ].join(" ")}
          >
            近一月
          </Button>
          {gType === 0 && (
            <RangePicker
              value={rangeValue}
              className={styles.item}
              disabledDate={(current) => disabledDate(current)}
              onChange={(dates, dateStrings) =>
                handleRangeChange(dates, dateStrings)
              }
              onCalendarChange={(val) => setDates(val)}
            />
          )}
          {gType === 1 && (
            <DatePicker
              className={styles.item}
              value={dateValue}
              disabledDate={(current) => disabledDate(current)}
              onChange={(date, dateString) =>
                handleDateChange(date, dateString)
              }
            />
          )}
          <Select
            className={styles.aSelect}
            style={{ width: 100 }}
            value={gType}
            placeholder="Select a time"
            onChange={(value) => handleClickSelect(value)}
          >
            <Antd.Option value={0}>天</Antd.Option>
            <Antd.Option value={1}>小时</Antd.Option>
          </Select>
        </div>
      </div>
      <div className={styles.linkBtnCont}>
        {catTypeList.map((item: CatTypeList, index: number): ReactElement => {
          return (
            <Button
              key={index}
              className={[
                styles.linkBtn,
                catType === index && styles.catType,
              ].join(" ")}
              onClick={() => handleCatTypeClick(item, index)}
            >
              {item.label}
            </Button>
          );
        })}
      </div>
      <Route path="/content/user/detail/dosage" component={Vdad} exact />
      <Route path="/content/user/detail/dosage/pushFlow" component={PushFlow} />
      <Route path="/content/user/detail/dosage/program" component={Program} />
      <Route path="/content/user/detail/dosage/cloud" component={Cloud} />
      <Route path="/content/user/detail/dosage/realTime" component={RealTime} />
      <Route path="/content/user/detail/dosage/board" component={Board} />
      <Route path="/content/user/detail/dosage/stream" component={Stream} />
      <Route path="/content/user/detail/dosage/intercom" component={Intercom} />
    </div>
  );
};

export default Dosage;
