import { FC, ReactElement, useEffect, useState } from 'react';
import { Drawer, Radio, Table } from 'antd';
import { Option } from '../../../../../axios/axios-types';
import { initEcharts } from '../../../../../api/echarts/monitor/drawerEcharts';
import { callDetailsReconstitutionFn } from '../../../../../api/monitor';
import { timeTransformation } from '../../../../../api/time';
import axios from '../../../../../axios/axios';
import styles from './callDetails.less';
const { Column } = Table;

interface IProps {
    visible: boolean;
    setVisible: Function;
    record: any;
};

interface Data {
	code: number;
	data: Array<RadioOption>;
};

interface Result {
	data: Data;
};

interface RadioOption {
    catName: string;
    category: string;
    uId: string;
    sartsTime: string | number;
    endTime: string | number;
};
const CallDetails: FC<IProps> = (props): ReactElement => {
	const [tableData, setTableData]: [Array<RadioOption>, Function] = useState([]);
	const [tabnameData, setTabnameData]: [string, Function] = useState('');
	const [loading, setLoading]: [boolean, Function] = useState(false);
    const [option, setOption]: [Array<RadioOption>, Function] = useState([]);
	const [consumeList, setConsumeList]: [any, Function] = useState({
        array: [],
        obj: {}
    });
    
    
    useEffect((): void => {
        const getProjectCategory: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/v2/project/getProjectCategory',
                data: { appId: props.record.appId },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                setOption(result.data.data);
                setTabnameData(result.data.data[0].category);
            }
            getChannelConsumptionFn(result.data.data[0].category);
        };

        const getChanUserList: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/project/getChanUserList',
                data: { 
                    appId: props.record.appId,
                    chanSId: props.record.chanSId,
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                result.data.data.forEach((item: any): void => {
                    item.sartsTime = timeTransformation(item.joinTs);
                    item.endTime = item.leaveTs > 0? timeTransformation(item.leaveTs) : "进行中";
                });
                setTableData(result.data.data);
            }
        };
        if (props.visible) {
            getProjectCategory();
            getChanUserList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    const getChannelConsumptionFn: Function = async (category: string): Promise<void> => {
        setLoading(true);
        const option: Option = {
            url: '/v2/project/getChannelConsumption',
            data: { 
                appId: props.record.appId,
                chanId: props.record.chanName,
                chanSId: props.record.chanSId,
                category: category
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setConsumeList(await callDetailsReconstitutionFn(result.data.data, category));
            initEcharts("callStatistics" + category, await callDetailsReconstitutionFn(result.data.data, category), styles);
        }
        setLoading(false);
    };

    const handleRodioChange: Function = (val: string): void => {
        getChannelConsumptionFn(val);
        setTabnameData(val);
    };
    return (
        <div className={ styles.callDetails }>
            <Drawer title={ `频道ID: ${props.record.chanName }` }
            placement="right"
            className={ styles.drawer }
            onClose={ () => props.setVisible(false) } 
            visible={ props.visible }
            getContainer={ false }
            width={ 640 }>
                <p className={ styles.title }>基本信息</p>
                <div className={ styles.line }>
                    <span className={ styles.span }>频道ID：</span>
                    <p>{ props.record.chanName }</p>
                </div>
                <div className={ styles.line }>
                    <div className={ styles.line }>
                        <span className={ styles.span }>开始时间：</span>
                        <p>{ props.record.chanSTs }</p>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>结束时间：</span>
                        <p>{ props.record.chanCTs }</p>
                    </div>
                </div>


                <p className={ styles.title }>通话统计</p>
                <Radio.Group value={ tabnameData } buttonStyle="solid" onChange={ (e) => handleRodioChange(e.target.value) }>
                    {
                        option.map((item: RadioOption, index: number): ReactElement => {
                            return <Radio.Button key={ index } value={ item.category }>{ item.catName }</Radio.Button>
                        })
                    }
                    
                </Radio.Group>
                <div className={ styles.echarts }>
                    <div className={ styles.callStatistics } id={ 'callStatistics' + tabnameData }></div>
                    <div className={ styles.tableContents }>
                        <h4>Total： { consumeList.obj.amount } minutes</h4>
                        <div id={ 'callStatisticsTable' + tabnameData } className={ styles.callStatisticsTd }>
                            {
                                consumeList.array.map((minute: any, index: number): ReactElement => {
                                    return (
                                        <div key={ minute.name } id={ minute.name } className={ index === 0? styles.callStatisticsActive : '' }>
                                            <div className={ styles.callStatisticsT }>
                                                <span className={ styles.dot } style={{ background: minute.color }}></span>
                                                <span> { minute.name }</span>
                                            </div>
                                            <span className={ styles.gray_200 }> { minute.minute } minutes</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>


                <p className={ styles.title }>用户记录</p>
                <Table
                    loading={ loading } 
                    className={ styles.table }
                    rowKey={(record) => `complete${record.uId}${record.sartsTime}${record.endTime}${new Date().getTime()}`}
                    sticky={ true }
                    pagination={ false }
                    dataSource={ tableData }>
                    <Column align='center' title="UID"  dataIndex="uId" />
                    <Column align='center' title="开始时间 - 结束时间" dataIndex="email" render={ (email: any, record: RadioOption): ReactElement => (
                        <div className={ styles.render }>
                            <p>{ record.sartsTime }</p>
                            <span> { record.endTime }</span>
                        </div>
                    ) } />
                </Table>

            </Drawer>
        </div>
    )
};

export default CallDetails;