import { timeTransformation } from "../reconsitution/time";
const P2pCall: any = {
	voice: {
		sending: [
			{
				title: "音频采集音量",
				tooltip: "The size of the audio signal collected locally",
				id: "sendingAudioAcquisitionVolumeaudio"
			},
			{
				title: "音频上行和网络丢包",
				tooltip:
					"The chart shows the user's audio transmission bit rate and uplink packet loss. If the network of the sender is poor, the packet loss is high and the transmitting bit rate decreases.",
				id: "sendingAudioUplinkAndNetworkPacketLossaudio"
			},
			{
				title: "设备状态",
				tooltip:
					"This chart shows the CPU usage of the user's APP and the global system, reflecting the busy status of the user's device. CPU information cannot be collected on the Web platform, and this chart is invalid for the Web platform.",
				id: "sendingEquipmentStatusaudio"
			},
			{
				title: "用户事件",
				tooltip: "Reflects the user's key events in the channel",
				id: "sendingEventDetailsaudio"
			}
		],
		receiving: [
			{
				title: "音频播放音量",
				tooltip: "The volume of the audio received from the sender.",
				id: "receivingAudioPlaybackVolumeaudio"
			},
			{
				title: "音频下行和端到端丢包",
				tooltip:
				"This chart reflects the user's audio reception bit rate and end-to-end network packet loss. End-to-end network loss refers to the loss of packets along the entire link from the sender to the receiver. This data overlays the uplink loss of packets at the sender. If the packet loss on the end-to-end network is high and the uplink of the sender does not lose packets, it usually means that the network of the receiver is poor and the downlink network is faulty.",
				id: "receivingAudioDownlinkAndToEndPacketLossaudio"
			},
			{
				title: "音频解码卡顿",
				tooltip:
				"The chart reflects the audio decoding stutter, if there is no hint that the audio is smooth. The lag indicator is determined by the time interval of decoded audio frames. The frame interval exceeds 200ms and the upper limit is 6000ms. The delay is affected by the following factors: 1. The packet loss rate of the upstream network at the sender 2. The SENDING CPU is high and the sending is uneven. 3. The receiving CPU is too high to process. The packet loss rate of the downstream network at the receiving end is high",
				id: "receivingAudioDecodingLagaudio"
			},
			{
				title: "设备状态",
				tooltip:
				"This chart shows the CPU usage of the user's APP and the global system, reflecting the busy status of the user's device. CPU information cannot be collected on the Web platform, and this chart is invalid for the Web platform.",
				id: "receivingEquipmentStatus"
			},
			{
				title: "用户事件",
				tooltip: "Reflects the user's key events in the channel",
				id: "receivingEventDetailsaudio"
			}
		]
	},
	video: {
		sending: [
			{
				title: "视频发送分辨率",
				tooltip: "The size of the audio signal collected locally",
				id: "sendingAudioAcquisitionVolume"
			},
			{
				title: "视频发送帧率",
				tooltip: "The size of the audio signal collected locally",
				id: "sendingVideoAcquisitionVolume"
			},
			{
				title: "视频上行和网络丢包",
				tooltip:
				"The chart shows the user's audio transmission bit rate and uplink packet loss. If the network of the sender is poor, the packet loss is high and the transmitting bit rate decreases.",
				id: "sendingAudioUplinkAndNetworkPacketLoss"
			},
			{
				title: "设备状态",
				tooltip:
				"This chart shows the CPU usage of the user's APP and the global system, reflecting the busy status of the user's device. CPU information cannot be collected on the Web platform, and this chart is invalid for the Web platform.",
				id: "sendingEquipmentStatus"
			},
			{
				title: "用户事件",
				tooltip: "Reflects the user's key events in the channel",
				id: "sendingEventDetails"
			}
		],
		receiving: [
			{
				title: "视频接收分辨率",
				tooltip: "The volume of the audio received from the sender.",
				id: "receivingAudioPlaybackVolume"
			},
			{
				title: "视频帧率和卡顿",
				tooltip:
				"This chart reflects the user's audio reception bit rate and end-to-end network packet loss. End-to-end network loss refers to the loss of packets along the entire link from the sender to the receiver. This data overlays the uplink loss of packets at the sender. If the packet loss on the end-to-end network is high and the uplink of the sender does not lose packets, it usually means that the network of the receiver is poor and the downlink network is faulty.",
				id: "receivingAudioDownlinkAndToEndPacketLoss"
			},
			{
				title: "视频下行和端到端丢包",
				tooltip:
				"The chart reflects the audio decoding stutter, if there is no hint that the audio is smooth. The lag indicator is determined by the time interval of decoded audio frames. The frame interval exceeds 200ms and the upper limit is 6000ms. The delay is affected by the following factors: 1. The packet loss rate of the upstream network at the sender 2. The SENDING CPU is high and the sending is uneven. 3. The receiving CPU is too high to process. The packet loss rate of the downstream network at the receiving end is high",
				id: "receivingAudioDecodingLag"
			},
			{
				title: "设备状态",
				tooltip:
				"This chart shows the CPU usage of the user's APP and the global system, reflecting the busy status of the user's device. CPU information cannot be collected on the Web platform, and this chart is invalid for the Web platform.",
				id: "receivingEquipmentStatus"
			},
			{
				title: "用户事件",
				tooltip: "Reflects the user's key events in the channel",
				id: "receivingEventDetails"
			}
		]
	}
};

let oIcon: any = require("../../assets/images/zhongzhi.svg").default;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
oIcon = "image://" + oIcon;
const barColor: Array<string> = ["#1BBC72", "#1BBC72", "#FF8723", "#FE4545"];
const oGrid: any = {
	containLabel: true,
	left: 20,
	top: 40,
	bottom: 10,
	right: 20
};
const oToolbox: any = {
	itemSize: 0,
	show: true,
	feature: {
		dataZoom: {
			yAxisIndex: "none",
			filterMode: "none",
			iconStyle: {
				opacity: 0,
				textPadding: 0
			},
			emphasis: {
				opacity: 0,
				textPadding: 0
			},
			title: {
				zoom: "",
				back: ""
			}
		},
		restore: {}
	}
};
const oTooltip: any = {
	show: true,
	trigger: "axis",
	confine: true,
	enterable: true,
	position: function(pt: any): Array<any> {
		return [pt[0], "10%"];
	}
};
const oX: any = {
	type: "category",
	boundaryGap: true,
	splitNumber: 6,
	position: "bottom",
	data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
	axisLine: {
		onZero: false,
		lineStyle: { color: "#DCDCDC" }
	},
	axisLabel: {
		margin: 20,
		color: "#2D2D2D",
		fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium"
	},
	axisTick: { show: false },
	splitLine: {
		show: true,
		lineStyle: { type: "dashed"  }
	}
};
const oY: any = {
	type: "value",
	minInterval: 1,
	axisLine: {
		onZero: false,
		lineStyle: { color: "#DCDCDC" }
	},
	splitLine: {
		show: true,
		lineStyle: { type: "dashed" }
	}
};
const EquipmentStatusOptions: any = {
	grid: Object.assign({}, oGrid, {
		top: 40,
		left: 30
	}),
	toolbox: oToolbox,
	legend: {
		type: "plain",
		left: 0,
		top: 0,
		itemGap: 40,
		itemWidth: 10,
		itemHeight: 10,
		data: [
			{
				name: "APP CPU 占用",
				icon: "circle",
				itemStyle: { color: "#1380FF" }
			},
			{
				name: "系统 CPU 占用",
				icon: "circle",
				itemStyle: { color: "#753BFF" }
			}
		]
	},
  	tooltip: Object.assign({}, oTooltip, {
		extraCssText: "box-shadow: 0px 2px 10px 0px rgba(19,128,255,0.50);padding:0;border:0;", 
		formatter: function(params: any[]) {
			let oa: any = params[0];
			let oTime: any = `<div style="font-size: 16px;color: #1380FF;font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
				${oa.name}
				</div>`;
			let oDiv: string = "";
			params.forEach((item: any, index: number): void => {
				if (item.seriesName === "APP CPU 占用") {
					oDiv += `<div key=${index} style="color: ${
						item.value >= 85 ? "#FE4545" : "#1380FF"
					};font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0"> 
						<span style="width:10px;height:10px;background: ${
						item.value >= 85 ? "#FE4545" : "#1380FF"
						};display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
						${item.seriesName}: ${item.value}%
						</div>`;
				} else {
					oDiv += `<div key=${index} style="color: ${
						item.value >= 85 ? "#FE4545" : "#753BFF"
					};font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0">
						<span style="width:10px;height:10px;background: ${
						item.value >= 85 ? "#FE4545" : "#753BFF"
						};display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
						${item.seriesName}: ${item.value}%
						</div>`;
				}
			});
			return `<div style="border-top: 5px solid #1380FF;padding:20px;background: #F4F9FF;">${oTime}<div>${oDiv}</div></div>`;
		}
	}),
	visualMap: [
		{
			top: 10,
			right: 10,
			show: false,
			seriesIndex: 0,
			pieces: [{ min: 85, max: 100, color: "#FE4545" }],
			outOfRange: { color: "#1380FF" }
		},
		{
			top: 10,
			right: 10,
			show: false,
			seriesIndex: 1,
			pieces: [{ min: 85, max: 100, color: "#FE4545" }],
			outOfRange: { color: "#753BFF" }
    	}
  	],
	xAxis: oX,
	yAxis: Object.assign({}, oY, {
		min: 0,
		max: 100,
		interval: 50,
		axisLabel: {
			margin: 20,
			color: "#2D2D2D",
			fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium",
			formatter: function(params: any): string {
				return params + "%";
			}
		}
	}),
	series: [
		{
			type: "line",
			name: "APP CPU 占用",
			data: [],
			symbolSize: 0
		},
		{
			type: "line",
			name: "系统 CPU 占用",
			data: [],
			symbolSize: 0
		}
	]
};
const NetworkAndLossOptions: any = {
	grid: Object.assign({}, oGrid, {
		top: 40,
		left: 12,
		right: 0
	}),
	toolbox: oToolbox,
	legend: {
		type: "plain",
		left: 0,
		top: 0,
		itemGap: 40,
		itemWidth: 10,
		itemHeight: 10,
		data: [
			{
				name: "视频接收帧率",
				icon: "circle",
				itemStyle: {
				color: "#1380FF"
				}
			},
			{
				name: "视频渲染卡顿时间",
				icon: "circle",
				itemStyle: {
				color: "#FF0000"
				}
			}
		]
	},
  	tooltip: Object.assign({}, oTooltip, {
		extraCssText: "box-shadow: 0px 2px 10px 0px rgba(19,128,255,0.50);padding:0;border:0;", // 额外附加到浮层的 css 样式
		formatter: function(params: any): string {
			let oa: any = params[0];
			let oTime: string = `<div style="font-size: 16px;color: #1380FF;font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
				${oa.name}
				</div>`;
			let oDiv: string = "";
			params.forEach((item: any, index: number): void => {
				if (
					item.seriesName === "视频发送码率" ||
					item.seriesName === "视频接收码率" ||
					item.seriesName === "音频发送码率" ||
					item.seriesName === "音频接收码率"
				) {
					oDiv += `<div key=${index} style="color: #1380FF;font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0"> 
						<span style="width:10px;height:10px;background: #1380FF;display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
						${item.seriesName}: ${item.value}kbps
						</div>`;
				} else {
					oDiv += `<div key=${index}style="color: #FE4545;font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0">
						<span style="width:10px;height:10px;background: #FE4545;display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
						${item.seriesName}: ${item.value}%
						</div>`;
				}
			});
			return `<div style="border-top: 5px solid #1380FF;padding:20px;background: #F4F9FF;">${oTime}<div>${oDiv}</div></div>`;
		}
	}),
	xAxis: oX,
	yAxis: [
		Object.assign({}, oY, {
			axisLabel: {
				margin: 20,
				color: "#2D2D2D",
				fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium",
				formatter: function(params: any): string {
					return params + "kbps";
				}
			}
		}),
		{
			type: "value",
			show: true,
			splitLine: { show: true }
		}
	],
	series: [
		{
			data: [820, 932, 901, 934, 1290, 1330, 1320],
			type: "line",
			name: "视频接收帧率",
			smooth: true,
			symbolSize: 0,
			color: "#1380FF"
		},
		{
			data: [8, 9, 1, 34, 189, 30, 20],
			type: "bar",
			name: "视频渲染卡顿时间",
			yAxisIndex: 1,
			smooth: true,
			barWidth: 1,
			z: 99,
			itemStyle: { color: "#FF0000" }
		}
	]
};
const EventOptions: any = {
	grid: Object.assign({}, oGrid, {
		top: 40,
		left: 80
	}),
	legend: {
		type: "plain",
		left: 0,
		top: 0,
		selectedMode: false,
		itemGap: 40,
		itemWidth: 10,
		itemHeight: 10,
		data: [
			{
				name: "正常事件",
				icon: "circle",
				itemStyle: {
				color: "#1bbc72"
				}
			},
			{
				name: "一般事件",
				icon: "circle",
				itemStyle: {
				color: "#FF8723"
				}
			},
			{
				name: "严重事件",
				icon: "circle",
				itemStyle: {
				color: "#FE4545"
				}
			}
		]
	},
  	toolbox: oToolbox,
	tooltip: Object.assign({}, oTooltip, {
		extraCssText:
		"box-shadow: 0px 2px 10px 0px rgba(19,128,255,0.50);padding:0;border:0;max-height:220px;overflow: auto;", // 额外附加到浮层的 css 样式
		formatter: function(params: any): string {
			let data: any = params[0].data;
			if (data && data.eventList) {
				let evtList = data.eventList;
				let oTime: string = `<div style="font-size: 16px;color: #FE4545;font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
				${params[0].name}
				</div>`;
					let tooltipUlContent = "";
					evtList.forEach((evt: any, index: number): void => {
						tooltipUlContent += `<div key=${index} style="color:${
							barColor[evt.etype.errType]
						};margin:10px 0;font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;"> <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
							barColor[evt.etype.errType]
						}"></span> ${timeTransformation(evt.lts, false)} ${evt.etype.tooltipDesc}</div>`;
					});
				return `<div style="border-top: 5px solid #FE4545;padding:20px;background: #FFF3F3;">${oTime}<div>${tooltipUlContent}</div></div>`;
			} else {
				return "";
			}
		}
  	}),
	xAxis: oX,
	yAxis: Object.assign({}, oY, {
		axisLabel: { show: false }
	}),
	series: [
		{
			data: [
				120,
				{
					value: 200,
					itemStyle: { color: "#a90000" }
				},
				150,
				80,
				70,
				110,
				130
			],
			type: "bar",
			barWidth: 1,
			name: "正常事件"
		},
		{
			data: [],
			type: "bar",
			barWidth: 1,
			name: "一般事件"
		},
		{
			data: [],
			type: "bar",
			barWidth: 1,
			name: "严重事件"
		}
	]
};
const AudioVolumeOptions: any = {
	grid: Object.assign({}, oGrid, {
		left: 40,
		right: 18
	}),
	toolbox: oToolbox,
	tooltip: Object.assign({}, oTooltip, {
		extraCssText: "box-shadow: 0px 2px 10px 0px rgba(19,128,255,0.50);padding:0;border:0;", // 额外附加到浮层的 css 样式
		formatter: function(params: any): string {
			let oa: any = params[0];
			let oTime: string = `<div style="font-size: 16px;color: #1380FF;font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
				${oa.name}
				</div>`;
			let oDiv: string = `<div style="color: #1380FF;font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0"> 
			<span style="width:10px;height:10px;background: #1380FF;display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
			${oa.seriesName}: ${oa.value}
			</div>`;
			return `<div style="border-top: 5px solid #1380FF;padding:20px;background: #F4F9FF;">${oTime}<div>${oDiv}</div></div>`;
		}
	}),
	xAxis: oX,
	yAxis: Object.assign({}, oY, {
		axisLabel: {
			margin: 20,
			color: "#2D2D2D",
			fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium"
		}
	}),
	series: [
		{
			data: [820, 932, 901, 934, 1290, 1330, 1320],
			type: "line",
			name: "音频采集音量",
			smooth: true,
			symbolSize: 0,
			color: "#1380FF"
		}
	]
};
const AudioDecodingLagOptions: any = {
	grid: Object.assign({}, oGrid, {
		left: 14
	}),
	toolbox: oToolbox,
	tooltip: Object.assign({}, oTooltip, {
		extraCssText: "box-shadow: 0px 2px 10px 0px rgba(19,128,255,0.50);padding:0;border:0;", // 额外附加到浮层的 css 样式
		formatter: function(params: any): string {
			let oa: any = params[0];
			let oTime: string = `<div style="font-size: 16px;color: ${oa.color};font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
				${oa.name}
				</div>`;
			let oDiv: string = `<div style="color: ${oa.color};font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0"> 
			<span style="width:10px;height:10px;background: ${oa.color};display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
			${oa.seriesName}: ${oa.value}ms
			</div>`;
			return `<div style="border-top: 5px solid ${oa.color};padding:20px;background: #fff;">${oTime}<div>${oDiv}</div></div>`;
		}
	}),
	xAxis: oX,
	yAxis: Object.assign({}, oY, {
		axisLabel: {
			margin: 20,
			color: "#2D2D2D",
			fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium",
			formatter: function(params: any): string {
				return params + "ms";
			}
		}
	}),
	series: [
		{
			data: [
				120,
				{
					value: 200,
					itemStyle: { color: "#a90000" }
				},
				150,
				80,
				70,
				110,
				130
			],
			type: "bar",
			barWidth: 1,
			name: "音频渲染卡顿时间"
		}
	]
};
const ResolutionRatioOptions: any = {
	grid: Object.assign({}, oGrid, {
		left: 0
	}),
	toolbox: oToolbox,
	tooltip: Object.assign({}, oTooltip, {
		extraCssText: "box-shadow: 0px 2px 10px 0px rgba(117,59,255,0.50);padding:0;border:0;",
		formatter: function(params: any): string {
			let oa: any = params[0];
			let oTime: string = `<div style="font-size: 16px;color: #753BFF;font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
				${oa.name}
				</div>`;
			let oDiv: string = `<div style="color: #753BFF;font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0"> 
			<span style="width:10px;height:10px;background: #753BFF;display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
			${oa.seriesName}: ${oa.data.w}*${oa.data.h} (宽*高)
			</div>`;
			return `<div style="border-top: 5px solid #753BFF;padding:20px;background: #f7f3ff;">${oTime}<div>${oDiv}</div></div>`;
		}
  	}),
	xAxis: oX,
	yAxis: Object.assign({}, oY, {
		interval: 360,
		min: 0,
		max: 1080,
		axisLabel: {
		margin: 20,
		color: "#2D2D2D",
		fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium",
		formatter: function(params: any): string {
			let oy: string = "";
			switch (params) {
				case 0:
					oy = "";
					break;
				case 360:
					oy = "SD(360)";
					break;
				case 720:
					oy = "HD(720)";
					break;
				case 1080:
					oy = "HD+(1080)";
					break;
				case 1440:
					oy = "2K(1440)";
					break;
				case 2160:
					oy = "2k+(2160)";
					break;
				default:
					oy = params + "p";
					break;
				}
				return oy;
			}
		}
	}),
	series: [
		{
			data: [150, 230, 224, 218, 135, 147, 260],
			type: "line",
			name: "视频发送分辨率",
			step: true,
			symbolSize: 0,
			color: "#753BFF",
			areaStyle: {
				color: {
					type: "linear",
					x: 0,
					y: 0,
					x2: 0,
					y2: 1,
					colorStops: [
						{
							offset: 0,
							color: "#F7F3FF"
						},
						{
							offset: 1,
							color: "white"
						}
					],
					global: false
				}
			}
		}
	]
};
const FrameRateOptions: any = {
	grid: Object.assign({}, oGrid, {
		left: 26
	}),
	toolbox: oToolbox,
	tooltip: Object.assign({}, oTooltip, {
		extraCssText: "box-shadow: 0px 2px 10px 0px rgba(19,128,255,0.50);padding:0;border:0;",
		formatter: function(params: any): string {
			let oa: any = params[0];
			let oTime: string = `<div style="font-size: 16px;color: #1380FF;font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
				${oa.name}
				</div>`;
			let oDiv: string = `<div style="color: #1380FF;font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0"> 
			<span style="width:10px;height:10px;background: #1380FF;display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
			${oa.seriesName}: ${oa.value}fps
			</div>`;
			return `<div style="border-top: 5px solid #1380FF;padding:20px;background: #F4F9FF;">${oTime}<div>${oDiv}</div></div>`;
		}
	}),
	xAxis: oX,
	yAxis: Object.assign({}, oY, {
		axisLabel: {
			margin: 20,
			color: "#2D2D2D",
			fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium",
			formatter: function(params: any): string {
				return params + " fps";
			}
		}
	}),
	series: [
		{
			data: [820, 932, 901, 934, 1290, 1330, 1320],
			type: "line",
			smooth: true,
			symbolSize: 0,
			color: "#1380FF"
		}
	]
};
const FrameRateAndLagOptions: any = {
	grid: Object.assign({}, oGrid, {
		top: 40
	}),
	toolbox: oToolbox,
	legend: {
		type: "plain",
		left: 0,
		top: 0,
		itemGap: 40,
		itemWidth: 10,
		itemHeight: 10,
		data: [
			{
				name: "视频接收帧率",
				icon: "circle",
				itemStyle: { color: "#1380FF" }
			},
			{
				name: "视频渲染卡顿时间",
				icon: "circle",
				itemStyle: { color: "#FF0000" }
			}
		]
	},
	tooltip: Object.assign({}, oTooltip, {
		extraCssText: "box-shadow: 0px 2px 10px 0px rgba(19,128,255,0.50);padding:0;border:0;", // 额外附加到浮层的 css 样式
		formatter: function(params: any): string {
		let oa: any = params[0];
		let oTime: string = `<div style="font-size: 16px;color: #1380FF;font-family: SourceHanSansCN, SourceHanSansCN-Heavy;font-weight: 800;">
			${oa.name}
			</div>`;
		let oDiv: string = "";
		params.forEach((item: any, index: number): void => {
			if (item.seriesName === "视频接收帧率") {
				oDiv += `<div key=${index} style="color: #1380FF;font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0"> 
					<span style="width:10px;height:10px;background: #1380FF;display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
					${item.seriesName}: ${item.value}fps
					</div>`;
			} else {
				oDiv += `<div key=${index} style="color: #FE4545;font-size: 14px;font-family: SourceHanSansCN, SourceHanSansCN-Medium;font-weight: 500;margin: 10px 0 0">
					<span style="width:10px;height:10px;background: #FE4545;display: inline-block;border-radius: 50%;margin-right:6px;"></span> 
					${item.seriesName}: ${item.value}ms
					</div>`;
			}
		});
		return `<div style="border-top: 5px solid #1380FF;padding:20px;background: #F4F9FF;">${oTime}<div>${oDiv}</div></div>`;
		}
	}),
	xAxis: oX,
	yAxis: [
		Object.assign({}, oY, {
			axisLabel: {
				margin: 20,
				color: "#2D2D2D",
				fontFamily: "SourceHanSansCN, SourceHanSansCN-Medium",
				formatter: function(params: any): string {
					return params + "fps";
				}
			}
		}),
		{
			type: "value",
			show: false,
			splitLine: { show: false }
		}
	],
	series: [
		{
			data: [820, 932, 901, 934, 1290, 1330, 1320],
			type: "line",
			name: "视频接收帧率",
			smooth: true,
			symbolSize: 0,
			color: "#1380FF"
		},
		{
			data: [8, 9, 1, 34, 189, 30, 20],
			type: "bar",
			name: "视频渲染卡顿时间",
			yAxisIndex: 1,
			smooth: true,
			barWidth: 1,
			z: 4,
			itemStyle: { color: "#FF0000" }
		}
	]
};
export {
	P2pCall,
	EquipmentStatusOptions,
	NetworkAndLossOptions,
	EventOptions,
	AudioVolumeOptions,
	AudioDecodingLagOptions,
	ResolutionRatioOptions,
	FrameRateOptions,
	FrameRateAndLagOptions
};