import { CSSProperties, ChangeEvent, FC, ReactElement, useEffect, useState } from 'react';
import { Descriptions, Switch, message, Button, Modal, Input, Select } from 'antd';
import { useLocation } from 'react-router-dom';
import { Option } from '../../../../../axios/axios-types';
import { findPermissions } from '../../../../../api/permiss';
import { getTimeFormate } from '../../../../../api/index';
import { EditOutlined } from '@ant-design/icons';
import axios from '../../../../../axios/axios';
import styles from './basicInfor.less';
const { TextArea } = Input;

namespace Antd {
    export const { Option } = Select;
};

interface Data {
	code: number;
	data: any;
};

interface Result {
	data: Data;
};

interface Options {
    [attr: string]: string
};

enum Status { never, '已停用', '在使用', '快到期', '已到期' }

const labelStyle: CSSProperties = { 
    width: '150px', 
    textAlign: 'right', 
    display: 'inline-block'
};

const BasicInfor: FC = (): ReactElement => {
    const location: any = useLocation();
    const [options, setOptions]: [Array<Options>, Function] = useState([]);
    const [switchValue, setSwitchValue]: [boolean, Function] = useState(false);
    const [devpInfo, setDevpInfo]: [any, Function] = useState({});
    const [licenseInfo, setLicenseInfo]: [any, Function] = useState({});
    const [restfulAPIInfo, setRestfulAPIInfo]: [any, Function] = useState({});
    const [remarksCopy, setRemarksCopy]: [string, Function] = useState('');
    const [contractId, setContractid]: [string, Function] = useState('');
    const [effect, setEffect]: [boolean, Function] = useState(false);
    const [isNotesModalVisible, setIsNotesModalVisible]: [boolean, Function] = useState(false);
    const [contractIdDisabled, setContractidDisabled]: [boolean, Function] = useState(true);
    const [contractIdSetLoading, setContractidSetLoading]: [boolean, Function] = useState(false);


    useEffect((): void => {
        const getDevpInfoDetail: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/primgr/getDevpInfoDetail',
                data: { 
                    oem: location.state.oem, 
                    uid: location.state.uid 
                },
				method: 'POST',
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                location.state.license = result.data.data.licenseInfo.license;
                result.data.data.devpInfo.state = result.data.data.devpInfo.state === 0? "异常" : "正常";
                result.data.data.devpInfo.emailState = result.data.data.devpInfo.emailState === 0? "未验证" : "已验证";
                result.data.data.licenseInfo.dType = result.data.data.licenseInfo.dType === 1? "正式期" : "测试期";
                result.data.data.licenseInfo.licType = result.data.data.licenseInfo.licType === 2? "混合云" : "私有云";
                result.data.data.licenseInfo.status = Status[result.data.data.licenseInfo.status];
                result.data.data.devpInfo.userCreateAt = getTimeFormate(result.data.data.devpInfo.userCreateAt);
                setSwitchValue(result.data.data.licenseInfo.state === 1);
                if (result.data.data.restfulAPInfo !== null && result.data.data.restfulAPInfo !== undefined) {
                    result.data.data.devpInfo.customerCertificate = result.data.data.restfulAPInfo.customerCertificate;
                    result.data.data.devpInfo.customerId = result.data.data.restfulAPInfo.customerId;
                }
                if (result.data.data.arrAdminInfo != null && result.data.data.arrAdminInfo !== undefined) {
                    setOptions([]);
                    result.data.data.arrAdminInfo.forEach((item: any): void => {
                        options.push({
                            value: item.aid,
                            label: item.name
                        });
                    });
                    let obj = {};
                    options.forEach((item: any, index: number): void => {
                        if (item.label === result.data.data.devpInfo.adminName) {
                            obj = item;
                            options.splice(index, 1);
                        }
                    });
                    options.unshift(obj);
                    setOptions(options);
                }
                options.forEach((item: any): void => {
                    if (item.label === result.data.data.devpInfo.adminName) {
                        result.data.data.devpInfo.adminName = item.value;
                    }
                });
                setDevpInfo(result.data.data.devpInfo);
                setLicenseInfo(result.data.data.licenseInfo);
                setRestfulAPIInfo(result.data.data.restfulAPIInfo);
            } else {
                message.error('数据获取失败');
            }
        };
        getDevpInfoDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect]);

    const handleChange: Function = async (checked: boolean): Promise<void> => {
        const option: Option = {
            url: '/primgr/updateLinceseState',
            data: {
                oem: location.state.oem, 
                state: checked? 1 : 0
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("操作成功");
        } else {
            message.error("操作失败");
        }
        setEffect(!effect);
    };

    const handleSelectChange: Function = async (value: string): Promise<void> => {
        const option: Option = {
            url: '/developers/updateUserSales',
            data: { uId: location.state.uid, salesId: value },
            method: 'POST',
        };
        const result: Result = await axios(option);
        if (result && result.data.code === 0) {
            setEffect(!effect);
            message.success('销售更新成功');
        } else {
            message.error('销售更新失败');
        }
    };

    const handleContractidClick: Function = (): void => {
        setContractidDisabled(false);
        setContractid(devpInfo.contractId);
    };

    const handleContractidOnCancel: Function = (): void => {
        devpInfo.contractId = contractId;
        setDevpInfo(devpInfo);
        setContractidDisabled(true);
    };

    const handleContractidOnOk: Function = async (): Promise<void> => {
        if (devpInfo.contractId.trim() === '') {
            message.error('合同号不能为空');
        } else {
            const option: Option = {
                url: '/developers/updateUserContractId',
                data: { uId: location.state.uid, contractId: devpInfo.contractId },
                method: 'POST',
            };
            setContractidSetLoading(true);
            const result: Result = await axios(option);
            if (result && result.data.code === 0) {
                message.success('合同编号修改成功');
            } else {
                message.error('合同编号修改失败');
            }
            setEffect(!effect);
            setContractidSetLoading(false);
            setContractidDisabled(true);
        }
    };

    const handleInputChange: Function = (e: ChangeEvent<HTMLInputElement>): void => {
        const devpInfoCopy: any = JSON.parse(JSON.stringify(devpInfo));
        devpInfoCopy.contractId = e.target.value;
        setDevpInfo(devpInfoCopy);
    };

    const handleRemarksClick: Function = (): void => {
        setRemarksCopy(licenseInfo.remark);
        setIsNotesModalVisible(true);
    };

    const handleRemarksOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/updateLicRemark',
            data: { oem: location.state.oem, remark: remarksCopy },
            method: 'POST',
        };
        const result: Result = await axios(option);
        if (result && result.data.code === 0) {
            message.success('备注更新成功');
        } else {
            message.error('备注更新失败');
        }
        setIsNotesModalVisible(false);
        setEffect(!effect);
    };

    return (
        <div className={ styles.basicInfor }>
            <div className={ styles.modal }>
                <p className={ styles.title }>基本信息</p>
                <Descriptions column={ 2 }>
                    <Descriptions.Item labelStyle={ labelStyle } label="昵称">{ devpInfo.name }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="注册时间">{ devpInfo.userCreateAt }</Descriptions.Item>
                    { licenseInfo.licType === '混合云' && 
                    <Descriptions.Item labelStyle={ labelStyle } label="管理服务">
                        <Switch checked={ switchValue } disabled={ !findPermissions(location.state.perm.glfw) } onChange={ (checked) => handleChange(checked) } />
                    </Descriptions.Item>}
                    <Descriptions.Item labelStyle={ labelStyle } label="邮箱">{ devpInfo.email }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="帐号状态">{ devpInfo.state }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="手机号">{ devpInfo.cellphone }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="所属销售">
                        <Select value={ devpInfo.adminName } disabled={ !findPermissions(location.state.perm.xs) } style={{ width: 120 }} onChange={ (value) => handleSelectChange(value) }>
                            {
                                options.map((item: any, index: number): ReactElement => {
                                    return (
                                        <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                                    );
                                })
                            }
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="并发量">{ licenseInfo.concurrentNum }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="合同编号">
                        <div className={ styles.line }>
                            <Input className={ styles.contractId } onChange={ (e) => handleInputChange(e) } disabled={ contractIdDisabled } value={ devpInfo.contractId } />
                            { (contractIdDisabled && findPermissions(location.state.perm.bh)) && <Button onClick={ () => handleContractidClick() } type="primary" shape="circle" icon={ <EditOutlined /> } /> }
                            { (contractIdDisabled && findPermissions(location.state.perm.bh)) || <Button className={ styles.contractIdBtn } onClick={ () => handleContractidOnOk() } loading={ contractIdSetLoading } disabled={ contractIdSetLoading } type="primary">OK</Button> }
                            { (contractIdDisabled && findPermissions(location.state.perm.bh)) || <Button className={ styles.contractIdBtn } onClick={ () => handleContractidOnCancel() }>Cancel</Button> }
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="续期次数">{ licenseInfo.renewalNum }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="部署类型">{ licenseInfo.licType }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="CID">{ devpInfo.cid }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="客户类型">{ licenseInfo.dType }</Descriptions.Item>
                    { findPermissions(location.state.perm.my) && <Descriptions.Item labelStyle={ labelStyle } label="license密钥">
                        <div className={ styles.line }>
                            <span>{ licenseInfo.license }</span>
                        </div>
                    </Descriptions.Item> }
                    <Descriptions.Item labelStyle={ labelStyle } label="服务状态">{ licenseInfo.status }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="APPID">{ licenseInfo.appId }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="Customer Certificate">{ restfulAPIInfo.customerCertificate }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="备注">
                        <div className={ styles.line }>
                            <span>{ licenseInfo.remark }</span>
                            { findPermissions(location.state.perm.bz) && <Button className={ licenseInfo.remark && styles.remarksBtn } 
                            onClick={ () => handleRemarksClick() } type="primary">添加备注</Button> }
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="Customer ID">{ restfulAPIInfo.customerId }</Descriptions.Item>
                </Descriptions>
            </div>
            <Modal centered title='添加备注' visible={ isNotesModalVisible } onOk={ () => handleRemarksOk() } onCancel={ () => setIsNotesModalVisible(false) }>
                <TextArea value={ remarksCopy } onChange={ (e) => setRemarksCopy(e.target.value) } rows={ 10 } />
            </Modal>
        </div>
    );
};


export default BasicInfor;