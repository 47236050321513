import { ChangeEvent, Fragment, FC, ReactElement, useState, useEffect } from 'react';
import { Input, Button, message, Table } from 'antd';
import { findPermissions, userMoudleViewPath } from '../../../../api/permiss';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { TableDataAttr } from '../tableColumns';
import { AuthType } from '../../../../types/enum';
import { Option } from '../../../../axios/axios-types';
import { getTimestampToTime, moneyComma } from '../../../../api';
import axios from '../../../../axios/axios';
import styles from './invoice.less';
const { Column } = Table;

interface Data {
	code: number;
	data: {
		list: Array<TableDataAttr>;
		totalNumber: number;
	};
};

interface Result {
	data: Data;
};

interface SearchResult {
	data: {
		code: number;
		data: Array<TableDataAttr>;
	}
};

interface IProps {
    cacheLifecycles: any;
};

const Invoice: FC<IProps> = (props): ReactElement => {
	const history: any = useHistory();
	const [loading, setLoading]: [boolean, Function] = useState(false);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [searchValue, setSearchValue]: [string, Function] = useState('');
	const [tableData, setTableData]: [Array<TableDataAttr>, Function] = useState([]);
	const [sortRule, setSortRule]: [number, Function] = useState(1);
	const [sortField, setSortField]: [string, Function] = useState('invTs');
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    
	useEffect((): void => {
		getApplyInvoiceList();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize, current, sortRule, sortField]);

	props.cacheLifecycles.didRecover(() => {
        getApplyInvoiceList();
    });

	useEffect((): void => {
		setCurrent(1);
	}, [sortRule, sortField]);

	const getApplyInvoiceList: Function = async (): Promise<void> => {
		setTableLoading(true);
		const option: Option = {
			url: '/developers/getApplyInvoiceList',
			method: 'POST',
			data: {
				sortRule,
				sortField,
				pageNum: current,
				pageSize,
			}
		};
		const result: Result = await axios(option);
		if (result) {
			if (result.data.code === 0) {
				result.data.data.list.forEach((item: TableDataAttr): void => {
					item.invTs = getTimestampToTime(item.invTs);
					item.invAmount = moneyComma(item.invAmount);
					item.devpAuthType = AuthType[item.devpAuthType as number];
				});
				setTotal(result.data.data.totalNumber);
				setTableData(result.data.data.list);
			} else {
				message.error('数据获取失败');
			}
		}
		setTableLoading(false);
	};

    const handleSearch: Function = async (): Promise<void> => {
        if (searchValue.trim()) {
			const option: Option = {
				url: '/developers/searchDevper',
				data: {
					searchParam: searchValue
				},
				method: 'POST'
			};
			setLoading(true);
			const result: SearchResult = await axios(option);
            setLoading(false);
			if (result.data.code === 0) {
				setSearchValue('');
				message.success('数据查询成功');
				history.push(userMoudleViewPath(), { uid: result.data.data[0].uid, private: {
					name: '发票申请',
					url: '/content/user/invoice'
				} });
			} else if (result.data.code === 1000) {
                message.error('开发者不存在');
            } else {
				message.error('数据查询失败');
			}
		}
    }

    const handleChange: Function = (e: ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(e.target.value);
	};

	const handleKeydown = (e: KeyboardEvent): void => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	const handleClick: Function = (record: TableDataAttr): void => {
		history.push('/content/user/detail/financial/invoice', { uid: record.uid, private: {
			name: '发票申请',
			url: '/content/user/invoice'
		} });
	};

	const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
	};

    useEffect(() => {
		window.addEventListener('keydown', handleKeydown);		
		return () => {
			window.removeEventListener('keydown', handleKeydown);		
		}
	});

    return (
        <div className={ styles.invoice }>
            <div className={ styles.serach }>
                <Input value={ searchValue } onChange={ (e) => handleChange(e) } className={ styles.input } placeholder="请输入用户的邮箱或手机号或AppID或CID" prefix={<SearchOutlined />} />
                <Button className={ styles.button } loading={ loading } disabled={ searchValue === '' } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
				<div className={ styles.total }>
					<span>申请未处理：</span>
					<span>{ total }</span>
				</div>
			</div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='invoiceid'
				sticky={ true }
				sortDirections={['ascend', 'descend', 'ascend']} 
				dataSource={ tableData }>
				<Column align='center' title="邮箱/手机号" dataIndex="email" render={ (email: any, record: TableDataAttr): ReactElement => (
					<div className={ styles.render }>
						<p>{ record.devpEmail }</p>
						<p>{ record.devpPhone }</p>
					</div>
				) } />
				<Column align='center' showSorterTooltip={ false } sorter={ true } title="申请金额(CNY)"  dataIndex="invAmount" />
				<Column align='center' showSorterTooltip={ false } sorter={ true } defaultSortOrder='ascend' title="申请日期" dataIndex="invTs" />
				<Column align='center' title="认证类型" dataIndex="devpAuthType" />
				<Column align='center' title="备注" dataIndex="remark" />
				<Column align='center' title="操作" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<Fragment>{ findPermissions(11002) && <p onClick={ () => handleClick(record) } className={ styles.operation }>查看</p> }</Fragment>
				) } />
			</Table>
        </div>
    )
};


export default Invoice;