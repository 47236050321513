import { ChangeEvent, FC, ReactElement, useState, useEffect } from 'react';
import { Button, Table, InputNumber, Modal, Radio, Input, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../../../../api/permiss';
import { getTimestampToTime } from '../../../../../../../../../api';
import { Option } from '../../../../../../../../../axios/axios-types';
import { SearchOutlined } from '@ant-design/icons';
import axios from '../../../../../../../../../axios/axios';
import styles from './authorList.less';
import { AxiosResponse } from 'axios';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const AuthorList: FC = (): ReactElement => {
    const location: any = useLocation();
    const [radioVal, setRadioVal]: [number, Function] = useState(0);
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [sortField, setSortField]: [string, Function] = useState('actTs');
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [modalLoading, setModalLoading]: [boolean, Function] = useState(false);
	const [modalTitle, setModalTitle]: [string, Function] = useState('');
	const [visible, setVisible]: [boolean, Function] = useState(false);
	const [searchValue, setSearchValue]: [string, Function] = useState('');
	const [isSearch, setIsSearch]: [boolean, Function] = useState(false);
	const [loading, setLoading]: [boolean, Function] = useState(false);
	const [modalType, setModalType]: [string, Function] = useState('');
    const [licenseMount, setLicenseMount]: [number, Function] = useState(36);
	const [record, setRecord]: [any, Function] = useState({});
	const [radioShow, setRadioShow]: [boolean[], Function] = useState([false, false]);
	const [licenseLic, setLicenseLic]: [any, Function] = useState({
        activat: 0,
        awaitAct: 0,
        overdue: 0
    });

    useEffect((): void => {
        getLicenseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect((): void => {
        getActLicenseDetailList();
        getLicNum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, location.state.appid, location.state.uid, pageSize, radioVal, sortField, sortRule]);

    const getLicenseList: Function = async (): Promise<void> => {
        const promiseAll: Array<Promise<AxiosResponse>> = []; 
        [0, 1].forEach((licType: number): void => {
            const option: Option = {
                url: '/lic/getLicenseList',
                data: {
                    appId: location.state.appid,
                    devpId: location.state.uid,
                    licType,
                },
                method: 'POST'
            };
            promiseAll.push(axios(option));
        });
        Promise.all(promiseAll).then((value: Array<AxiosResponse>): void => {
            const radioS: boolean[] = JSON.parse(JSON.stringify(radioShow));
            value.forEach((result: AxiosResponse, index: number): void => {
                if (result.data.code === 0 && Array.isArray(result.data.data)) {
                    radioS[index] = result.data.data.length > 0;
                    setRadioShow(radioS);
                }
            })
        });
    };
    
    const getLicNum: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/lic/getLicNum',
            data: {
                appId: location.state.appid,
                devpId: location.state.uid,
                licType: radioVal
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            if (Array.isArray(result.data.data)) {
                const licenseLi: any = JSON.parse(JSON.stringify(licenseLic));
                result.data.data.forEach((item: any) : void => {
                    if (item.licStatus === 1) {
                        licenseLi.awaitAct = item.licNum;
                    } else if (item.licStatus === 2) {
                        licenseLi.activat = item.licNum;
                    } else if (item.licStatus === 3) {
                        licenseLi.overdue = item.licNum;
                    }
                });
                setLicenseLic(licenseLi);
            }
        }
    };

    const getActLicenseDetailList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/lic/getActLicenseDetailList',
            data: {
                appId: location.state.appid,
                devpId: location.state.uid,
                licType: radioVal,
                sortRule,
                sortField,
                pageNum: current,
                pageSize,
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            if (Array.isArray(result.data.data.list)) {
                result.data.data.list.forEach((item: any): void => {
                    item.actTs = getTimestampToTime(item.actTs);
                    item.expirTs = getTimestampToTime(item.expirTs);
                });
                setTableData(result.data.data.list);
                setTotal(result.data.data.totalNumber);
            } else {
                setTableData([]);
            }
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
	};

    const handleChange: Function = (e: ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(e.target.value);
	};

    const handleRadioChange: Function = (value: number): void => {
		setRadioVal(value);
        setSearchValue('');
        setIsSearch(false);
	};

    const handleSearch: Function = async (): Promise<void> => {
        if (searchValue.trim()) {
			const option: Option = {
				url: '/lic/getLicDetailByLic',
				data: {
                    licType: radioVal,
                    license: searchValue,
                    appId: location.state.appid,
                    devpId: location.state.uid,
				},
				method: 'POST'
			};
			setLoading(true);
			const result: Result = await axios(option);
			setLoading(false);
            setIsSearch(true);
			if (result.data.code === 0) {
                if (Array.isArray(result.data.data)) {
                    result.data.data.forEach((item: any): void => {
                        item.actTs = getTimestampToTime(item.actTs);
                        item.expirTs = getTimestampToTime(item.expirTs);
                    });
                    setTableData(result.data.data);
                } else {
                    setTableData([]);
                }
				message.success('License查询成功');
			} else {
                setTableData([]);
				if (result.data.code === 4000) {
                    message.error("License信息不存在");
                } else if (result.data.code === 4007) {
                    message.error("设备License未激活");
                } else {
                    message.error(result.data.data.msg);
                }
			}
		}
    };

    const handleOperation: Function = (record: any, type: string): void => {
        setRecord(record);
        setModalType(type);
        setVisible(true);
        if (type === 'unbund') {
            setModalTitle('解绑设备');
        } else if (type === 'renewal') {
            setModalTitle('续期操作');
        }
    };

    const handleSearchCancel: Function = (): void => {
        setSearchValue('');
        setIsSearch(false);
        getActLicenseDetailList();
    };

   const handleOk: Function = (): void => {
        if (modalType === 'unbund') {
            handleModalAxios({
                url: '/lic/updateLicStatus',
                data: { licDid: record.licDid },
                success: 'License解绑成功',
                error: 'License解绑失败',
                callBack: (): void => {
                    setIsSearch(false);
                    setSearchValue('');
                    getActLicenseDetailList();
                }
            });
        } else if (modalType === 'renewal') {
            handleModalAxios({
                url: '/lic/updateLicPeriod',
                data: { 
                    licDid: record.licDid,
                    period: licenseMount,
                    devpId: location.state.uid
                },
                success: 'License续期成功',
                error: 'License续期失败',
                callBack: (): void => {
                    if (isSearch) {
                        handleSearch();
                    } else {
                        getActLicenseDetailList();
                    }
                }
            });
        }
   };

   const handleModalAxios: Function = async (config: any): Promise<void> => {
        const option: Option = {
            url: config.url,
            data: config.data,
            method: 'POST'
        };
        setModalLoading(true);
        const result: Result = await axios(option);
        setModalLoading(false);
        if (result.data.code === 0) {
            message.success(config.success);
            handleCancel();
            config.callBack();
            getLicNum();
        } else {
            message.error(config.error);
        }
   };

   const handleCancel: Function = (): void => {
       setVisible(false);
       setLicenseMount(36);
   };
    return (<div className={ styles.authorList }>
        <div className={ styles.radioBox }>
            <Radio.Group onChange={ (e) => handleRadioChange(e.target.value) } value={ radioVal }>
                { radioShow[0] && <Radio value={ 0 }>临时License列表</Radio> }
                { radioShow[1] && <Radio value={ 1 }>正式License列表</Radio> }
            </Radio.Group>
        </div>
        { (radioShow[0] || radioShow[1]) && <>
            <div className={ styles.licenseNum }>
                已激活{ licenseLic.activat }个设备 ，可激活数量为{ licenseLic.awaitAct }，已过期数量为{ licenseLic.overdue }个
            </div>
            <div className={ styles.serach }>
                <Input value={ searchValue } onChange={ (e) => handleChange(e) } className={ styles.input } placeholder="请输入License" prefix={<SearchOutlined />} />
                <Button className={ styles.button } loading={ loading } disabled={ searchValue === '' } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
                { isSearch && <Button className={ styles.button } onClick={ () => handleSearchCancel() } type="primary">Cancel</Button> }
            </div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
                    loading={ tableLoading } 
                    className={ styles.table }
                    pagination={{
                        defaultCurrent: 1,
                        current,
                        showSizeChanger: true,
                        pageSize,
                        total
                    }}
                    rowKey='uid'
                    sticky={ true }
                    sortDirections={['ascend', 'descend', 'ascend']} 
                    dataSource={ tableData }>
                    <Column align='center' title="License" dataIndex="license" />
                    <Column align='center' title="设备标识"  dataIndex="uniqueId" />
                    <Column align='center' title="IMEI" dataIndex="imei" />
                    <Column align='center' showSorterTooltip={ false } sorter={ true } title="续费次数" dataIndex="renewalNum" />
                    <Column align='center' showSorterTooltip={ false } sorter={ true } title="解绑次数" dataIndex="abolishNum" />
                    <Column align='center' showSorterTooltip={ false } sorter={ true } defaultSortOrder='descend' title="激活时间" dataIndex="actTs" />
                    <Column align='center' showSorterTooltip={ false } sorter={ true } title="过期时间" dataIndex="expirTs" />
                    <Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
                        <div className={ styles.operationBox }>
                            { findPermissions(11237) && <p className={ [styles.operation, styles.delete].join(' ') } onClick={ () => handleOperation(record, 'unbund') }>解绑</p> }
                            { findPermissions(11236) && <p className={ styles.operation } onClick={ () => handleOperation(record, 'renewal') }>续期</p> }
                        </div>
                    ) } />
            </Table>
        </> }

        { (!radioShow[0] && !radioShow[1]) && <div className={ styles.noData }>
            <img src={ require('../../../../../../../../../assets/images/chakan.png').default } alt="" />
            <p>暂无数据</p>
        </div> }

        <Modal centered title={ modalTitle } getContainer={ false } confirmLoading={ modalLoading } visible={ visible } onOk={ () => handleOk() } onCancel={ () => handleCancel() }>
            { modalType === 'unbund' && <p>解绑设备后，该设备可以再绑定其他的License！</p> }
            { modalType === 'renewal' && <div>
                <p>确定为License：{ record.license }续期吗？</p>
                <div className={ styles.line }>
                    <span className={ styles.span }>续期时长：</span>
                    <InputNumber className={ styles.inputNumber } min={ 1 } value={ licenseMount } onChange={ (value) => setLicenseMount(value) } />
                </div>
            </div> }
        </Modal>
    </div>);
};

export default AuthorList;