import { FC, ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../api/permiss';
import { minutes, getTimeFormate } from '../../../../../../api/index';
import { EditOutlined } from '@ant-design/icons';
import { Switch, Button, Table, Modal, InputNumber, Input, message } from 'antd';
import { Option } from '../../../../../../axios/axios-types';
import axios from '../../../../../../axios/axios';
import styles from './billing.less';
import { AxiosResponse } from 'axios';
const { Column } = Table;

interface Data {
	code: number;
	data: { list: Array<TableData> };
};

interface Result {
	data: Data;
};

interface TableData {
    stopBase: number;
    startBase: number;
    modules: number;
};

interface Strategy {
    arCat: number,
    tableData: Array<TableData>,
    modalTitle: string
};

interface MinCharge {
    offon: boolean;
    amount?: number;
    period: string;
    expireTs: number;
};

interface ExpireTs extends MinCharge {
    expireTs: number;
};

const Billing: FC = (): ReactElement => {
    const location: any = useLocation();
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
    const [addTableData, setAddTableData]: [Array<TableData>, Function] = useState([]);
    const [modalTitle, setModalTitle]: [string, Function] = useState('');
    const [minter, setMinter]: [number, Function] = useState(0);
    const [index, setIndex]: [number, Function] = useState(0);
    const [arCat, setArCat]: [number, Function] = useState(0);
    const [isModalVisible, setIsModalVisible]: [boolean, Function] = useState(false);
    const [minterVisible, setMinterVisible]: [boolean, Function] = useState(false);
    const [effect, setEffect]: [boolean, Function] = useState(false);
    const [monthlyVisible, setMonthlyVisible]: [boolean, Function] = useState(false);
    const [monthlyOffon, setMonthlyOffon]: [boolean, Function] = useState(false);
    const [monthlyNum, setMonthlyNum]: [number, Function] = useState(1);
    const [minimumVisible, setMinimumVisible]: [boolean, Function] = useState(false);
    const [isChange, setIsChange]: [boolean, Function] = useState(false);
    const [minimumOffon, setMinimumOffon]: [boolean, Function] = useState(false);
    const [minimumNum, setMinimumNum]: [number, Function] = useState(1);
    const [minimumAmount, setMinimumAmount]: [number, Function] = useState(1);
    const [minCharge, setMinCharge]: [MinCharge, Function] = useState({
        offon: false,
        amount: 0,
        period: '',
        expireTs: 0
    });
    const [expireTs, setExpireTs]: [ExpireTs, Function] = useState({
        offon: false,
        expireTs: 0,
        period: ''
    });
    const [data, setData]: [any, Function] = useState({});
    const [strategyList, setStrategyList]: [Array<Strategy>, Function] = useState([
        {
            arCat: 1,
            tableData: [],
            modalTitle: 'RTC阶梯优惠'
        },
        {
            arCat: 3,
            tableData: [],
            modalTitle: '云端录制阶梯优惠'
        },
        {
            arCat: 6,
            tableData: [],
            modalTitle: '旁路推流阶梯优惠'
        },
        {
            arCat: 2,
            tableData: [],
            modalTitle: 'RTM阶梯优惠'
        },
    ]);

    useEffect(() => {
        const getDevpFinInfo: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/developers/getDevpFinInfo',
                data: { uid: location.state.uid },
                method: 'POST'
            };
            const result: AxiosResponse = await axios(option);
            if (result.data.code === 0) {
                setExpireTs({
                    offon: false,
                    expireTs: 0,
                    period: '',
                });
                setMinCharge({
                    offon: false,
                    amount: 0,
                    period: '',
                    expireTs: 0
                });
                if (result.data.data.payType === 1) {
                    const target = JSON.parse(JSON.stringify(minCharge));
                    target.offon = true;
                    target.amount = result.data.data.minCharge / 1000;
                    target.period = getTimeFormate(result.data.data.expireTs, 'yyyy-MM');
                    target.expireTs = result.data.data.expireTs;
                    setMinCharge(target);
                } else if (result.data.data.payType === 2) {
                    const target = JSON.parse(JSON.stringify(expireTs));
                    target.offon = true;
                    target.expireTs = result.data.data.expireTs;
                    target.period = getTimeFormate(result.data.data.expireTs, 'yyyy-MM');
                    setExpireTs(target);
                }
            }
            setData(result.data.data);
        };
        const queryLeftCash: Function = (): void => {
            setTableLoading(true);
            const promiseAll: Array<Promise<AxiosResponse>> = [];
            strategyList.forEach((item: Strategy): void => {
                const option: Option = {
                    url: '/developers/getStepBenefit',
                    data: { uid: location.state.uid, arCat: item.arCat },
                    method: 'POST'
                };
                promiseAll.push(axios(option));
            });
            Promise.all(promiseAll).then((value: Array<AxiosResponse>): void => {
                const table = JSON.parse(JSON.stringify(strategyList));
                value.forEach((result: AxiosResponse): void => {
                    if (result.data.code === 0) {
                        const arCat = JSON.parse(result.config.data).arCat;
                        const index = table.findIndex((item: Strategy): boolean => item.arCat === arCat);
                        table[index].tableData = result.data.data.list;
                    };
                })
                setStrategyList(table);
            })
            setTableLoading(false);
        };
        queryLeftCash();
        getDevpFinInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect]);

    const handleEmidClick: Function = (title: string, type: number, tableData: TableData): void => {
        if (expireTs.offon) {
            message.error("该用户是包月用户");
            return;
        }
        const table = JSON.parse(JSON.stringify(tableData));
        if (table.length >= 4) {
            table[3].stopBase = '--';
        };
        setArCat(type);
        setAddTableData(table);
        setModalTitle(title);
        setIsModalVisible(true);
    };

    const handleAddClick: Function = (): void => {
        const table = JSON.parse(JSON.stringify(addTableData));
        if (table.length === 0) {
            table.push({
                startBase: 0,
                stopBase: arCat === 2? 100000 : 300000,
                modules: 100,
                change: false
            });
        } else {
            if (table[table.length - 1].modules === undefined) {
                table[table.length - 1].modules = table[table.length - 2].modules;
            }
            table.push({
                startBase: table[table.length - 1].stopBase,
                stopBase: table.length < 3? table[table.length - 1].stopBase + (arCat === 2? 50000 : 300000) : "--",
                modules: table[table.length - 1].modules - 1,
                change: false
            });
        }
        setAddTableData(table);
    };

    const handleInClick: Function = (index: number): void => {
        const table = JSON.parse(JSON.stringify(addTableData));
        table.splice(index);
        setAddTableData(table);
    };

    const handleChangeMinter: Function = (index: number, minter: number | string): void => {
        if (addTableData.length - 1 === index && minter !== "--") {
            setMinter(minter);
            setIndex(index);
            setMinterVisible(true);
        }
    };

    const handleModalCancel: Function = (): void => {
        setAddTableData([]);
        setIsModalVisible(false);
    };

    const handleModalOk: Function = async (): Promise<void> => {
        if (addTableData.length >= 4 && isNaN(addTableData[3].stopBase)) {
            addTableData[3].stopBase = 9223372036854775000;
        };
        const option: Option = {
            url: '/developers/insertStepBenefit',
            data: {
                uid: location.state.uid,
                stepBenefitInfo: addTableData,
                arCat
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
          message.success('优惠策略添加成功');
          handleModalCancel();
          setEffect(!effect);
        } else {
            statusJudge(result.data.code);
        }
    };

    const statusJudge: Function = (code: number): void => {
        var msg = '';
        if (code === 1044) {
            msg = '用户为包月用户';
        } else if (code === 1045) {
            msg = '用户为最低消费用户';
        } else if (code === 1046) {
            msg = '用户有未用完的套餐包';
        } else if (code === 1047) {
            msg = '用户添加了阶梯定价';
        }
        message.error(msg);
    };

    const handleInChange: Function = (index: number, value: number | null): void => {
        const table = JSON.parse(JSON.stringify(addTableData));
        if (typeof value === 'number') {
            table[index].modules = value;
        }
        setAddTableData(table);
    };

    const handleMinterOk: Function = (): void => {
        if (index === 0) {
            if (minter < 10000) {
                message.error('分钟数不能小于10000分钟');
                return
            } else {
                message.success('修改分钟数成功');
            }
        } else {
            if (minter > addTableData[index].startBase) {
                message.success('修改分钟数成功');
            } else {
                message.error(`分钟数不能小于 ${addTableData[index].startBase}`);
                return
            }
        }
        const table = JSON.parse(JSON.stringify(addTableData));
        table[index].stopBase = minter;
        setAddTableData(table);
        setMinterVisible(false);
    };

    const compytedStrategyList: Function = (): boolean => {
        return strategyList.some((item: Strategy): boolean => {
            return item.tableData.length > 0;
        });
    };

    const handleMonthlyClick: Function = (checked: boolean): void => {
        if (checked) {
            if (data.leftCash < 0) {
                message.error("用户欠费，不能执行该操作");
            } else if (data.payType === 1) {
                message.error("用户为最低消费用户");
            } else if (compytedStrategyList()) {
                message.error("用户添加了阶梯定价");
            } else {
                setMonthlyVisible(true);
            }
        } else {
            setMonthlyVisible(true);
        }
        setMonthlyOffon(checked);
        setIsChange(false);
    };

    const handleMinimumClick: Function = (checked: boolean): void => {
        if (checked) {
            if (data.leftCash < 0) {
                message.error("用户欠费，不能执行该操作");
            } else if (data.payType === 2) {
                message.error("用户为包月用户");
            } else {
                setMinimumVisible(true);
            }
        } else {
            setMinimumVisible(true);
        }
        setMinimumOffon(checked);
        setIsChange(false);
        setMinimumAmount('');
    };
    
    const handleMonthlyOnCancel: Function = (): void => {
        setMonthlyNum(1);
        setMonthlyVisible(false);
    };

    const handleMinimumOnCancel: Function = (): void => {
        setMinimumNum(1);
        setMinimumVisible(false);
    };

    const computedMonthlyNum: Function = (time: string, num: string): string => {
        const myDate: Date = new Date();
        var year: number = time? +time.split("-")[0] : myDate.getFullYear();
        var month: number | string = time? +time.split("-")[1] : myDate.getMonth() + 1;
        for (let i = 1; i <= +num; i++) {
            (month as number)++;
            if (month > 12) {
                year++;
                month = 1;
            }
            month < 10 && (month = "0" + month);
        }
        return year + "/" + month;
    };

    const handleMonthlyOk: Function = async (): Promise<void> => {
        const url: string = isChange? 'updateDevpFinInfo' : 'updateDevpPayType';
        const date: number = isChange? 
            parseInt((expireTs.expireTs + (3600 * 1000 * 24 * 30 * +monthlyNum) / 1000).toString()) : 
            parseInt(((new Date().getTime() + 3600 * 1000 * 24 * 30 * +monthlyNum) / 1000).toString())
        const option: Option = {
            url: `/developers/${url}`,
            data: {
                uid: location.state.uid,
                expireTs: date,
                minCharge: 0,
                payType: 2,
                isOn: expireTs.offon? monthlyOffon? 1 : 0 : 1
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            if (monthlyOffon) {
                message.success("修改包月功能成功");
            } else if (expireTs.offon) {
                message.success("关闭包月功能成功");
            } else if (!expireTs.offon) {
                message.success("开启包月功能成功");
            }
            setEffect(!effect);
            handleMonthlyOnCancel();
        } else {
            statusJudge(result.data.code);
        }
    }; 

    const handleMinimumlyOk: Function = async (): Promise<void> => {
        if (!minCharge.offon) {
            if (!minimumAmount) {
                message.error("请输入最低消费金额");
                return;
            } else if (+minimumAmount <= 0) {
                message.error("金额不能小于0");
                return;
            } else if (minimumAmount.toString().indexOf(".") !== -1) {
                message.error("金额不能是小数");
                return;
            } else if (+minimumAmount > 1000000) {
                message.error("金额不能超过100W");
                return;
            }
        };

        const url: string = isChange? 'updateDevpFinInfo' : 'updateDevpPayType';
        const expireTs: number = isChange? 
            parseInt((minCharge.expireTs + (3600 * 1000 * 24 * 30 * +minimumNum) / 1000).toString()) : 
            parseInt(((new Date().getTime() + 3600 * 1000 * 24 * 30 * +minimumNum) / 1000).toString())
        const option: Option = {
            url: `/developers/${url}`,
            data: {
                uid: location.state.uid,
                expireTs,
                minCharge: minCharge.offon? minimumOffon? +minimumAmount * 1000 : 0 : +minimumAmount * 1000,
                payType: 1,
                isOn: minCharge.offon? minimumOffon? 1 : 0 : 1
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            if (minimumOffon) {
                message.success("修改最低消费金额成功");
            } else if (minCharge.offon) {
                message.success("关闭最低消费功能成功");
            } else if (!minCharge.offon) {
                message.success("开启最低消费功能成功");
            }
            setEffect(!effect);
            handleMinimumOnCancel();
        } else {
            statusJudge(result.data.code);
        }
    }; 

    const handleBtnClick: Function = (): void => {
        setMonthlyVisible(true);
        setMonthlyOffon(true);
        setIsChange(true);
    };


    const handleBtnMinimumClick: Function = (): void => {
        setMinimumAmount(minCharge.amount);
        setMinimumVisible(true);
        setMinimumOffon(true);
        setIsChange(true);
    };

    return (
        <div className={ styles.billing }>
            <div className={ styles.line }>
                <span className={ styles.span }>包月功能</span>
                <Switch checkedChildren="open" disabled={ !findPermissions(11423) } unCheckedChildren="close" checked={ expireTs.offon } onClick={ (checked) => handleMonthlyClick(checked) } />
                { (expireTs.offon && findPermissions(11423)) && <p>有效期至：{ expireTs.period }</p> }
                { (expireTs.offon && findPermissions(11423)) && <Button onClick={ () => handleBtnClick() } className={ styles.btn } type="primary" shape="circle" icon={ <EditOutlined /> } /> }
            </div>
            <div className={ styles.line }>
                <span className={ styles.span }>最低消费</span>
                <Switch checkedChildren="open" disabled={ !findPermissions(11424) } unCheckedChildren="close" checked={ minCharge.offon } onClick={ (checked) => handleMinimumClick(checked) } />
                { (minCharge.offon && findPermissions(11424)) && <p>最低消费：{ minCharge.amount }</p> }
                { (minCharge.offon && findPermissions(11424)) && <p>有效期至：{ minCharge.period }</p> }
                { (minCharge.offon && findPermissions(11424)) && <Button onClick={ () => handleBtnMinimumClick() } className={ styles.btn } type="primary" shape="circle" icon={ <EditOutlined /> } /> }
            </div>

            {
                strategyList.map((item: Strategy, index: number): ReactElement => {
                    return (<div key={ index }>
                        <div className={ styles.titleLine }>
                            <p className={ styles.title }>{ item.modalTitle }</p>
                            { findPermissions(11422) && <Button className={ styles.modify } type="link" 
                            onClick={ () => handleEmidClick(item.modalTitle, item.arCat, item.tableData) }>
                            修改</Button> }
                        </div>
                        <Table
                            loading={ tableLoading }
                            pagination={ false } 
                            className={ styles.table }
                            rowKey='priceId'
                            sticky={ true }
                            dataSource={ item.tableData }>
                            <Column align='center' title={ item.arCat === 2? '日活>=' : '分钟数 >=' } render={ (operation: any, record: TableData): ReactElement => (
                                <p>{ minutes(record.startBase) }</p>
                            ) } />
                            <Column align='center' title={ item.arCat === 2? '日活<' : '分钟数 <' } render={ (operation: any, record: TableData): ReactElement => (
                                <p>{ record.stopBase > 9000000000000000000? '--' : minutes(record.stopBase) }</p>
                            ) } />
                            <Column align='center' title="折扣系数 %" render={ (operation: any, record: TableData): ReactElement => (
                                <p>{ record.modules + '%' }</p>
                            ) } />
                        </Table>
                    </div>)
                })
            }

            
            <Modal getContainer={ false } 
            title={ modalTitle } 
            width={ 1000 } 
            centered visible={ isModalVisible } 
            onCancel={ () => handleModalCancel() }
            onOk={ () => handleModalOk() }>
                { addTableData.length > 0 && <Table
                    className={ styles.table }
                    rowKey='startBase'
                    sticky={ true }
                    pagination={ false }
                    dataSource={ addTableData }>
                    <Column align='center' title={ arCat === 2? '日活>=' : '分钟数 >=' }  dataIndex="startBase" />
                    <Column align='center' title={ arCat === 2? '日活<' : '分钟数 <' } render={ (operation: any, record: TableData, index: number): ReactElement => (
                        <p onClick={ () => handleChangeMinter(index, record.stopBase) }>{ record.stopBase }</p>
				    ) } />
                    <Column align='center' title="折扣系数 %" render={ (operation: any, record: TableData, index: number): ReactElement => (
					    <div>
                            { (index > 0 && index === addTableData.length - 1) || <p>{ record.modules }</p> }
                            { index > 0 && index === addTableData.length - 1 && <InputNumber onChange={ (value) => handleInChange(index, value) } min={ 1 } max={ addTableData[index - 1].modules - 1 } defaultValue={ record.modules } /> }
                        </div>
				    ) } />
                    <Column align='center' width={ 50 } render={ (operation: any, record: TableData, index: number): ReactElement => (
                        <div>
					        { index === addTableData.length - 1 && <img onClick={ () => handleInClick(index) } src={ require('../../../../../../assets/images/detele.png').default } alt="" /> }
                        </div>
                    ) } />
                </Table> }
                { addTableData.length <= 0 && <p className={ styles.tip }>暂无优惠策略 , 点击下方添加策略</p> }
                { addTableData.length < 4 && (addTableData.length !== 0? addTableData[addTableData.length - 1].modules !== 50 : true) &&
                <div className={ styles.addBtn }>
                    <img onClick={ () => handleAddClick() } src={ require('../../../../../../assets/images/add.png').default } alt="" />
                </div> }
            </Modal>

            <Modal title='修改参数' centered visible={ minterVisible } onOk={ () => handleMinterOk() } onCancel={ () => setMinterVisible(false) }>
                <Input placeholder="请输入分钟数" value={ minter } onChange={ (e) => setMinter(+e.target.value) } />
            </Modal>


            <Modal title='包月功能' getContainer={ false } centered visible={ monthlyVisible } onOk={ () => handleMonthlyOk() } onCancel={ () => handleMonthlyOnCancel() }>
                { monthlyOffon || <p className={ styles.tip }>是否关闭用户的月订阅功能</p> }
                { monthlyOffon && <div className={ styles.monthlyBox }>
                    <div className={ styles.line }>
                        <span className={ styles.span }>包月时长：</span>
                        <InputNumber min={ 1 } value={ monthlyNum } onChange={ (value) => setMonthlyNum(value) } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>有效期至：</span>
                        <p>{ computedMonthlyNum(expireTs.period, monthlyNum) }</p>
                    </div>
                </div> }
            </Modal>

            <Modal title='最低消费' width={ 600 } getContainer={ false } centered visible={ minimumVisible } onOk={ () => handleMinimumlyOk() } onCancel={ () => handleMinimumOnCancel() }>
                { minimumOffon || <p className={ styles.tip }>是否关闭用户的最低消费</p> }
                { minimumOffon && <div className={ styles.minimumBox }>
                    <div className={ styles.line }>
                        <span className={ styles.span }>最低消费：</span>
                        <Input className={ styles.input } value={ minimumAmount } onChange={ (e) => setMinimumAmount(e.target.value) } placeholder="请输入最低消费金额" />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>开通时长：</span>
                        <InputNumber min={ 1 } value={ minimumNum } onChange={ (value) => setMinimumNum(value) } />
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>有效期至：</span>
                        <p>{ computedMonthlyNum(minCharge.period, minimumNum) }</p>
                    </div>
                </div> }
            </Modal>
        </div>
    );
};

export default Billing;