import { FC, ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../api/permiss';
import { Input, Upload, Button, message } from 'antd';
import { uploadUrl } from '../../../../../../axios/baseUrl';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Option } from '../../../../../../axios/axios-types';
import { connect } from 'react-redux';
import { TOKEN } from '../../../../../../store/reducer';
import { moneyComma } from '../../../../../../api/index';
import axios from '../../../../../../axios/axios';
import styles from './topup.less';

interface Data {
	code: number;
	data: { lcash: number };
};

interface Result {
	data: Data;
};

interface IProps {
	token: TOKEN;
};

const Topup: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();
    const [tup, setTup]: [string, Function] = useState('');
    const [signature, setSignature]: [string, Function] = useState('');
    const [amount, setAmount]: [number, Function] = useState(0);
    const [lcash, setLcash]: [number, Function] = useState(0);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [sigLoading, setSigLoading]: [boolean, Function] = useState(false);
    const [effect, setEffect]: [boolean, Function] = useState(false);

    useEffect(() => {
        const queryLeftCash: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/developers/queryLeftCash',
                data: { uid: location.state.uid },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                setLcash(result.data.data.lcash);
            }
          }
          queryLeftCash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect])

    const handleChange: Function = (info: any, type: string): void => {
        if (info.file.status === 'uploading') {
            if (type === 'setTup') {
                setLoading(true);
            } else if (type === 'setSignature') {
                setSigLoading(true);
            }
            return
        };
        if (info.file.status === 'done') {
            if (info.file.response.code === 0) {
                message.success('图片上传成功');
                if (type === 'setTup') {
                    setTup(info.file.response.data.url);
                } else if (type === 'setSignature') {
                    setSignature(info.file.response.data.url);
                }
            } else {
                message.error('图片上传失败');
            }
            if (type === 'setTup') {
                setLoading(false);
            } else if (type === 'setSignature') {
                setSigLoading(false);
            }
        }
    };

    const isDisabled: Function = (list: Array<any>): boolean => {
        return !list.every((item: any) => item);
    };

    const handleBtnClick: Function = async (): Promise<void> => {
        if (amount > 0 && !isNaN(amount)) {
            const option: Option = {
                url: '/developers/recharge',
                data: {
                    amount: amount * 1000,
                    detail: tup,
                    uid: location.state.uid
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                message.success('充值成功');
                setAmount(0);
                setTup('');
                setEffect(!effect);
            } else {
                message.error('充值失败');
            }
        }
    };

    const handleClearClick: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/account2Zero',
            data: {
                amount: lcash,
                detail: signature,
                uid: location.state.uid
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success('余额抹平成功');
            setSignature('');
            setEffect(!effect);
        } else {
            message.error('余额抹平失败');
        }
    };

    return (
        <div className={ styles.topup }>
            <p className={ styles.title }>充值</p>
            <div className={ styles.line }>
                <span className={ styles.span }>账户余额：</span>
                <p className={ styles.lcash }>{ moneyComma(lcash) }</p>
            </div>
            <div className={ styles.line }>
                <span className={ styles.span }>充值金额：</span>
                <Input value={ amount || '' } className={ styles.input } onChange={ (e) => setAmount(e.target.value) } placeholder="请输入金额" />
            </div>
            <div className={ [styles.line, styles.baseline].join(' ') }>
                <span className={ styles.span }>转账截图：</span>
                <Upload
                    className={ [styles.upload, 'avatar-uploader'].join(' ') }
                    name="file"
                    listType="picture-card"
                    showUploadList={ false }
                    action={ uploadUrl + 'anyrtc' }
                    headers={{
                        'Artc-Token': props.token
                    }}
                    onChange={ (info) => handleChange(info, 'setTup') }
                >
                    { tup ? 
                        <img className={ styles.uploadImg } src={ tup } alt="avatar" style={{ width: '100%' }} /> : 
                        <div>
                            { loading ? <LoadingOutlined /> : <PlusOutlined /> }
                            <div>Upload</div>
                        </div>
                    }
                </Upload>
            </div>
            <div className={ styles.line }>
                <span className={ styles.span }></span>
                <Button type="primary" disabled={ isDisabled([tup, amount]) || !findPermissions(11413) } onClick={ () => handleBtnClick() }>充值</Button>
            </div>

            
            <p className={ [styles.title, styles.bottom].join(' ') }>抹平余额</p>
            <div className={ styles.line }>
                <span className={ styles.span }>账户余额：</span>
                <p className={ styles.lcash }>{ moneyComma(lcash) }</p>
            </div>
            <div className={ [styles.line, styles.baseline].join(' ') }>
                <span className={ styles.span }>领导签字截图：</span>
                <Upload
                    className={ [styles.upload, 'avatar-uploader'].join(' ') }
                    name="file"
                    listType="picture-card"
                    showUploadList={ false }
                    action={ uploadUrl + 'anyrtc' }
                    headers={{
                        'Artc-Token': props.token
                    }}
                    onChange={ (info) => handleChange(info, 'setSignature') }
                >
                    { signature ? 
                        <img className={ styles.uploadImg } src={ signature } alt="avatar" style={{ width: '100%' }} /> : 
                        <div>
                            { sigLoading ? <LoadingOutlined /> : <PlusOutlined /> }
                            <div>Upload</div>
                        </div>
                    }
                </Upload>
            </div>
            <div className={ styles.line }>
                <span className={ styles.span }></span>
                <Button type="primary" disabled={ isDisabled([signature]) || !findPermissions(11412) } onClick={ () => handleClearClick() }>抹平余额</Button>
            </div>
        </div>
    );
};

type MapStateToProps = (state: object) => object;

const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps)(Topup);