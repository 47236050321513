import { FC, ReactElement, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Radio, Button, Modal, message } from 'antd';
import { Option } from '../../../../../../../axios/axios-types';
import axios from '../../../../../../../axios/axios';
import styles from './license.less';

interface Result {
	data: {
        code: number;
	    data: any;
    };
};

const License: FC = (): ReactElement => {
    const history: any = useHistory();
    const location: any = useLocation();
    
    const [radioVal, setRadioVal]: [number, Function] = useState(1);
    const [modalVisible, setModalVisible]: [boolean, Function] = useState(false);
    const [modalContent, setModalContent]: [string, Function] = useState('');
    const [effect, setEffect]: [boolean, Function] = useState(false);

    useEffect((): void => {
        const getLicenseType: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/v2/lic/getLicenseType',
                data: {
                    appId: location.state.appid
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0 && result.data.data) {
                if (result.data.data.licType === 0) {
                    history.push('/content/user/detail/appInformation/projectEditor/single', location.state);
                } else if (result.data.data.licType === 1) {
                    history.push('/content/user/detail/appInformation/projectEditor/mony', location.state);
                }
            }
        };
        getLicenseType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect]);

    const handleChange: Function = (value: number): void => {
        setRadioVal(value);
    };

    const handleClick: Function = (): void => {
        setModalVisible(true);
        setModalContent(`确定要为用户开通使用一对${radioVal === 1? '多' : '一'}授权模式？`);
    };

    const handleOk: Function = async (): Promise<void> => {
        const option : Option = {
            url: '/v2/lic/updateLicenseType',
            data: {
                appId: location.state.appid,
                licType: radioVal
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success('操作成功');
            setEffect(!effect);
        } else {
            message.error('操作失败');
        };
    };
    return (<div className={ styles.license }>
        <p className={ styles.title }>license</p>
        <div className={ styles.content }>
            <Radio.Group onChange={ (e) => handleChange(e.target.value) } value={ radioVal }>
                <Radio className={ styles.radio } value={ 1 }>一对多授权模式</Radio>
                <Radio className={ styles.radio } value={ 0 }>一对一授权模式</Radio>
            </Radio.Group>
            <Button type="primary" onClick={ () => handleClick() }>选择模式</Button>
        </div>
        <Modal centered title='提示' visible={ modalVisible } onOk={ () => handleOk() } onCancel={ () => setModalVisible(false) }>
            <p>{ modalContent }</p>
        </Modal>
    </div>);
};

export default License;