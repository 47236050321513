import { FC, ReactElement, useState, useEffect } from 'react';
import { Menu } from 'antd';
import { UserOutlined, createFromIconfontCN } from '@ant-design/icons';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { MenuInfo } from 'rc-menu/lib/interface';
import { scriptUrl } from '../../../../axios/baseUrl';
import { findPermissions } from '../../../../api/permiss';
import BasicInfor from './basicInfor/basicInfor';
import Server from './server/server'; 
import Added from './added/added'; 
import AddedDetails from './addedDetails/addedDetails'; 
import Renewal from './renewal/renewal'; 
import styles from './detail.less';

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

const list: string[] = ['basicInfor', 'server', 'added', 'addedDetails', 'renewal'];

const Detail: FC = (): ReactElement => {
    const history: any = useHistory();
    const location: any = useLocation();
    const [selectedKeys, setSelectedKeys]: [string, Function] = useState('basicInfor');

    const handleClick: Function = (e: MenuInfo): void => {
        setSelectedKeys(e.key);
        history.push(`/content/private/detail/${e.key}`, Object.assign(location.state, {appid: ''}));
    };

    useEffect((): void => {
        const path: Array<string> = location.pathname.split('/');
        list.forEach((item: string): void => {
            path.includes(item) && setSelectedKeys(item);
        });
    }, [location.pathname]);

    return (
        <div className={ styles.userDetail }>
            <Menu className={ styles.menu } onClick={ (e) => handleClick(e) } selectedKeys={ [selectedKeys] } mode="horizontal">
                <Menu.Item key="basicInfor" icon={ <UserOutlined /> }>
                基本信息
                </Menu.Item>
                { findPermissions(location.state.perm.fwq) && <Menu.Item key="server" icon={ <IconFont type="icon-fuwuqi1" /> }>
                服务器信息
                </Menu.Item> }
                { findPermissions(location.state.perm.zzfw) && <Menu.Item key="added" icon={ <IconFont type="icon-wode-zengzhichanpin" /> }>
                增值服务
                </Menu.Item> }
                { findPermissions(location.state.perm.zzxq) && <Menu.Item key="addedDetails" icon={ <IconFont type="icon-wj-mxjl" /> }>
                增值详情
                </Menu.Item> }
                { findPermissions(location.state.perm.sqxq) && <Menu.Item key="renewal" icon={ <IconFont type="icon-hekriconyuyue" /> }>
                申请续期
                </Menu.Item> }
            </Menu>
            <div className={ styles.routeContent }>
                <Route path='/content/private/detail/basicInfor' component={ BasicInfor } />
                { findPermissions(location.state.perm.fwq) && <Route path='/content/private/detail/server' component={ Server } /> }
                { findPermissions(location.state.perm.zzfw) && <Route path='/content/private/detail/added' component={ Added } /> }
                { findPermissions(location.state.perm.zzxq) && <Route path='/content/private/detail/addedDetails' component={ AddedDetails } /> }
                { findPermissions(location.state.perm.sqxq) && <Route path='/content/private/detail/renewal' component={ Renewal } /> }
            </div>
        </div>
    );
};


export default Detail;