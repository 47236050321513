import { FC, ReactElement, useState, useEffect } from 'react';
import { Button, Table, Modal, InputNumber, Input, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../../../../api/permiss';
import { getTimestampToTime } from '../../../../../../../../../api';
import { Option } from '../../../../../../../../../axios/axios-types';
import axios from '../../../../../../../../../axios/axios';
import styles from './authorSet.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const AuthorSet: FC = (): ReactElement => {
    const location: any = useLocation();
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
    const [testTableLoading, setTestTableLoading]: [boolean, Function] = useState(false);
	const [testTableData, setTestTableData]: [Array<any>, Function] = useState([]);
	const [modalLoading, setModalLoading]: [boolean, Function] = useState(false);
	const [modalTitle, setModalTitle]: [string, Function] = useState('');
	const [isModalModify, setIsModalModify]: [boolean, Function] = useState(false);
	const [visible, setVisible]: [boolean, Function] = useState(false);
	const [addType, setAddType]: [string, Function] = useState('');
	const [licenseMount, setLicenseMount]: [number, Function] = useState(36);
	const [licenseNum, setLicenseNum]: [string, Function] = useState('');

    
    useEffect((): void => {
        getLicenseList(0);
        getLicenseList(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state.appid, location.state.uid]);

    const getLicenseList: Function = async (licType: number): Promise<void> => {
        const option: Option = {
            url: '/lic/getLicenseList',
            data: {
                appId: location.state.appid,
                devpId: location.state.uid,
                licType,
            },
            method: 'POST'
        };
        licType? setTableLoading(true) : setTestTableLoading(true);
        const result: Result = await axios(option);
        licType? setTableLoading(false) : setTestTableLoading(false);
        if (result.data.code === 0) {
            if (Array.isArray(result.data.data)) {
                result.data.data.forEach((item: any): void => {
                    item.period = item.period + "个月";
                    item.licTs = getTimestampToTime(item.licTs);
                });
                licType? setTableData(result.data.data) : setTestTableData(result.data.data);
            } else {
                licType? setTableData([]) : setTestTableData([]);
            }
        }
    };

    const handleAddLicense: Function = (type: string): void => {
        if (type === 'test') {
            setModalTitle('开通测试License');
        } else if (type === 'formal') {
            setModalTitle('开通正式License');
        }
        setIsModalModify(true);
        setAddType(type);
    };

    const handleOk: Function = async (): Promise<void> => {
        if (addType === 'test') {
            const option: Option = {
                url: '/lic/addTestLicense',
                data: { 
                    appId: location.state.appid,
                    devpId: location.state.uid,
                },
                method: 'POST'
            };
            setModalLoading(true);
            const result: Result = await axios(option);
            setModalLoading(false);
            if (result === undefined) {
                message.error("网络或服务错误，测试License添加失败");
            } else if (result.data.code === 0) {
                handleModalCancel();
                getLicenseList(0);
                message.success("测试License添加成功");
            } else {
                message.error("测试License添加失败, 请重新添加");
            }
        } else if (addType === 'formal') {
            check() && setVisible(true);
        }
    };

    const check: Function = (): boolean => {
        const reg: RegExp = /\s+/g;
        let bool: boolean = false;
        if (licenseNum.match(reg)) {
            message.error("终端数量不能有空格");
        } else if (licenseNum === "") {
            message.error("请输入终端数量");
        } else if (isNaN(+licenseNum)) {
            message.error("请输入数字");
        } else if (licenseNum.indexOf(".") !== -1) {
            message.error("终端数量不能为小数");
        } else if (+licenseNum < 1) {
            message.error("终端数量不能小于1");
        } else if (licenseNum.split("")[0] === '0') {
            message.error("终端数量不能以0开头");
        } else if (+licenseNum > 500000) {
            message.error("终端数量不能大于50万");
        } else if (licenseMount < 1) {
            message.error("有效期不能小于1");
        } else if (!licenseMount) {
            message.error("有效期错误");
        } else {
            bool = true;
        }
        return bool;
    };

    const handleOkModify: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/lic/addOfficalLicense',
            data: { 
                appId: location.state.appid,
                devpId: location.state.uid,
                num: +licenseNum,
                period: licenseMount
            },
            method: 'POST'
        };
        setModalLoading(true);
        const result: Result = await axios(option);
        setModalLoading(false);
        if (result === undefined) {
            message.error("网络或服务错误，正式License添加失败");
        } else if (result.data.code === 0) {
            setVisible(false);
            handleModalCancel();
            getLicenseList(1);
            message.success("正式License添加成功");
        } else {
            message.error("正式License添加失败, 请重新添加");
        }
    };

    const handleModalCancel: Function = (): void => {
        setIsModalModify(false);
        setLicenseMount(36)
        setLicenseNum('');
    };

    const handleSorter: Function = (a: any, b: any, type: string): boolean => {
        if (type === 'period') {
            return a[type].localeCompare(b[type]);
        } else {
            return a[type] > b[type];
        }
    };

    return (<div className={ styles.authorSet }>
        { (findPermissions(11231) && testTableData.length === 0) && <div className={ styles.addBox }><Button className={ styles.addBtn } onClick={ () => handleAddLicense('test') } type="primary">添加测试 License</Button></div> }
        { (findPermissions(11231) && testTableData.length !== 0) && <span className={ styles.span }>测试License</span> }
        { (findPermissions(11231) && testTableData.length !== 0) && <Table
            loading={ testTableLoading } 
            className={ styles.table }
            pagination={ false }
            rowKey={() => `{Math.floor(Math.random() * 123333)}`}
            sticky={ true }
            dataSource={ testTableData }>
            <Column align='center' title="创建日期" dataIndex="licTs" />
            <Column align='center' title="有效期"  dataIndex="period" />
            <Column align='center' title="License数量" dataIndex="licNum" />
            <Column align='center' title="激活数量" dataIndex="licActNum" />
		</Table> }

        { (findPermissions(11232) && tableData.length === 0) && <div className={ styles.addBox }><Button className={ styles.addBtn } onClick={ () => handleAddLicense('formal') } type="primary">添加正式 License</Button></div> }
        { (findPermissions(11232) && tableData.length !== 0) && <div className={ styles.formalAdd }>
            <span className={ styles.span }>正式License</span>
            { findPermissions(11235) && <Button onClick={ () => handleAddLicense('formal') } type="primary">Add License</Button> }
        </div> }
        { (findPermissions(11232) && tableData.length !== 0) && <Table
            loading={ tableLoading } 
            className={ styles.table }
            pagination={ false }
            rowKey={() => `{Math.floor(Math.random() * 123333)}`}
            sticky={ true }
            sortDirections={['ascend', 'descend', 'ascend']} 
            dataSource={ tableData }>
            <Column align='center' showSorterTooltip={ false } sorter={ (a, b) => handleSorter(a, b, 'licTs') } defaultSortOrder='descend' title="创建日期" dataIndex="licTs" />
            <Column align='center' showSorterTooltip={ false } sorter={ (a, b) => handleSorter(a, b, 'period') } title="有效期"  dataIndex="period" />
            <Column align='center' title="License数量" dataIndex="licNum" />
            <Column align='center' showSorterTooltip={ false } sorter={ (a, b) => handleSorter(a, b, 'licActNum') } title="激活数量" dataIndex="licActNum" />
		</Table> }

        <Modal centered title={ modalTitle } getContainer={ false } confirmLoading={ modalLoading } visible={ isModalModify } onOk={ () => handleOk() } onCancel={ () => handleModalCancel() }>
            { addType === 'test' && <p>确定开通测试License?</p> }
            { addType === 'formal' && <div>
                <div className={ styles.line }>
                    <span className={ styles.span }>终端数量：</span>
                    <Input placeholder="请输入终端数量" value={ licenseNum } onChange={ (e) => setLicenseNum(e.target.value) } />
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>有效期：</span>
                    <InputNumber className={ styles.inputNumber } min={ 1 } value={ licenseMount } onChange={ (value) => setLicenseMount(value) } />
                </div>
            </div> }
        </Modal>

        <Modal centered title={ modalTitle } confirmLoading={ modalLoading } visible={ visible } onOk={ () => handleOkModify() } onCancel={ () => setVisible(false) }>
            <p>License授权数量：{ licenseNum }个? 有效期：{ licenseMount }个月?</p>
        </Modal>
    </div>);
};

export default AuthorSet;