const brand: any[] = [
    {
      value: "all",
      label: "品牌"
    }
];

const aec: any[] = [
    {
        value: 99,
        label: "硬件Aec"
    },
    {
        value: 1,
        label: "支持"
    },
    {
        value: 0,
        label: "不支持"
    }
];

const opensles: any[] = [
    {
        value: 99,
        label: "Opensles"
    },
    {
        value: 1,
        label: "支持"
    },
    {
        value: 0,
        label: "不支持"
    }
];

const model: any[] = [
    {
        value: "all",
        label: "model"
    }
];

export {
    brand,
    aec,
    opensles,
    model
}