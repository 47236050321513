import { FC, ReactElement, useState, useEffect } from 'react';
import { Select, Input, Table, Button, Modal, message } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { SearchOutlined } from '@ant-design/icons';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './aecList.less';
const { Column } = Table;

namespace Antd {
    export const { Option } = Select;
};

interface Result {
	data:  {
        code: number;
        data: any;
    };
};


const AecList: FC = (): ReactElement => {
    const [modelList, setmModelList]: [any, Function] = useState([{
        value: "model",
        label: "model"
    }]);
    const [model, setModel]: [any, Function] = useState('model');
    const [value, setValue]: [string, Function] = useState('');
    const [modalValue, setModalValue]: [string, Function] = useState('');
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [visibleLoading, setVisibleLoading]: [boolean, Function] = useState(false);
    const [addVisibleLoading, setAddVisibleLoading]: [boolean, Function] = useState(false);
    const [visible, setVisible]: [boolean, Function] = useState(false);
    const [addVisible, setAddVisible]: [boolean, Function] = useState(false);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [any[], Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [record, setRecord]: [any, Function] = useState({});
	const [effect, setEffect]: [boolean, Function] = useState(false);

    useEffect((): void => {
		const getAecList: Function = async (): Promise<void> => {
			const option: Option = {
				url: '/device/getAecList',
				data: {
					pageNum: current,
					pageSize,
                    model: model === 'model'? 'all' : model,
				},
				method: 'POST'
			};
			setTableLoading(true);
			const result: Result = await axios(option);
            if (result.data.code === 0) {
                setTableData(result.data.data.list);
                setTotal(result.data.data.totalNumber);
            } else {
                message.error('数据获取失败');
            }
			setTableLoading(false);
		};
		getAecList();
	}, [pageSize, current, model, effect]);


    useEffect((): void => {
        const getAecModeList: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/device/getAecModeList',
                method: 'GET'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                if (Array.isArray(result.data.data)) {
                    const modelLis: any[] = [{
                        value: "model",
                        label: "model"
                    }];
                    result.data.data.forEach((item: any): void => {
                        modelLis.push({
                            value: item.model,
                            label: item.model
                        });
                    });
                    setmModelList(modelLis);
                    setModel('model');
                }
            }
        };
        getAecModeList();
    }, [effect]);

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleSearch: Function = async (): Promise<void> => {
        if (value === '') {
            message.error("请输入关键字");
        } else {
            const option: Option = {
                url: '/device/searchAecList',
                data: {
                    pageNum: current,
					pageSize,
                    searchParam: value
                },
                method: 'POST'
            };
            setLoading(true);
            const result: Result = await axios(option);
            setLoading(false);
            if (result.data.code === 0) {
                setTableData(result.data.data.list);
                setTotal(result.data.data.totalNumber);
            } else {
                message.error('数据获取失败');
            }
        }
    };

    const handleSelectChange: Function = (value: any): void => {
        setValue('');
        setModel(value === 'all'? 'modal' : value);
    };

    const handleDelete: Function = (record: any): void => {
        setRecord(record);
        setVisible(true);
    };

    const handleOnOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/device/deleteAec',
            data: { aceId: record.aceId },
            method: 'POST'
        };
        setVisibleLoading(true);
        const result: Result = await axios(option);
        setVisibleLoading(false);
        message[result.data.code === 0? "success" : "error"](
            result.data.code === 0? "删除成功" : "删除失败"
        );
        if (result.data.code === 0) {
            setVisible(false);
            setEffect(!effect);
        }
    };

    const handleAddOnOk: Function = async (): Promise<void> => {
        const reg: RegExp = /(^\s)|(\s$)/;
        if (modalValue === "") {
            message.error("请输入型号");
        } else if (modalValue.match(/^[ ]*$/)) {
            message.error("型号不能包含空格");
        } else if (reg.test(modalValue)) {
            message.error("型号两边不能有空格");
        } else {
            const option: Option = {
                url: '/device/insertAec',
                data: { model: modalValue },
                method: 'POST'
            };
            setAddVisibleLoading(true);
            const result = await axios(option);
            setAddVisibleLoading(false);
            message[result.data.code === 0? "success" : "error"](
                result.data.code === 0? "添加成功" : "添加失败"
            );
            if (result.data.code === 0) {
                setModalValue('');
                setAddVisible(false);
                setEffect(!effect);
            }
        }
    };

    const handleOnCancel: Function = (): void => {
        setModalValue('');
        setAddVisible(false);
    };

    const handleAdd: Function = (): void => {
        setAddVisible(true);
    };
    return (
        <div className={ styles.aecList }>
            <div className={ styles.search }>
                <div className={ styles.select }>
                    <span className={ styles.span }>model</span>
                    <Select
                    style={{ width: 200 }}
                    value={ model }
                    onChange={ (value) => handleSelectChange(value) }
                    >
                        {modelList.map((item: any, index: number): ReactElement => {
                            return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                        })}
                    </Select>
                </div>
                <div className={ styles.addAndSerach }>
                    <div className={ styles.select }>
                        <Input className={ styles.input } value={ value } onChange={ (e) => setValue(e.target.value) } placeholder="请输入关键词" />
                        <Button type="primary" disabled={ !findPermissions(23231) } onClick={ () => handleSearch() } icon={<SearchOutlined />} loading={ loading }>Search</Button>
                    </div>
                    <div className={ styles.select }>
                        <Button type="primary" className={ styles.btn } onClick={ () => handleAdd() }>Add</Button>
                    </div>
                </div>
            </div>

            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey={() => `${Math.floor(Math.random() * 123333)}`}
				sticky={ true }
				dataSource={ tableData }>
				<Column align='center' title="型号 / Model" dataIndex="model" />
				<Column align='center' width={ 200 } title="操作" render={ (operation: any, record: any): ReactElement => (
					<p className={ styles.operation } onClick={ () => handleDelete(record) }>删除</p>
				) } />
			</Table>

            <Modal title='删除Aec白名单' width={ 550 }
            confirmLoading={ visibleLoading }
            getContainer={ false } 
            centered visible={ visible } 
            onCancel={ () => setVisible(false)}
            onOk={ () => handleOnOk() }>
                <p>确认删除?</p>
            </Modal>

            <Modal title='添加Aec白名单' width={ 550 }
            confirmLoading={ addVisibleLoading }
            getContainer={ false } 
            centered visible={ addVisible } 
            onCancel={ () => handleOnCancel() }
            onOk={ () => handleAddOnOk() }>
                <Input value={ modalValue } onChange={ (e) => setModalValue(e.target.value) } placeholder="请输入型号" />
            </Modal>
        </div>
    )
};

export default AecList;