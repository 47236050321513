import store from '../store';

const findPermissions: Function = (perm: number): boolean => {
    const permissions: any[] = store.getState().permissions;
    if (permissions) {
        const target: object | undefined = permissions.find(item => item.pid === perm);
        return target? true : false;
    } else {
        return false;
    }
};

const contentModule: Function = (): string => {
    if (findPermissions(10001)) {
        return '/content/user';
    } else if (findPermissions(20001)) {
        return '/content/problem';
    } else if (findPermissions(21001)) {
        return '/content/email';
    } else if (findPermissions(22001)) {
        return '/content/version';
    } else if (findPermissions(23001)) {
        return directoriesPath();
    } else if (findPermissions(24001)) {
        return financialPath();
    } else if (findPermissions(30000)) {
        return privateIndexPath();
    } else if (findPermissions(25001)) {
        return permissPath();
    } else {
        return '/notPage';
    };
};

const userMoudleViewPath: Function = (): string => {
    if (findPermissions(11101)) {
        return '/content/user/detail/basicInformation';
    } else if (findPermissions(11201)) {
        return '/content/user/detail/appInformation';
    } else if (findPermissions(11301)) {
        return '/content/user/detail/dosage';
    } else if (findPermissions(11401)) {
        return userFinancialPath();
    } else {
        return '/notPage';
    };
};

const userMoudleAppPath: Function = (): string => {
    if (findPermissions(11201)) {
        if (findPermissions(11221)) {
            return '/content/user/detail/appInformation/projectEditor';
        } else {
            return '/content/user/detail/appInformation';
        }
    } else if (findPermissions(11101)) {
        return '/content/user/detail/basicInformation';
    } else if (findPermissions(11301)) {
        return '/content/user/detail/dosage';
    } else if (findPermissions(11401)) {
        return userFinancialPath();
    } else {
        return '/notPage';
    };
};

const userFinancialPath: Function = (): string => {
    if (findPermissions(11411)) {
        return '/content/user/detail/financial';
    } else if (findPermissions(11421)) {
        return '/content/user/detail/financial/billing';
    } else if (findPermissions(11431)) {
        return '/content/user/detail/financial/bill';
    } else if (findPermissions(11441)) {
        return '/content/user/detail/financial/record';
    } else if (findPermissions(11451)) {
        return '/content/user/detail/financial/invoice';
    } else if (findPermissions(11461)) {
        return '/content/user/detail/financial/package';
    } else {
        return '/notPage';
    };
};

const directoriesPath: Function = (): string => {
    if (findPermissions(23101)) {
        return '/content/directories';
    } else if (findPermissions(23201)) {
        return '/content/directories/aecwhitelist';
    } else if (findPermissions(23301)) {
        return '/content/directories/brandwhitelist';
    } else if (findPermissions(23401)) {
        return '/content/directories/brandblacklist';
    } else {
        return '/notPage';
    };
};

const financialPath: Function = (): string => {
    if (findPermissions(24101)) {
        return '/content/financial';
    } else if (findPermissions(24201)) {
        return '/content/financial/invoice';
    } else if (findPermissions(24301)) {
        return '/content/financial/package';
    } else {
        return '/notPage';
    };
};

const privateIndexPath: Function = (): string => {
    if (findPermissions(30001)) {
        return '/content/private';
    } else if (findPermissions(32001)) {
        return '/content/private/applyfor';
    } else if (findPermissions(33001)) {
        return '/content/private/review';
    } else if (findPermissions(34001)) {
        return '/content/private/expiring';
    } else if (findPermissions(35001)) {
        return '/content/private/dueformally';
    } else if (findPermissions(31001)) {
        return '/content/private/period';
    } else {
        return '/notPage';
    };
};

const permissPath: Function = (): string => {
    if (findPermissions(25101)) {
        return '/content/permiss';
    } else if (findPermissions(25201)) {
        return '/content/permiss/role';
    } else {
        return '/notPage';
    };
};


const privateDetailPath: Function = (id: number): string => {
    if (findPermissions(id)) {
        return '/content/private/detail/renewal';
    } else {
        return '/content/private/detail/basicInfor';
    }
};
export {
    findPermissions,
    contentModule,
    userMoudleViewPath,
    userFinancialPath,
    directoriesPath,
    financialPath,
    privateIndexPath,
    permissPath,
    userMoudleAppPath,
    privateDetailPath
};