import { FC, ReactElement, useEffect, useState } from 'react';
import { Select, Input, Button, Modal, Table, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { findPermissions } from '../../../../../api/permiss';
import { useHistory } from 'react-router-dom';
import { getTimestampToTime } from '../../../../../api/index';
import { Option } from '../../../../../axios/axios-types';
import axios from '../../../../../axios/axios';
import pubsub from 'pubsub-js';
import styles from './formal.less';
const { Column } = Table;

namespace Antd {
    export const { Option } = Select;
};

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

enum Status {never, '已停用', '在使用', '快到期', '已到期'};

const Formal: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const selectOption: any[] = [
        {
            value: 5,
            label: "全部"
        },
        {
            value: 2,
            label: "在使用"
        },
        {
            value: 3,
            label: "快到期"
        },
        {
            value: 4,
            label: "已到期"
        },
        {
            value: 1,
            label: "已停用"
        }
    ];
    const [selectValue, setSelectValue]: [number, Function] = useState(5);
    const [searchParam, setSearchParam]: [string, Function] = useState('');
    const [url, setUrl]: [string, Function] = useState('getAllUserList');
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
    const [deleteVisible, setDeleteVisible]: [boolean, Function] = useState(false);
    const [confirmLoading, setConfirmLoading]: [boolean, Function] = useState(false);
    const [record, setRecord]: [any, Function] = useState({});
    const [effect, setEffect]: [boolean, Function] = useState(false);
    
    useEffect((): void => {
        getAllUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current, selectValue, url, effect]);

    props.cacheLifecycles.didRecover(() => {
        getAllUserList();
    });

    const getAllUserList: Function = async (): Promise<void> => {
        const option: Option = {
            url: `/primgr/${url}`,
            data: {
                dType: 1,
                status: selectValue,
                pageNum: current,
                pageSize: pageSize,
                searchParam,
            },
            method: 'POST'
        };
        setTableLoading(true);
        url === 'searchAllUserList' && setLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        url === 'searchAllUserList' && setLoading(false);
        if (result.data.code === 0 && result.data.data) {
            result.data.data.list.forEach((item: any): void => {
                item.status = Status[item.status];
                item.licType = item.licType === 2? "混合云" : "私有云";
                item.ts = getTimestampToTime(item.ts);
                item.stopTs = getTimestampToTime(item.stopTs);
            });
            url === 'getAllUserList' && setSearchParam('');
            setTotal(result.data.data.totalNumber);
            setTableData(result.data.data.list);
        } else {
            message.error('数据获取失败');
        }
    };

    const handleSearch: Function = (): void => {
        if (searchParam) {
            setUrl('searchAllUserList');
            setEffect(!effect);
            setCurrent(1);
            setSelectValue(5);
        } else {
            message.error('Please enter the content');
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const computedClass: Function = (record: any): string => {
        return record.status === "在使用"? styles.complete : record.status === "快到期"? styles.refused : styles.agree;
    };

    const handleAdd: Function = (): void => {
        history.push('/content/private/customerAdd', { devp: 1 });
    };

    const handleSelectChange: Function = (value: number): void => {
        setCurrent(1);
        setUrl('getAllUserList');
        setSearchParam('');
        setSelectValue(value);
    };

    const handleDelete: Function = (record: any): void => {
        setRecord(record);
        setDeleteVisible(true);
    };

    const handleView: Function = (record: any): void => {
        const data: any = {
            private: {
                name: '正式客户',
                url: '/content/private'
            },
            perm: {
                xs: 30202,
                bh: 20203,
                my: 30204,
                glfw: 30205,
                bz: 30206,
                fwq: 30211,
                zzfw: 30221,
                zzxq: 30222,
                sqxq: 30223,
                bjxx: 30212,
                sc: 30213,
                jqxq: 30214
            }
        };
        
        history.push('/content/private/detail/basicInfor', Object.assign(record, data));
    };

    const handleRemove: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/deleteDevp',
            data: { oem: record.oem },
            method: 'POST'
        };
        setConfirmLoading(true);
        const result: Result = await axios(option);
        setConfirmLoading(false);
        if (result.data.code === 0) {
            message.success('Customer deleted Successfully');
            getAllUserList();
            pubsub.publish('getSummaryUserNum');
            setDeleteVisible(false);
        } else {
            message.error('Customer deletion failed');
        }
    };
    return (
        <div className={ styles.formal }>
            <div className={ styles.toolbar }>
                <div className={ styles.toolbarLeft }>
                    <Select
                    className={ styles.toolbarItem }
                    style={{ width: 200 }}
                    value={ selectValue }
                    onChange={ (value) => handleSelectChange(value) }
                    >
                        {
                            selectOption.map((item: any, index: number): ReactElement => {
                                return <Antd.Option value={ item.value } key={ index }>{ item.label }</Antd.Option>
                            })
                        }
                    </Select>
                    <Input className={ styles.toolbarItem } value={ searchParam } onChange={ (e) => setSearchParam(e.target.value) } placeholder="请输入内容" />
                    <Button className={ styles.toolbarItem } loading={ loading } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
                </div>
                { findPermissions(30101) && <Button className={ styles.button } onClick={ () => handleAdd() } type="primary">添加</Button> }
            </div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="申请时间"  dataIndex="ts" />
                <Column align='center' title="截止时间" dataIndex="stopTs" />
                <Column align='center' title="有效期 / 天" dataIndex="period" />
                <Column align='center' title="备注"  dataIndex="remark" />
                <Column align='center' title="预留邮箱" dataIndex="email" />
                <Column align='center' title="预留手机号"  dataIndex="phone" />
                <Column align='center' title="部署类型" dataIndex="licType" />
                <Column align='center' title="服务类型" dataIndex="serviceType" />
                <Column align='center' title="厂商" dataIndex="oem" />
                <Column align='center' title="状态" render={ (operation: any, record: any): ReactElement => (
                    <span className={ computedClass(record) }>{ record.status }</span>
                ) } />
                <Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { findPermissions(30201) && <p onClick={ () => handleView(record) } className={ styles.operation }>查看</p> }
                        { findPermissions(30301) && <p onClick={ () => handleDelete(record) } className={ [styles.operation, styles.delete].join(' ') }>删除</p> }
                    </div>
                ) } />
            </Table>

            <Modal title='删除客户' width={ 500 } getContainer={ false } centered 
            confirmLoading={ confirmLoading }
            visible={ deleteVisible } onOk={ () => handleRemove() } onCancel={ () => setDeleteVisible(false)}>
                <p>您确定要删除该客户吗?</p>
            </Modal>
        </div>
    )
};

export default Formal;