import { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../../api/permiss';
import { Option } from '../../../../../../../axios/axios-types';
import { Button, Input, Upload, message, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { uploadUrl } from '../../../../../../../axios/baseUrl';
import { TOKEN } from '../../../../../../../store/reducer';
import { connect } from 'react-redux';
import styles from './enterprise.less';
import axios from '../../../../../../../axios/axios';


interface Data {
	code: number;
	data: any;
};

interface Result {
	data: Data;
};

interface IProps {
	token?: TOKEN;
};

const Enterprise: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();

    const [disabled, setDisabled]: [boolean, Function] = useState(true);
    const [information, setInformation]: [any, Function] = useState({});
    const [effect, setEffect]: [boolean, Function] = useState(false);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [isAdding, setIsAdd] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible]: [boolean, Function] = useState(false);
    const [visibleLoading, setVisibleLoading]: [boolean, Function] = useState(false);
    const [modalVisible, setModalVisible]: [boolean, Function] = useState(false);
    const [viewImgUrl, setViewImgUrl] = useState<string>('');
    const [changeOrAdd, setChangeOrAdd] = useState<string>('add');

    useEffect((): void => { 
        const getDevpAuthInfo: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/v2/developers/getDevpAuthInfo',
                data: { uid: location.state.uid },
				method: 'POST',
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                setInformation(result.data.data);
            }
        };
        getDevpAuthInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect]);

    const handleHeadBtnClick: Function = async (type: string): Promise<void> => {
        if (type === 'OK' && checkEnterpriseRules()) { 
            setIsModalVisible(true);
        } else if (type === 'Cancel') {
            setDisabled(true);
            setEffect(!effect);
        }
    };

    const checkEnterpriseRules: Function = (): boolean => {
        const licNoReg: RegExp = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g; //社会统一信用代码正则表达式
        const pattern: RegExp = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/; // 银行卡号
        if (information.comName && information.comName.trim() === "") {
            message.error('企业名称错误');
            return false;
        } else if (!licNoReg.test(information.licNo)) {
            message.error('统一社会信用代码有误');
            return false;
        } else if (information.bankName && information.bankName.trim() === "") {
            message.error('企业银行名称错误');
            return false;
        } else if (information.bankOpeningName && information.bankOpeningName.trim() === "") {
            message.error('企业银行开户行有误');
            return false;
        } else if (!pattern.test(information.bankNum)) {
            message.error('企业银行帐号错误');
            return false;
        } else if ((information.licUrl && information.licUrl.trim()) === "" || !information.licUrl) {
            message.error('请上传营业执照');
            return false;
        }
        return true;
    };

    const computedComAuthState: () => ReactElement = (): ReactElement => {
        if (information.comAuthState === 1) {
            return <span style={{ color: '#FFAE30' }}>审核中</span>
        } else if (information.comAuthState === 2) {
            return <span style={{ color: '#FE0000' }}>未通过 - { information.comAuthDesc }</span>
        } else {
            return <span style={{ color: '#5FC268' }}>已通过</span>
        }
    };

    const handleInputChange: Function = (e: ChangeEvent<HTMLInputElement>, type: string): void => {
        const informationCopy = JSON.parse(JSON.stringify(information));
        informationCopy[type] = e.target.value;
        setInformation(informationCopy);
    };

    const handleChange: Function = (info: any): void => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return
        };
        if (info.file.status === 'done') {
            if (info.file.response.code === 0) {
                const informationCopy = JSON.parse(JSON.stringify(information));
                informationCopy.licUrl = info.file.response.data.url;
                message.success('图片上传成功');
                setInformation(informationCopy);
            } else {
                message.error('图片上传失败');
            }
            setLoading(false);
        }
    };

    const handleAdd: () => void = (): void => {
        setIsAdd(true);
        setDisabled(false);
        setChangeOrAdd('add');
    };

    const handleModalOk: () => void = async (): Promise<void> => {
        const option: Option = {
            url: `/developers/${changeOrAdd === 'add'? 'companyAuth' : 'updateCompanyAuth'}`,
            data: { uId: location.state.uid, ...information },
            method: 'POST',
        };
        setVisibleLoading(true);
        const result: Result = await axios(option);
        setVisibleLoading(false);
        if (result.data.code === 0) {
            message.success('认证信息更新成功');
            setDisabled(true);
            setEffect(!effect);
            setIsModalVisible(false);
        } else {
            message.error('认证信息更新失败');
        }
    };

    const handleModalCancel: () => void = (): void => {
        setDisabled(true);
        setEffect(!effect);
        setIsModalVisible(false);
    };

    const handleView: (url: string) => void = (url: string): void => {
        if (disabled) {
            setViewImgUrl(url);
            setModalVisible(true);
        }
    };

    const handleModify: () => void = (): void => {
        setChangeOrAdd('change');
        setDisabled(false);
    };

    if ((information.comAuthState !== 0 && information.comAuthState) || isAdding) {
        return (
            <div className={ styles.enterprise }>
                <div className={ styles.head }>
                    <p className={ styles.title }>企业认证</p>
                    { (disabled && findPermissions(11107)) && <Button onClick={ () => handleModify() } type="link">修改</Button> }
                    { (disabled && findPermissions(11107)) ||
                        <div>
                            <Button onClick={ () => handleHeadBtnClick('OK') } type="link">完成</Button>
                        </div>
                    }
                </div>
                { disabled && <div className={ styles.line }>
                    <span className={ styles.span }>认证状态：</span>
                    <div className={ styles.infor }>
                        <div>{ computedComAuthState() }</div>
                    </div>
                </div> }
                <div className={ styles.line }>
                    <span className={ styles.span }>企业名称：</span>
                    <Input className={ styles.input } placeholder='请输入企业名称' onChange={ (e) => handleInputChange(e, 'comName') } disabled={ disabled } value={ information.comName } />
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>统一社会征信代码：</span>
                    <Input className={ styles.input } placeholder='请输入统一社会征信代码' onChange={ (e) => handleInputChange(e, 'licNo') } disabled={ disabled } value={ information.licNo } />
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>企业银行名称：</span>
                    <Input className={ styles.input } placeholder='请输入企业银行名称' onChange={ (e) => handleInputChange(e, 'bankName') } disabled={ disabled } value={ information.bankName } />
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>企业银行开户行：</span>
                    <Input className={ styles.input } placeholder='请输入企业银行开户行' onChange={ (e) => handleInputChange(e, 'bankOpeningName') } disabled={ disabled } value={ information.bankOpeningName } />
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>企业银行账户：</span>
                    <Input className={ styles.input } placeholder='请输入企业银行账户' onChange={ (e) => handleInputChange(e, 'bankNum') } disabled={ disabled } value={ information.bankNum } />
                </div>
                <div className={ [styles.line, styles.baseline].join(' ') }>
                    <span className={ styles.span }>营业执照：</span>
                    <Upload
                        disabled={ disabled }
                        className={ [styles.upload, 'avatar-uploader'].join(' ') }
                        name="file"
                        listType="picture-card"
                        showUploadList={ false }
                        action={ uploadUrl + 'anyrtc' }
                        headers={{
                            'Artc-Token': props.token as TOKEN
                        }}
                        onChange={ (info) => handleChange(info) }
                    >
                        { information.licUrl ? 
                            <img src={ information.licUrl } alt="avatar" 
                            onClick={ () => handleView(information.licUrl) }
                            style={{ width: '100%' }} /> : 
                            <div>
                                { loading ? <LoadingOutlined /> : <PlusOutlined /> }
                                <div style={{ color: '#409EFF' }}>营业执照</div>
                            </div>
                        }
                    </Upload>
                </div>
                <Modal centered title='企业认证' 
                maskClosable={ false } 
                visible={ isModalVisible } 
                confirmLoading={ visibleLoading }
                cancelText='取消'
                okText='确定' 
                onOk={ () => handleModalOk() } 
                onCancel={ () => handleModalCancel() }>
                    <span>是否保存本次修改?</span>
                </Modal>
                <Modal centered title='查看图片'
                width='70%'
                getContainer={ false }
                visible={ modalVisible }
                onOk={ () => setModalVisible(false) } 
                onCancel={ () => setModalVisible(false) }>
                    <img className={ styles.viewImg } src={ viewImgUrl } alt="" />
                </Modal>
            </div>
        );
    } else {
        return (
            <div className={ styles.enterprise }>
                <div className={ styles.head }>
                    <p className={ styles.title }>企业认证</p>
                </div>
                <div className={ styles.unauthorized }>
                    <p>尚无认证，是否手动</p>
                    <p className={ styles.add } onClick={ () => handleAdd() }>添加</p>
                    <p>?</p>
                </div>
            </div>
        );
    };
};

type MapStateToProps = (state: object) => object;

const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps)(Enterprise);