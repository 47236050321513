import { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../../api/permiss';
import { Option } from '../../../../../../../axios/axios-types';
import { Button, Input, Upload , message, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { TOKEN } from '../../../../../../../store/reducer';
import { connect } from 'react-redux';
import { uploadUrl } from '../../../../../../../axios/baseUrl';
import styles from './identity.less';
import axios from '../../../../../../../axios/axios';

interface Data {
	code: number;
	data: any;
};

interface Result {
	data: Data;
};

interface IProps {
	token?: TOKEN;
};

const Identity: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();

    const [disabled, setDisabled]: [boolean, Function] = useState(true);
    const [information, setInformation]: [any, Function] = useState({});
    const [effect, setEffect]: [boolean, Function] = useState(false);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [backLoading, setBackLoading]: [boolean, Function] = useState(false);
    const [isAdding, setIsAdd] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible]: [boolean, Function] = useState(false);
    const [visibleLoading, setVisibleLoading]: [boolean, Function] = useState(false);
    const [modalVisible, setModalVisible]: [boolean, Function] = useState(false);
    const [viewImgUrl, setViewImgUrl] = useState<string>('');
    const [changeOrAdd, setChangeOrAdd] = useState<string>('add');


    useEffect((): void => {
        const getDevpAuthInfo: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/v2/developers/getDevpAuthInfo',
                data: { uid: location.state.uid },
				method: 'POST',
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                setInformation(result.data.data);
            }
        };
        getDevpAuthInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect]);

    const handleHeadBtnClick: Function = (type: string): void => {
        if (type === 'OK' && checkEnterpriseRules()) {
            setIsModalVisible(true);
        } else if (type === 'Cancel') {
            setDisabled(true);
            setEffect(!effect);
        }
    };

    const conversion: Function = (data: any): object => {
        let obj: any = {};
        for (let key in data) {
            if (key === "idBack") {
                obj.userNoback = data[key];
            } else if (key === "idFront") {
                obj.userNoFront = data[key];
            } else if (key === "idName") {
                obj.userName = data[key];
            } else if (key === "idNo") {
                obj.userNo = data[key];
            } else {
                obj[key] = data[key];
            }
        }
        return obj;
    };

    const checkEnterpriseRules: Function = (): boolean => {
        const reg: RegExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; //身份证正则表达式
        if (information.idName && information.idName.trim() === "") {
            message.error('个人名称错误');
            return false;
        } else if (!reg.test(information.idNo)) {
            message.error('身份证号码错误');
            return false;
        } else if ((information.idFront && information.idFront.trim() === "") || (information.idBack && information.idBack.trim() === "")) {
            message.error('请上传身份证照片');
            return false;
        }
        return true;
    };

    const handleInputChange: Function = (e: ChangeEvent<HTMLInputElement>, type: string): void => {
        const informationCopy = JSON.parse(JSON.stringify(information));
        informationCopy[type] = e.target.value;
        setInformation(informationCopy);
    };

    const handleChange: Function = (info: any, type: string): void => {
        if (info.file.status === 'uploading') {
            type === 'idFront'? setLoading(true) : setBackLoading(true);
            return
        };
        if (info.file.status === 'done') {
            if (info.file.response.code === 0) {
                const informationCopy = JSON.parse(JSON.stringify(information));
                informationCopy[type] = info.file.response.data.url;
                message.success('图片上传成功');
                setInformation(informationCopy);
            } else {
                message.error('图片上传失败');
            }
            type === 'idFront'? setLoading(false) : setBackLoading(false);
        }
    };

    const handleAdd: () => void = (): void => {
        setIsAdd(true);
        setDisabled(false);
        setChangeOrAdd('add');
    };

    const computedIdAuthState: () => ReactElement = (): ReactElement => {
        if (information.idAuthState === 1) {
            return <span style={{ color: '#FFAE30' }}>审核中</span>
        } else if (information.idAuthState === 2) {
            return <span style={{ color: '#FE0000' }}>未通过 - { information.idAuthDesc }</span>
        } else {
            return <span style={{ color: '#5FC268' }}>已通过</span>
        }
    };

    const handleModalOk: () => void = async (): Promise<void> => {
        const option: Option = {
            url: `/developers/${changeOrAdd === 'add'? 'personalAuth' : 'updatePersonalAuth'}`,
            data: { uId: location.state.uid, ...conversion(information) },
            method: 'POST',
        };
        setVisibleLoading(true);
        const result: Result = await axios(option);
        setVisibleLoading(false);
        if (result.data.code === 0) {
            message.success('认证信息更新成功');
            setDisabled(true);
            setEffect(!effect);
            setIsModalVisible(false);
        } else {
            message.error('认证信息更新失败');
        }
    };

    const handleModalCancel: () => void = (): void => {
        setDisabled(true);
        setEffect(!effect);
        setIsModalVisible(false);
    };


    const handleView: (url: string) => void = (url: string): void => {
        if (disabled) {
            setViewImgUrl(url);
            setModalVisible(true);
        }
    };

    const handleModify: () => void = (): void => {
        setChangeOrAdd('change');
        setDisabled(false);
    };

    if ((information.idAuthState !== 0 && information.idAuthState) || isAdding) {
        return (
            <div className={ styles.identity }>
                <div className={ styles.head }>
                    <p className={ styles.title }>个人认证</p>
                    { (disabled && findPermissions(11107)) && <Button onClick={ () => handleModify() } type="link">修改</Button> }
                    { (disabled && findPermissions(11107)) ||
                        <div>
                            <Button onClick={ () => handleHeadBtnClick('OK') } type="link">完成</Button>
                        </div>
                    }
                </div>
                { disabled && <div className={ styles.line }>
                    <span className={ styles.span }>认证状态：</span>
                    <div className={ styles.infor }>
                        <div>{ computedIdAuthState() }</div>
                    </div>
                </div> }
                <div className={ styles.line }>
                    <span className={ styles.span }>个人名称：</span>
                    <Input className={ styles.input } placeholder='请输入个人名称' onChange={ (e) => handleInputChange(e, 'idName') } disabled={ disabled } value={ information.idName } />
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>证件号码：</span>
                    <Input className={ styles.input } placeholder='请输入证件号码' onChange={ (e) => handleInputChange(e, 'idNo') } disabled={ disabled } value={ information.idNo } />
                </div>
                <div className={ [styles.line, styles.baseline].join(' ') }>
                    <span className={ styles.span }>证件照片：</span>
                    <Upload
                        disabled={ disabled }
                        className={ [styles.upload, 'avatar-uploader'].join(' ') }
                        name="file"
                        listType="picture-card"
                        showUploadList={ false }
                        action={ uploadUrl + 'anyrtc' }
                        headers={{
                            'Artc-Token': props.token as TOKEN
                        }}
                        onChange={ (info) => handleChange(info, 'idFront') }
                    >
                        { information.idFront ? 
                            <img
                            src={ information.idFront } alt="avatar" 
                            onClick={ () => handleView(information.idFront) }
                            style={{ width: '100%' }} /> : 
                            <div>
                                { loading ? <LoadingOutlined /> : <PlusOutlined /> }
                                <div style={{ color: '#409EFF' }}>手持身份证头像面照片</div>
                            </div>
                        }
                    </Upload>
                    <Upload
                        disabled={ disabled }
                        className={ [styles.upload, 'avatar-uploader'].join(' ') }
                        name="file"
                        listType="picture-card"
                        showUploadList={ false }
                        action={ uploadUrl + 'anyrtc' }
                        headers={{
                            'Artc-Token': props.token as TOKEN
                        }}
                        onChange={ (info) => handleChange(info, 'idBack') }
                    >
                        { information.idBack ? 
                            <img
                            src={ information.idBack } alt="avatar"
                            onClick={ () => handleView(information.idBack) }
                            style={{ width: '100%' }} /> : 
                            <div>
                                { backLoading ? <LoadingOutlined /> : <PlusOutlined /> }
                                <div style={{ color: '#409EFF' }}>手持身份证国徽面照片</div>
                            </div>
                        }
                    </Upload>
                </div>
                <Modal centered title='个人认证' 
                maskClosable={ false } 
                visible={ isModalVisible } 
                confirmLoading={ visibleLoading } 
                cancelText='取消'
                okText='确定'
                onOk={ () => handleModalOk() } 
                onCancel={ () => handleModalCancel() }>
                    <span>是否保存本次修改?</span>
                </Modal>
                <Modal centered title='查看图片'
                width='70%'
                getContainer={ false }
                visible={ modalVisible }
                onOk={ () => setModalVisible(false) } 
                onCancel={ () => setModalVisible(false) }>
                    <img className={ styles.viewImg } src={ viewImgUrl } alt="" />
                </Modal>
            </div>
        );
    } else {
        return (
            <div className={ styles.identity }>
                <div className={ styles.head }>
                    <p className={ styles.title }>个人认证</p>
                </div>
                <div className={ styles.unauthorized }>
                    <p>尚无认证，是否手动</p>
                    <p className={ styles.add } onClick={ () => handleAdd() }>添加</p>
                    <p>?</p>
                </div>
            </div>
        );
    };
};

type MapStateToProps = (state: object) => object;

const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps)(Identity);