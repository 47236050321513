import { FC, ReactElement, useState, useEffect } from 'react';
import { Button, Table, Input, Modal, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { getTimestampToTime, handleCopy } from '../../../../../../../../../api';
import { Option } from '../../../../../../../../../axios/axios-types';
import { scriptUrl } from '../../../../../../../../../axios/baseUrl';
import axios from '../../../../../../../../../axios/axios';
import styles from './records.less';
const { Column } = Table;

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const Records: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [loading, setLoading]: [boolean, Function] = useState(false);
    const [tableData, setTableData]: [any[], Function] = useState([]);
    const [increaseLoading, setIncreaseLoading]: [boolean, Function] = useState(false);
    const [tableIncreaseData, setTableIncreaseData]: [any[], Function] = useState([]);
    const [active, setActive]: [number, Function] = useState(2);
	const [searchValue, setSearchValue]: [string, Function] = useState('');
    const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [sortField, setSortField]: [string, Function] = useState('licUserNum');
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [visible, setVisible]: [boolean, Function] = useState(false);
	const [total, setTotal]: [number, Function] = useState(0);
	const [licNum, setLicNum]: [string, Function] = useState('');
	const [modalLoading, setModalLoading]: [boolean, Function] = useState(false);
    const [type, setType]: [any[], Function] = useState([
        {
            name: '所有',
            num: 0,
            type: 0,
            path: ''
        },
        {
            name: '已激活',
            num: 0,
            type: 2,
            path: ''
        },
        {
            name: '未激活',
            num: 0,
            type: 0,
            path: ''
        },
        {
            name: '即将过期',
            num: 0,
            type: 4,
            path: ''
        },
        {
            name: '已过期',
            num: 0,
            type: 3,
            path: ''
        },
        {
            name: '禁用',
            num: 0,
            type: -1,
            path: ''
        },
        {
            name: '用户数',
            num: 0,
            type: 0,
            path: ''
        }
    ]);
    
    useEffect((): void => {
        getLicenseIncreaseList();
        getLicenseNumArr();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect((): void => {
        getLicenseDetailList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current, sortField, sortRule, active]);

    const getLicenseNumArr: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/lic/getLicenseNumArr',
            data: {
                licModelId: location.state.licModelId
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const t: any[] = JSON.parse(JSON.stringify(type));
            const Num: any = {
                '所有': 'licNum',
                '已激活': 'licActNum',
                '未激活': 'licUnActNum',
                '即将过期': 'licNearExpiredNum', 
                '已过期': 'licExpiredNum', 
                '禁用': 'licAbolishedNum',
                '用户数': 'licUserNum',
            };
            t.forEach((item: any): void => {
                item.num = result.data.data[`${Num[item.name]}`]
            });
            setType(t);
        }
    };

    const getLicenseIncreaseList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/lic/getLicenseIncreaseList',
            data: { licModelId: location.state.licModelId },
            method: 'POST'
        };
        setIncreaseLoading(true);
        const result: Result = await axios(option);
        setIncreaseLoading(false);
        if (result.data.code === 0) {
            result.data.data.forEach((item: any): void => {
                item.licPeriod = item.licPeriod + "个月";
                item.licTs = getTimestampToTime(item.licTs, 'yyyy-MM-DD');
            });
            setTableIncreaseData(result.data.data);
        }
    };

    const getLicenseDetailList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/v2/lic/getLicenseDetailList',
            data: { 
                sortRule,
                sortField,
                pageNum: current,
                pageSize,
                licModelId: location.state.licModelId,
                licListType: active
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.licExpirTs = getTimestampToTime(item.licExpirTs);
                item.licActTs = getTimestampToTime(item.licActTs);
            });
            setTableData(result.data.data.list);
            setTotal(result.data.data.totalNumber);
        }
    };

    const handleMenuChange: Function = (index: number): void => {
        setActive(index);
        getLicenseNumArr();
    };
    
    const handleSearch: Function = async (): Promise<void> => {
        if (searchValue.trim()) {
			const option: Option = {
				url: '/v2/lic/getLicenseDetailListByUniqueId',
				data: {
					licModelId: location.state.licModelId,
                    licUniqueId: searchValue
				},
				method: 'POST'
			};
			setLoading(true);
			const result: Result = await axios(option);
			setLoading(false);
			if (result.data.code === 0) {
				setSearchValue('');
				message.success('Data search succeeded');
                setTotal(1);
                result.data.data.forEach((item: any): void => {
                    item.licExpirTs = getTimestampToTime(item.licExpirTs);
                    item.licActTs = getTimestampToTime(item.licActTs);
                });
                setTableData(result.data.data);
			} else {
				message.error('Data lookup failure');
			}
		}
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
	};

    const handleView: Function = (record: any): void => {
        history.push('/content/user/detail/appInformation/projectEditor/mony/detail', Object.assign(location.state, record));
    };

    const handleAddLicense: Function = (): void => {
        setVisible(true);
    };

    const handleModalCancel: Function = (): void => {
        setVisible(false);
        setLicNum('');
    };

    const handleModalOk: Function = async (): Promise<void> => {
        if (isNaN(+licNum) || licNum === '') {
            message.error('终端数量不正确');
        } else if (+licNum <= 0) {
            message.error('终端数量不能小于1');
        } else if (licNum.toString().indexOf('.') !== -1) {
            message.error('终端数量不能是小数');
        }  else {
            const option: Option = { 
                url: '/v2/lic/updateLicNum',
                data: {
                    licModelId: location.state.licModelId, 
                    licNum: +licNum 
                },
                method: 'POST'
            };
            setModalLoading(true);
            const result = await axios(option);
            setModalLoading(false);
            if (result.data.code === 0) {
                handleModalCancel();
                getLicenseIncreaseList();
                getLicenseDetailList();
                getLicenseNumArr();
            }
        }
    };

    const handleSorter: Function = (a: any, b: any, type: string): boolean => {
        return a[type] > b[type];
    };
    
    return (<div className={ styles.records }>
        <div className={ styles.header }>
            <div className={ styles.title }>
                <span className={ styles.span }>添加记录</span>
                <Button type="primary" onClick={ () => history.goBack() }>Back</Button>
            </div>
            { location.state.type === 'formal' && <Button type="primary" onClick={ () => handleAddLicense() }>增加License数量</Button> }
        </div>
        <Table
            loading={ increaseLoading } 
            className={ styles.table }
            pagination={ false }
            rowKey={() => `${Math.floor(Math.random() * 123333)}`}
            sticky={ true }
            sortDirections={['ascend', 'descend', 'ascend']} 
            dataSource={ tableIncreaseData }>
            <Column align='center' showSorterTooltip={ false } sorter={ (a, b) => handleSorter(a, b, 'licTs') } defaultSortOrder='descend' title="创建日期" dataIndex="licTs" />
            <Column align='center' showSorterTooltip={ false } sorter={ (a, b) => handleSorter(a, b, 'licPeriod') } title="有效期"  dataIndex="licPeriod" />
            <Column align='center' showSorterTooltip={ false } sorter={ (a, b) => handleSorter(a, b, 'licNum') } title="License数量" dataIndex="licNum" />
		</Table>

        <div className={ styles.menu }>
            {
                type.map((item: any, index: number): ReactElement => {
                    return  <Button key={ index } 
                    onClick={ () => handleMenuChange(item.type) }
                    disabled={ !item.type }
                    className={ [styles.menuItem, active === item.type && styles.active].join(' ') }>
                    { item.name } { item.num }</Button>
                })
            }
        </div>

        <div className={ styles.searchAndLicense }>
            <div className={ styles.search }>
                <Input className={ [styles.item, styles.input].join(' ') } value={ searchValue } onChange={ (e) => setSearchValue(e.target.value) } placeholder="请输入内容" prefix={<SearchOutlined />} />
                <Button className={ styles.button } loading={ loading } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
            </div>
            <div className={ styles.license }>
                <span className={ styles.item }>License:</span>
                <Input placeholder="license" value={ location.state.license } addonAfter={ <IconFont onClick={ () => handleCopy(location.state.license) } className={ styles.copy } type="icon-fuzhi" /> } disabled={ true } style={{ width: 300 }} />
            </div>
        </div>

        <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
            loading={ tableLoading } 
            className={ styles.table }
            pagination={{
                defaultCurrent: 1,
                current,
                showSizeChanger: true,
                pageSize,
                total
            }}
            rowKey={() => `${Math.floor(Math.random() * 1233113)}`}
            sticky={ true }
            sortDirections={['ascend', 'descend', 'ascend']} 
            dataSource={ tableData }>
            <Column align='center' showSorterTooltip={ false } sorter={ true } defaultSortOrder='descend' title="用户数" dataIndex="licUserNum" />
            <Column align='center' showSorterTooltip={ false } title="设备标识"  dataIndex="licUniqueId" />
            <Column align='center' showSorterTooltip={ false } title="IMEI" dataIndex="licImei" />
            <Column align='center' showSorterTooltip={ false } sorter={ true } title="续期次数" dataIndex="licRenewalNum" />
            <Column align='center' showSorterTooltip={ false } sorter={ true } title="激活时间"  dataIndex="licActTs" />
            <Column align='center' showSorterTooltip={ false } sorter={ true } title="过期时间" dataIndex="licExpirTs" />
            <Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
				<p onClick={ () => handleView(record) } className={ styles.operation }>查看</p>
			) } />
		</Table>

        <Modal centered title='增加License数量' getContainer={ false } confirmLoading={ modalLoading } visible={ visible } onOk={ () => handleModalOk() } onCancel={ () => handleModalCancel() }>
            <div className={ styles.line }>
                <span>License数量：</span>
                <Input value={ licNum } placeholder="请输入终端数量" onChange={ (e) => setLicNum(e.target.value) } />
            </div>
        </Modal>
    </div>);
};

export default Records;