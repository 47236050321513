import { FC, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Index from './index/index';
import AddRole from './addRole/addRole';


const Role: FC = (): ReactElement => {
    return (
        <div>
            <Switch>
                <Route path='/content/permiss/role/addRole' component={ AddRole } />
                <Route path='/content/permiss/role' component={ Index } />
            </Switch>
        </div>
    )
};

export default Role;