import { ChangeEvent, Fragment, FC, ReactElement, useState, useEffect } from 'react';
import { Input, Button, message, Table, DatePicker } from 'antd';
import { findPermissions, userMoudleViewPath } from '../../../../api/permiss';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { TableDataAttr } from '../tableColumns';
import { Option } from '../../../../axios/axios-types';
import { getTimestampToTime, moneyComma, getDate } from '../../../../api';
import { AuthType } from '../../../../types/enum';
import moment from 'moment';
import axios from '../../../../axios/axios';
import styles from './usage.less';
const { Column } = Table;
const { RangePicker } = DatePicker;

interface Data {
	code: number;
	data: {
		list: Array<TableDataAttr>;
		totalNumber: number;
	};
};

interface Result {
	data: Data;
};

interface SearchResult {
	data: {
		code: number;
		data: Array<TableDataAttr>;
	}
};

interface IProps {
    cacheLifecycles: any;
};

const Usage: FC<IProps> = (props): ReactElement => {
	const history: any = useHistory();
	const [loading, setLoading]: [boolean, Function] = useState(false);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [searchValue, setSearchValue]: [string, Function] = useState('');
	const [url, setUrl]: [string, Function] = useState('/developers/getRecentUserListByTs');
	const [tableData, setTableData]: [Array<TableDataAttr>, Function] = useState([]);
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [sortField, setSortField]: [string, Function] = useState('totalTime');
	const [stopTs, setStopTs]: [number, Function] = useState(parseInt(((new Date(new Date().toLocaleDateString()).getTime() / 1000) + (60 * 60 * 24 - 1)).toString()));
	const [startTs, setStartTs]: [number, Function] = useState(getDate(7));
	const [activeTime, setActiveTime]: [number, Function] = useState(7);
	const [dates, setDates]: [Array<any>, Function] = useState([]);
	const [rangeValue, setRangeValue]: [any, Function] = useState([]);
	const [sortedInfo, setSortedInfo]: [any, Function] = useState({
		order: 'descend',
		field: 'totalTime',
	});
	
	useEffect((): void => {
		getRecentUserListByTs();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize, current, sortRule, sortField, url, stopTs, startTs, activeTime]);

	useEffect((): void => {
		setCurrent(1);
	}, [sortRule, sortField]);

	props.cacheLifecycles.didRecover(() => {
        getRecentUserListByTs();
    });

	const getRecentUserListByTs: Function = async (): Promise<void> => {
		setTableLoading(true);
		const option: Option = {
			url,
			method: 'POST',
			data: {
				stopTs,
				startTs,
				sortRule,
				sortField,
				pageNum: current,
				pageSize,
			}
		};
		const result: Result = await axios(option);
		if (result) {
			if (result.data.code === 0) {
				result.data.data.list.forEach((item: TableDataAttr): void => {
					item.regTime = getTimestampToTime(item.regTime);
					item.totalAmount = moneyComma(item.totalAmount);
					item.authType = AuthType[item.authType as number];
				});
				setTotal(result.data.data.totalNumber);
				setTableData(result.data.data.list);
			} else {
				message.error('数据获取失败');
			}
		}
		setTableLoading(false);
	};

    const handleSearch: Function = async (): Promise<void> => {
        if (searchValue.trim()) {
			const option: Option = {
				url: '/developers/searchDevper',
				data: {
					searchParam: searchValue
				},
				method: 'POST'
			};
			setLoading(true);
			const result: SearchResult = await axios(option);
            setLoading(false);
			if (result.data.code === 0) {
				setSearchValue('');
				message.success('数据查询成功');
				history.push(userMoudleViewPath(), { uid: result.data.data[0].uid, private: {
					name: '近期用户用量',
					url: '/content/user/usage'
				} });
			} else if (result.data.code === 1000) {
                message.error('开发者不存在');
            } else {
				message.error('数据查询失败');
			}
		}
    }

    const handleChange: Function = (e: ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(e.target.value);
	};

	const handleKeydown = (e: KeyboardEvent): void => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	const resetData: Function = (): void => {
		setCurrent(1);
		setSortRule(0);
		setSortField('totalTime');
		setSortedInfo({
			order: 'descend',
			field: 'totalTime',
		});
	};

	const handleClick: Function = (record: TableDataAttr): void => {
		history.push(userMoudleViewPath(), { uid: record.uid, private: {
			name: '近期用户用量',
			url: '/content/user/usage'
		} });
	};

	const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
		setSortedInfo(sorter);
	};

	const handleBtnClickTimeChange: Function = (type: number): void => {
		if (type === 0) {
			setUrl('/developers/getRecentUserList');
		} else {
			setUrl('/developers/getRecentUserListByTs');
			setStopTs(parseInt(((new Date(new Date().toLocaleDateString()).getTime() / 1000) + (60 * 60 * 24 - 1)).toString()));
			setStartTs(getDate(type));
		}
		setActiveTime(type);
		setRangeValue([]);
		resetData();
	};

	const disabledDate: Function = (current: any): boolean => {
		if (!dates || dates.length === 0) {
			return current > moment().endOf('day');
		}
		// 只能选择当前日期之前, 并且日期范围不超过90天
		const tooLate = dates[0] && current.diff(dates[0], 'days') > 90;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') > 90;
		return tooEarly || tooLate || current > moment().endOf('day');
	};

	const handleRangeChange: Function = (dates: Array<any>, dateStrings: Array<string>): void => {
		setRangeValue(dates);
		if (dateStrings[0] && dateStrings[1]) {
			setActiveTime(100);
			setStartTs(parseInt((new Date(new Date(dateStrings[0]).toLocaleDateString()).getTime() / 1000).toString()));
			setStopTs(parseInt(((new Date(new Date(dateStrings[1]).toLocaleDateString()).getTime() / 1000)  + (60 * 60 * 24 - 1)).toString()));
			setUrl('/developers/getRecentUserListByTs');
			resetData();
		} else {
			setActiveTime(7);
			handleBtnClickTimeChange(7);
		}
	};

    useEffect(() => {
		window.addEventListener('keydown', handleKeydown);		
		return () => {
			window.removeEventListener('keydown', handleKeydown);		
		}
	});

    return (
        <div className={ styles.usage }>
            <div className={ styles.serach }>
                <Input value={ searchValue } onChange={ (e) => handleChange(e) } className={ styles.input } placeholder="请输入用户的邮箱或手机号或AppID或CID" prefix={<SearchOutlined />} />
                <Button className={ styles.button } loading={ loading } disabled={ searchValue === '' } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
				<div className={ styles.total }>
					<span>近期用户用量：</span>
					<span>{ total }</span>
				</div>
				<div className={ styles.RangePicker }>
					<Button onClick={ () => handleBtnClickTimeChange(0) } className={ activeTime === 0 && styles.activeTime }>全部</Button>
					<Button onClick={ () => handleBtnClickTimeChange(7) } className={ activeTime === 7 && styles.activeTime }>7天</Button>
					<Button onClick={ () => handleBtnClickTimeChange(14) } className={ activeTime === 14 && styles.activeTime }>14天</Button>
					<Button onClick={ () => handleBtnClickTimeChange(30) } className={ activeTime === 30 && styles.activeTime }>近一月</Button>
					<RangePicker
					value={ rangeValue }
					disabledDate={ (current) => disabledDate(current) }
					onChange={ (dates, dateStrings) => handleRangeChange(dates, dateStrings) }
					onCalendarChange={ val => setDates(val) }
					/>
				</div>
			</div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='uid'
				sticky={ true }
				sortDirections={['ascend', 'descend', 'ascend']} 
				dataSource={ tableData }>
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'regTime' && sortedInfo.order} sorter={ true } title="注册时间" dataIndex="regTime" />
				<Column align='center' title="邮箱/手机号" dataIndex="email" render={ (email: any, record: TableDataAttr): ReactElement => (
					<div className={ styles.render }>
						<p>{ record.email }</p>
						<p>{ record.cellphone }</p>
					</div>
				) } />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'totalAmount' && sortedInfo.order} sorter={ true } title="总消费金额(CNY)"  dataIndex="totalAmount" />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'totalTime' && sortedInfo.order} sorter={ true } title="合计/分钟" dataIndex="totalTime" />
				<Column align='center' title="认证类型" dataIndex="authType" />
				<Column align='center' title="备注" dataIndex="remark" />
				<Column align='center' title="操作" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<Fragment>{ findPermissions(11002) && <p onClick={ () => handleClick(record) } className={ styles.operation }>查看</p> }</Fragment>
				) } />
			</Table>
        </div>
    )
};


export default Usage;