import { FC, ReactElement } from 'react';
import Identity from './identity/identity';
import Enterprise from './enterprise/enterprise';
import styles from './authentication.less';

const Authentication: FC = (): ReactElement => {
    return (
        <div className={ styles.authentication }>
            <Identity />
            <Enterprise />
        </div>
    );
};

export default Authentication;