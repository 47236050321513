import { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Table, message } from 'antd';
import { findPermissions } from '../../../../../../../api/permiss';
import { baseURL } from "../../../../../../../axios/baseUrl";
import { getTimestampToTime, moneyComma } from '../../../../../../../api/index';
import { Option } from '../../../../../../../axios/axios-types';
import axios from '../../../../../../../axios/axios';
import exportSCV from '../../../../../../../api/excal';
import styles from './detail.less';
const { Column } = Table;

interface Data {
	code: number;
	data: { 
        invoiceBilList: Array<any>;
        invVBRKItem: any;
        invAmount: number | string;
        invType: number | string;
        invState: number | string;
        totalNumber: number;
    };
};

interface Result {
	data: Data;
};

enum InvType {never, '个人发票', '企业普票', '企业专票'};
enum InvState {never, '已同意', '已拒绝', '已发送'};
const fileName: string = 'bill-reconciliation';

const Detail: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [result, setResult]: [any, Function] = useState({});
	const [invVBRKItem, setInvVBRKItem]: [any, Function] = useState({});


    useEffect((): void => {
        getInvoiceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current]);

    const getInvoiceInfo: Function = async (): Promise<void> => {
        setTableLoading(true);
        const option: Option = {
            url: '/developers/getInvoiceInfo',
            data: { 
                pageSize,
                pageNum: current,
                invoiceid: location.state.invoiceid
            },
            method: 'POST'
        };
        let result: Result = await axios(option);
        if (result.data.code === 0) {
            result.data.data.invAmount = moneyComma(result.data.data.invAmount);
            result.data.data.invoiceBilList.forEach((item: any): void => {
                item.payTs = getTimestampToTime(item.payTs, 'yyyy-MM-DD');
                item.billAmount = moneyComma(item.billAmount);
                item.billState =item.billState? '已扣费' : '未扣费';
            });
            if (!isNaN((result.data.data.invType as number))) {
                result.data.data.invType = InvType[(result.data.data.invType as number)];
            }
            result.data.data.invState = InvState[(result.data.data.invState as number)];
            setResult(result.data.data);
            setInvVBRKItem(result.data.data.invVBRKItem);
            setTableData(result.data.data.invoiceBilList);
			setTotal(result.data.data.totalNumber);
        };
        setTableLoading(false);
    };

    const handleView: Function = (): void => {
        history.push('/content/user/detail/financial/invoice', location.state);
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const computedClass: Function = (state: number | string): string => {
        return state === "已完成"? styles.complete : state === "已拒绝"? styles.refused : styles.agree;
    };

    const handlePdfFn: Function = (row: any): void => {
        const { billId } = row;
        const option: Option = {
            url: `${baseURL.replace('/v1', '/v3')}/developers/exportBillDetailInfo/${billId}`,
            method: 'GET',
            responseType: 'arraybuffer'
        };
        axios(option).then(res => {
            const fileName: string = `账单.pdf`;
            const url: string = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf;charset=utf-8" }));
            const a = document.createElement("a");
            a.download = fileName;
            a.href = url;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    };

    const handleDownload: Function =  async (row: any): Promise<void> => {
        const { billId } = row;
        const option: Option = {
            url: '/developers/getBillDayDetailInfo',
            data: { billId, devpId: location.state.uid },
            method: 'POST',
        }; 
        const result = await axios(option);
        if (result.data.code !== 0) {
            message.error("Bill details download failed");
            return;
        }
        const tHeader: Array<string> = [
            "项目ID",
            "日期",
            "音频",
            "高清视频",
            "超高清视频",
            "实时消息",
            "2K消费分钟数",
            "2K+消费分钟数",
            "实时对讲"
        ];
        const filterVal: Array<string> = [
            "appId",
            "day",
            "audMin",
            "hdMin",
            "hhdMin",
            "rtmNum",
            "tkMin",
            "tkpMin",
            "talkAudMin"
        ];
        if (result.data.data.isMiniAppOn) {
            tHeader.push("小程序音频");
            tHeader.push("小程序视频");
            filterVal.push("miniAppAudMin");
            filterVal.push("miniAppVidMin");
        };
        if (result.data.data.isVodOn) {
            tHeader.push("云端录制音频");
            tHeader.push("云端录制高清视频");
            tHeader.push("云端录制超高清视频");
            tHeader.push("云端录制2K视频消费分钟数");
            tHeader.push("云端录制2k+视频消费分钟数");

            filterVal.push("audRecMin");
            filterVal.push("hdRecMin");
            filterVal.push("hhdRecMin");
            filterVal.push(" tkRecMin");
            filterVal.push("tkpRecMin");
        };
        if (result.data.data.isPushOn) {
            tHeader.push("音频旁路推流");
            tHeader.push("高清视频旁路推流");
            tHeader.push("超高清视频旁路推流");
            tHeader.push("2k旁路推流视频消费分钟数");
            tHeader.push("2k+旁路推流视频消费分钟数");

            filterVal.push("audPushMin");
            filterVal.push("hdPushMin");
            filterVal.push("hhdPushMin");
            filterVal.push("tkPushMin");
            filterVal.push("tkpPushMin");
        };
        exportSCV(tHeader, filterVal, result.data.data.data, fileName);
    };
    
    return (
        <div className={ styles.detail }>
            <div className={ styles.titleBox }>
                <p className={ styles.title } onClick={ () => handleView() }>发票管理</p>
                <span className={ styles.slash }>/</span>
                <span>发票详情</span>
            </div>

            <div className={ styles.information }>
                { result.invType !== '个人发票' && <div className={ styles.entson }>
                    <p>发票类型</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>发票类型：</span>
                        <span>{ result.invType }</span>
                    </div>
                    <p>发票抬头信息</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>企业名称：</span>
                        <span>{ invVBRKItem.comName }</span>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>社会信用代码：</span>
                        <span>{ invVBRKItem.comSocialId }</span>
                    </div>
                    <p>发票信息</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>金额：</span>
                        <span>{ result.invAmount }</span>
                    </div>
                    { invVBRKItem.remarks && <div className={ styles.line }>
                        <span className={ styles.span }>备注：</span>
                        <span>{ result.remarks }</span>
                    </div> }
                    <p>收件地址</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>收件人：</span>
                        <span>{ invVBRKItem.comCnee }</span>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>联系方式：</span>
                        <span>{ invVBRKItem.comCneePhone }</span>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>收件地址：</span>
                        <span>{ invVBRKItem.comCneeAddr }</span>
                    </div>
                    <p>状态</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>状态：</span>
                        <span className={ computedClass(invVBRKItem.invState) }>{ result.invState }</span>
                    </div>
                    { result.invState === '已同意' && <div className={ styles.line }>
                        <span className={ styles.span }>运单号：</span>
                        <span>{ result.waybillNo }</span>
                    </div> }
                    { result.invState === '已拒绝' && <div className={ styles.line }>
                        <span className={ styles.span }>拒绝原因：</span>
                        <span>{ result.detail }</span>
                    </div> }
                </div> }

                { result.invType === '个人发票' && <div className={ styles.entson }>
                    <p>发票类型</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>发票类型：</span>
                        <span>{ result.invType }</span>
                    </div>
                    <p>发票抬头信息</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>名称：</span>
                        <span>{ invVBRKItem.pIdName }</span>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>社会信用代码：</span>
                        <span>{ invVBRKItem.comSocialId }</span>
                    </div>
                    <p>发票信息</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>金额：</span>
                        <span>{ result.invAmount }</span>
                    </div>
                    { invVBRKItem.remarks && <div className={ styles.line }>
                        <span className={ styles.span }>备注：</span>
                        <span>{ result.remarks }</span>
                    </div> }
                    <p>收件地址</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>收件人：</span>
                        <span>{ invVBRKItem.pIdCnee }</span>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>联系方式：</span>
                        <span>{ invVBRKItem.pIdCneePhone }</span>
                    </div>
                    <div className={ styles.line }>
                        <span className={ styles.span }>收件地址：</span>
                        <span>{ invVBRKItem.pIdCneeAddr }</span>
                    </div>
                    <p>状态</p>
                    <div className={ styles.line }>
                        <span className={ styles.span }>状态：</span>
                        <span className={ computedClass(invVBRKItem.invState) }>{ result.invState }</span>
                    </div>
                    { result.invState === 3 && <div className={ styles.line }>
                        <span className={ styles.span }>运单号：</span>
                        <span>{ result.waybillNo }</span>
                    </div> }
                    { result.invState === 2 && <div className={ styles.line }>
                        <span className={ styles.span }>拒绝原因：</span>
                        <span>{ result.detail }</span>
                    </div> }
                </div> }
                
                <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='billId'
				sticky={ true }
				dataSource={ tableData }>
				<Column align='center' title="账期" render={ (operation: any, record: any): ReactElement => (
					<div>{ record.billMonth }{ record.extra }</div>
				) } />
				<Column align='center' title="金额"  dataIndex="billAmount" />
				<Column align='center' title="状态" render={ (operation: any, record: any): ReactElement => (
					<div className={ computedClass(record.billState) }>{ record.billState }</div>
				) }/>
				<Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
					<div className={ styles.operationBox }>
                        { (record.billType !== 5 && findPermissions(11455)) && <p onClick={ () => handlePdfFn(record) } className={ styles.operation }>下载</p> }
                        { (record.billType !== 5 && record.billType !== 4 && findPermissions(11456)) && <p onClick={ () => handleDownload(record) } className={ styles.operation }>下载账单详情</p> }
                    </div>
				) } />
			</Table>
            </div>
        </div>
    )
};

export default Detail;