import * as echarts from 'echarts';

const chartsColor: Array<string> = ["#1380FF", "#FF8723", "#1BBC72", "#FE4545", "#4554FE"];

const oPtioncharst: any = {
	tooltip: {
		trigger: "item",
		position: [0, 30],
		formatter: `<div style='width:82px;text-align: center;color:#7B838B;font-size:16px'>{b0}</div>
		<div style='text-align: center;border-top: 1px solid #ccc;color:#7B838B;font-size:16px'>{c0}分钟</div>`,
		backgroundColor: "rgba(255,255,255,1)",
		extraCssText: "box-shadow: 0 0 8px 0 rgba(202,202,202,1);"
	},
	series: [
		{
			name: "基本通信",
			type: "pie",
			radius: ["40%", "80%"],
			avoidLabelOverlap: false,
			itemStyle: {
				borderRadius: 10,
				borderColor: "#fff",
				borderWidth: 2
			},
			label: {
				show: false,
				position: "center"
			},
			emphasis: {
				label: {
				show: false,
				fontSize: "40",
				fontWeight: "bold"
				}
			},
			labelLine: {
				show: false
			},
			data: [
				{ value: 1048, name: "Audio", itemStyle: { color: chartsColor[0] } },
				{ value: 735, name: "HD", itemStyle: { color: chartsColor[1] } },
				{ value: 580, name: "Full HD", itemStyle: { color: chartsColor[2] } },
				{ value: 484, name: "2K", itemStyle: { color: chartsColor[3] } },
				{ value: 300, name: "2K+", itemStyle: { color: chartsColor[4] } }
			]
		}
  	]
};

const linkageFn: Function = (myEcharts: echarts.ECharts, category: string, styles: any) => {
	const oAll: any = (document.getElementById("callStatisticsTable" + category) as HTMLElement).children;
	if (oAll) {
		myEcharts.dispatchAction({ type: "showTip", seriesIndex: 0, name: oAll[0].id });
		myEcharts.dispatchAction({ type: "highlight", seriesIndex: 0, dataIndex: 0 });
		oAll[0].className = styles.callStatisticsActive;
		myEcharts.on("mousemove", (params: any): void => {
			for (let index = 0; index < oAll.length; index++) {
				oAll[index].className = "";
			}
			const oId: HTMLElement = (document.getElementById(params.name) as HTMLElement);
			oId.className = styles.callStatisticsActive;
		});
		myEcharts.on("mouseout", (): void => {
			for (let index = 0; index < oAll.length; index++) {
				oAll[index].className = "";
			}
		});
		for (let index = 0; index < oAll.length; index++) {
			// eslint-disable-next-line no-loop-func
			oAll[index].onmouseover = (): void => {
				for (let item = 0; item < oAll.length; item++) {
					oAll[item].className = "";
				}
				oAll[index].className = styles.callStatisticsActive;
				myEcharts.dispatchAction({ type: "showTip", seriesIndex: 0, name: oAll[index].id });
				myEcharts.dispatchAction({ type: "highlight", seriesIndex: 0, dataIndex: index });
			}
			// eslint-disable-next-line no-loop-func
			oAll[index].onmouseout = (): void => {
				myEcharts.dispatchAction({ type: "hideTip", seriesIndex: 0, name: oAll[index].id });
				myEcharts.dispatchAction({ type: "downplay", seriesIndex: 0, dataIndex: index });
			};
    	}

    	(document.getElementById("callStatisticsTable" + category) as HTMLElement).addEventListener("mouseout", (): void => {
			for (let index = 0; index < oAll.length; index++) {
				oAll[index].className = "";
			}
		});
  	}
};

const initEcharts: Function = (id: string, data: { obj: any; array: any; }, styles: any) => {
	const drawerObj: any = data.obj;
	const drawerArray: Array<any> = data.array;
	const setTimer = setInterval(async (): Promise<void> => {
		if (document.getElementById(id)) {
			clearInterval(setTimer);
			echarts.dispose(document.getElementById(id) as HTMLElement)
			const drawerEcharts = echarts.init(document.getElementById(id) as HTMLElement);
			oPtioncharst.series[0].name = drawerObj.catName;
			const oArray: Array<any> = [];
			drawerArray.forEach((item: any, index: number): void => {
				oArray.push({
					value: item.minute,
					name: item.name,
					itemStyle: { color: chartsColor[index] }
				});
			});
			oPtioncharst.series[0].data = oArray;
			drawerEcharts.setOption(oPtioncharst, true);
			await linkageFn(drawerEcharts, drawerObj.category, styles);
		}
	}, 100);
};

export { initEcharts };
