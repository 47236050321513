import axios, { AxiosResponse } from 'axios';
import { Option } from './axios-types';
import { message } from 'antd';
import { baseURL } from './baseUrl';
import store from '../store';
axios.defaults.baseURL = baseURL;

export default function request(config: Option): Promise<any> {
    let index: number = config.url.indexOf('/v2');
    let indexv3: number = config.url.indexOf('/v3');
    if (index === 0 || indexv3 === 0) {
        axios.defaults.baseURL = baseURL.replace('/v1', '');
    } else {
        index = config.url.indexOf('getBillDayDetailInfo');
        if (index !== -1) {
            axios.defaults.baseURL = baseURL.replace('/v1', '/v4');
        } else {
            axios.defaults.baseURL = baseURL;
        };
    }
    axios.defaults.headers['Artc-Token'] = store.getState().token;
    // 添加响应拦截器
    axios.interceptors.response.use((response): AxiosResponse => response, 
    (error): any => {
        const networkError = error.toString().indexOf('Network Error');
        const tokenError = error.toString().indexOf('401');
        const permError = error.toString().indexOf('403');
        if (tokenError !== -1) {
            message.error('Token authentication failed');
            window.location.href = '/';
        } else if (networkError !== -1) {
            return error;
        } else if (permError !== -1) {
            return {data: {
                code: 403,
                type: 'perm Error',
                error
            }};
        }
    });

    return new Promise((resolve, reject): void => {
        let p: Promise<AxiosResponse>;
        if (config.method === 'POST') {
            p = axios.post(config.url, config.data);
        } else {
            p = axios.get(config.url, {
                params: config.data,
                responseType: config.responseType
            });
        }
        p.then((res: AxiosResponse): void => {
            resolve(res);
        }).catch((err: any): void => {
            reject(err);
        })
    });
};