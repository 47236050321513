
import { FC, ReactElement, useState, useEffect } from 'react';
import { Table, Select } from 'antd';
import { findPermissions } from '../../../../../../../../api/permiss';
import { useLocation, useHistory } from 'react-router-dom';
import { getTimestampToTime, moneyComma } from '../../../../../../../../api/index';
import { Option } from '../../../../../../../../axios/axios-types';
import axios from '../../../../../../../../axios/axios';
import styles from './pendhasbeen.less';
const { Column } = Table;
namespace Antd {
    export const { Option } = Select;
};

interface TableDataAttr {
    invAmount: number;
    invMonth: string;
    invState: number | string;
    invTs: number;
    invoiceid: string;

    billAmount:	number;
    billMonth: string;
    billState: number | string;
    billid: string;
    payTs: string;
};

interface Data {
	code: number;
	data: { 
        list: Array<TableDataAttr>;
        totalNumber: number;
    };
};

interface Result {
	data: Data;
};

enum InvState {never, '已同意', '已拒绝', '已完成'};
enum BillState {'未扣费', '已扣费'};

const Pendhasbeen: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<TableDataAttr>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [selectValue, setSelectValue]: [number, Function] = useState(1);
	const [url, setUrl]: [string, Function] = useState('/developers/getHandledInvoiceList');

    useEffect((): void => {
        const value = location.state.selectValue;
        if (value) {
            setSelectValue(value);
            setUrl(value === 1? '/developers/getHandledInvoiceList' : '/developers/getWaitApplyBill');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect((): void => {
        getInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, pageSize, current]);

    const getInvoiceList: Function = async (): Promise<void> => {
        setTableLoading(true);
        const option: Option = {
            url,
            data: { 
                pageSize,
                pageNum: current,
                uid: location.state.uid
            },
            method: 'POST'
        };
        let result: Result = await axios(option);
        if (result.data.code === 0) {
            if (selectValue === 1) {
                result.data.data.list.forEach((item: TableDataAttr): void => {
                    item.invState = InvState[(item.invState as number)];
                    item.invAmount = moneyComma(item.invAmount);
                    item.invTs = getTimestampToTime(item.invTs, 'yyyy-MM-DD');
                });
            } else if (selectValue === 2) {
                result.data.data.list.forEach((item: TableDataAttr): void => {
                    item.billState = BillState[(item.billState as number)];
                    item.billAmount = moneyComma(item.billAmount);
                });
            }
            setTableData(result.data.data.list);
			setTotal(result.data.data.totalNumber);
        };
        setTableLoading(false);
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleSelectChange: Function = (value: number): void => {
        setSelectValue(value);
        setUrl(value === 1? '/developers/getHandledInvoiceList' : '/developers/getWaitApplyBill');
    };

    const computedClass: Function = (record: TableDataAttr): string => {
        const target: string = selectValue === 1? (record.invState as string) : (record.billState as string);
        if (selectValue === 1) {
            return target === "已完成"? styles.complete : target === "已拒绝"? styles.refused : styles.agree;
        } else {
            return target === "已扣费"? styles.agree : styles.refused;
        }
    };

    const handleView: Function = (record: TableDataAttr): void => {
        history.push('/content/user/detail/financial/invoice/detail', Object.assign(location.state, record));
    };
    return (
        <div className={ styles.pendhasbeen }>
            <p className={ styles.title }>已处理 / 待申请发票</p>
            <Select
                showSearch
                style={{ width: 200 }}
                value={ selectValue }
                placeholder="Select a person"
                onChange={ (value) => handleSelectChange(value) }
            >
                <Antd.Option value={ 1 }>已处理</Antd.Option>
                <Antd.Option value={ 2 }>待申请</Antd.Option>
            </Select>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey={() => `${Math.floor(Math.random() * 123333)}`}
				sticky={ true }
				dataSource={ tableData }>
				{ selectValue === 1 && <Column align='center' title="申请日期"  dataIndex="invTs" /> }
				<Column align='center' title="账期" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<span>{ selectValue === 1? record.invMonth : record.billMonth }</span>
				) } />
				<Column align='center' title="金额" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<span>{ selectValue === 1? record.invAmount : record.billAmount }</span>
				) } />
				<Column align='center' title="状态" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<span className={ computedClass(record) }>{ selectValue === 1? record.invState : record.billState }</span>
				) } />
				<Column align='center' title="操作" render={ (operation: any, record: TableDataAttr): ReactElement => (
                    <div>
					    { (selectValue === 1 && findPermissions(11454)) && <p className={ styles.operation } onClick={ () => handleView(record) }>查看</p> }
                        { selectValue === 2 && <img className={ styles.img } src={ require('../../../../../../../../assets/images/jianshao.svg').default } alt="" /> }
                    </div>
				) } />
			</Table>
        </div>
    );
};

export default Pendhasbeen;