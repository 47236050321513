import { FC, ReactElement, useState } from 'react';
import { Form, Input, Button, InputNumber, Select, message } from 'antd';  
import { SearchOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { getTimestampToTime, getNowFormatDate } from '../../../../api/index';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './customerAdd.less';
const { TextArea } = Input;

namespace Antd {
    export const { Option } = Select;
};

interface Result {
	data: {
        code: number;
        data: any;
    };
};


const CustomerAdd: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [searchLoading, setSearchLoading]: [boolean, Function] = useState(false);
    const [nextLoading, setNextLoading]: [boolean, Function] = useState(false);
    const [cellphone, setCellphone]: [string, Function] = useState('');
    const [arrAdminInfo, setArrAdminInfo]: [any[], Function] = useState([]);
    const [arrUserAppId, setArrUserAppId]: [any[], Function] = useState([]);
    const [devpAuthInfo, setDevpAuthInfo]: [any, Function] = useState({});
    const [userDetail, setUserDetail]: [any, Function] = useState({});
    const [infor, setInfor]: [any, Function] = useState({
        tsstr: getNowFormatDate(),
        period: 180,
        concurrentNum: 6,
        email: "",
        phone: "",
        oem: "",
        appId: "",
        devpType: location.state.devp,
        licType: 3,
        remark: ""
    });
    const [isSearch, setIsSearch]: [boolean, Function] = useState(false);

    const handleSearch: Function = async (): Promise<void> => {
        if (cellphone) {
            const option: Option = {
                url: '/primgr/getDevpInfoByCellPhone',
                data: { cellphone },
                method: 'POST'
            };
            setSearchLoading(true);
            const result: Result = await axios(option);
            setSearchLoading(false);
            if (result.data.code === 0) {
                const arrAdminInf: any[] = [];
                const arrUserAppI: any[] = [];
                result.data.data.arrAdminInfo.forEach((item: any): void => {
                    arrAdminInf.push({
                        value: item.aid,
                        label: item.name
                    });
                    if (result.data.data.userDetail.adminName === item.name) {
                        result.data.data.userDetail.adminName = item.aid;
                    }
                });
                result.data.data.arrUserAppId.forEach((item: any): void => {
                    arrUserAppI.push({
                        value: item.appId,
                        label: item.appId
                    });
                });
                setArrAdminInfo(arrAdminInf);
                setArrUserAppId(arrUserAppI);
                result.data.data.userDetail.userCreateAt = getTimestampToTime(result.data.data.userDetail.userCreateAt, 'yyyy-MM-DD');
                setDevpAuthInfo(result.data.data.devpAuthInfo);
                setUserDetail(result.data.data.userDetail);
                setIsSearch(true);
            } else if (result.data.code === 1000) {
                message.error("客户信息不存在");
            } else {
                message.error("未知错误");
            }
        } else {
            message.error('电话号码错误');
        }
    };

    const handleChange: Function = (value: any, type: string): void => {
        const info: any = JSON.parse(JSON.stringify(infor));
        info[type] = value;
        setInfor(info);
    };

    const updateUserSales: Function = async (value: string): Promise<void> => {
        const data: any = {
            salesId: value,
            uId: userDetail.uid
        };
        const option: Option = {
            url: '/developers/updateUserSales',
            data,
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            handleSearch();
        } else {
            message.error('销售修改失败');
        }
    };

    const handleNextStep: Function = async (): Promise<void> => {
        setNextLoading(true);
        if (isSearch) {
            const option: Option = {
                url: '/primgr/insertDevp',
                data: {
                    ...infor,
                    period: +infor.period,
                    name: userDetail.name || "",
                    uid: userDetail.uid,
                    ts: parseInt((Date.parse((new Date()).toString()) / 1000).toString())
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                setNextLoading(false);
                const query: any = {
                    uid: userDetail.uid,
                    licType: infor.licType,
                    devp: location.state.devp,
                    license: result.data.data.license
                };
                history.push('/content/private/customerAddNext', query);
            } else if (result.data.code === 5002) {
                message.error("厂商已经存在");
            } else {
                message.error("数据提交失败");
            }
        } else {
            message.error("请搜索电话号码");
        }
        setNextLoading(false);
    };

    const handleBack: Function = (): void => {
        history.goBack();
    };

    const validateLimit: any = async (rule: any, value: any): Promise<void> => {
        if (rule.field === "email") {
            // eslint-disable-next-line no-useless-escape
            const myreg:RegExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
            // const myreg: RegExp = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
            if (value === "" || !myreg.test(value)) {
                throw new Error('预留邮箱格式不正确');
            }
        } else if (rule.field === "period") {
            if (value <= 0) {
                throw new Error("最小使用时间为1天");
            } else if (isNaN(value)) {
                throw new Error("最小使用时间只能是数字");
            } else if ((value + '').indexOf('.') !== -1) {
                throw new Error("最小使用时间只能是正整数");
            }
        } else if (rule.field === "concurrentNum") {
            if (!(value >= 1)) {
                throw new Error("最小并发请求数为1");
            }
        } else if (rule.field === "phone") {
            const phoneReg: RegExp =/^[1][3,4,5,7,8,9][0-9]{9}$/;
            if (value === '') {
                throw new Error("请填写预留手机号码");
            } else if (!phoneReg.test(value)) {
                throw new Error("预留手机号码有误");
            }
        } else if (rule.field === "oem") {
            if (value === '') {
                throw new Error("请填写厂商");
            }
        } else if (rule.field === "appId") {
            if (!value) {
                throw new Error("请选择appid");
            }
        }
    };

    const onFinish: any = (): void => {
        handleNextStep();
    };

    return (
        <div className={ styles.customerAdd }>
            <div className={ styles.modal }>
                <p className={ styles.title }>添加客户</p>
                <div className={ styles.search }>
                    <span>手机号：</span>
                    <Input placeholder="请输入手机号" 
                    value={ cellphone } className={ styles.input }
                    onChange={ (e) => setCellphone(e.target.value) } />
                    <Button type="primary" loading={ searchLoading } 
                    onClick={ () => handleSearch() }
                    icon={<SearchOutlined />}>Search</Button>
                </div>
                <Form
                name="basic"
                layout="inline"
                initialValues={ infor }
                onFinish={ onFinish }
                autoComplete="off"
                >
                    <Form.Item label="客户名称">
                        <Input className={ styles.input } value={ userDetail.name } placeholder="暂无信息" disabled={ true } />
                    </Form.Item>
                    <Form.Item label="添加类型">
                        <Select
                        value={ infor.devpType }
                        style={{ width: 200 }}
                        onChange={ (value) => handleChange(value, 'devpType') }
                        >
                            <Antd.Option value={ 1 }>正式期</Antd.Option>
                            <Antd.Option value={ 2 }>测试期</Antd.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="邮箱">
                        <Input className={ styles.input } value={ userDetail.email } placeholder="暂无信息" disabled={ true } />
                    </Form.Item>
                    <Form.Item label="部署类型">
                        <Select
                        value={ infor.licType }
                        style={{ width: 200 }}
                        onChange={ (value) => handleChange(value, 'licType') }
                        >
                            <Antd.Option value={ 3 }>私有云</Antd.Option>
                            <Antd.Option value={ 2 }>混合云</Antd.Option>
                        </Select>
                    </Form.Item>  
                    <Form.Item label="公司地址">
                        <Input className={ styles.input } value={ devpAuthInfo.comAddr } placeholder="暂无信息" disabled={ true } />
                    </Form.Item>
                    <Form.Item label="appid" name="appId" rules={[{ required: true }, { validator: validateLimit }]}>
                        <Select
                        value={ infor.appId || undefined }
                        placeholder="Select appId"
                        style={{ width: 200 }}
                        onChange={ (value) => handleChange(value, 'appId') }
                        >
                            {
                                arrUserAppId.map((item: any, index: number): ReactElement => {
                                    return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="手机号">
                        <Input className={ styles.input } value={ userDetail.cellphone } placeholder="暂无信息" disabled={ true } />
                    </Form.Item>
                    <Form.Item label="所属销售">
                        <Select
                        value={ userDetail.adminName }
                        style={{ width: 200 }}
                        onChange={ (value) => updateUserSales(value) }
                        >
                            {
                                arrAdminInfo.map((item: any, index: number): ReactElement => {
                                    return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="注册时间">
                        <Input className={ styles.input } value={ userDetail.userCreateAt } placeholder="暂无信息" disabled={ true } />
                    </Form.Item>
                    <Form.Item label="预留手机号" name="phone" rules={[{ required: true }, { validator: validateLimit }]}>
                        <Input className={ styles.input } placeholder="请输入预留的手机号码" value={ infor.phone } onChange={ (e) => handleChange(e.target.value, 'phone') } />
                    </Form.Item>
                    <Form.Item label="申请时间">
                        <Input className={ styles.input } value={ infor.tsstr } placeholder="暂无信息" disabled={ true } />
                    </Form.Item>
                    <Form.Item label="厂商" name="oem" rules={[{ required: true }, { validator: validateLimit }]}>
                        <Input className={ styles.input } placeholder="请输入厂商" value={ infor.oem } onChange={ (e) => handleChange(e.target.value, 'oem') } />
                    </Form.Item>
                    <Form.Item label="认证信息">
                        { devpAuthInfo.uAuthType === 1 && <div className={ styles.hoverbox }>
                            <a href={ devpAuthInfo.idFront } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.idFront.substr(devpAuthInfo.idFront.length - 15) }</a>
                            <a href={ devpAuthInfo.idBack } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.idBack.substr(devpAuthInfo.idBack.length - 15) }</a>
                        </div> }
                        { devpAuthInfo.uAuthType === 2 && <div className={ styles.hoverbox }>
                            <a href={ devpAuthInfo.licUrl } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.licUrl.substr(devpAuthInfo.licUrl.length - 15) }</a>
                        </div> }
                        { !devpAuthInfo.uAuthType && <Input className={ styles.input } value='暂未认证' placeholder="暂无信息" disabled={ true } /> }
                    </Form.Item>
                    <Form.Item label="预留邮箱" name='email' rules={[{ required: true }, { validator: validateLimit }]}>
                        <Input className={ styles.input } placeholder="请输入预留邮箱" value={ infor.email } onChange={ (e) => handleChange(e.target.value, 'email') } />
                    </Form.Item>
                    <Form.Item label="使用时长" name='period' rules={[{ required: true }, { validator: validateLimit }]}>
                        <Input className={ styles.input } addonAfter='天' placeholder="请输入使用时长" value={ infor.period } onChange={ (e) => handleChange(e.target.value, 'period') } />
                    </Form.Item>
                    <Form.Item label="并发数量" name='concurrentNum' rules={[{ required: true }, { validator: validateLimit }]}>
                        <InputNumber className={ styles.input } placeholder="请输入并发数量" min={ 1 } value={ infor.concurrentNum } onChange={ (value) => handleChange(value, 'concurrentNum') } />
                    </Form.Item>
                    <Form.Item label="备注">
                        <TextArea className={ styles.textarea } placeholder="请输入备注" rows={ 8 } value={ infor.remark } onChange={ (e) => handleChange(e.target.value, 'remark') } />
                    </Form.Item>
                    <Form.Item className={ styles.btnItem }>
                        <div className={ styles.btnBox }>
                           { isSearch && <Button className={ styles.btn } htmlType="submit" disabled={ nextLoading } loading={ nextLoading } type="primary">Next step</Button> }
                           { isSearch || <Button className={ styles.btn } type="primary" onClick={ () => handleNextStep() }>Next step</Button> }
                           <Button className={ styles.btn } onClick={ () => handleBack() }>Back</Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};

export default CustomerAdd;