import { FC, ReactElement, useEffect, useState } from 'react';
import { Table, Modal, Button, message } from 'antd';
import { ResultData } from '../resultTypes';
import axios from '../../../../../../axios/axios';
import { Option } from '../../../../../../axios/axios-types';
import pubsub from 'pubsub-js';
import * as echarts from 'echarts';
import styles from './board.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const Board: FC = (): ReactElement => {
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<ResultData>, Function] = useState([]);
	const [boardNums, setBoardNums]: [number, Function] = useState(0);
	const [opened, setOpened]: [boolean, Function] = useState(false);
	const [data, setData]: [any, Function] = useState({});
    const [isModalVisible, setIsModalVisible]: [boolean, Function] = useState(false);

    useEffect((): any => {
        window.addEventListener("resize", windowResize);
        return (): void => {
            window.removeEventListener("resize", windowResize);
        };
    });

    const windowResize = (): void => {
        echarts.init(document.getElementById('echartsLine') as HTMLElement).resize();
    };

    useEffect((): void => {
        pubsub.unsubscribe('dosage');
        pubsub.subscribe('dosage', async (name: string, data: any): Promise<void> => {
            setData(data);
            const open: boolean = await getAppIsOn(data);
            setOpened(open);
            open && getUserConsumption(data);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAppIsOn: Function = async (data: any): Promise<boolean> => {
        if (data.sType) {
            const option: Option = {
                url: '/developers/getAppIsOn',
                data: { aid: data.appid },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                return result.data.data[0].wbOn === 1;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const getUserConsumption: Function = async (data: any): Promise<void> => {
        const option: Option = {
            url: '/developers/getUserConsumption',
            data: {
                catType: 7,
                appId: data.appid,
                devpId: data.uid,
                gType: data.gType,
                sType: data.sType,
                startTs: data.startTs,
                stopTs: data.stopTs,
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        if (result.data.code === 0 && result.data.data.list) {
            initCharts(result.data.data.list);
            setBoardNums(result.data.data.list[0].wbMin);
        };
        setTableLoading(false);
    };

    const initCharts: Function = (data: Array<ResultData>): void => {
        setTableData(data);
        const timeList: Array<string> = [];
        const dataList: Array<number> = [];
        data.forEach((item: ResultData): void => {
            timeList.push(item.date);
            dataList.push(item.wbMin as number);
        });
        const element: HTMLElement | null = document.getElementById('echartsLine');
		if (!element) return
        echarts.init(element).setOption({
            tooltip: {
                trigger: "axis",
                backgroundColor: "#FFF",
                extraCssText: "box-shadow:0 0 18px #ccc",
                textStyle: {
                    fontSize: "12px",
                    width: "40px",
                    fontFamily: "Microsoft YaHei Regular, Microsoft YaHei Regular-Regular",
                    fontWeight: 400,
                    color: "#606060"
                },
                formatter: function(params: any): string {
                  var res = `<div style='padding:2px 10px;'>${params[0].name}</div>`;
                  for (var i = 0, l = params.length; i < l; i++) {
                    if (params[i].seriesName === "白板分钟数") {
                      res += `
                        <div style='display: flex;align-items:center;justify-content: space-between;padding:4px 10px;width: 150px'>
                            <div style='display: flex;align-items:center;'>
                                <span style='width: 16px;height: 3px; display: inline-block; background: #2FA2FF;margin-right: 10px'></span>
                                <span>${params[i].seriesName}</span>
                            </div>
                            <span>${params[i].value}</span>
                        </div>`;
                    }
                    setBoardNums(params[i].value);
                  }
                  return res;
                },
                position: ["6%", "30%"]
              },
              grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true
              },
              color: ["#38A4FC"],
              xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: timeList,
                    axisLine: {
                        show: false //不显示坐标轴线
                    },
                    axisLabel: {
                        show: true,
                        textStyle: { color: "#666" }
                    },
              },
              yAxis: {
                    type: "value",
                    axisLine: { show: false },
                    axisLabel: {
                        show: true,
                        textStyle: { color: "#666" }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ["#eee"],
                            width: 1,
                            type: "solid"
                        }
                    }
              },
            series: [
                {
                    name: "白板分钟数",
                    type: "line",
                    data: dataList
                }
            ]
        });
    };

    const handleSorter: Function = (a: any, b: any): boolean => {
        return a.date > b.date;
    }; 

    const handleOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/app/updateWbOn',
            data: { aid: data.appid, wbOn: 1 },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const open: boolean = await getAppIsOn(data);
            setOpened(open);
            open && getUserConsumption(data);
            message.success('服务开通成功');
        } else {
            message.error('服务开通失败');
        }
        setIsModalVisible(false);
    };
    return (
        <div className={ styles.board }>
            { opened && <>
                <div className={ styles.top }>
                    <img src={ require('../../../../../../assets/images/board.png').default } alt="" />
                    <div className={ styles.number }>
                    <span>{ boardNums }</span>
                    <span>白板分钟数</span>
                    </div>
                </div>
                <div id='echartsLine' className={ styles.echartsLine }></div>
                <Table 
                    loading={ tableLoading } 
                    className={ styles.table }
                    rowKey='date'
                    sticky={ true }
                    pagination={ false }
                    sortDirections={['ascend', 'descend', 'ascend']} 
                    dataSource={ tableData }
                    summary={ pageData => {
                        let boardNum: number = 0;
                        pageData.forEach((item: any) => {
                            item.wbMin > boardNum && (boardNum = item.wbMin);
                        });
                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell align='center' className={ styles.cell } index={ 1 }>最大值</Table.Summary.Cell>
                                <Table.Summary.Cell align='center' className={ styles.cell } index={ 2 }>{ boardNum }</Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    }}
                    >
                    <Column align='center' defaultSortOrder='descend' showSorterTooltip={ false } sorter={ (a, b) => handleSorter(a, b) } title="日期" dataIndex="date" />
                    <Column align='center' title="白板分钟数"  dataIndex="wbMin" />
                </Table>
                <div className={ styles.instructions }>
                    <ul>
                        <li><span>*</span> 当天数据会有大约2小时延迟。</li>
                        <li><span>*</span> 由于本页统计以秒计量，可能与最终以分钟计量的账单用量有误差。最终实际用量需要查看准确帐单列表的账单详情。</li>
                    </ul>
                </div>
            </> }
            { opened || <>
                <div className={ styles.noOpen }>
                    <img src={ require('../../../../../../assets/images/Without_permission.png').default } alt="" />
                    <div className={ styles.noOpenSon }>
                        <p>您需要为当前的项目开通白板功能吗?</p>
                        <Button onClick={ () => setIsModalVisible(true) } type="primary">开通白板功能</Button>
                    </div>
                </div>
                <Modal title="开通服务"
                centered
                visible={ isModalVisible } onOk={ () => handleOk() } 
                onCancel={ () => setIsModalVisible(false) }>
                    <p>您需要为当前的项目开通白板功能吗?</p>
                </Modal>
            </> }
        </div>
    )
};


export default Board;