import { FC, ReactElement } from 'react';
import { Route } from 'react-router-dom';
import Records from './records/records';
import Detail from './detail/detail';
import Index from './index/index';
import styles from './mony.less';


const Mony: FC = (): ReactElement => {
    return (<div className={ styles.mony }>
        <p className={ styles.title }>license (一对多授权模式)</p>
        <Route path='/content/user/detail/appInformation/projectEditor/mony/records' component={ Records } exact />
        <Route path='/content/user/detail/appInformation/projectEditor/mony/detail' component={ Detail } exact />
        <Route path='/content/user/detail/appInformation/projectEditor/mony' component={ Index } exact />
    </div>);
};

export default Mony;