import { FC, ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { CacheRoute } from 'react-router-cache-route';
import { findPermissions } from '../../api/permiss';
import NavMenu from '../../components/navMenu';
import Header from '../../components/header';
import User from './user/user';
import Problem from './problem/problem';
import Email from './email/email';
import Version from './version/version';
import Directories from './directories/directories';
import Financial from './financial/financial';
import Private from './private/private';
import Permiss from './permiss/permiss';
import styles from './index.less';

const Content: FC = (): ReactElement => {
    return (
        <div className={ styles.content }>
            <Header />
            <div className={ styles.area }>
                <NavMenu />
                <div className={ styles.router }>
                    { findPermissions(10001) && <Route path='/content/user' component={ User } /> }
                    { findPermissions(20001) && <Route path='/content/problem' component={ Problem } /> }
                    { findPermissions(21001) && <Route path='/content/email' component={ Email } /> }
                    { findPermissions(22001) && <Route path='/content/version' component={ Version } /> }
                    { findPermissions(23001) && <Route path='/content/directories' component={ Directories } /> }
                    { findPermissions(24001) && <CacheRoute path='/content/financial' cacheKey='financial' component={ Financial } /> }
                    { findPermissions(30000) && <Route path='/content/private' component={ Private } /> }
                    { findPermissions(25001) && <Route path='/content/permiss' component={ Permiss } /> }
                </div>
            </div>
        </div>
    )
};

export default Content;