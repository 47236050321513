import { FC, ReactElement, useEffect, useState } from 'react';
import { findPermissions } from '../../../../../api/permiss';
import { useLocation } from 'react-router-dom';
import { Select, Input, AutoComplete, Cascader, Button, Table, message } from 'antd';
import { Option } from '../../../../../axios/axios-types';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getDate, transformDataDataFn } from '../../../../../api/index';
import axios from '../../../../../axios/axios';
import { scriptUrl } from '../../../../../axios/baseUrl';
import CallDetails from '../callDetails/callDetails';
import styles from './realTimeCloud.less';
const { Column } = Table;

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

namespace Antd {
    export const { Option } = Select;
};

interface TableData {
    timerLength: string;
    appId: string;
    chanCTs: string | number;
    chanMemNum: number;
    chanName: string;
    chanSId: string;
    chanSTs: string | number;
    chanState: number;
    starTms: string | number;
    endTms: string | number;
    ts: number;
    spamStyle: boolean;
};

interface SelectData {
    value: string | number;
    label: string;
}

interface Data {
	code: number;
	data: {
		chanList: Array<TableData>;
		totalNumber: number;
	};
};

interface Result {
	data: Data;
};
const RealTimeCloud: FC = (): ReactElement => {
    const location: any = useLocation();
    const [selectData, setSelectData]: [Array<SelectData>, Function] = useState([]);
    const [options]: [Array<SelectData>, Function] = useState([
        {
            value: 0,
            label: "频道ID"
        },
        {
            value: 1,
            label: "用户ID"
        }
    ]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<TableData>, Function] = useState([]);
	const [appId, setAppId]: [string, Function] = useState('');
	const [placeholder, setPlaceholder]: [string, Function] = useState('请输入频道ID');
	const [startTs, setStartTs]: [number, Function] = useState(getDate(1));
	const [state, setState]: [number, Function] = useState(0);
	const [sType, setSType]: [number, Function] = useState(0);
	const [appType, setAppType]: [number, Function] = useState(1);
	const [input, setInput]: [string, Function] = useState('');
	const [day, setDay]: [number, Function] = useState(1);
	const [first, setFirst]: [boolean, Function] = useState(true);
    const [visible, setVisible]: [boolean, Function] = useState(false);
    const [record, setRecord]: [any, Function] = useState({});
    const [loading, setLoading]: [boolean, Function] = useState(false);


    useEffect((): void => {
        getRedisAppId();
    }, []);

    useEffect((): void => {
		first? setFirst(false) : getV2ChannelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current]);

    const getRedisAppId: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/project/getRedisAppId',
            method: 'POST'
        };
        const result: {
            data: {
                code: number;
                data: Array<{ appId: string }>;
            }
        } = await axios(option);
        if (result.data.code === 0) {
            const target: Array<SelectData> = [];
            result.data.data.forEach((item: { appId: string }): void => {
                target.push({
                    value: item.appId,
                    label: item.appId
                });
            });
            setSelectData(target);
        } else {
            message.error('获取appId失败');
        }
    };

    const getV2ChannelList: Function = async (): Promise<void> => {
        if (appId) {
            let stopTs: number = 0;
            day === 2 && (stopTs = getDate(2) + 86399);
            day !== 2 && (stopTs = parseInt(((new Date(new Date().toLocaleDateString()).getTime() / 1000) + 60 * 60 * 24 - 1).toString()));
            setTableLoading(true);
            setLoading(true);
            const gType: number = location.state? location.state.gType : 1;
            const option: Option = {
                url: '/project/getV2ChannelList',
                data: {
                    appId,
                    input,
                    gType,
                    sType,
                    state,
                    startTs,
                    stopTs,
                    pageNum: current,
					pageSize,
                },
                method: 'POST'
            };
            const result: Result = await axios(option);
            setTableLoading(false);
            setLoading(false);
            setAppType(gType);
            if (result.data.code === 0) {
                setTotal(result.data.data.totalNumber);
                if (result.data.data.chanList.length > 0) {
                    setTableData(reorganization(result.data.data.chanList));
                } else {
                    setTableData([]);
                }
            } else {
                message.error('数据获取失败');
            }
        } else {
            message.error('请选择appId');
        } 
    };

    const reorganization: Function = (data: Array<TableData>): Array<TableData> => {
        const oArray: Array<TableData> = [];
        if (typeof data[0].chanCTs === "number" && typeof data[0].chanSTs === "number") {
            data.forEach((obj: TableData): void => {
                obj.starTms = obj.chanSTs;
                obj.endTms = obj.chanCTs;
                var second: number = 0;
                if (obj.endTms === 0) {
                    obj.spamStyle = false;
                    second = parseInt((Date.parse((new Date()).toString()) / 1000).toString()) - (obj.starTms as number);
                    obj.chanCTs = 0;
                } else {
                    obj.spamStyle = true;
                    second = (obj.endTms as number) - (obj.starTms as number);
                    obj.chanCTs = transformDataDataFn((obj.endTms as number) * 1000);
                }
                let hr: number = Math.floor(second / 3600);
                let min: number = Math.floor((second / 60) % 60);
                let sec: number = Math.floor(second % 60);
    
                if (hr === 0 && min > 0) {
                    obj.timerLength = min + "min " + sec + "s";
                } else if (min === 0) {
                    obj.timerLength = sec + "s";
                } else {
                    obj.timerLength = " " + hr + "h " + min + "min " + sec + "s";
                }
                obj.chanSTs = transformDataDataFn((obj.chanSTs as number) * 1000);
                oArray.push(obj);
            });
            return oArray;
        } else {
            return data;
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleCascaderChange: Function = (val: Array<number>): void => {
		setSType(val[0]); 
        setPlaceholder(val? '请输入用户ID' : '请输入频道ID');
	};

    const handleSelectChange: Function = (val: number): void => {
		setDay(val); 
        setStartTs(getDate(val === 3? 7 : val));
	};

    const handleBtnClick: Function = (): void => {
        current === 1? getV2ChannelList() : setCurrent(1);
    };

    const handleCallDetail: Function = (record: TableData): void => {
        setVisible(true);
        setRecord(record);
    };

    const handleIconClick: Function = async (e: any, appId: string): Promise<void> => {
        e.stopPropagation();
        const option: Option = {
            url: '/project/deleteRedisAppId',
            data: { appId },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            getRedisAppId();
            message.success('appId删除成功');
        } else {
            message.error('appId删除失败');
        };
    };

    return (
        <div className={ styles.realTimeCloud }>
            <div className={ styles.search }>
                <AutoComplete
                placeholder='请输入appId'
                className={ styles.select }
                style={{ width: 200 }}
                onChange={ (val) => setAppId(val) }
                >
                    { selectData.map((item: any, index: number) => (
                        <AutoComplete.Option key={ index } value={ item.value }>
                            <div>{ item.value }</div>
                            <IconFont onClick={ (e) => handleIconClick(e, item.value) } type="icon-close" />
                        </AutoComplete.Option>
                    )) }
                </AutoComplete>
                <Select
                    className={ styles.select }
                    style={{ width: 200 }}
                    value={ day }
                    onChange={ (val) => handleSelectChange(val) }
                >
                    <Antd.Option value={ 1 }>今天</Antd.Option>
                    <Antd.Option value={ 2 }>昨天</Antd.Option>
                    <Antd.Option value={ 3 }>近七天</Antd.Option>
                </Select>
                <Input
                    className={ styles.input }
                    value={ input }
                    placeholder={ placeholder }
                    onChange={ (e) => setInput(e.target.value) }
                    addonBefore={<Cascader 
                        options={ options } 
                        defaultValue={ [sType] } 
                        onChange={ (val: any) => handleCascaderChange(val) }
                        placeholder="cascader" 
                        allowClear={ false }
                        style={{ width: 110 }} />}
                    defaultValue="mysite"
                />
                <Select
                    className={ styles.select }
                    style={{ width: 200 }}
                    value={ state }
                    onChange={ (val) => setState(val) }
                >
                    <Antd.Option value={ 0 }>全部</Antd.Option>
                    <Antd.Option value={ 1 }>通话进行中</Antd.Option>
                    <Antd.Option value={ 2 }>通话已结束</Antd.Option>
                </Select>
                <Button 
                disabled={ !findPermissions(20002) } 
                className={ styles.button } 
                loading={ loading }
                onClick={ () => handleBtnClick() } 
                type="primary" 
                icon={ <SearchOutlined /> }>
                Search
                </Button>
            </div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey={(record) => `${Math.floor(Math.random() * 123333)}`}
				sticky={ true }
				dataSource={ tableData }>
				<Column align='center' title="频道ID"  dataIndex="chanName" />
				<Column align='center' title="开始时间 - 结束时间" dataIndex="email" render={ (email: any, record: TableData): ReactElement => (
					<div className={ styles.render }>
						<p>{ record.chanSTs }</p>
                        <span className={ styles.span }>-</span>
						{ record.spamStyle && <p>{ record.chanCTs }</p> }
                        { record.spamStyle || <span className={ styles.ongoing }>进行中</span> }
					</div>
				) } />
                <Column align='center' title="时长" dataIndex="timerLength" />
				<Column align='center' title="参与用户数" dataIndex="chanMemNum" />
				<Column align='center' title="操作" width={ 240 } render={ (operation: any, record: TableData): ReactElement => (
                    <div className={ styles.operationBox }>
                        { (appType === 1 && findPermissions(20111)) && <Link className={ styles.operation } target='_blank' 
                            to={{ pathname: '/content/problem/monitoring', search: record.chanSId }}>监控诊断</Link> }
                        { (appType !== 1 && findPermissions(20111)) && <Link className={ styles.operation } target='_blank' 
                            to={{ pathname: '/content/problem/problemSee', search: JSON.stringify({ ...record }) }}>问题查看</Link> }
                        { findPermissions(20121) && <Button type="link" className={ record.spamStyle? styles.operation : styles.disable } 
                        disabled={ !record.spamStyle } onClick={ () => handleCallDetail(record) }>通话详情</Button> }
                    </div>
				) } />
			</Table>

            <CallDetails visible={ visible } setVisible={ setVisible } record={ record } />
        </div>
    )
};

export default RealTimeCloud;