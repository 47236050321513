import { FC, ReactElement } from 'react';
import Invoices from './invoices/invoices';
import WaitInvoice from './waitInvoice/waitInvoice';
import Pendhasbeen from './pendhasbeen/pendhasbeen';
import styles from './index.less';


const Index: FC = (): ReactElement => {
    
    return (
        <div className={ styles.index }>
            <p className={ styles.title }>发票管理</p>
            <Invoices />
            <WaitInvoice />
            <Pendhasbeen />
        </div>
    );
};

export default Index;