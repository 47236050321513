import { FC, ReactElement, useEffect, useState } from 'react';
import { Select, Input, Button, Modal, Table, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { findPermissions } from '../../../../../api/permiss';
import { getTimestampToTime } from '../../../../../api/index';
import { Option } from '../../../../../axios/axios-types';
import axios from '../../../../../axios/axios';
import pubsub from 'pubsub-js';
import styles from './applyfor.less';
const { Column } = Table;

namespace Antd {
    export const { Option } = Select;
};

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

enum VerifyStatus {never, '待审核', '已通过', '未通过', '已到期'};

const Applyfor: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const selectOption: any[] = [
        {
            value: 1,
            label: "正式客户"
        },
        {
            value: 2,
            label: "测试客户"
        }
    ];
    const [selectValue, setSelectValue]: [number, Function] = useState(1);
    const [searchParam, setSearchParam]: [string, Function] = useState('');
    const [url, setUrl]: [string, Function] = useState('getUserList');
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
    const [invalidVisible, setInvalidVisible]: [boolean, Function] = useState(false);
    const [record, setRecord]: [any, Function] = useState({});
    const [active, setActive]: [number | unknown, Function] = useState(null); // 1
    const [modalTitle, setModalTitle]: [string, Function] = useState('');
    const [confirmLoading, setConfirmLoading]: [boolean, Function] = useState(false);
    const [modalType, setModalType]: [number, Function] = useState(1);
    const [effect, setEffect]: [boolean, Function] = useState(false);
    
    useEffect((): void => {
        applyType();
    }, []);

    useEffect((): void => {
        active !== null && getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current, selectValue, url, active, effect]);

    props.cacheLifecycles.didRecover(() => {
        getList();
    });

    const applyType: Function = (): void => {
        if (findPermissions(32101)) {
            setActive(1);
        } else if (findPermissions(32201)) {
            setActive(2);
        }
    };

    const getList: Function = async (): Promise<void> => {
        const bool: boolean = url === 'searchUserList' || url === 'searchUserRenewalList';
        const option: Option = {
            url: `/primgr/${url}`,
            data: {
                dType: selectValue,
                status: selectValue,
                pageNum: current,
                pageSize: pageSize,
                searchParam,
            },
            method: 'POST'
        };
        setTableLoading(true);
        bool && setLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        bool && setLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.verifyStatus = VerifyStatus[item.verifyStatus];
                item.licType = item.licType === 2? "混合云" : "私有云";
                item.ts = getTimestampToTime(item.ts);
                item.stopTs = item.stopTs === 0? "--" : getTimestampToTime(item.stopTs);
            });
            !bool && setSearchParam('');
            setTotal(result.data.data.totalNumber);
            setTableData(result.data.data.list);
        } else {
            message.error('数据获取失败');
        }
    };

    const handleSearch: Function = (): void => {
        if (searchParam) {
            setUrl(active === 1? 'searchUserList' : 'searchUserRenewalList');
            setEffect(!effect);
            setCurrent(1);
        } else {
            message.error('请输入内容');
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const computedClass: Function = (record: any): string => {
        return record.verifyStatus === "待审核"? styles.complete : record.verifyStatus === "未通过"? styles.refused : styles.agree;
    };

    const handleSelectChange: Function = (value: number): void => {
        setCurrent(1);
        setUrl(active === 1? 'getUserList' : 'getUserRenewalList');
        setSearchParam('');
        setSelectValue(value);
    };

    const handleModal: Function = (record: any, num: number): void => {
        if (num === 1) {
            setModalTitle('部署服务');
        } else if (num === 2) {
            setModalTitle('续期服务');
        } else if (num === 3) {
            setModalTitle('作废申请');
        }
        setRecord(record);
        setInvalidVisible(true);
        setModalType(num);
    };

    const handleView: Function = (record: any): void => {
        const data: any = {
            private: {
                name: '申请',
                url: '/content/private/applyfor'
            },
            perm: {
                xs: active === 1? 32112 : 32212,
                bh: active === 1? 32113 : 32213,
                my: active === 1? 32114 : 32214,
                glfw: active === 1? 32115 : 32215,
                bz: active === 1? 32116 : 32216,
                fwq: active === 1? 32121 : 32221,
                zzfw: active === 1? 32131 : 32231,
                zzxq: active === 1? 32132 : 32232,
                sqxq: active === 1? 32133 : 32233,
                bjxx: active === 1? 32122 : 32222,
                sc: active === 1? 32123 : 32223,
                jqxq: active === 1? 32124 : 32224
            }
        };
        history.push('/content/private/detail/basicInfor', Object.assign(record, data));
    };

    const handleModalOk: Function = (): void => {
        if (modalType === 1) {
            handleDealwith({
                url: 'deployDevp',
                data: { oem: record.oem },
                msg: {
                    success: '部署成功',
                    error: '部署失败'
                }
            });
        } else if (modalType === 2) {
            handleDealwith({
                url: 'renewalDevp',
                data: { rid: record.rid },
                msg: {
                    success: '续期成功',
                    error: '续期失败'
                }
            });
        } else if (modalType === 3) {
            handleDealwith({
                url: active === 1? 'deleteDevp' : 'deleteRenewalRecord',
                data: { oem: record.oem, rid: record.rid },
                msg: {
                    success: '作废成功',
                    error: '作废失败'
                }
            });
        }
    };

    const handleDealwith: Function = async (options: any): Promise<void> => {
        const option: Option = {
            url: `/primgr/${options.url}`,
            data: options.data,
            method: 'POST'
        };
        setConfirmLoading(true);
        const result: Result = await axios(option);
        setConfirmLoading(false);
        if (result.data.code === 0) {
            message.success(options.msg.success);
            pubsub.publish('getSummaryUserNum');
            getList();
            setInvalidVisible(false);
        } else {
            message.error(options.msg.error);
        }
    };

    const handleClick: Function = (index: number): void => {
        setActive(index);
        setSelectValue(1);
        setSearchParam('');
        setCurrent(1);
        setUrl(index === 1? 'getUserList' : 'getUserRenewalList');
    };

    const computedPerm: Function = (): boolean => {
        return findPermissions(32101) || findPermissions(32201);
    };

    const computedViewPerm: Function = (type: string): boolean => {
        var perm: number = 0;
        if (type === 'view') {
            perm = active === 1? 32111 : 32211;
        } else if (type === 'invalid') {
            perm = active === 1? 32141 : 32241;
        }
        return findPermissions(perm);
    };
    return (
        <div className={ styles.applyfor }>
            <div>
                { findPermissions(32101) && <Button shape="round" onClick={ () => handleClick(1) } className={ [styles.link, active === 1 && styles.active].join(' ') }>
                添加申请
                </Button> }
                { findPermissions(32201) && <Button shape="round" onClick={ () => handleClick(2) } className={ [styles.link, active === 2 && styles.active].join(' ') }>
                续期申请
                </Button> }
            </div>
            { computedPerm() && <div className={ styles.toolbar }>
                <div className={ styles.toolbarLeft }>
                    <Select
                    className={ styles.toolbarItem }
                    style={{ width: 200 }}
                    value={ selectValue }
                    onChange={ (value) => handleSelectChange(value) }
                    >
                        {
                            selectOption.map((item: any, index: number): ReactElement => {
                                return <Antd.Option value={ item.value } key={ index }>{ item.label }</Antd.Option>
                            })
                        }
                    </Select>
                    <Input className={ styles.toolbarItem } value={ searchParam } onChange={ (e) => setSearchParam(e.target.value) } placeholder="请输入内容" />
                    <Button className={ styles.toolbarItem } loading={ loading } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
                </div>
            </div> }
            { computedPerm() && <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="申请时间"  dataIndex="ts" />
                <Column align='center' title="截止时间" dataIndex="stopTs" />
                { active === 1 && <Column align='center' title="有效期 / 天" dataIndex="period" /> }
                { active === 2 && <Column align='center' title="续期时长" dataIndex="period" /> }
                <Column align='center' title="备注"  dataIndex="remark" />
                <Column align='center' title="预留邮箱" dataIndex="email" />
                <Column align='center' title="预留手机号"  dataIndex="phone" />
                <Column align='center' title="部署类型" dataIndex="licType" />
                <Column align='center' title="服务类型" dataIndex="serviceType" />
                <Column align='center' title="厂商" dataIndex="oem" />
                <Column align='center' title="状态" render={ (operation: any, record: any): ReactElement => (
                    <span className={ computedClass(record) }>{ record.verifyStatus }</span>
                ) } />
                <Column align='center' width={ 260 } title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { (active === 1 && findPermissions(32102)) && <Button type='text' onClick={ () => handleModal(record, 1) } disabled={ record.verifyStatus !== '已通过' }
                        className={ [record.verifyStatus !== '已通过' && styles.disable, styles.operation].join(' ') }>
                        部署</Button> }
                        { (active === 2 && findPermissions(32202)) && <Button type='text' onClick={ () => handleModal(record, 2) } disabled={ record.verifyStatus !== '已通过' }
                        className={ [record.verifyStatus !== '已通过' && styles.disable, styles.operation].join(' ') }>续期</Button> }
                        { computedViewPerm('view') && <p onClick={ () => handleView(record) } className={ styles.operation }>查看</p> }
                        { computedViewPerm('invalid') && <p onClick={ () => handleModal(record, 3) } className={ [styles.operation, styles.delete].join(' ') }>作废</p> }
                    </div>
                ) } />
            </Table> }

            <Modal title={ modalTitle } width={ 500 } getContainer={ false } centered 
            confirmLoading={ confirmLoading } visible={ invalidVisible } 
            onOk={ () => handleModalOk() } onCancel={ () => setInvalidVisible(false)}>
                { modalType === 1 && <p>您确定要部署该服务吗?</p> }
                { modalType === 2 && <p>您确定续期该服务吗?</p> }
                { modalType === 3 && <p>您确定要作废该申请吗?</p> }
            </Modal>
        </div>
    )
};

export default Applyfor;