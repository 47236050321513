import { FC, ReactElement, useEffect, useState } from 'react';
import { Input, Button, Modal, Table, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { findPermissions, privateDetailPath } from '../../../../../api/permiss';
import { getTimestampToTime } from '../../../../../api/index';
import { Option } from '../../../../../axios/axios-types';
import axios from '../../../../../axios/axios';
import pubsub from 'pubsub-js';
import styles from './expiring.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

enum Status {never, '已停用', '在使用', '快到期', '已到期'};

const Expiring: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const [searchParam, setSearchParam]: [string, Function] = useState('');
    const [url, setUrl]: [string, Function] = useState('getAllExpireUserList');
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
    const [deleteVisible, setDeleteVisible]: [boolean, Function] = useState(false);
    const [confirmLoading, setConfirmLoading]: [boolean, Function] = useState(false);
    const [record, setRecord]: [any, Function] = useState({});
    const [selectedRowKeys, setSelectedRowKeys]: [any[], Function] = useState([]);
    const [isSearch, setIsSearch]: [boolean, Function] = useState(false);
    const [effect, setEffect]: [boolean, Function] = useState(false);

    const data: any = {
        private: {
            name: '快到期正式用户',
            url: '/content/private/expiring'
        },
        perm: {
            xs: 34202,
            bh: 34203,
            my: 34204,
            glfw: 34205,
            bz: 34206,
            fwq: 34211,
            zzfw: 34221,
            zzxq: 34222,
            sqxq: 34223,
            bjxx: 34212,
            sc: 34213,
            jqxq: 34214,
        }
    };

    useEffect((): void => {
        getAllExpireUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current, url, effect]);

    props.cacheLifecycles.didRecover(() => {
        getAllExpireUserList();
    });

    const getAllExpireUserList: Function = async (): Promise<void> => {
        const option: Option = {
            url: `/primgr/${url}`,
            data: {
                status: 3,
                pageNum: current,
                pageSize: pageSize,
                searchParam,
            },
            method: 'POST'
        };
        setTableLoading(true);
        url === 'searchAllExpireUserList' && setLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        url === 'searchAllExpireUserList' && setLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.status = Status[item.status];
                item.licType = item.licType === 2? "混合云" : "私有云";
                item.ts = getTimestampToTime(item.ts);
                item.stopTs = getTimestampToTime(item.stopTs);
            });
            url === 'getAllExpireUserList' && setSearchParam('');
            setTotal(result.data.data.totalNumber);
            setTableData(result.data.data.list);
        } else {
            message.error('数据获取失败');
        }
    };

    const sendEmailByArrOem: Function = async (): Promise<void> => {
        if (selectedRowKeys.length > 0) {
            const option: Option = {
                url: '/primgr/sendEmailByArrOem',
                data: { oem: selectedRowKeys },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                getAllExpireUserList();
                setSelectedRowKeys([]);
                message.success("提醒成功");
            } else {
                message.error("提醒失败");
            }
        } else {
            message.error('请先选择需要提醒的用户');
        };
    };

    const handleSearch: Function = (): void => {
        if (searchParam) {
            setUrl('searchAllExpireUserList');
            setIsSearch(true);
            setCurrent(1);
            setEffect(!effect);
        } else {
            message.error('请输入内容');
        }
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const computedClass: Function = (record: any): string => {
        return record.status === "在使用"? styles.complete : record.status === "快到期"? styles.refused : styles.agree;
    };
    
    const handleDelete: Function = (record: any): void => {
        setRecord(record);
        setDeleteVisible(true);
    };

    const handleView: Function = (record: any): void => {
        history.push('/content/private/detail/basicInfor', Object.assign(record, data));
    };

    const handleRenewal: Function = (record: any): void => {
        history.push(privateDetailPath(data.perm.sqxq), Object.assign(record, data));
    };

    const handleRemove: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/deleteDevp',
            data: { oem: record.oem },
            method: 'POST'
        };
        setConfirmLoading(true);
        const result: Result = await axios(option);
        setConfirmLoading(false);
        if (result.data.code === 0) {
            message.success('客户删除成功');
            getAllExpireUserList();
            pubsub.publish('getSummaryUserNum');
            setDeleteVisible(false);
        } else {
            message.error('客户删除失败');
        }
    };

    const onChange: any = (selectedRowKeys: React.Key[], selectedRows: any[]): void => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const getCheckboxProps: any = (record: any): any => {
        /*
        sendEmailByArrOem 接口请求完毕后  再调用getAllExpireUserList函数得到的结果
        还是以前的状态，这会导致发送过的行，还能继续被勾选（应该要被禁用），这里导致的问题是后端的原因
        */
        return {
            disabled: record.iSendEmail !== 0, // Column configuration not to be checked
            name: record,
        }
    };

    const handleSearchBack: Function = (): void => {
        setSearchParam('');
        setUrl('getAllExpireUserList');
        setIsSearch(false);
    };
    return (
        <div className={ styles.expiring }>
            <div className={ styles.toolbar }>
                <div className={ styles.toolbarLeft }>
                    <Input className={ styles.toolbarItem } value={ searchParam } onChange={ (e) => setSearchParam(e.target.value) } placeholder="请输入内容" />
                    <Button className={ styles.toolbarItem } loading={ loading } onClick={ () => handleSearch() } type="primary" icon={<SearchOutlined />}>Search</Button>
                    { isSearch && <Button onClick={ () => handleSearchBack() } type="primary">Back</Button> }
                </div>
                <Button className={ styles.button } disabled={ !findPermissions(34102) } onClick={ () => sendEmailByArrOem() } type="primary">一键提醒</Button>
            </div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowSelection={{ 
                    type: 'checkbox',
                    onChange,
                    selectedRowKeys,
                    getCheckboxProps
                }}
                rowKey='oem'
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="使用时间"  dataIndex="ts" />
                <Column align='center' title="截止时间" dataIndex="stopTs" />
                <Column align='center' title="有效期 / 天" dataIndex="period" />
                <Column align='center' title="备注"  dataIndex="remark" />
                <Column align='center' title="预留邮箱" dataIndex="email" />
                <Column align='center' title="预留手机号"  dataIndex="phone" />
                <Column align='center' title="部署类型" dataIndex="licType" />
                <Column align='center' title="服务类型" dataIndex="serviceType" />
                <Column align='center' title="厂商" dataIndex="oem" />
                <Column align='center' title="状态" render={ (operation: any, record: any): ReactElement => (
                    <span className={ computedClass(record) }>{ record.status }</span>
                ) } />
                <Column align='center' width={ 240 } title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { findPermissions(34101) && <p onClick={ () => handleRenewal(record) } className={ styles.operation }>续期</p> }
                        { findPermissions(34201) && <p onClick={ () => handleView(record) } className={ styles.operation }>查看</p> }
                        { findPermissions(34301) && <p onClick={ () => handleDelete(record) } className={ [styles.operation, styles.delete].join(' ') }>删除</p> }
                    </div>
                ) } />
            </Table>

            <Modal title='删除客户' width={ 500 } getContainer={ false } centered 
            confirmLoading={ confirmLoading }
            visible={ deleteVisible } onOk={ () => handleRemove() } onCancel={ () => setDeleteVisible(false)}>
                <p>您确定要删除该客户吗?</p>
            </Modal>
        </div>
    )
};

export default Expiring;