const thatVeryDay: Function = (date: string | number | Date, pattern: string = "-"): any => {
    const oNew: Date = new Date(date);
    const oY: number = oNew.getFullYear();
    const oM: string | number = oNew.getMonth() + 1 > 10? oNew.getMonth() + 1 : "0" + (oNew.getMonth() + 1);
    const oD: string | number = oNew.getDate() > 10 ? oNew.getDate() : "0" + oNew.getDate(); //天
    // eslint-disable-next-line no-useless-concat
    const timer: string = oY + pattern + oM + pattern + oD + " " + "00:00:00";
    const stampMS: number = new Date(timer).getTime();
    const stampS: number = parseInt((new Date(timer).getTime() / 1000).toString());
    return {
        timer,
        stampMS,
        stampS
    };
};
  
const timeTransformation: Function = (time: number, ymd: boolean = true, pattern: string = "-"): string => {
    const timestamp: number = time.toString().length <= 10 ? Number(time * 1000) : Number(time);
    const date: Date = new Date(timestamp);
    const Y: string = date.getFullYear() + pattern;
    const M: number | string = (date.getMonth() + 1 < 10? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + pattern;
    const D: number | string = (date.getDate() < 10? "0" + date.getDate() : date.getDate()) + " ";
    const h: number | string = (date.getHours() < 10? "0" + date.getHours() : date.getHours()) + ":";
    const m: number | string = date.getMinutes() < 10? "0" + date.getMinutes() : date.getMinutes();
    const s: number | string = date.getSeconds() < 10? "0" + date.getSeconds() : date.getSeconds();
    return ymd? Y + M + D + h + m + ":" + s : h + m + ":" + s;
};

const durationScale: Function = (duration: number, locale: string = "en"): string => {
    const internationalization: Array<string> = locale === "en"? ["d", "h", "min", "s"] : ["天", "小时", "分钟", "秒"];
    const days: number = Math.floor(duration / (24 * 3600 * 1000));
    const leave1: number = duration % (24 * 3600 * 1000);
    const hours: number = Math.floor(leave1 / (3600 * 1000));
    const leave2: number = leave1 % (3600 * 1000);
    const minutes: number = Math.floor(leave2 / (60 * 1000));
    const leave3: number = leave2 % (60 * 1000);
    const seconds: number = Math.round(leave3 / 1000);
    var str: string = "";
    if (days > 0) {
        str = days < 10? "0" + days + internationalization[0] : days + internationalization[0];
    }
    if (hours > 0) {
        str = hours < 10? str + "0" + hours + internationalization[1] : str + hours + internationalization[1];
    }
    if (minutes > 0) {
        str = minutes < 10? str + "0" + minutes + internationalization[2] : str + minutes + internationalization[2];
    }
    if (seconds > 0) {
        str = seconds < 10? str + "0" + seconds + internationalization[3] : str + seconds + internationalization[3];
    }
    return str;
};

const durationComputeAll: Function = (all: any[], startTime: string = "startTime", endTime: string = "endTime", locale: string = "en"): any => {
    var duration: number = 0;
    all.forEach((time: any): void => {
        if (time[endTime] === 0) {
            time[endTime] = parseInt((Date.parse((new Date()).toString()) / 1000).toString());
        }
        duration = duration + time[endTime] - time[startTime];
    });
    const oScale: number = durationScale(duration * 1000, locale);
    return { character: oScale, duration };
};

const durationCompute: Function = (start: number, end: number, locale: string = "en"): any => {
    start = start.toString().length <= 10 ? Number(start * 1000) : Number(start);
    end = end.toString().length <= 10 ? Number(end * 1000) : Number(end);
    const internationalization: Array<string> = locale === "en" ? ["d", "h", "min", "s"] : ["天", "小时", "分钟", "秒"];
    const duration: number = end - start;
    const days: number = Math.floor(duration / (24 * 3600 * 1000));
    const leave1: number = duration % (24 * 3600 * 1000);
    const hours: number = Math.floor(leave1 / (3600 * 1000));
    const leave2: number = leave1 % (3600 * 1000);
    const minutes: number = Math.floor(leave2 / (60 * 1000));
    const leave3: number = leave2 % (60 * 1000);
    const seconds: number = Math.floor(leave3 / 1000);
    var str: string = "";
    if (days > 0) {
        str = days < 10 ? "0" + days + internationalization[0] : days + internationalization[0];
    }
    if (hours > 0 && hours < 24) {
        str = hours < 10 ? str + "0" + hours + internationalization[1] : str + hours + internationalization[1];
    }
    if (minutes > 0 && minutes < 60) {
        str = minutes < 10? str + "0" + minutes + internationalization[2] : str + minutes + internationalization[2];
    }
    if (seconds > 0 && seconds < 60) {
        str = seconds < 10? str + "0" + seconds + internationalization[3] : str + seconds + internationalization[3];
    }
    return { character: str, duration: parseInt((duration / 1000).toString()) };
};
  
export {
    thatVeryDay,
    timeTransformation,
    durationComputeAll,
    durationCompute,
    durationScale
};
  