import { FC, ReactElement, useEffect, useState } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { Link } from 'react-router-dom';
import { createFromIconfontCN } from '@ant-design/icons';
import { scriptUrl } from '../../../../../axios/baseUrl';
import { restoreEcharts, chartInitFn } from '../../../../../api/echarts/monitor/callsCharts';
import styles from './callEcharts.less';
const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

interface IProps {
	calleChartData: any;
}

const CallEcharts: FC<IProps> = (props): ReactElement => {
	const [dataDisplay, setDataDisplay]: [Array<any>, Function] = useState([]);
	const [p2pcallPeersShow, setP2pcallPeersShow]: [Array<any>, Function] = useState([]);
	const [p2pcallPeers, setP2pcallPeers]: [Array<any>, Function] = useState([]);

	useEffect((): void => {
		const oLine: Array<any> = [];
		if (props.calleChartData.sendDataLites.length) {
			props.calleChartData.sendDataLites.forEach((line: any): void => {
				oLine.push(line.uId);
			});
		}
		setTimeout(async (): Promise<void> => {
			const { has, peerlists } = await chartInitFn(
				props.calleChartData.echartsDataLites,
				oLine,
				props.calleChartData.searchTime
			);
			setDataDisplay(has);
			setP2pcallPeers(peerlists);
		}, 200);
	}, [props.calleChartData]);

	const getMenu: Function = (data: any): ReactElement => {
		if (p2pcallPeersShow.length) {
			return (<Menu>
				{p2pcallPeersShow.map((item: string, index: number): ReactElement => {
					return <>{ item !== data.uId && <Menu.Item key={ index }>
						<Link className={ styles.operation } target='_blank' 
                            to={{ pathname: '/content/problem/monitoringDetails', search: JSON.stringify(computData(item, data.uId)) }}>{ item }</Link>
					</Menu.Item> }</>
				})}
			</Menu>)
		} else {
			return <Menu></Menu>
		}
    };

	const computData: Function = (item: any, peerId: string): any => {
		const oInfo: any = props.calleChartData.echartsDataLites[0];
		return {
			chanSId: oInfo.chanSId,
			uId: item,
			peerId,
			appId: oInfo.appId
		}
	};

	const controlAppeared: Function = (peer: string): boolean => {
		var oA: boolean = false;
		p2pcallPeers.forEach((item: any): void => {
			item.uId === peer && item.peerLists.length > 0 && (oA = true);
		});
		return oA;
	};

	const p2pcallPeersShowFn: Function = (peer: string): void => {
      	var oA: Array<any> = [];
      	p2pcallPeers.forEach((item: any): void => {
			item.uId === peer && (oA = item.peerLists);
      	});
      	setP2pcallPeersShow(oA);
    };

	return (
		<div className={styles.callEcharts}>
			{
				props.calleChartData.echartsDataLites.map((item: any, index: number): ReactElement => {
					return (item.echarShow && <div key={ index } className={ styles.content }>
						<div className={ styles.chartHeader }>
							<div className={[styles.flex, styles.chartHeader1].join(' ')}>
								<h4>user：<span>{ item.uId }</span></h4>
								<div>
									{
										dataDisplay.map((dis: any, index: number): ReactElement => {
											return (<div key={ index }>
												{ dis.uId === item.uId && <div className={ styles.flex }>
													{ !dis.has.videohas && <div className={ [styles.flex, styles.itemCenter, styles.font1].join(' ') }>
														<img src={ require('../../../../../assets/images/wushipin.svg').default } alt="" />
														<span>There is no video</span> 
													</div> }
													{ !dis.has.audiohas && <div key={ index } className={ [styles.flex, styles.itemCenter, styles.font1].join(' ') }>
														<img src={ require('../../../../../assets/images/wuyuyin.svg').default } alt="" />
														<span>There is no audio</span>
													</div> }
												</div> }
											</div>)
										})
									}
								</div>
							</div>
							<div className={ [styles.flex, styles.chartHeader2].join(' ') }>
								<div className={ styles.flex }>
									<div className={ [styles.font2, styles.version].join(' ') }>
										<span>sdk version：</span> <span className={ styles.font2Blod }>{ item.sdkVersion }</span>
									</div>
									<div className={ [styles.font2, styles.version].join(' ') }>
										<span>platform：</span> <span className={ styles.font2Blod }>{ item.os }</span>
									</div>
									</div>
									{
										dataDisplay.map((dis: any, index: number): ReactElement => {
											return (
												(dis.uId === item.uId && controlAppeared(item.uId) 
												&& (dis.has.videohas || dis.has.audiohas)) 
												&& <Dropdown 
												arrow={ true }
												trigger={['click']}
												overlay={ () => getMenu(item) }
												key={ index }>
													<Button type="link" className={ styles.adduserSingleAddM } onClick={ () => p2pcallPeersShowFn(item.uId) }>
														<span className={ styles.pr3 }>选择发送端查看</span>
													</Button>
												</Dropdown>
											)
										})
									}
							</div>
						</div>
						<div id={ 'echarts' + item.uId } key={ 'echarts' + item.uId } className={ styles.echartsid }></div>
						<div id={ 'echarts' + item.uId + 'restore' } key={ 'echarts' + item.uId + 'restore' } className={ styles.restore } onClick={ () => restoreEcharts() }>
							<Button type="primary" icon={<IconFont type="icon-zhongzhi" />}>reset</Button>
						</div>
					</div>)
				})
			}
		</div>
	)
};

export default CallEcharts;