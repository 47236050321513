import { CSSProperties, FC, ReactElement, useState, useEffect } from 'react';
import { Select, Input, Table, Button, Radio, Modal, Descriptions, message } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { SearchOutlined } from '@ant-design/icons';
import { Option } from '../../../../axios/axios-types';
import { aec, opensles, brand } from '../selectData';
import axios from '../../../../axios/axios';
import styles from './overview.less';
const { Column } = Table;
const { TextArea } = Input;

namespace Antd {
    export const { Option } = Select;
};

interface Result {
	data:  {
        code: number;
        data: any;
    };
};

const contentStyle: CSSProperties = {
    height: '60px', 
    width: '80%'
};

const Overview: FC = (): ReactElement => {
    const [cpuList, setCpuList]: [any, Function] = useState([{
        value: "all",
        label: "CPU型号"
    }]);
    const [value, setValue]: [string, Function] = useState('');
    const [mbrand, setMbrand]: [string, Function] = useState('all');
    const [mcpu, setMcpu]: [string, Function] = useState('all');
    const [mhwAec, setMhwAec]: [number, Function] = useState(99);
    const [mopenSles, setMopenSles]: [number, Function] = useState(99);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [modalLoading, setModalLoading]: [boolean, Function] = useState(false);
    const [editVisible, setEditVisible]: [boolean, Function] = useState(false);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [any[], Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [result, setResult]: [any, Function] = useState({});
	const [record, setRecord]: [any, Function] = useState({});
	const [effect, setEffect]: [boolean, Function] = useState(false);

    useEffect((): void => {
		const getAllDevpList: Function = async (): Promise<void> => {
			const option: Option = {
				url: '/device/getDeviceList',
				method: 'POST',
				data: {
					pageNum: current,
					pageSize,
                    brand: mbrand,
                    cpu: mcpu,
                    hwAec: mhwAec,
                    openSles: mopenSles,
				}
			};
			setTableLoading(true);
			const result: Result = await axios(option);
            if (result.data.code === 0) {
                result.data.data.list.forEach((item: any): void => {
                    item.hwAec !== undefined && (item.hwAec = item.hwAec === 0? "不支持" : "支持");
                    item.openSles !== undefined && (item.openSles = item.openSles === 0? "不支持" : "支持");
                });
                setTableData(result.data.data.list);
                setTotal(result.data.data.totalNumber);
            } else {
                message.error('数据获取失败');
            }
			setTableLoading(false);
		};
		getAllDevpList();
	}, [pageSize, current, mbrand, mcpu, mhwAec, mopenSles, effect]);


    useEffect((): void => {
        const getBrandCpuList: Function = async (): Promise<void> => {
            const option: Option = {
                url: '/device/getBrandCpuList',
                method: 'GET'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                if (Array.isArray(result.data.data)) {
                    const list: any = {};
                    result.data.data.forEach((item: any): void => {
                        brand.push({
                            value: item.brand,
                            label: item.brand
                        });
                        list[item.brand] = item.cpuList;
                    });
                    setResult(list);
                    assembly(list);
                }
            }
        };
        getBrandCpuList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assembly: Function = (data: any): void => {
        const cpu: any[] = JSON.parse(JSON.stringify(cpuList));
        setCpuList(forKeys(data, cpu));
    };

    const forKeys: Function = (data: any, cpu: any[]): any[] => {
        Object.keys(data).forEach((key: string): void => {
            data[key].forEach((item: any): void => {
                const target: any = cpu.find((li: any): boolean => li.label === item);                
                if (target === undefined) {
                    cpu.push({
                        value: item,
                        label: item
                    });
                }
            });
        });
        return cpu;
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleSelect: Function = (value: string): void => {
        setMbrand(value);
        let cpu: any[] = [{
            value: "all",
            label: "CPU型号"
        }];
        if (value === 'all') {
            cpu = forKeys(result, cpu);
        } else {
            result[value].forEach((item: any): void => {
                const target: any = cpu.find((li: any): boolean => li.label === item);                
                if (target === undefined) {
                    cpu.push({
                        value: item,
                        label: item
                    });
                }
            });
        }
        setCpuList(cpu);
        setMcpu('all');
        setValue('');
    };

    const handleSelectAll: Function = (value: string, callback: Function): void => {
        callback(value);
        setValue('');
    };

    const handleSearch: Function = async (): Promise<void> => {
        if (value === '') {
            message.error("请输入关键字");
        } else {
            const option: Option = {
                url: '/device/searchDeviceList',
                data: {
                    pageNum: current,
					pageSize,
                    searchParam: value
                },
                method: 'POST'
            };
            setLoading(true);
            const result: Result = await axios(option);
            setLoading(false);
            if (result.data.code === 0) {
                result.data.data.list.forEach((item: any): void => {
                    item.hwAec !== undefined && (item.hwAec = item.hwAec === 0? "不支持" : "支持");
                    item.openSles !== undefined && (item.openSles = item.openSles === 0? "不支持" : "支持");
                });
                setTableData(result.data.data.list);
                setTotal(result.data.data.totalNumber);
            } else {
                message.error('数据获取失败');
            }
        }
    };

    const handleEdit: Function = async (record: any): Promise<void> => {
        const option : Option = {
            url: '/device/getDeviceInfo',
            data: { deviceId: record.deviceId },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setEditVisible(true);
            setRecord(result.data.data[0]);
        } else {
            message.error("查询失败");
        }
    };

    const handleEditChange: Function = (value: any, type: string): void => {
        const recor: any = JSON.parse(JSON.stringify(record));
        recor[type] = value;
        setRecord(recor);
    };

    const handleOnOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/device/updateDeviceInfo',
            data: record,
            method: 'POST'
        };
        setModalLoading(true);
        const result = await axios(option);
        setModalLoading(false);
        message[result.data.code === 0? "success" : "error"](
            result.data.code === 0? "保存成功" : "保存失败"
        );
        if (result.data.code === 0) {
            setEditVisible(false);
            setEffect(!effect);
        }
    };
    return (
        <div className={ styles.overview }>
            <div className={ styles.search }>
                <div className={ styles.select }>
                    <span className={ styles.span }>品牌名称</span>
                    <Select
                    style={{ width: 200 }}
                    value={ mbrand }
                    onChange={ (value) => handleSelect(value) }
                    >
                        {brand.map((item: any, index: number): ReactElement => {
                            return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                        })}
                    </Select>
                </div>
                <div className={ styles.select }>
                    <span className={ styles.span }>CPU型号</span>
                    <Select
                    style={{ width: 200 }}
                    value={ mcpu }
                    onChange={ (value) => handleSelectAll(value, setMcpu) }
                    >
                        {cpuList.map((item: any, index: number): ReactElement => {
                            return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                        })}
                    </Select>
                </div>
                <div className={ styles.select }>
                    <span className={ styles.span }>硬件Aec</span>
                    <Select
                    style={{ width: 200 }}
                    value={ mhwAec }
                    onChange={ (value) => handleSelectAll(value, setMhwAec) }
                    >
                        {aec.map((item: any, index: number): ReactElement => {
                            return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                        })}
                    </Select>
                </div>
                <div className={ styles.select }>
                    <span className={ styles.span }>Opensles</span>
                    <Select
                    style={{ width: 200 }}
                    value={ mopenSles }
                    onChange={ (value) => handleSelectAll(value, setMopenSles) }
                    >
                        {opensles.map((item: any, index: number): ReactElement => {
                            return <Antd.Option key={ index } value={ item.value }>{ item.label }</Antd.Option>
                        })}
                    </Select>
                </div>
                <div className={ styles.select }>
                    <Input className={ styles.input } value={ value } onChange={ (e) => setValue(e.target.value) } placeholder="请输入关键字" />
                    <Button type="primary" disabled={ !findPermissions(23121) } onClick={ () => handleSearch() } icon={ <SearchOutlined /> } loading={ loading }>Search</Button>
                </div>
            </div>

            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey={() => `${Math.floor(Math.random() * 123333)}`}
				sticky={ true }
				dataSource={ tableData }>
				<Column align='center' title="品牌" dataIndex="brand" />
				<Column align='center' title="设备型号"  dataIndex="model" />
				<Column align='center' title="CPU型号" dataIndex="cpu" />
				<Column align='center' title="硬件Aec" dataIndex="hwAec" />
				<Column align='center' title="Opensles" dataIndex="openSles" />
				<Column align='center' title="备注" dataIndex="remark" />
				<Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
					<p className={ styles.operation } onClick={ () => handleEdit(record) }>编辑</p>
				) } />
			</Table>

            <Modal title='Edit' width='40%'
            confirmLoading={ modalLoading }
            getContainer={ false } 
            centered visible={ editVisible } 
            onCancel={ () => setEditVisible(false)}
            onOk={ () => handleOnOk() }>
                <Descriptions column={ 1 } contentStyle={ contentStyle } bordered size='small'>
                    <Descriptions.Item label="品牌">{ record.brand }</Descriptions.Item>
                    <Descriptions.Item label="设备型号">{ record.model }</Descriptions.Item>
                    <Descriptions.Item label="CPU型号">{ record.cpu }</Descriptions.Item>
                    <Descriptions.Item label="硬件Aec">{ record.hwAec === 1? "支持" : "不支持" }</Descriptions.Item>
                    <Descriptions.Item label="OpenSLES">{ record.openSles === 1? "支持" : "不支持" }</Descriptions.Item>
                    <Descriptions.Item label="软件回声消除打开">
                        <Radio.Group onChange={ (e) => handleEditChange(e.target.value, 'saecOpenStatus') } value={ record.saecOpenStatus }>
                            <Radio value={ 1 }>有回声</Radio>
                            <Radio value={ 0 }>无回声</Radio>
                        </Radio.Group>    
                    </Descriptions.Item>
                    <Descriptions.Item label="软件回声消除关闭">
                        <Radio.Group onChange={ (e) => handleEditChange(e.target.value, 'saeCloseStatus') } value={ record.saeCloseStatus }>
                            <Radio value={ 1 }>有回声</Radio>
                            <Radio value={ 0 }>无回声</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="硬件Aec回声消除实际状况">
                        <Radio.Group onChange={ (e) => handleEditChange(e.target.value, 'haecStatus') } value={ record.haecStatus }>
                            <Radio value={ 1 }>有回声</Radio>
                            <Radio value={ 0 }>无回声</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="备注">
                        <TextArea placeholder="请输入备注" 
                        rows={ 8 } 
                        onChange={ (e) => handleEditChange(e.target.value, 'remark') }
                        value={ record.remark } />
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        </div>
    )
};

export default Overview;