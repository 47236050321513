import styles from '../../views/content/problem/monitoring/callEcharts/callEcharts.less';
const chartLodingFn: Function = (charts: any): void => {
    charts.showLoading("default", {
        text: "loading",
        color: "#409EFF",
        textColor: "#000",
        maskColor: "rgba(255, 255, 255, .8)",
        zlevel: 0,
        fontSize: 24,
        showSpinner: true,
        spinnerRadius: 10,
        lineWidth: 5,
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "sans-serif"
    });
};
const chartFinishFn: Function = (charts: any): void => {
  	charts.hideLoading();
};
const createDeletion: Function = (type: string, id: any, str: string): void => {
	const oDiv: HTMLDivElement = document.createElement("div");
	oDiv.className = styles.hideEcharts;
	const oImg: HTMLImageElement = document.createElement("img");
	oImg.addEventListener("mousedown", e => {
		e.preventDefault();
	});

	switch (type) {
		case "Lack of data":
			oImg.src = require("../../assets/images/shujuqueshi.svg").default;
			break;
		case "No data":
			oImg.src = require("../../assets/images/shoujishuju.svg").default;
			break;
		case "Data to the CPU":
			oImg.src = require("../../assets/images/zanwushujubeifen.svg").default;
			break;
		case "Temporarily no data":
			oImg.src = require("../../assets/images/shoujishuju.svg").default;
			break;
	}
  	const oP: HTMLElement = document.createElement("p");
	oP.innerHTML = str;
	oDiv.appendChild(oImg);
	oDiv.appendChild(oP);
	id.appendChild(oDiv);
};

export { chartLodingFn, chartFinishFn, createDeletion };
