import { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Input, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Option } from '../../../../axios/axios-types';
import { TOKEN } from '../../../../store/reducer';
import { uploadUrl } from '../../../../axios/baseUrl';
import axios from '../../../../axios/axios';
import styles from './dealVersion.less';
const { TextArea } = Input;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
	token: TOKEN;
};

const DealVersion: FC<IProps> = (props): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [version, setVersion]: [string, Function] = useState('');
    const [textarea, setTextarea]: [string, Function] = useState('');
    const [pageTitle, setPageTitle]: [string, Function] = useState('');
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const [file, setFile]: [any, Function] = useState({});

    const updateProps: any = {
        name: 'file',
        action: uploadUrl + 'artcsdk',
        maxCount: 1,
        headers: {
            authorization: 'authorization-text',
            'Artc-Token': props.token
        },
        defaultFileList: [],
        beforeUpload: async (file: any): Promise<any> => {
            const option: Option = {
                url: `sdk/getFileIsNotUpload/${file.name}`,
                method: 'GET'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                if (result.data.data.totalNum === 0) {
                    return true;
                } else {
                    message.error('该文件已经上传');
                }
            }
            return Upload.LIST_IGNORE;
        },
        onChange(info: any): void {
            if (info.file.status === 'done') {
                setFile(info.file.response.data);
                message.success(`${info.file.name} 上传成功`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败`);
            }
        },
    };

    useEffect((): void => {
        setPageTitle(location.state.versionId? 'Edit the sdk' : 'Add the sdk');
        if (location.state.versionId) {
            setTextarea(location.state.updateDesc);
            setVersion(location.state.bigVersion);
            updateProps.defaultFileList.push({
                uid: 1,
                name: location.state.fileName,
                status: 'done',
                url: location.state.url,
            });
            setFile({
                name: location.state.fileName,
                url: location.state.url,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBack: Function = (): void => {
        history.goBack();
    };

    const handleSaveInfor: Function = async (): Promise<void> => {
        if (check()) {        
            const option: Option = {
                url: '',
                data: {},
                method: 'POST'
            };
            option.url = location.state.versionId? '/sdk/updateSdkVersion' : '/sdk/insertSdkVersion';
            option.data = location.state.versionId? {
                desc: textarea,
                fileName: file.name,
                updateVersion: version,
                url: file.url,
                versionId: location.state.versionId
            } : {
                bigVersion: version,
                desc: textarea,
                fileName: file.name,
                softId: location.state.softId,
                typeId: location.state.typeId,
                url: file.url
            }
            setLoading(true);
            const result: Result = await axios(option);
            setLoading(false);
            if (result.data.code === 0) {
                message.success(location.state.versionId? 'SDK修改成功' : 'SDK添加成功');
                handleBack();
            } else {
                message.error(location.state.versionId? 'SDK修改失败' : 'SDK添加失败');
            }
        }
    };
    
    const check: Function = (): boolean => {
        var bool: boolean = false;
        if (!file.name) {
            message.error('请上传SDK文件');
        } else if (!version) {
            message.error('请输入SDK版本号');
        } else if (!textarea) {
            message.error('请输入更新内容');
        } else {
            bool = true;
        }
        return bool;
    };
    return (
        <div className={ styles.dealVersion }>
            <div className={ styles.modal }>
                <p className={ styles.title }>{ pageTitle }</p>
                <div className={ styles.content }>
                    <div className={ styles.box }>
                        <span className={ styles.span }>SDK文件：</span>
                        <Upload { ...updateProps }>
                            <Button type="primary" icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </div>
                    <div className={ styles.box }>
                        <span className={ styles.span }>版本号：</span>
                        <Input placeholder="请输入版本号" value={ version } onChange={ (e) => setVersion(e.target.value) } />
                    </div>
                    <div className={ [styles.box, styles.textArea].join(' ') }>
                        <span className={ styles.span }>版本更新内容：</span>
                        <TextArea placeholder="请输入版本更新内容" rows={ 8 } value={ textarea } onChange={ (e) => setTextarea(e.target.value) } />
                    </div>
                    <div className={ styles.btnBox }>
                        <Button onClick={ () => handleSaveInfor() } className={ styles.btn } loading={ loading } type="primary">保存</Button>
                        <Button className={ styles.btn } onClick={ () => handleBack() }>返回</Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

type MapStateToProps = (state: object) => object;
const mapStateToProps: MapStateToProps = (state: object): object => {
	return state;
};

export default connect(mapStateToProps, null)(DealVersion);
