
import { FC, ReactElement, useState, useEffect } from 'react';
import { Table, Modal, Input, message } from 'antd';
import { findPermissions } from '../../../../../../../../api/permiss';
import { useLocation, useHistory } from 'react-router-dom';
import { getTimestampToTime, moneyComma } from '../../../../../../../../api/index';
import { Option } from '../../../../../../../../axios/axios-types';
import axios from '../../../../../../../../axios/axios';
import styles from './waitInvoice.less';
const { Column } = Table;

interface TableDataAttr {
    amount: number;
    invoiceid: string;
    month: string;
    ts: number;
    type: number | string;
};

interface Data {
	code: number;
	data: { 
        list: Array<TableDataAttr>;
        totalNumber: number;
    };
};

interface Result {
	data: Data;
};

const WaitInvoice: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    
    const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<TableDataAttr>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [invoiceVisible, setInvoiceVisible]: [boolean, Function] = useState(false);
    const [invoiceTitle, setInvoiceTitle]: [string, Function] = useState('');
    const [record, setRecord]: [any, Function] = useState({});
    const [offon, setOffon]: [boolean, Function] = useState(false);
    const [reason, setReason]: [string, Function] = useState('');
    const [order, setOrder]: [string, Function] = useState('');
    const [note, setNote]: [string, Function] = useState('');

    useEffect((): void => {
        getUnHandledInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current]);

    const  getUnHandledInvoiceList: Function = async (): Promise<void> => {
        setTableLoading(true);
        const option: Option = {
            url: '/developers/getUnHandledInvoiceList',
            data: { 
                pageSize,
                pageNum: current,
                uid: location.state.uid
            },
            method: 'POST'
        };
        let result: Result = await axios(option);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: TableDataAttr): void => {
                switch (item.type) {
                  case 1:
                    item.type = "个人普通";
                    break;
                  case 2:
                    item.type = "企业普通";
                    break;
                  case 3:
                    item.type = "企业专票";
                    break;
                }
                item.amount = moneyComma(item.amount);
                item.ts = getTimestampToTime(item.ts, 'yyyy-MM-DD');
              });
            setTableData(result.data.data.list);
			setTotal(result.data.data.totalNumber);
        };
        setTableLoading(false);
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleView: Function = (record: TableDataAttr): void => {
        history.push('/content/user/detail/financial/invoice/pendingInvoicesDetails', Object.assign(location.state, record));
    };

    const handleClickDecision: Function = (record: TableDataAttr, offon: boolean): void => {
        if (offon) {
            setInvoiceTitle('同意开票');
        } else {
            setInvoiceTitle('拒绝开票');
        };
        setInvoiceVisible(true);
        setOrder('');
        setNote('');
        setReason('');
        setRecord(record);
        setOffon(offon);
    };

    const handleInvoiceModalOk: Function = async (): Promise<void> => {
        offon? handleAagree() : handleRefused();
    };

    const handleRefused: Function = async (): Promise<void> => {
        if (reason === "") {
            message.error("请输入拒绝的原因");
            return;
        }
        const option: Option = {
            url: '/developers/refuseUnHandledInvoice',
            data: {
                invoiceid: record.invoiceid,
                detail: reason,
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("拒绝开票申请成功");
            getUnHandledInvoiceList();
            setInvoiceVisible(false);
        } else {
            message.error("拒绝开票申请失败");
        }
    };

    const handleAagree: Function = async (): Promise<void> => {
        if (order === "" || note === "") {
            message.error("请输入快递单号或备注");
            return;
        };
        const option: Option = {
            url: '/developers/accessUnHandledInvoice',
            data: {
                invoiceid: record.invoiceid,
                remarks: note,
                waybillNo: order,
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success("同意开票申请成功");
            getUnHandledInvoiceList();
            setInvoiceVisible(false);
        } else {
            message.error("同意开票申请失败");
        }
    };
    return (
        <div className={ styles.waitInvoice }>
            <p className={ styles.title }>待处理发票</p>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='invoiceid'
				sticky={ true }
				dataSource={ tableData }>
				<Column align='center' title="申请日期"  dataIndex="ts" />
				<Column align='center' title="账期" dataIndex="month" />
				<Column align='center' title="金额" dataIndex="amount" />
				<Column align='center' title="发票类型" dataIndex="type" />
				<Column align='center' width={ 240 } title="操作" render={ (operation: any, record: TableDataAttr): ReactElement => (
					<div className={ styles.operationBox }>
                        { findPermissions(11454) && <p onClick={ () => handleView(record) } className={ styles.operation }>查看</p> }
                        { findPermissions(11453) && <p onClick={ () => handleClickDecision(record, false) } className={ [styles.operation, styles.refused].join(' ') }>拒绝</p> }
                        { findPermissions(11453) && <p onClick={ () => handleClickDecision(record, true) } className={ [styles.operation, styles.agree].join(' ') }>同意</p> }
                    </div>
				) } />
			</Table>

            <Modal title={ invoiceTitle } width={ 600 } getContainer={ false } centered visible={ invoiceVisible } onOk={ () => handleInvoiceModalOk() } onCancel={ () => setInvoiceVisible(false) }>
                { offon && <>
                        <div className={ styles.modalLine }>
                            <span>快递单号：</span>
                            <Input placeholder="请输入快递单号" value={ order } onChange={ (e) => setOrder(e.target.value) } />
                        </div>
                        <div className={ styles.modalLine }>
                            <span>备注：</span>
                            <Input placeholder="请输入备注" value={ note } onChange={ (e) => setNote(e.target.value) } />
                        </div>
                </> }
                { offon || <div className={ styles.modalLine }>
                        <span>拒绝原因：</span>
                        <Input placeholder="请输入拒绝开票原因" value={ reason } onChange={ (e) => setReason(e.target.value) } />
                </div> }
            </Modal>
        </div>
    );
};

export default WaitInvoice;