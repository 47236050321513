const thatVeryDay: Function = (date: string | number | Date, pattern = "-"): any => {
    let oNew: Date = new Date(date);
    let oY: number = oNew.getFullYear();
    let oM: string | number = oNew.getMonth() + 1 > 10? oNew.getMonth() + 1 : "0" + (oNew.getMonth() + 1);
    let oD: string | number = oNew.getDate() > 10? oNew.getDate() : "0" + oNew.getDate();
    // eslint-disable-next-line no-useless-concat
    let timer: string = oY + pattern + oM + pattern + oD + " " + "00:00:00";
    let stampMS = new Date(timer).getTime();
    let stampS = parseInt((new Date(timer).getTime() / 1000).toString());
    return {
		timer,
		stampMS,
		stampS
    };
};

const timeTransformation: Function = (time: number, ymd: boolean = true, pattern: string = "-"): string => {
	let timestamp: number = time.toString().length <= 10 ? Number(time * 1000) : Number(time);
	let date: Date = new Date(timestamp);
	let Y: string = date.getFullYear() + pattern;
	let M: string = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + pattern;
	let D: string = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
	let h: string = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
	let m: string | number = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	let s: string | number = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
	return ymd ? Y + M + D + h + m + ":" + s : h + m + ":" + s;
};

const durationScale: Function = (duration: number, locale: string = "en"): string => {
	let internationalization: string[] = locale === "en"? ["d", "h", "min", "s"] : ["天", "小时", "分钟", "秒"];
	let days: number = Math.floor(duration / (24 * 3600 * 1000));
	let leave1: number = duration % (24 * 3600 * 1000);
	let hours: number = Math.floor(leave1 / (3600 * 1000));
	let leave2: number = leave1 % (3600 * 1000);
	let minutes: number = Math.floor(leave2 / (60 * 1000));
	let leave3: number = leave2 % (60 * 1000);
	let seconds: number = Math.round(leave3 / 1000);
	let str: string = "";
	if (days > 0) {
		str = days < 10 ? "0" + days + internationalization[0] : days + internationalization[0];
	}

	if (hours > 0) {
		str = hours < 10
		? str + "0" + hours + internationalization[1]
		: str + hours + internationalization[1];
	}

	if (minutes > 0) {
		str = minutes < 10
		? str + "0" + minutes + internationalization[2]
		: str + minutes + internationalization[2];
	}

	if (seconds > 0) {
		str = seconds < 10
		? str + "0" + seconds + internationalization[3]
		: str + seconds + internationalization[3];
	}
	return str;
};

const durationComputeAll: Function = (all: any[], startTime: string = "startTime", endTime: string = "endTime", locale = "en"): any => {
	let duration: number = 0;
	let startT: number = 0;
	all.forEach((time: any, index: number): void => {
		if (time[endTime] === 0 && index === all.length - 1) {
			time[endTime] = parseInt((Date.parse((new Date()).toString()) / 1000).toString());
		} else if (time[endTime] === 0) {
			startT === 0 && (startT = time[startTime]);
		} else {
			if (startT) {
				duration = duration + time[endTime] - startT;
				startT = 0;
			} else {
				duration = duration + time[endTime] - time[startTime];
			}
		}
	});
	let oScale = durationScale(duration * 1000, locale);
	return { character: oScale, duration };
};

const durationCompute: Function = (start: number, end: number, locale: string = "en"): any => {
	start = start.toString().length <= 10 ? Number(start * 1000) : Number(start);
	end = end.toString().length <= 10 ? Number(end * 1000) : Number(end);
	let internationalization: string[] = locale === "en" ? ["d", "h", "min", "s"] : ["天", "小时", "分钟", "秒"];
	let duration: number = end - start;
	let days: number = Math.floor(duration / (24 * 3600 * 1000));
	let leave1: number = duration % (24 * 3600 * 1000);
	let hours: number = Math.floor(leave1 / (3600 * 1000));
	let leave2: number = leave1 % (3600 * 1000);
	let minutes: number = Math.floor(leave2 / (60 * 1000));
	let leave3: number = leave2 % (60 * 1000);
	let seconds: number = Math.floor(leave3 / 1000);
	let str: string = "";
	if (days > 0) {
		str = days < 10 ? "0" + days + internationalization[0] : days + internationalization[0];
	}
	if (hours > 0 && hours < 24) {
		str = hours < 10
		? str + "0" + hours + internationalization[1]
		: str + hours + internationalization[1];
	}
	if (minutes > 0 && minutes < 60) {
		str = minutes < 10
		? str + "0" + minutes + internationalization[2]
		: str + minutes + internationalization[2];
	}
	if (seconds > 0 && seconds < 60) {
		str = seconds < 10
		? str + "0" + seconds + internationalization[3]
		: str + seconds + internationalization[3];
	}
	return { character: str, duration: parseInt((duration / 1000).toString()) };
};

export {
	thatVeryDay,
	timeTransformation,
	durationComputeAll,
	durationCompute,
	durationScale
};
