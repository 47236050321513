import { FC, ReactElement, useState, useEffect, useRef } from 'react';
import { getTimestampToTime } from '../../../../../../api/index';
import { createFromIconfontCN } from '@ant-design/icons';
import { Option } from '../../../../../../axios/axios-types';
import { scriptUrl } from '../../../../../../axios/baseUrl';
import axios from '../../../../../../axios/axios';
import styles from './fnAndCb.less';
import { AxiosResponse } from 'axios';

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    tableData: any[];
    type: string;
};

const FnAndCb: FC<IProps> = (props): ReactElement => {
    const [myMethod, setMyMethod]: [any[], Function] = useState([]);
    const [myCallback, setMyCallback]: [any[], Function] = useState([]);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const myRef = useRef<any>([]);

    useEffect((): void => {
        props.type === 'event' && fn();
        props.type === 'callback' && callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tableData]);

    const fn: Function = async (): Promise<void> => {
        const result: Array<AxiosResponse> = await getApiInfo();
        props.tableData.forEach((item: any, index: number): void => {
            item.isOpen = false;
            item.index = 1;
            item.thumbnailUrl = '../' + item.apiUrl.split('/')[item.apiUrl.split('/').length - 1];
            Object.assign(item, result[index].data.data);
        });
        setMyMethod(props.tableData);
    };

    const getApiInfo: Function = async (): Promise<Array<AxiosResponse>> => {
        const promiseAll: Array<Promise<AxiosResponse>> = [];  
        props.tableData.forEach((item: any): void => {
            const option: Option = {
                url: '/stream/getApiInfo',
                data: { apiId: item.apiId },
                method: 'POST'
            };
            promiseAll.push(axios(option));
        });
        return Promise.all(promiseAll);
    };

    const callback: Function = (): void => {
        props.tableData.forEach((item: any): void => {
            item.loading = false;
            item.isOpen = false;
            item.index = 1;
            item.list = null;
        });
        setMyCallback(props.tableData);
    };

    const changeOpen: Function = (index: number): void => {
        const myMetho: any[] = JSON.parse(JSON.stringify(myMethod));
        myMetho[index].isOpen = !myMetho[index].isOpen;
        setMyMethod(myMetho);
    };
      
    const changeIndex: Function = (index: number, num: number): void => {
        const myMetho: any[] = JSON.parse(JSON.stringify(myMethod));
        myMetho[index].index = num;
        setMyMethod(myMetho);
    };

    const changeCallbackOpen: Function = async (bool: boolean, index: number, cbId: any): Promise<void> => {
        if (loading) return;
        setLoading(true);
        const myCallbac = JSON.parse(JSON.stringify(myCallback));
        if (!myCallbac[index].isOpen && !myCallbac[index].list) {
            myCallbac[index].loading = true;
            setMyCallback(myCallbac);
            const option: Option = {
                url: '/stream/getCbInfo',
                data: { cbId },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0) {
                myCallbac[index].cbBody = result.data.data.cbBody;
                myCallbac[index].list = [];
                if (result.data.data.cbUrl) {
                    result.data.data.cbTs = getTimestampToTime(result.data.data.cbTs);
                    myCallbac[index].list = [result.data.data];
                };
            };
        }
        let height: any = null;
        if (myCallbac[index].isOpen) {
            height = 44;
        } else {
            if (myCallbac[index].list.length) {
                height = myCallbac[index].list.length * 73 + 248;
            } else {
                height = 318;
            }
        }
        myRef.current[index].style.height = height + "px";
        if (bool) {
            myCallbac[index].isOpen = bool;
        } else {
            myCallbac[index].isOpen = !myCallbac[index].isOpen;
        }
        myCallbac[index].loading = false;
        setMyCallback(JSON.parse(JSON.stringify(myCallbac)));
        setLoading(false);
    };

    return (
        <div className={ styles.fnAndCb }>
            { (props.type === 'event' && props.tableData.length !== 0) && <div className={ styles.content }>
                {
                    myMethod.map((item: any, index: number): ReactElement => {
                        return (<div className={ [styles.methods, (item.apiCode !== 0 && item.apiCode !== 200)? styles.error : '', item.isOpen? '' : styles.openMethods].join(' ') } key={ index }>
                            <div className={ styles.contentTitle } onClick={ () => changeOpen(index) }>
                                <div className={ styles.cTop }>
                                    <div className={ styles.type }>{ item.apiMethod }</div>
                                    <div className={ styles.address }>{ item.thumbnailUrl }</div>
                                    <div className={ styles.name }>{ item.apiDesc }</div>
                                </div>
                                <div className={ styles.tail }>
                                    <div className={ styles.time }>{ item.apiReqTs }</div>
                                    <div className={ [styles.i, item.isOpen? styles.up : ''].join(' ') }><IconFont type="icon-arrow" /></div>
                                </div>
                            </div>
                            <div className={ styles.url }>{ item.apiUrl }</div>
                            <div>
                                <div className={ styles.option }>
                                    <p className={ item.index === 1? styles.show : ''  } 
                                    onClick={ () => changeIndex(index, 1) }>Response</p>
                                    <p className={ item.index === 2? styles.show : ''  }
                                    onClick={ () => changeIndex(index, 2) }>Header</p>
                                    <p className={ item.index === 3? styles.show : ''  }
                                    onClick={ () => changeIndex(index, 3) }>Body参数</p>
                                </div>
                                <div className={ styles.line }>
                                    <p className={[item.index === 1? styles.t1 : '',
                                        item.index === 2? styles.t2 : '',
                                        item.index === 3? styles.t3 : ''].join(' ')}
                                    ></p>
                                </div>
                                <div className={ styles.result }>
                                    { item.index === 1 && <p>{ item.apiResponse }</p> }
                                    { item.index === 2 && <p>{ item.apiHeader }</p> }
                                    { item.index === 3 && <p>{ item.apiParams }</p> }
                                </div>
                            </div>
                        </div>)
                    })
                }
            </div> }
            { (props.type === 'callback' && props.tableData.length !== 0) && <div className={ styles.content }>
                {
                    myCallback.map((item: any, index: number): ReactElement => {
                        return (<div className={ [styles.callback, item.cbCode !== 0? styles.error : '' ].join(' ') }
                        ref={ el => (myRef.current[index] = el) } 
                        key={ index }>
                            <div className={ styles.contentTitle } onClick={ () => changeCallbackOpen(null, index, item.cbId) }>
                                <div className={ styles.cTop }>
                                    <div className={ styles.type }>POST</div>
                                    <div className={ styles.address }>{ item.cbType }: { item.cbEventMsg }</div>
                                    <div className={ styles.name }>{ item.cbEventDesc }</div>
                                </div>
                                <div className={ styles.tail }>
                                    <div className={ styles.time }>{ item.cbTs }</div>
                                    <div className={ [styles.i, item.isOpen? styles.up : ''].join(' ') }>
                                        { item.loading && <IconFont className={ styles.loading } type="icon-jiazai" /> }
                                        { item.loading || <IconFont type="icon-arrow" /> }
                                    </div>
                                </div>
                            </div>
                            { item.cbBody && <div className={ styles.result }>
                                <p>{ item.cbBody }</p>
                            </div> }
                            <div className={ styles.url }>Callback event</div>
                            { item.list && <div>
                                {
                                    item.list.map((callback: any, callindex: number): ReactElement => {
                                        return (<div key={ callindex } className={ styles.cblist }>
                                            <div className={ styles.item }>
                                                <div className={ styles.top }>
                                                    <div className={ styles.myurl }>{ callback.cbUrl }</div>
                                                    <div>{ callback.cbTs }</div>
                                                </div>
                                                <div>{ callback.cbRes }</div>
                                            </div>
                                        </div>)
                                    })
                                }
                            </div> }
                            { item.list && item.list.length === 0 && <div className={ styles.notCb }>无回调地址</div> }
                        </div>)
                    })
                }
            </div> }
            { 
                !props.tableData.length && <div className={ styles.notData }>暂无数据</div>
            }
        </div>
    )
};

export default FnAndCb;