// (图片, sdk文件)上传地址
const uploadUrl = 'http://api.admin.agrtc.cn:6369/arapi/arcrm/v1/file/v2UploadFile/'; // 公网
// const uploadUrl = 'http://pro.api.admin.agrtc.cn:6369/arapi/arcrm/v1/file/v2UploadFile/'; // 测试
// const uploadUrl = 'http://192.168.1.140:6369/arapi/arcrm/v1/file/v2UploadFile/'; // 李岩
// const uploadUrl = 'http://192.168.1.111:6369/arapi/arcrm/v1/file/v2UploadFile/'; // 牛明

// 接口请求地址
const baseURL = 'http://api.admin.agrtc.cn:6369/arapi/arcrm/v1'; // 公网
// const baseURL = 'http://pro.api.admin.agrtc.cn:6369/arapi/arcrm/v1'; //测试
// const baseURL = 'http://192.168.1.12:6369/arapi/arcrm/v1'; // 李岩
// const baseURL = 'http://192.168.1.111:6369/arapi/arcrm/v1'; // 牛明


const scriptUrl = '//at.alicdn.com/t/font_1996800_n0qml9z627.js'; // 阿里图标链接


// const baseURL = 'http://192.168.199.140:13475/arapi/arprimgr/v1'; // 接口请求地址  临时 李岩


export {
    uploadUrl,
    scriptUrl,
    baseURL
};