import { FC, ReactElement, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { findPermissions } from '../../../api/permiss';
import { createFromIconfontCN } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { scriptUrl } from '../../../axios/baseUrl';
import Member from './member/member';
import Role from './role/role';
const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

const Permiss: FC = (): ReactElement => {
    const history: any = useHistory();
    const location: any = useLocation();
    const [selectedKeys, setSelectedKeys]: [string, Function] = useState('permiss');
    useEffect((): void => {
        const path: Array<string> = location.pathname.split('/');
        if (path.includes('role')) {
            setSelectedKeys('role');
        } else {
            setSelectedKeys(path[path.length - 1]);
        }
    }, [location.pathname]);

    const handleClick: Function = (e: MenuInfo): void => {
        setSelectedKeys(e.key);
        if (e.key === 'permiss') {
            history.push(`/content/${e.key}`);
        } else {
            history.push(`/content/permiss/${e.key}`);
        }
    };
    return (
        <div>
            <Menu onClick={ (e) => handleClick(e) } selectedKeys={ [selectedKeys] } mode="horizontal">
                { findPermissions(25101) && <Menu.Item key="permiss" icon={ <IconFont type="icon-tianjiabaimingdan" /> }>
                成员管理
                </Menu.Item> }
                { findPermissions(25201) && <Menu.Item key="role" icon={ <IconFont type="icon-heimingdan" /> }>
                角色管理
                </Menu.Item> }
            </Menu>
            <Switch>
                { findPermissions(25201) && <Route path='/content/permiss/role' component={ Role } /> }
                { findPermissions(25101) && <Route path='/content/permiss' component={ Member } exact /> }
            </Switch>
        </div>
    )
};

export default Permiss;