import { FC, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { findPermissions } from '../../../../api/permiss';
import { Button, Table, DatePicker } from 'antd';
import { 
    moneyComma,
    getCurrentMonthOneDay, 
    getTimeFormateTwo, 
    todayS, getMonth, 
    getDate, 
    getDateOne, 
    getToday 
} from '../../../../api/index';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import moment from 'moment';
import styles from './index.less';
const { Column } = Table;
const { RangePicker } = DatePicker;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

enum State {'未支付', '支付失败', '支付成功'};
enum Type {'支付宝充值', '银行转账', '月账单消费', '余额初始化', '一次性账单消费'};
enum DealType {'recharge', 'aliPay', 'bank', 'deal2Zero'};

const Index: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const typeList: string[] = ["充值金额", "支付宝充值", "对公转账", "抹平余额记录"];
	const [dates, setDates]: [Array<any>, Function] = useState([]);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [starTs, setStarTs]: [boolean, Function] = useState(false);
	const [stopTs, setStopTs]: [boolean, Function] = useState(false);
	const [rangeValue, setRangeValue]: [any, Function] = useState([]);
	const [activeTime, setActiveTime]: [number, Function] = useState(1);
	const [today, setToday]: [string, Function] = useState('');
	const [dealTypeStr, setDealTypeStr]: [string, Function] = useState('recharge');
	const [type, setType]: [number, Function] = useState(0);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
    const [moneyList, setMoneyList]: [any[], Function] = useState([
        {
            money: 0,
            tabel: "总收入",
            color: '#409EFF',
            type: "all"
        },
        {
            money: 0,
            tabel: "总抹平金额",
            color: '#409EFF',
            type: "screeding"
        },
        {
            money: 0,
            tabel: "总欠费金额",
            color: '#409EFF',
            type: "owe"
        },
        {
            money: 0,
            tabel: "总盈利金额",
            color: '#409EFF',
            type: "profit"
        }
    ]);
    const [topUpList, setTopUpList]: [any[], Function] = useState([
        {
            money: 0,
            tabel: "充值金额",
            type: "totalDealChargeAmountByTs"
        },
        {
            money: 0,
            tabel: "支付宝充值",
            type: "totalAliPayAmountByTs"
        },
        {
            money: 0,
            tabel: "对公转账",
            type: "totalBankAmountByTs"
        },
        {
            money: 0,
            tabel: "抹平余额",
            type: "totalDealZeroAmountByTs"
        }
    ]);

    useEffect((): void => {
        getSummaryAmount();
        thisGetToday(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect((): void => {
        getDealAmountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, current, starTs, stopTs, type]);

    props.cacheLifecycles.didRecover(() => {
        thisGetToday(activeTime);
        getSummaryAmount();
        getDealAmountList();
    });

    const getDealAmountList: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/finance/getDealAmountList',
            data: {
                dealTypeStr,
                pageNum: current,
                pageSize,
                starTs,
                stopTs
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        setTableLoading(false);
        if (result.data.code === 0) {
            result.data.data.list.forEach((item: any): void => {
                item.dAmount = moneyComma(item.dAmount);
                item.dLeftCash = moneyComma(item.dLeftCash);
                item.dState = State[item.dState];
                item.dType = Type[item.dType];
            });
            setTableData(result.data.data.list);
            setTotal(result.data.data.totalNumber);
        }
    };

    const getSummaryAmount: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/finance/getSummaryAmount',
            method: 'GET'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const moneyLis: any[] = JSON.parse(JSON.stringify(moneyList));
            moneyLis.forEach((item: any): void => {
                switch (item.type) {
                    case "profit":
                        item.money = moneyComma(result.data.data.totalAmount).split('.')[0];
                        break;
                    case "owe":
                        item.money = moneyComma(result.data.data.totalArrearageAmount).split('.')[0];
                        break;
                    case "all":
                        item.money = moneyComma(result.data.data.totalDealChargeAmount).split('.')[0];
                        break;
                    case "screeding":
                        item.money = moneyComma(result.data.data.totalDealZeroAmount).split('.')[0];
                        break;
                }
            });
            setMoneyList(moneyLis);
        }
    };

    const handleBtnClickTimeChange: Function = (index: number): void => {
        thisGetToday(index);
		setActiveTime(index);
		setRangeValue([]);
	};

    const disabledDate: Function = (current: any): boolean => {
		if (!dates || dates.length === 0) {
			return current > moment().endOf('day');
		}
		// 只能选择当前日期之前, 并且日期范围不超过1095天(3年)
		const tooLate = dates[0] && current.diff(dates[0], 'days') > 1095;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') > 1095;
		return tooEarly || tooLate || current > moment().endOf('day');
	};

    const handleRangeChange: Function = (dates: Array<any>, dateStrings: Array<string>): void => {
		setRangeValue(dates);
		if (dateStrings[0] && dateStrings[1]) {
			setActiveTime(100);
			thisGetToday(dateStrings);
		} else {
			setActiveTime(1);
			handleBtnClickTimeChange(1);
		}
	};

    const thisGetToday: Function = (index: number): void => {
        let today: any = [];
        if (index === 1) {
            today.push(getCurrentMonthOneDay());
            today.push(getToday(0));
        } else if (index === 2) {
            today = getToday(89);
        } else if (index === 3) {
            today = getToday(179);
        } else if (Array.isArray(index)) {
            today.push(getTimeFormateTwo(index[0]));
            today.push(getTimeFormateTwo(index[1]));
        }
        getSummaryAmountByTime(index);
        setToday(today[0] + " / " + today[today.length - 1]);
    };

    const getSummaryAmountByTime: Function = async (index: number | any[]): Promise<void> => {
        let data: any = {
            starTs: null,
            stopTs: todayS()
        };
        if (index === 1) {
            data.starTs = getMonth();
        } else if (index === 2) {
            data.starTs = getDate(90);
        } else if (index === 3) {
            data.starTs = getDateOne(180);
        } else if (Array.isArray(index)) {
            data.starTs = parseInt((new Date(new Date(index[0]).toLocaleDateString()).getTime() / 1000).toString());
			data.stopTs = parseInt(((new Date(new Date(index[1]).toLocaleDateString()).getTime() / 1000)  + (60 * 60 * 24 - 1)).toString());
        }
        setStopTs(data.stopTs);
        setStarTs(data.starTs);
        const option: Option = {
            url: '/finance/getSummaryAmountByTime',
            data,
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const topUpLis: any = JSON.parse(JSON.stringify(topUpList));
            topUpLis.forEach((item: any): void => {
                switch (item.type) {
                    case "totalDealChargeAmountByTs":
                        item.money = moneyComma(result.data.data.totalDealChargeAmountByTs).split('.')[0];
                        break;
                    case "totalAliPayAmountByTs":
                        item.money = moneyComma(result.data.data.totalAliPayAmountByTs).split('.')[0];
                        break;
                    case "totalBankAmountByTs":
                        item.money = moneyComma(result.data.data.totalBankAmountByTs).split('.')[0];
                        break;
                    case "totalDealZeroAmountByTs":
                        item.money = moneyComma(result.data.data.totalDealZeroAmountByTs).split('.')[0];
                        break;
                }
            });
            setTopUpList(topUpLis);
        }
    };

    const handleCatTypeClick: Function = (index: number): void => {
        setType(index);
		setCurrent(1);
        setDealTypeStr(DealType[index]);
    };

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};

    const handleView: Function = (record: any): void => {
        history.push('/content/user/detail/financial/record', { uid: record.uid, private: {
            name: '财务管理',
            url: '/content/financial'
        } });
    };

    return (
        <div className={ styles.index }>
            <div className={ styles.money }>
                {
                    moneyList.map((item: any, index: number): ReactElement => {
                        return (
                            <div className={ styles.moneyItem } key={ index }>
                                <span className={ styles.type }>{ item.tabel }</span>
                                <span className={ styles.number } style={{ color: item.color }}>{ item.money }</span>
                            </div>
                        )
                    })
                }
            </div>
			<div className={ styles.RangePicker }>
                <Button onClick={ () => handleBtnClickTimeChange(1) } className={ [styles.btn, activeTime === 1 && styles.activeTime].join(' ') }>当前月</Button>
                <Button onClick={ () => handleBtnClickTimeChange(2) } className={ [styles.btn, activeTime === 2 && styles.activeTime].join(' ') }>近三个月</Button>
                <Button onClick={ () => handleBtnClickTimeChange(3) } className={ [styles.btn, activeTime === 3 && styles.activeTime].join(' ') }>近六个月</Button>
                <RangePicker
                value={ rangeValue }
                disabledDate={ (current) => disabledDate(current) }
                onChange={ (dates, dateStrings) => handleRangeChange(dates, dateStrings) }
                onCalendarChange={ val => setDates(val) }
                />
            </div>
            <div className={ styles.statistical }>
                <span>总额统计: { today }</span>
                <div className={ styles.statis }>
                    {
                        topUpList.map((item: any, index: number): ReactElement => {
                            return (
                                <div className={ styles.statisItem } key={ index }>
                                    <span className={ styles.type }>{ item.tabel }</span>
                                    <span className={ styles.number }>{ item.money }</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={ styles.linkBtnCont }>
                {
                    typeList.map((item: any, index: number): ReactElement => {
                        return <Button key={ index } className={ [styles.linkBtn, type === index && styles.catType].join(' ') }
                            onClick={ () => handleCatTypeClick(index) }>{ item }</Button>
                    })
                }
            </div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                loading={ tableLoading } 
                className={ styles.table }
                pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
                rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                sticky={ true }
                dataSource={ tableData }>
                <Column align='center' title="日期"  dataIndex="dTs" />
                <Column align='center' title="交易号" dataIndex="dealId" />
                <Column align='center' title="交易类型"  dataIndex="dType" />
                <Column align='center' title="交易金额" dataIndex="dAmount" />
                <Column align='center' title="账户余额"  dataIndex="dLeftCash" />
                <Column align='center' title="状态" dataIndex="dState" />
                <Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { findPermissions(24111) && <p onClick={ () => handleView(record) } className={ styles.operation }>查看</p> }
                    </div>
                ) } />
            </Table>
        </div>
    )
};

export default Index;