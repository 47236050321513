import { FC, Fragment, ReactElement, useState, useEffect } from 'react';
import { message, Table, Select } from 'antd';
import { findPermissions, userMoudleAppPath } from '../../../../api/permiss';
import { useHistory } from 'react-router-dom';
import { TableAppDataAttr } from '../tableColumns';
import { AuthType } from '../../../../types/enum';
import { Option } from '../../../../axios/axios-types';
import { getTimestampToTime } from '../../../../api';
import axios from '../../../../axios/axios';
import styles from './mediaStream.less';
const { Column } = Table;

namespace Antd {
	export const { Option } = Select;
};

interface Data {
	code: number;
	data: {
		list: Array<TableAppDataAttr>;
		totalNumber: number;
	};
};

interface Result {
	data: Data;
};

const MediaStream: FC = (): ReactElement => {
	const history: any = useHistory();
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<TableAppDataAttr>, Function] = useState([]);
	const [sortRule, setSortRule]: [number, Function] = useState(0);
	const [sortField, setSortField]: [string, Function] = useState('appStreamTs');
	const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [authType, setAuthType]: [number, Function] = useState(3);
	const [total, setTotal]: [number, Function] = useState(0);
	const [sortedInfo, setSortedInfo]: [any, Function] = useState({
		order: 'descend',
		field: 'appStreamTs',
	});
    
	useEffect((): void => {
		const getOnlineTestAppList: Function = async (): Promise<void> => {
			setTableLoading(true);
			const option: Option = {
				url: '/v2/app/getStreamAppList',
				method: 'POST',
				data: {
					sortRule,
					sortField,
					pageNum: current,
					pageSize,
					appStage: authType,
				}
			};
			const result: Result = await axios(option);
			if (result) {
				if (result.data.code === 0) {
					result.data.data.list.forEach((item: TableAppDataAttr): void => {
						item.appCreateTs = getTimestampToTime(item.appCreateTs);
						item.appStreamTs = getTimestampToTime(item.appStreamTs);
						item.userAuthType = AuthType[item.userAuthType as number];
					});
					setTotal(result.data.data.totalNumber);
					setTableData(result.data.data.list);
				} else {
					message.error('数据获取失败');
				}
			}
			setTableLoading(false);
		};
		getOnlineTestAppList();
	}, [pageSize, current, sortRule, sortField, authType]);


	useEffect((): void => {
		setCurrent(1);
	}, [sortRule, sortField]);

	const handleClick: Function = (record: TableAppDataAttr): void => {
		history.push(userMoudleAppPath(), { uid: record.uid, appid: record.appId, private: {
			name: '开通输入在线媒体流应用',
			url: '/content/user/mediaStream'
		} });
	};

	const resetData: Function = (): void => {
		setCurrent(1);
		setSortRule(0);
		setSortField('appStreamTs');
		setSortedInfo({
			order: 'descend',
			field: 'appStreamTs',
		});
	};

	const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
		setSortRule(sorter.order === 'ascend'? 1 : 0);
		setSortField(sorter.field);
		setSortedInfo(sorter);
	};

	const handleSelectChange: Function = async (value: number): Promise<void> => {
		setAuthType(value);
		resetData();
	};

    return (
        <div className={ styles.mediaStream }>
            <div className={ styles.serach }>
				<Select
					className={ styles.select }
					style={{ width: 200 }}
					value={ authType }
					onChange={ (value) => handleSelectChange(value) }
				>
					<Antd.Option value={ 3 }>全部</Antd.Option>
					<Antd.Option value={ 2 }>已上线</Antd.Option>
					<Antd.Option value={ 1 }>测试中</Antd.Option>
				</Select>
				<div className={ styles.total }>
					<span>开通输入在线媒体流应用：</span>
					<span>{ total }</span>
				</div>
			</div>
            <Table onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) } 
				loading={ tableLoading } 
				className={ styles.table }
				pagination={{
					defaultCurrent: 1,
					current,
					showSizeChanger: true,
					pageSize,
					total
				}}
				rowKey='appId'
				sticky={ true }
				sortDirections={['ascend', 'descend', 'ascend']} 
				dataSource={ tableData }>
				<Column align='center' title="应用名称" dataIndex="appName" />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'appCreateTs' && sortedInfo.order} sorter={ true } title="创建时间" dataIndex="appCreateTs" />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'appStreamTs' && sortedInfo.order} sorter={ true } title="开通时间" dataIndex="appStreamTs" />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'appRtcCons	' && sortedInfo.order} sorter={ true } title="RTC时长(分钟)" dataIndex="appRtcCons" />
				<Column align='center' showSorterTooltip={ false } sortOrder={ sortedInfo.field === 'appStreamCons' && sortedInfo.order} sorter={ true } title="输入媒体流时长(分钟)" dataIndex="appStreamCons" />
				<Column align='center' title="认证类型" dataIndex="userAuthType" />
				<Column align='center' title="操作" render={ (operation: any, record: TableAppDataAttr): ReactElement => (
					<Fragment>{ findPermissions(11002) && <p onClick={ () => handleClick(record) } className={ styles.operation }>查看</p> }</Fragment>
				) } />
			</Table>
        </div>
    )
};


export default MediaStream;