import { FC, ReactElement, useEffect, useState } from 'react';
import { Table, Button, Modal, message } from 'antd';
import { ResultData } from '../resultTypes';
import axios from '../../../../../../axios/axios';
import { Option } from '../../../../../../axios/axios-types';
import pubsub from 'pubsub-js';
import * as echarts from 'echarts';
import styles from './cloud.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface EchEle {
    id: string;
    color: Array<string>;
    data: 'audMin' | 'hdMin' | 'hhdMin' | 'tkMin' | 'tkpMin';
    type: string;
};

const Cloud: FC = (): ReactElement => {
    const [audMin, setAudMin]: [number, Function] = useState(0);
    const [hdMin, setHdMin]: [number, Function] = useState(0);
    const [hhdMin, setHhdMin]: [number, Function] = useState(0);
    const [tkMin, setTkMin]: [number, Function] = useState(0);
    const [tkpMin, setTkpMin]: [number, Function] = useState(0); 
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<ResultData>, Function] = useState([]);
	const [opened, setOpened]: [boolean, Function] = useState(false);
	const [data, setData]: [any, Function] = useState({});
	const [isModalVisible, setIsModalVisible]: [boolean, Function] = useState(false);
    const [echEle]: [Array<EchEle>, Function] = useState([
        { 
            id: 'echartsAudio',
            color: ["#FFAC69", "#E5E9F2"],
            type: '语音',
            data: 'audMin'
        }, 
        { 
            id: 'echartsVideo',
            color: ["#5DE6D7", "#E5E9F2"],
            type: '高清视频',
            data: 'hdMin'
        }, 
        { 
            id: 'echartsHdvideo',
            color: ["#2FA2FF", "#E5E9F2"],
            type: '超高清视频',
            data: 'hhdMin'
        }, 
        { 
            id: 'echartsTwok',
            color: ["#FE5656", "#E5E9F2"],
            type: '2K',
            data: 'tkMin'
        }, 
        { 
            id: 'echartsTwoka',
            color: ["#4554FE", "#E5E9F2"],
            type: '2K+',
            data: 'tkpMin'
        }]);

    useEffect((): any => {
        window.addEventListener("resize", windowResize);
        return (): void => {
            window.removeEventListener("resize", windowResize);
        };
    });

    const windowResize = (): void => {
        getElementById('echartsLine').resize();
    }; 

    const getElementById: Function = (id: string): any => {
		const element: HTMLElement | null = document.getElementById(id);
		if (element) {
			return echarts.init(element);
		}
    };

    useEffect((): void => {
        pubsub.unsubscribe('dosage');
        pubsub.subscribe('dosage', async (name: string, data: any): Promise<void> => {
            setData(data);
            const open: boolean = await getAppIsOn(data);
            setOpened(open);
            open && getUserConsumption(data);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAppIsOn: Function = async (data: any): Promise<boolean> => {
        if (data.sType) {
            const option: Option = {
                url: '/developers/getAppIsOn',
                data: { aid: data.appid },
                method: 'POST'
            };
            const result = await axios(option);
            if (result.data.code === 0) {
                return result.data.data[0].vod_on === 1;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };
    const getUserConsumption: Function = async (data: any): Promise<void> => {
        const option: Option = {
            url: '/developers/getUserConsumption',
            data: {
                catType: 3,
                appId: data.appid,
                devpId: data.uid,
                gType: data.gType,
                sType: data.sType,
                startTs: data.startTs,
                stopTs: data.stopTs,
            },
            method: 'POST'
        };
        setTableLoading(true);
        const result: Result = await axios(option);
        if (result.data.code === 0 && result.data.data.list) {
            initCharts(result.data.data.list);
        };
        setTableLoading(false);
    };

    const initCharts: Function = (data: Array<ResultData>): void => {
        const timeList: Array<string> = [];
        const audMinList: Array<number> = [];
        const hdMinList: Array<number> = [];
        const hhdMinList: Array<number> = [];
        const tkMinList: Array<number> = [];
        const tkpMinList: Array<number> = [];

        const num = {
            audMin: 0,
            hdMin: 0,
            hhdMin: 0,
            tkMin: 0,
            tkpMin: 0,
        }
        
        data.forEach((item: ResultData): void => {
            item.combined = (item.audMin as number) + (item.hdMin as number) + (item.hhdMin as number) + (item.tkMin as number) + (item.tkpMin as number);
            num.audMin += (item.audMin as number);
            num.hdMin += (item.hdMin as number);
            num.hhdMin += (item.hhdMin as number);
            num.tkMin += (item.tkMin as number);
            num.tkpMin += (item.tkpMin as number);
            audMinList.push((item.audMin as number));
            hdMinList.push((item.hdMin as number));
            hhdMinList.push((item.hhdMin as number));
            tkMinList.push((item.tkMin as number));
            tkpMinList.push((item.tkpMin as number));
            timeList.push((item.date as string));
        });
        var combined: number = (num.audMin as number) + (num.hdMin as number) + (num.hhdMin as number) + (num.tkMin as number) + (num.tkpMin as number);
        setAudMin(num.audMin);
        setHdMin(num.hdMin);
        setHhdMin(num.hhdMin);
        setTkMin(num.tkMin);
        setTkpMin(num.tkpMin);
        setTableData(data);
        echEle.forEach((item: EchEle): void => {
            const target: any = getElementById(item.id);
            if (!target) return
            target.setOption({
                series: [
                    {
                        hoverAnimation: false,
                        type: "pie",
                        radius: ["85%", "92%"],
                        avoidLabelOverlap: false,
                        labelLine: { show: false },
                        silent: true,
                        color: item.color,
                        data: [num[item.data], num[item.data] === 0 ? 1 : combined - num[item.data]]
                    }
                ]
            });
        });


        const element: any = getElementById('echartsLine');
        if (!element) return
        element.setOption({
            tooltip: {
                trigger: "axis",
                backgroundColor: "#FFF",
                extraCssText: "box-shadow:0 0 18px #ccc",
                textStyle: {
                    fontSize: "12px",
                    width: "40px",
                    fontFamily:
                    "Microsoft YaHei Regular, Microsoft YaHei Regular-Regular",
                    fontWeight: 400,
                    color: "#606060"
                },
                formatter: function(params: string | any[]): string {
                    var res = `<div style='padding:2px 10px;'>${params[0].name}</div>`;
                    for (var i = 0, l = params.length; i < l; i++) {
                        var color: string = '';
                        if (params[i].seriesName === '语音') {
                            color = '#ffac6f';
                        } else if (params[i].seriesName === "高清视频") {
                            color = '#71ede0';
                        } else if (params[i].seriesName === "超高清视频") {
                            color = '#2FA2FF';
                        } else if (params[i].seriesName === "2K")  {
                            color = '#FE5656';
                        } else if (params[i].seriesName === "2K+") {
                            color = '#4554FE';
                        }

                        res += `<div style='display: flex;align-items:center;justify-content: space-between;padding:4px 10px;width: 150px'>
                            <div style='display: flex;align-items:center;'>
                                <span style='width: 16px;height: 3px; display: inline-block; background: ${color};margin-right: 10px'></span>
                                <span>${params[i].seriesName}</span>
                            </div>
                            <span>${params[i].value}</span>
                        </div>`;
                    }
                    return res;
                },
                position: ["6%", "30%"]
            },
            legend: {
                data: ["语音", "高清视频", "超高清视频", "2K", "2K+"]
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
            },
            color: ["#FFAC69", "#5DE6D7", "#2FA2FF", "#FE5656", "#4554FE"],
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: timeList,
                axisLabel: {
                    show: true,
                    textStyle: { color: "#666" }
                },
                axisLine: { show: false }
            },
            yAxis: {
                type: "value",
                axisLine: { show: false },
                axisLabel: {
                    show: true,
                    textStyle: { color: "#666" }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ["#eee"],
                        width: 1,
                        type: "solid"
                    }
                }
            },
            series: [
                {
                    name: "语音",
                    type: "line",
                    data: audMinList
                },
                {
                    name: "高清视频",
                    type: "line",
                    data: hdMinList
                },
                {
                    name: "超高清视频",
                    type: "line",
                    data: hhdMinList
                },
                {
                    name: "2K",
                    type: "line",
                    data: tkMinList
                },
                {
                    name: "2K+",
                    type: "line",
                    data: tkpMinList
                }
            ]
        });
    };

    const calculateT: Function = (type: string): number => {
        switch (type) {
            case 'audMin':
                return audMin;
            case 'hdMin':
                return hdMin;
            case 'hhdMin':
                return hhdMin;
            case 'tkMin':
                return tkMin;
            case 'tkpMin':
                return tkpMin;
            default:
                return 0;
        }
    };

    const handleOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/updateVodOn',
            data: { aid: data.appid, vod_on: 1 },
            method: 'POST'
        };
        const result = await axios(option);
        if (result.data.code === 0) {
            const open: boolean = await getAppIsOn(data);
            setOpened(open);
            open && getUserConsumption(data);
            message.success('服务开通成功');
        } else {
            message.error('服务开通失败');
        }
        setIsModalVisible(false);
    };

    const handleSorter: Function = (a: any, b: any): boolean => {
        return a.date > b.date;
    };

    return (
        <div className={ styles.cloud }>
            { opened && <>
                <div className={ styles.echartsCont }>
                    {
                        echEle.map((item: EchEle, index: number): ReactElement => {
                            return (
                                <div className={ styles.roundBox } key={ index }>
                                    <div id={ item.id } className={ styles.echarts }></div>
                                    <div className={ styles.roundTime }>
                                    <img src={ require('../../../../../../assets/images/time.png').default } alt=""/>
                                    <p className={ styles.time }>{ calculateT(item.data) }</p>
                                    <p className={ styles.minutes }>分钟</p>
                                    </div>
                                    <p className={ styles.roundP }>{ item.type }</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div id='echartsLine' className={ styles.echartsLine }></div>
                <Table
                    loading={ tableLoading } 
                    className={ styles.table }
                    rowKey='date'
                    sticky={ true }
                    pagination={ false }
                    sortDirections={['ascend', 'descend', 'ascend']} 
                    dataSource={ tableData }
                    summary={ pageData => {
                        let audMin: number = 0;
                        let hdMin: number = 0;
                        let hhdMin: number = 0;
                        let tkMin: number = 0;
                        let tkpMin: number = 0;
                        let combined: number = 0;
                        pageData.forEach((item: any) => {
                            audMin += item.audMin;
                            hdMin += item.hdMin;
                            hhdMin += item.hhdMin;
                            tkMin += item.tkMin;
                            tkpMin += item.tkpMin;
                            combined += item.combined;
                        });
                        return (
                            <Table.Summary.Row>
                            <Table.Summary.Cell align='center' className={ styles.cell } index={ 1 }>合计</Table.Summary.Cell>
                            <Table.Summary.Cell align='center' className={ styles.cell } index={ 2 }>{ audMin }</Table.Summary.Cell>
                            <Table.Summary.Cell align='center' className={ styles.cell } index={ 3 }>{ hdMin }</Table.Summary.Cell>
                            <Table.Summary.Cell align='center' className={ styles.cell } index={ 4 }>{ hhdMin }</Table.Summary.Cell>
                            <Table.Summary.Cell align='center' className={ styles.cell } index={ 5 }>{ tkMin }</Table.Summary.Cell>
                            <Table.Summary.Cell align='center' className={ styles.cell } index={ 6 }>{ tkpMin }</Table.Summary.Cell>
                            <Table.Summary.Cell align='center' className={ styles.cell } index={ 7 }>{ combined }</Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    }}
                    >
                    <Column align='center' defaultSortOrder='descend' showSorterTooltip={ false } sorter={ (a, b) => handleSorter(a, b) } title="日期" dataIndex="date" />
                    <Column align='center' title="语音"  dataIndex="audMin" />
                    <Column align='center' title="高清视频" dataIndex="hdMin" />
                    <Column align='center' title="超高清视频" dataIndex="hhdMin" />
                    <Column align='center' title="2K" dataIndex="tkMin" />
                    <Column align='center' title="2K+" dataIndex="tkpMin" />
                    <Column align='center' title="合计" dataIndex="combined" />
                </Table>
                <div className={ styles.instructions }>
                    <ul>
                        <li><span>*</span> 当天数据会有大约2小时延迟。</li>
                        <li><span>*</span> 由于本页统计以秒计量，可能与最终以分钟计量的账单用量有误差。最终实际用量需要查看准确帐单列表的账单详情。</li>
                    </ul>
                </div>
            </> }
            { opened || <>
                <div className={ styles.noOpen }>
                    <img src={ require('../../../../../../assets/images/Without_permission.png').default } alt="" />
                    <div className={ styles.noOpenSon }>
                        <p>您需要为当前项目开通云端录制服务吗?</p>
                        <Button onClick={ () => setIsModalVisible(true) } type="primary">开通云端录制</Button>
                    </div>
                </div>
                <Modal title="开通服务" 
                centered
                visible={ isModalVisible } onOk={ () => handleOk() } 
                onCancel={ () => setIsModalVisible(false) }>
                    <p>您需要为当前项目开通云端录制服务吗?</p>
                </Modal>
            </> }
        </div>
    )
};


export default Cloud;