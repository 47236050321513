import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Menu, message } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { getTimestampToTime } from '../../../../api/index';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './index.less';
const { Column } = Table;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

interface IProps {
    cacheLifecycles: any;
};

const Index: FC<IProps> = (props): ReactElement => {
    const history: any = useHistory();
    const [loading, setLoading]: [boolean, Function] = useState(false);
	const [tableData, setTableData]: [Array<any>, Function] = useState([]);
    const [selectedKeys, setSelectedKeys]: [string, Function] = useState('RTC');
    const [selectedType, setSelectedType]: [number, Function] = useState(1);
    const options: any[] = [
        {
            key: 'RTC',
            type: 1,
            label: 'RTC-SDK'
        },
        {
            key: 'RTM',
            type: 2,
            label: 'RTM-SDK'
        },
        {
            key: 'HDBB',
            type: 3,
            label: '互动白板-SDK'
        },
        {
            key: 'Talk',
            type: 4,
            label: 'Talk-SDK'
        }
    ];
    
    useEffect((): void => {
        getSdkVersionList(selectedType);
    }, [selectedType]);

    props.cacheLifecycles.didRecover(() => {
        getSdkVersionList(selectedType);
    });


    const getSdkVersionList: Function = async (softid: number): Promise<void> => {
        const option: Option = {
            url: '/sdk/getSdkVersionList',
            data: { softid },
            method: 'POST',
        };
        setLoading(true);
        const result: Result = await axios(option);
        setLoading(false);
        if (result.data.code === 0) {
            result.data.data.forEach((item: any): void => {
                item.createAt = item.createAt? getTimestampToTime(item.createAt) : '';
            });
            setTableData(result.data.data);
        } else {
            message.error('数据获取失败');
        }
    };

    const handleView: Function = (typeId: number): void => {
        history.push('/content/version/detail', { softId: selectedType, typeId });
    };

    const handleUpdate: Function = (typeId: number): void => {
        history.push('/content/version/dealVersion', { softId: selectedType, typeId });
    };

    const handleClick: Function = (item: any): void => {
        setSelectedKeys(item.key);
        setSelectedType(item.type);
    };

    return (
        <div className={ styles.index }> 
            { findPermissions(22100) && <Fragment>
                <Menu selectedKeys={ [selectedKeys] } mode="horizontal">
                    {options.map((item: any, index: number): ReactElement => {
                        return (<Fragment key={ index }>
                            <Menu.Item key={ item.key } onClick={ () => handleClick(item) } className={ styles.menuItem }>{ item.label } { item.num }</Menu.Item>
                        </Fragment>)
                    })}
                </Menu>
                <div className={ styles.titleAndTable }>
                    <Table
                        loading={ loading } 
                        className={ styles.table }
                        pagination={ false }
                        rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                        sticky={ true }
                        dataSource={ tableData }>
                        <Column align='center' title="SDK名称"  dataIndex="typeName" />
                        <Column align='center' title="版本" dataIndex="bigVersion" />
                        <Column align='center' title="更新内容" dataIndex="updateDesc" />
                        <Column align='center' title="更新时间" dataIndex="createAt" />
                        <Column align='center' width={ 180 } title="操作" render={ (operation: any, record: any): ReactElement => (
                            <div className={ styles.operationBox }>
                                { findPermissions(22101) && <p className={ styles.operation } onClick={ () => handleView(record.typeId) }>查看</p> }
                                { findPermissions(22201) && <p className={ styles.operation } onClick={ () => handleUpdate(record.typeId) }>更新</p> }
                            </div>
                        ) } />
                    </Table>
                </div>
            </Fragment> }
        </div>
    )
};

export default Index;