import { FC, ReactElement, useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { findPermissions } from '../../../../api/permiss';
import { useLocation, useHistory } from 'react-router-dom';
import { Option } from '../../../../axios/axios-types';
import axios from '../../../../axios/axios';
import styles from './viewEmail.less';

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const ViewEmail: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [email, setEmail]: [any, Function] = useState({});
    const [awaitEmail, setAwaitEmail]: [any[], Function] = useState([]);

    useEffect((): void => {
        setEmail(location.state);
        getWaitSendEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getWaitSendEmail: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/getWaitSendEmail',
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setAwaitEmail(result.data.data.list);
        } else {
            message.error('数据获取失败');
        }
    };

    const edit: Function = (): void => {
        history.push('/content/email/dealEmail', email);
    };

    const back: Function = (): void => {
        history.push('/content/email');
    };

    return (
        <div className={ styles.viewEmail }>
            <div className={ styles.modal }>
                <div className={ styles.title }>
                    <span className={ styles.span }>{ email.title }</span>
                    <div className={ styles.btnBox }>
                        <Button className={ styles.btn } disabled={ !findPermissions(21602) || awaitEmail.length >= 4 } type="primary" onClick={ () => edit() }>Edit email</Button>
                        <Button className={ styles.btn } onClick={ () => back() }>Back</Button>
                    </div>
                </div>
                <div className={ styles.innerHtml } dangerouslySetInnerHTML={{ __html: email.content }}></div>
            </div>
        </div>
    )
};

export default ViewEmail;
