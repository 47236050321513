import { FC, ReactElement } from 'react';
import { CacheRoute } from 'react-router-cache-route';
import { Route } from 'react-router-dom';
import Index from './index/index';
import DealEmail from './dealEmail/dealEmail';
import ViewEmail from './viewEmail/viewEmail';

const Email: FC = (): ReactElement => {
    return (
        <div>
            <CacheRoute path='/content/email' component={ Index } exact />
            <Route path='/content/email/dealEmail' component={ DealEmail } exact />
            <Route path='/content/email/viewEmail' component={ ViewEmail } exact />
        </div>
    )
};

export default Email;