import { FC, ReactElement, useEffect, useState } from 'react';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { findPermissions } from '../../../api/permiss';
import { createFromIconfontCN } from '@ant-design/icons';
import { CacheRoute, dropByCacheKey, getCachingKeys } from 'react-router-cache-route';
import { scriptUrl } from '../../../axios/baseUrl';
import { useHistory, useLocation } from 'react-router-dom';
import Index from './index/index';
import Invoice from './invoice/invoice';
import Package from './package/package';

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

const Financial: FC = (): ReactElement => {
    const history: any = useHistory();
    const location: any = useLocation();
    const [selectedKeys, setSelectedKeys]: [string, Function] = useState('financial');

    useEffect((): void => {
        const path: Array<string> = location.pathname.split('/');
        setSelectedKeys(path[path.length - 1]);
    }, [location.pathname]);

    const handleClick: Function = (e: MenuInfo): void => {
        getCachingKeys().forEach((item: string): void => {
			dropByCacheKey(item);
		});
        setSelectedKeys(e.key);
        if (e.key === 'financial') {
            history.push(`/content/${e.key}`);
        } else {
            history.push(`/content/financial/${e.key}`);
        }
    };
    return (
        <div>
            <Menu onClick={ (e) => handleClick(e) } selectedKeys={ [selectedKeys] } mode="horizontal">
                { findPermissions(24101) && <Menu.Item key="financial" icon={ <IconFont type="icon-jiaoyi" /> }>
                交易记录
                </Menu.Item> }
                { findPermissions(24201) && <Menu.Item key="invoice" icon={ <IconFont type="icon-fapiao" /> }>
                发票管理
                </Menu.Item> }
                { findPermissions(24301) && <Menu.Item key="package" icon={ <IconFont type="icon-xiexiangbao" /> }>
                套餐包管理
                </Menu.Item> }
            </Menu>
            { findPermissions(24101) && <CacheRoute path='/content/financial' cacheKey='records' component={ Index } exact /> }
            { findPermissions(24201) && <CacheRoute path='/content/financial/invoice' cacheKey='invoic' component={ Invoice } exact /> }
            { findPermissions(24301) && <CacheRoute path='/content/financial/package' cacheKey='package' component={ Package } exact /> }
        </div>
    )
};

export default Financial;