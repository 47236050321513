import { FC, ReactElement } from 'react';
import { CacheRoute } from 'react-router-cache-route';
import { Route } from 'react-router-dom';
import Index from './index';
import Detail from './detail/detail';
import All from './all/all';
import Recent from './recent/recent';
import Usage from './usage/usage';
import Realname from './realname/realname';
import Registration from './registration/registration';
// import Registered from './registered/registered';
// import OldUser from './oldUser/oldUser';
import TokenAuth from './tokenAuth/tokenAuth';
import Invoice from './invoice/invoice';
import Crtification from './crtification/crtification';
import Pay from './pay/pay';
import OweMoney from './oweMoney/oweMoney';
import Balance from './balance/balance';
import Giving from './giving/giving';
import Monthly from './monthly/monthly';
import Minimum from './minimum/minimum';
import LadderPricing from './ladderPricing/ladderPricing';
import TotalApp from './totalApp/totalApp';
import Added from './added/added';
import Cloudtorecord from './cloudtorecord/cloudtorecord';
import SmallProgram from './smallProgram/smallProgram';
import Live from './live/live';
import EvenWheat from './evenWheat/evenWheat';
import HalfPrice from './halfPrice/halfPrice';
import Receive from './receive/receive';
import Plate from './plate/plate';
import MediaStream from './mediaStream/mediaStream';




const User: FC = (): ReactElement => {
    return (
        <div>
            <Route path='/content/user' component={ Index } exact />
            <CacheRoute cacheKey='all' path='/content/user/all' component={ All } />
            <CacheRoute cacheKey='recent' path='/content/user/recent' component={ Recent } />
            <CacheRoute cacheKey='usage' path='/content/user/usage' component={ Usage } />
            <CacheRoute cacheKey='realname' path='/content/user/realname' component={ Realname } />
            <CacheRoute cacheKey='registration' path='/content/user/registration' component={ Registration } />
            {/* <CacheRoute cacheKey='registered' path='/content/user/registered' component={ Registered } />
            <CacheRoute cacheKey='oldUser' path='/content/user/oldUser' component={ OldUser } /> */}
            <CacheRoute cacheKey='tokenAuth' path='/content/user/tokenAuth' component={ TokenAuth } />
            <CacheRoute cacheKey='invoice' path='/content/user/invoice' component={ Invoice } />
            <CacheRoute cacheKey='crtification' path='/content/user/crtification' component={ Crtification } />
            <CacheRoute cacheKey='pay' path='/content/user/pay' component={ Pay } />
            <CacheRoute cacheKey='oweMoney' path='/content/user/oweMoney' component={ OweMoney } />
            <CacheRoute cacheKey='balance' path='/content/user/balance' component={ Balance } />
            <CacheRoute cacheKey='giving' path='/content/user/giving' component={ Giving } />
            <CacheRoute cacheKey='monthly' path='/content/user/monthly' component={ Monthly } />
            <CacheRoute cacheKey='minimum' path='/content/user/minimum' component={ Minimum } />
            <CacheRoute cacheKey='ladderPricing' path='/content/user/ladderPricing' component={ LadderPricing } />
            <CacheRoute cacheKey='totalApp' path='/content/user/totalApp' component={ TotalApp } />
            <CacheRoute cacheKey='added' path='/content/user/added' component={ Added } />
            <CacheRoute cacheKey='cloudtorecord' path='/content/user/cloudtorecord' component={ Cloudtorecord } />
            <CacheRoute cacheKey='smallProgram' path='/content/user/smallProgram' component={ SmallProgram } />
            <CacheRoute cacheKey='live' path='/content/user/live' component={ Live } />
            <CacheRoute cacheKey='evenWheat' path='/content/user/evenWheat' component={ EvenWheat } />
            <CacheRoute cacheKey='halfPrice' path='/content/user/halfPrice' component={ HalfPrice } />
            <CacheRoute cacheKey='receive' path='/content/user/receive' component={ Receive } />
            <CacheRoute cacheKey='plate' path='/content/user/plate' component={ Plate } />
            <CacheRoute cacheKey='mediaStream' path='/content/user/mediaStream' component={ MediaStream } />
            <Route path='/content/user/detail' component={ Detail } />
        </div>
    );
}


export default User;