import XLSX from 'xlsx';

const exportSCV = (header: Array<string>, filterVal: Array<string>, data: Array<any>, fileName: string) => {
	const json = data.map((item: any): Array<any> => {
		return filterVal.reduce((newData: any, key: string, index: number): any => {
			newData[header[index]] = item[key];
			return newData;
		}, {});
	});
	const sheet = XLSX.utils.json_to_sheet(json);
	openDownloadDialog(sheet2blob(sheet, undefined), `${fileName}.xlsx`);
};

const openDownloadDialog = (url: string | Blob, saveName: string) => {
	if (typeof url == 'object' && url instanceof Blob) {
		url = URL.createObjectURL(url);
	};
	var aLink = document.createElement('a');
	aLink.href = url;
	aLink.download = saveName || '';
	var event;
	if (window.MouseEvent) event = new MouseEvent('click');
	else {
		event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
	}
	aLink.dispatchEvent(event);
};
const sheet2blob = (sheet: XLSX.WorkSheet, sheetName: string | undefined) => {
	sheetName = sheetName || 'sheet1';
	var workbook: any = {
		SheetNames: [sheetName],
		Sheets: {}
	};
	workbook.Sheets[sheetName] = sheet;

	var wopts: any = {
		bookType: 'xlsx',
		bookSST: false,
		type: 'binary'
	};
	var wbout = XLSX.write(workbook, wopts);
	var blob = new Blob([s2ab(wbout)], {
		type: "application/octet-stream"
	});
	function s2ab(s: string) {
		var buf = new ArrayBuffer(s.length);
		var view = new Uint8Array(buf);
		for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
		return buf;
	};
	return blob;
};

export default exportSCV;