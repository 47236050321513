import { CSSProperties, FC, ReactElement, useEffect, useState } from 'react';
import { Descriptions, Button, Radio, Input, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { getNowFormatDate } from '../../../../../api/index';
import { Option } from '../../../../../axios/axios-types';
import axios from '../../../../../axios/axios';
import styles from './renewal.less';
const { TextArea } = Input;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const labelStyle: CSSProperties = { 
    width: '100px', 
    textAlign: 'right', 
    fontSize: '12px',
    display: 'inline-block'
};

const Renewal: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [remark, setRemark]: [string, Function] = useState('');
    const [devpAuthInfo, setDevpAuthInfo]: [any, Function] = useState({});
    const [licenseInfo, setLicenseInfo]: [any, Function] = useState({});
    const [period, setPeriod]: [number, Function] = useState(180);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const ts: string = getNowFormatDate();
    

    useEffect((): void => {
        getRenewalDevpInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRenewalDevpInfo: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/primgr/getRenewalDevpInfo',
            data: {
                oem: location.state.oem,
                uid: location.state.uid
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setDevpAuthInfo(result.data.data.devpAuthInfo);
            setLicenseInfo(result.data.data.licenseInfo);
        } else {
            message.error("获取客户信息失败");
        }
    };

    const handleTo: Function = (): void => {
        history.push('/content/private/detail/server', location.state);
    };

    const handleSubmit: Function = async (): Promise<void> => {
        if (/[\d]/.test(period + '')) {
            if (period >= 1) {
                const data: any = {
                    license: licenseInfo.license,
                    period: +period,
                    remark: remark,
                    uid: location.state.uid
                };
                const option: Option = {
                    url: '/primgr/applyRenewal',
                    data,
                    method: 'POST'
                };
                setLoading(true);
                const result: Result = await axios(option);
                setLoading(false);
                if (result.data.code === 0) {
                    message.success("续期申请成功");
                    handleTo();
                } else {
                    message.error("续期申请失败");
                }
            } else {
                message.error('续期时长不能少于一天');
            }
        } else {
            message.error('请输入正确的续期时长');
        }
    };
    return (
        <div className={ styles.renewal }>
            <div className={ styles.modal }>
                <p className={ styles.title }>申请续期</p>
                <Descriptions title='续期客户' column={ 2 } className={ styles.descriptions }>
                    <Descriptions.Item labelStyle={ labelStyle } label="厂商">{ licenseInfo.oem }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="申请时间">{ ts }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="预留邮箱">{ licenseInfo.email }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="预留手机号">{ licenseInfo.phone }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="认证信息">{ devpAuthInfo.uAuthType === 1 && <div className={ styles.hoverbox }>
                            <a href={ devpAuthInfo.idFron } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.idFron && devpAuthInfo.idFron.substr(devpAuthInfo.idFron.length - 15) }</a>
                            <a href={ devpAuthInfo.idBack } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.idBack && devpAuthInfo.idBack.substr(devpAuthInfo.idBack.length - 15) }</a>
                        </div> }
                        { devpAuthInfo.uAuthType === 2 && <div className={ styles.hoverbox }>
                            <a href={ devpAuthInfo.licUrl } target="_blank" rel="noopener noreferrer">{ devpAuthInfo.licUrl && devpAuthInfo.licUrl.substr(devpAuthInfo.licUrl.length - 15) }</a>
                        </div> }
                        { !devpAuthInfo.uAuthType && <p>There is no certification</p> }
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="公司地址">{ devpAuthInfo.comAddr }</Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="备注">
                        <TextArea
                        className={ styles.textarea }
                        value={ remark }
                        onChange={ (e) => setRemark(e.target.value) }
                        maxLength={ 30 }
                        placeholder="请输入备注"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        />    
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={ labelStyle } label="续期时长">
                        <Input className={ styles.input } addonAfter='天'
                        type='number'
                        min={ 1 }
                        placeholder="请输入续期时长" value={ period } 
                        onChange={ (e) => setPeriod(e.target.value) } />
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions title='服务类型'>
                    <Descriptions.Item labelStyle={ labelStyle }>
                        <Radio defaultChecked={ true } disabled={ true }>音视频</Radio>
                    </Descriptions.Item>
                </Descriptions>
                <div className={ styles.btnBox }>
                    <Button className={ styles.btn } onClick={ () => handleTo() }>Cancel</Button>
                    <Button className={ styles.btn } disabled={ loading } loading={ loading } onClick={ () => handleSubmit() } type="primary">Submit</Button>
                </div>
            </div>
        </div>
    )
};

export default Renewal;