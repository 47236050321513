import { FC, ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { findPermissions } from '../../../../../../api/permiss';
import { Button, Table, Select, DatePicker, message, Input, Modal, Radio } from 'antd';
import { getHalfyear, getTimestampToTime, moneyComma } from '../../../../../../api/index';
import { Option } from '../../../../../../axios/axios-types';
import { PlusOutlined } from '@ant-design/icons';
import { baseURL } from "../../../../../../axios/baseUrl";
import exportSCV from '../../../../../../api/excal';
import axios from '../../../../../../axios/axios';
import moment from 'moment';
import styles from './bill.less';
const { Column } = Table;
const { RangePicker } = DatePicker;

namespace Antd {
    export const { Option } = Select;
}

interface List {
    bUId: string;
    billAmount: number | string;
    billExpireTs: number;
    billMonth: string;
    billState: number | string;
    billid: string;
    proName: string;
    ts: string;
};

interface Data {
	code: number;
	data: { 
        list: Array<List>;
        totalNumber: number;
    };
};

interface Result {
	data: Data;
};

interface WaitResult {
	data: {
        code: number;
        data: Array<List>
    };
};


const tHeader: Array<string> = ["发布日期", "账期", "截止日期", "金额", "支付状态"];
const filterVal: Array<string> = [
        "ts",
        "billMonth",
        "billExpireTs",
        "billAmount",
        "billState"
    ];
const fileName = 'bill-reconciliation';

const Bill: FC = (): ReactElement => {
    const location: any = useLocation();
    
	const [tableData, setTableData]: [Array<List>, Function] = useState([]);
	const [waitSendData, setWaitSendData]: [Array<List>, Function] = useState([]);
	const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);
	const [billVisible, setBillVisibleg]: [boolean, Function] = useState(false);
	const [isAdd, setIsAdd]: [boolean, Function] = useState(false);
	const [billDeleteVisible, setBillDeleteVisibleg]: [boolean, Function] = useState(false);
	const [billTitle, setBillTitle]: [string, Function] = useState('');
	const [describe, setDescribe]: [string, Function] = useState('');
	const [billid, setBillid]: [string, Function] = useState('');
	const [price, setPrice]: [string, Function] = useState('');
	const [waitTableLoading, setWaitTableLoading]: [boolean, Function] = useState(false);
	const [selectValue, setSelectValue]: [number, Function] = useState(2);
    const [rangeValue, setRangeValue]: [any, Function] = useState([]);
	const [dates, setDates]: [Array<any>, Function] = useState([]);
    const [current, setCurrent]: [number, Function] = useState(1);
	const [pageSize, setPageSize]: [number, Function] = useState(10);
	const [total, setTotal]: [number, Function] = useState(0);
	const [startTime, setStartTime]: [number, Function] = useState(getHalfyear());
	const [stopTime, setStopTime]: [number, Function] = useState(parseInt((new Date().getTime() / 1000).toString()));

    useEffect((): void => {
		const getDevpBillList: Function = async (): Promise<void> => {
			setTableLoading(true);
			const option: Option = {
				url: '/developers/getDevpBillList',
				method: 'POST',
				data: {
                    bType: selectValue,
                    startTime,
                    stopTime,
					pageNum: current,
					pageSize,
                    uid: location.state.uid
				}
			};
			const result: Result = await axios(option);
			if (result.data.code === 0) {
                result.data.data.list.forEach((item: List): void => {
                    item.ts = getTimestampToTime(item.ts, 'yyyy-MM-DD');
                    item.billExpireTs = getTimestampToTime(item.billExpireTs, 'yyyy-MM-DD');
                    item.billAmount = moneyComma(item.billAmount);
                    item.billState = item.billState? '已扣费' : '未扣费';
                });
                setTotal(result.data.data.totalNumber);
                setTableData(result.data.data.list);
            } else {
                message.error('数据获取失败');
            }
			setTableLoading(false);
		};
		getDevpBillList();
        getUserList();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize, current, selectValue, startTime, stopTime, location.state.uid]);

    const getUserList: Function = async (): Promise<void> => {
        setWaitTableLoading(true);
        const option: Option = {
            url: '/developers/getWaitSendBillList',
            data: {
                bType: 4,
                uid: location.state.uid
            },
            method: 'POST'
        };
        const result: WaitResult = await axios(option);
        if (result.data.code === 0) {
            result.data.data.forEach((item: List): void => {
                item.billAmount = moneyComma(item.billAmount);
                item.billState = item.billState? '已扣费' : '未扣费';
                item.billExpireTs = getTimestampToTime(item.billExpireTs, 'yyyy-MM-DD');
                item.ts = getTimestampToTime(item.ts, 'yyyy-MM-DD');
            });
            setWaitSendData(result.data.data);
        }
        setWaitTableLoading(false);
    };

    const disabledDate: Function = (current: any): boolean => {
		if (!dates || dates.length === 0) {
			return current > moment().endOf('day');
		}
		// 只能选择当前日期之前, 并且日期范围不超过90天
		const tooLate = dates[0] && current.diff(dates[0], 'days') > 90;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') > 90;
		return tooEarly || tooLate || current > moment().endOf('day');
	};

    const handleRangeChange: Function = (dates: Array<any>, dateStrings: Array<string>): void => {
		setRangeValue(dates);
		if (dateStrings[0] && dateStrings[1]) {
			setStartTime(parseInt((new Date(new Date(dateStrings[0]).toLocaleDateString()).getTime() / 1000).toString()));
			setStopTime(parseInt(((new Date(new Date(dateStrings[1]).toLocaleDateString()).getTime() / 1000)  + (60 * 60 * 24 - 1)).toString()));
		} else {
            setStartTime(getHalfyear());
			setStopTime(parseInt((new Date().getTime() / 1000).toString()));
        }
	};

    const handlePagChange: Function = (pagination: any, filters: any, sorter: any): void => {
		setCurrent(pagination.current);
		setPageSize(pagination.pageSize);
	};
    
    const handleExportSCV: Function = (): void => {
        exportSCV(tHeader, filterVal, tableData, fileName);
    };

    const handlePdfFn: Function = (row: List): void => {
        const { billid } = row;
        const option: Option = {
            url: `${baseURL.replace('/v1', '/v3')}/developers/exportBillDetailInfo/${billid}`,
            method: 'GET',
            responseType: 'arraybuffer'
        };
        axios(option).then(res => {
            const fileName: string = `账单.pdf`;
            const url: string = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf;charset=utf-8" }));
            const a = document.createElement("a");
            a.download = fileName;
            a.href = url;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    };

    const handleDownload: Function =  async (row: List): Promise<void> => {
        if (selectValue === 2) {
            const { billid } = row;
            const option: Option = {
                url: '/developers/getBillDayDetailInfo',
                data: { billId: billid, devpId: location.state.uid },
                method: 'POST',
            }; 
            const result = await axios(option);
            if (result.data.code !== 0) {
                message.error("账单详情下载失败");
                return;
            }
            const tHeader: Array<string> = [
                "项目ID",
                "日期",
                "音频",
                "高清视频",
                "超高清视频",
                "实时消息",
                "2K消费分钟数",
                "2K+消费分钟数",
                "实时对讲",
            ];
            const filterVal: Array<string> = [
                "appId",
                "day",
                "audMin",
                "hdMin",
                "hhdMin",
                "rtmNum",
                "tkMin",
                "tkpMin",
                "talkAudMin"
            ];
            if (result.data.data.isMiniAppOn) {
                tHeader.push("小程序音频");
                tHeader.push("小程序视频");
                filterVal.push("miniAppAudMin");
                filterVal.push("miniAppVidMin");
            };
            if (result.data.data.isVodOn) {
                tHeader.push("云端录制音频");
                tHeader.push("云端录制高清视频");
                tHeader.push("云端录制超高清视频");
                tHeader.push("云端录制2K视频消费分钟数");
                tHeader.push("云端录制2k+视频消费分钟数");
    
                filterVal.push("audRecMin");
                filterVal.push("hdRecMin");
                filterVal.push("hhdRecMin");
                filterVal.push(" tkRecMin");
                filterVal.push("tkpRecMin");
            };
            if (result.data.data.isPushOn) {
                tHeader.push("音频旁路推流");
                tHeader.push("高清视频旁路推流");
                tHeader.push("超高清视频旁路推流");
                tHeader.push("2k旁路推流视频消费分钟数");
                tHeader.push("2k+旁路推流视频消费分钟数");
    
                filterVal.push("audPushMin");
                filterVal.push("hdPushMin");
                filterVal.push("hhdPushMin");
                filterVal.push("tkPushMin");
                filterVal.push("tkpPushMin");
            };
            exportSCV(tHeader, filterVal, result.data.data.data, fileName);
        }
    };

    const handleBillOk: Function = async (): Promise<void> => {
        const reg: RegExp = /((^[1-9]\d*)|^0)(\.\d{1,3}){0,1}$/;
        if (!reg.test(price)) {
            message.error("金额不正确，请重新输入");
            return;
        }
        const money: number = parseFloat(price);
        if (money <= 0 || isNaN(money)) {
            message.error("数量不能小于1");
        } else if (describe.replace(/\s*/g, "") === '') {
            message.error("请输入产品说明");
        } else {
            isAdd? addBill(money) : modleBill(money);
        }
    };

    const modleBill: Function = async (money: number): Promise<void> => {
        const option: Option = {
            url: '/developers/updateBill',
            data: {
                amount: money * 1000,
                proName: describe,
                billid
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success('账单修改成功');
            handleBillCancel();
            getUserList();
        } else {
            message.error('账单修改失败');
        }
    };

    const addBill: Function = async (money: number): Promise<void> => {
        const option: Option = {
            url: '/developers/devpInsertBill',
            data: {
                amount: money * 1000,
                proName: describe,
                type: 4,
                uid: location.state.uid
            },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            message.success('账单添加成功');
            handleBillCancel();
            getUserList();
        } else {
            message.error('账单添加失败');
        }
    };

    const handleBillCancel: Function = (): void => {
        setBillVisibleg(false);
        setIsAdd(false);
    };

    const handleModify: Function = (row: List): void => {
        setBillVisibleg(true);
        setBillid(row.billid);
        setBillTitle('修改账单');
        setPrice((row.billAmount as string).split("￥").join("").split(",").join("").split(".").join(""));
        setDescribe(row.proName);
    };

    const handleDelete: Function  = (row: List): void => {
        setBillDeleteVisibleg(true);
        setBillid(row.billid);
    };

    const handleBillDeleteOk: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/developers/deleteBill',
            data: { billid },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            getUserList();
            message.success("账单删除成功");
            setBillDeleteVisibleg(false);
        } else {
            message.error("账单删除失败");
        }
    };

    const handleAddBill: Function = (): void => {
        setIsAdd(true);
        setBillVisibleg(true);
        setBillTitle('添加账单');
        setPrice('');
        setDescribe('');
    };
    
    return (
        <div className={ styles.bill }>
            { findPermissions(11432) && <div className={ styles.addBill }>
                <Button type="primary" disabled={ waitSendData.length > 3 } onClick={ () => handleAddBill() } icon={<PlusOutlined />}>添加账单</Button>
            </div> }
            <p className={ styles.title }>待发送账单</p>
            <Table
				loading={ waitTableLoading } 
				className={ styles.table }
				pagination={ false }
				rowKey='billid'
				sticky={ true }
				dataSource={ waitSendData }>
				<Column align='center' title="创建日期"  dataIndex="ts" />
				<Column align='center' title="账期" dataIndex="billMonth" />
				<Column align='center' title="截止日期" dataIndex="billExpireTs" />
				<Column align='center' title="金额" dataIndex="billAmount" />
				<Column align='center' title="产品概述" dataIndex="proName" />
				<Column align='center' title="操作" render={ (operation: any, record: any): ReactElement => (
                    <div className={ styles.operationBox }>
                        { findPermissions(11433) && <p onClick={ () => handleModify(record) } className={ styles.operation }>修改</p> }
                        { findPermissions(11434) && <p onClick={ () => handleDelete(record) } className={ styles.operation }>删除</p> }
                        { findPermissions(11435) && <p onClick={ () => handlePdfFn(record) } className={ styles.operation }>下载</p> }
                    </div>
				) } />
			</Table>
            <div className={ styles.history }>
                <p className={ styles.title }>历史账单</p>
                <div className={ styles.search }>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        value={ selectValue }
                        placeholder="Select a the"
                        onChange={ (value) => setSelectValue(value) }
                    >
                        <Antd.Option value={ 2 }>月账单</Antd.Option>
                        <Antd.Option value={ 4 }>一次性账单</Antd.Option>
                    </Select>
                    <div>
                        <RangePicker
                        value={ rangeValue }
                        className={ styles.item }
                        disabledDate={ (current) => disabledDate(current) }
                        onChange={ (dates, dateStrings) => handleRangeChange(dates, dateStrings) }
                        onCalendarChange={ val => setDates(val) }
                        />
                        { findPermissions(11436) && <Button className={ styles.scv } type="primary" onClick={ () => handleExportSCV() }>Export SCV</Button> }
                    </div>
                </div>
                <Table
                    onChange={ (pagination, filters, sorter) => handlePagChange(pagination, filters, sorter) }
                    loading={ tableLoading } 
                    className={ styles.table }
                    pagination={{
                        defaultCurrent: 1,
                        current,
                        showSizeChanger: true,
                        pageSize,
                        total
                    }}
                    rowKey='billid'
                    sticky={ true }
                    dataSource={ tableData }>
                    <Column align='center' title="发布日期" dataIndex="ts" />
                    <Column align='center' title="账期" dataIndex="billMonth" />
                    <Column align='center' title="截止日期" dataIndex="billExpireTs" />
                    { selectValue === 2 && <Column align='center' title="产品名称" dataIndex="proName" /> }
                    <Column align='center' title="金额" dataIndex="billAmount" />
                    <Column align='center' title="支付状态" render={ (operation: any, record: List): ReactElement => (
                        <span className={ record.billState? styles.deduction : styles.noDeduction }>{ record.billState }</span>
                    ) } />
                    <Column align='center' title="操作" render={ (operation: any, record: List): ReactElement => (
                        <div>
                            { (selectValue === 4 && findPermissions(11437)) && <p onClick={ () => handlePdfFn(record) } className={ styles.operation }>download</p> }
                            { selectValue === 2 && <div className={ styles.operationBox }>
                            { findPermissions(11437) && <p onClick={ () => handlePdfFn(record) } className={ styles.operation }>下载账单</p> }
                            { findPermissions(11438) && <p onClick={ () => handleDownload(record) } className={ styles.operation }>下载用量详情</p> }
                            </div> }
                        </div>
                    ) } />
                </Table>
            </div>

            <Modal title={ billTitle } width={ 600 } getContainer={ false } centered visible={ billVisible } onOk={ () => handleBillOk() } onCancel={ () => handleBillCancel() }>
                { isAdd && <div className={ styles.line }>
                    <span className={ styles.span }>账单类型：</span>
                    <Radio.Group value={ 2 }>
                        <Radio disabled={ true } value={ 1 }>月账单</Radio>
                        <Radio value={ 2 }>一次性账单</Radio>
                    </Radio.Group>
                </div> }
                <div className={ styles.line }>
                    <span className={ styles.span }>产品描述：</span>
                    <Input className={ styles.input } value={ describe } onChange={ (e) => setDescribe(e.target.value) } placeholder="请输入产品描述" />
                </div>
                <div className={ styles.line }>
                    <span className={ styles.span }>产品价格：</span>
                    <Input className={ styles.input } value={ price } onChange={ (e) => setPrice(e.target.value) } placeholder="请输入产品价格" />
                </div>
            </Modal>

            <Modal title='删除帐单' width={ 600 } getContainer={ false } centered visible={ billDeleteVisible } onOk={ () => handleBillDeleteOk() } onCancel={ () => setBillDeleteVisibleg(false) }>
                <p>您确定要删除该账单吗?</p>
            </Modal>
        </div>
    );
};

export default Bill;