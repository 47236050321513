import { FC, ReactElement, useState, useEffect, useRef } from 'react';
import { Table } from 'antd';
import { getTimestampToTime } from '../../../../api/index';
import { createFromIconfontCN } from '@ant-design/icons';
import { Option } from '../../../../axios/axios-types';
import { useLocation } from 'react-router-dom';
import { scriptUrl } from '../../../../axios/baseUrl';
import axios from '../../../../axios/axios';
import styles from './problemSeeDetails.less';
const { Column } = Table;

const IconFont = createFromIconfontCN<string>({
	scriptUrl: scriptUrl,
});

interface Result {
	data: {
        code: number;
        data: any;
    };
};
const ProblemSeeDetails: FC = (): ReactElement => {
    const location: any = useLocation();
    const [myMethod, setMyMethod]: [any[], Function] = useState([]);
    const [myCallback, setMyCallback]: [any[], Function] = useState([]);
    const [loading, setLoading]: [boolean, Function] = useState(false);
    const myRef = useRef<any>([]);

    useEffect((): void => {
        init();
        callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init: Function =  async (): Promise<void> => {
        const option: Option = {
            url: '/project/getVodApiInfo',
            data: { reSId: location.state.reSId },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            result.data.data.forEach((item: any): void => {
                item.isOpen = false;
                item.index = 1;
                item.thumbnailUrl = '../' + item.apiUrl.split('/')[item.apiUrl.split('/').length - 1];
            });
            setMyMethod(result.data.data);
        };
    };

    const callback: Function =  async (): Promise<void> => {
        const option: Option = {
            url: '/project/getCbTypeList',
            data: { reSId: location.state.reSId },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            result.data.data.forEach((item: any): void => {
                item.loading = false;
                item.isOpen = false;
                item.index = 1;
                item.list = null;
            });
            setMyCallback(result.data.data);
        };
    };

    const changeOpen: Function = (index: number): void => {
        const myMetho: any[] = JSON.parse(JSON.stringify(myMethod));
        myMetho[index].isOpen = !myMetho[index].isOpen;
        setMyMethod(myMetho);
    };
      
    const changeIndex: Function = (index: number, num: number): void => {
        const myMetho = JSON.parse(JSON.stringify(myMethod));
        myMetho[index].index = num;
        setMyMethod(myMetho);
    };

    const changeCallbackOpen: Function = async (bool: boolean, index: number, eventType: any, reSId: any): Promise<void> => {
        if (loading) return;
        setLoading(true);
        const myCallbac = JSON.parse(JSON.stringify(myCallback));
        if (!myCallbac[index].isOpen && !myCallbac[index].list) {
            myCallbac[index].loading = true;
            setMyCallback(myCallbac);
            const option: Option = {
                url: '/project/getCbEventList',
                data: { eventType, reSId },
                method: 'POST'
            };
            const result: Result = await axios(option);
            if (result.data.code === 0 || result.data.code === 3002) {
                myCallbac[index].cbBody = result.data.data[result.data.data.length - 1]
                ? result.data.data[result.data.data.length - 1].cbBody
                : "";
                if (Array.isArray(result.data.data)) {
                    result.data.data.forEach((item: any): void => {
                        item.cbTs = getTimestampToTime(item.cbTs);
                    });
                }
                myCallbac[index].list = result.data.data;
            };
        }
        let height: any = null;
        if (myCallbac[index].isOpen) {
            height = 44;
        } else {
            if (myCallbac[index].list.length) {
                height = myCallbac[index].list.length * 73 + 248;
            } else {
                height = 318;
            }
        }
        myRef.current[index].style.height = height + "px";
        if (bool) {
            myCallbac[index].isOpen = bool;
        } else {
            myCallbac[index].isOpen = !myCallbac[index].isOpen;
        }
        myCallbac[index].loading = false;
        setMyCallback(JSON.parse(JSON.stringify(myCallbac)));
        setLoading(false);
    };

    return (
        <div className={ styles.problemSeeDetails }>
            <Table
				className={ styles.table }
				pagination={ false }
				rowKey={() => `${Math.floor(Math.random() * 123333)}`}
				sticky={ true }
				dataSource={ location.state.listData }>
				<Column align='center' title="项目名称"  dataIndex="appName" />
				<Column align='center' title="频道名称"  dataIndex="chanName" />
				<Column align='center' title="用户数"  dataIndex="chanUNum" />
				<Column align='center' title="频道时间" render={ (email: any, record: any): ReactElement => (
					<div className={ styles.render }>
						<p>{ record.chanSTs }</p>
                        <span className={ styles.span }>-</span>
						{ record.spamStyle && <p>{ record.chanCTs }</p> }
                        { record.spamStyle || <span className={ styles.ongoing }>进行中</span> }
					</div>
				) } />
                <Column align='center' title="时长" dataIndex="timerLength" />
			</Table>
            <div className={ styles.content }>
                <p className={ styles.title }>Function</p>
                {
                    myMethod.map((item: any, index: number): ReactElement => {
                        return (<div className={ [styles.methods, (item.apiCode !== 0 && item.apiCode !== 200)? styles.error : '', item.isOpen? '' : styles.openMethods].join(' ') } key={ index }>
                            <div className={ styles.contentTitle } onClick={ () => changeOpen(index) }>
                                <div className={ styles.cTop }>
                                    <div className={ styles.type }>{ item.apiMethod }</div>
                                    <div className={ styles.address }>{ item.thumbnailUrl }</div>
                                    <div className={ styles.name }>{ item.apiDesc }</div>
                                </div>
                                <div className={ [styles.i, item.isOpen? styles.up : ''].join(' ') }><IconFont type="icon-arrow" /></div>
                            </div>
                            <div className={ styles.url }>{ item.apiUrl }</div>
                            <div>
                                <div className={ styles.option }>
                                    <p className={ item.index === 1? styles.show : ''  } 
                                    onClick={ () => changeIndex(index, 1) }>Response</p>
                                    <p className={ item.index === 2? styles.show : ''  }
                                    onClick={ () => changeIndex(index, 2) }>Header</p>
                                    <p className={ item.index === 3? styles.show : ''  }
                                    onClick={ () => changeIndex(index, 3) }>Body参数</p>
                                </div>
                                <div className={ styles.line }>
                                    <p className={[item.index === 1? styles.t1 : '',
                                        item.index === 2? styles.t2 : '',
                                        item.index === 3? styles.t3 : ''].join(' ')}
                                    ></p>
                                </div>
                                <div className={ styles.result }>
                                    { item.index === 1 && <p>{ item.apiResponse }</p> }
                                    { item.index === 2 && <p>{ item.apiHeader }</p> }
                                    { item.index === 3 && <p>{ item.apiParams }</p> }
                                </div>
                            </div>
                        </div>)
                    })
                }
                <div className={ styles.content }>
                    <p className={ styles.title }>CallBack</p>
                    {
                        myCallback.map((item: any, index: number): ReactElement => {
                            return (<div className={ [styles.callback, item.cbCode !== 0? styles.error : '' ].join(' ') }
                            ref={ el => (myRef.current[index] = el) } 
                            key={ index }>
                                <div className={ styles.contentTitle } onClick={ () => changeCallbackOpen(null, index, item.eventType, item.resId) }>
                                    <div className={ styles.cTop }>
                                        <div className={ styles.type }>POST</div>
                                        <div className={ styles.address }>{ item.eventType }: { item.eventMsg }</div>
                                        <div className={ styles.name }>{ item.eventDesc }</div>
                                    </div>
                                    <div className={ [styles.i, item.isOpen? styles.up : ''].join(' ') }>
                                        { item.loading && <IconFont className={ styles.loading } type="icon-jiazai" /> }
                                        { item.loading || <IconFont type="icon-arrow" /> }
                                    </div>
                                </div>
                                { item.cbBody && <div className={ styles.result }>
                                    <p>{ item.cbBody }</p>
                                </div> }
                                <div className={ styles.url }>Callback event</div>
                                { item.list && <div>
                                    {
                                        item.list.map((callback: any, callindex: number): ReactElement => {
                                            return (<div key={ callindex } className={ styles.cblist }>
                                                <div className={ styles.item }>
                                                    <div className={ styles.top }>
                                                        <div className={ styles.myurl }>{ callback.cbUrl }</div>
                                                        <div>{ callback.cbTs }</div>
                                                    </div>
                                                    <div>{ callback.cbRes }</div>
                                                </div>
                                            </div>)
                                        })
                                    }
                                </div> }
                                { item.list && item.list.length === 0 && <div className={ styles.cblist }>无回调地址</div> }
                            </div>)
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default ProblemSeeDetails;